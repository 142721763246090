import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../context/AuthContext';
import NumberFormat from 'react-number-format';
import { useHistory, useParams, Link } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import useObtenerContacto from '../../hooks/useObtenerContacto';
import useTransxDest from '../../hooks/useTransxDest';
import DocId from '../../../img/icons/documentid.svg';
import '../transferencias/transferencia.css';
import BorrarDestino from './components/Borrar';

const Destinatario = ({OpenModalNuevo,CerrarModal}) =>{

    const history = useHistory();
    const {usuario, infoUser} = useAuth();
    const {id} = useParams();
    const [contacto, load] = useObtenerContacto(id);
    const [transferencias, loading] = useTransxDest(id);

    //ADMINISTRADOR
    const [rol, setRol] = useState(false);
    const [contactos, setContactos] = useState();

    useEffect(()=>{
        setContactos(infoUser.contactos)
        if(infoUser.rol === 'Administrador'){setRol(true);}
    },[infoUser])

    //DESTINATARIO
    const [tipo, setTipo] = useState('Persona');
    const [nombre, setNombre] = useState('');
    const [fechaCreacion, setFecha] = useState('');
    const [sNombre, setSNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [sApellido, setSApellido] = useState('');
    const [tipoId, setTipoId] = useState('');
    const [noId, setNoId] = useState('');
    const [documento, setDocumento] = useState('');
    const [email, setEmail] = useState('');
    const [celular, setCelular]  = useState('');
    const [pais, setPais] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [address, setAddress] = useState('');

    const [titular, setTitular] = useState('');
    const [nombreBanco, setNombreBanco] = useState('');
    const [numeroBSB, setNumeroBSB] = useState('');
    const [numerodeCuenta, setNumerodeCuenta] = useState('');

    // FUNCIONES
    const formatearFecha = (fecha) => {return format(fromUnixTime(fecha), "dd 'de' MMMM 'de' yyyy", { locale: es });}

    useEffect(() => {
        if(contacto){
            if(contacto.data().usuario === usuario.uid || rol){
                if(contacto.data().tipo){
                    setTipo(contacto.data().tipo)
                }
                setNombre(contacto.data().nombre.nombre)
                setSNombre(contacto.data().nombre.segNombre)
                setApellido(contacto.data().nombre.apellido)
                setSApellido(contacto.data().nombre.segApellido)
                setTipoId(contacto.data().documento.tipoDocumento)
                setNoId(contacto.data().documento.numeroDocumento)
                setDocumento(contacto.data().documento.urlDocumento)
                setEmail(contacto.data().email)
                setCelular(contacto.data().celular)
                setPais(contacto.data().direccion.pais)
                setCiudad(contacto.data().direccion.ciudad)
                setAddress(contacto.data().direccion.direccion)
                setFecha(formatearFecha(contacto.data().fecha))
                setTitular(contacto.data().cuenta.titular)
                setNombreBanco(contacto.data().cuenta.banco)
                setNumeroBSB(contacto.data().cuenta.bsb)
                setNumerodeCuenta(contacto.data().cuenta.numeroCuenta)
            } else{
               history.push('/destinatarios');
            }
        }
    }, [contacto, usuario, history, rol])

    const AbrirDelete = () =>{OpenModalNuevo(<BorrarDestino OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} id={id} contactos={contactos} />)}

    return(
        <>
            <Helmet><title>Destinatario</title></Helmet>
            <div className="TituloPag"><p>Destinatario</p></div>

            {load ? <div className="loadingContainer pageLoader"><div className="lds-ripple"><div></div><div></div></div></div> :
            <div className="row m-0">
                <div className="col-12 col-md-7 col-lg-8 p-0">
                    <div className="CardBody">
                        <h3>Información</h3>
                        <div className="row p-0 DatosTrans">
                            <div className="col-6 col-sm-4"><p><label>Tipo</label>{tipo}</p></div>
                            <div className={tipo === 'Persona' ? 'col-6 col-sm-4' : 'col-6'}><p><label>Nombre</label>{nombre+ ' ' + sNombre}</p></div>
                            {tipo === 'Persona' && <div className="col-6 col-sm-4"><p><label>Apellido</label>{apellido+ ' ' +sApellido}</p></div>}
                            <div className="col-6 col-sm-4"><p><label>Tipo de Id</label>{tipoId}</p></div>
                            <div className="col-6 col-sm-4"><p><label>Número de Id</label>{noId}</p></div>
                            <div className="col-6 col-sm-4"><p><label>Celular</label>{celular}</p></div>
                            <div className="col-6 col-sm-4"><p><label>País</label>{pais}</p></div>
                            <div className="col-12 col-sm-8"><p><label>Email</label>{email}</p></div>
                            {ciudad && <div className="col-6 col-sm-4"><p><label>Ciudad</label>{ciudad}</p></div>}
                            <div className="col-12"><p><label>Dirección</label>{address}</p></div>
                        </div>
                    </div>

                    <div className="CardBody">
                        <h3>Cuenta</h3>
                        <div className="row p-0 DatosTrans">
                            <div className="col-6 col-sm-4"><p><label>Titular</label>{titular}</p></div>
                            <div className="col-6 col-sm-4"><p><label>Nombre del Banco</label>{nombreBanco}</p></div>
                            <div className="col-6 col-sm-4"><p><label>Número BSB</label>{numeroBSB}</p></div>
                            <div className="col-6 col-sm-4"><p><label>No. de Cuenta</label>{numerodeCuenta}</p></div>
                        </div>
                    </div>

                    <div className="CardBody">
                        <h3>Documentos</h3>
                        <div className="TransDocuments">
                            <a href={documento} target="blank_">Id Destinatario<br/><img src={DocId} alt="Documento" /></a>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5 col-lg-4 p-0">
                    <div className="CardBody">
                        <h3>Registros</h3>
                        <div className="row p-0 DatosTrans">
                            <div className="col-12"><p><label>Fecha de Creación</label>{fechaCreacion}</p></div>
                        </div>
                    </div>
                    <div className="CardBody">
                        <h3>Transferencias</h3>
                        {loading ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
                        : <>
                        {transferencias.length > 0 ? 
                            <>{transferencias.map((transferencia)=>{
                                let claseEstado = '';
								if(transferencia.estado === 'Registrada'){
									claseEstado = ('Registrada')
								}
								if(transferencia.estado === 'En Revisión'){
									claseEstado = ('Revision')
								}
								if(transferencia.estado === 'En Proceso'){
									claseEstado = ('Proceso')
								}
								if(transferencia.estado === 'Procesada'){
									claseEstado = ('Procesada')
								}
								if(transferencia.estado === 'Cancelada'){
									claseEstado = ('Cancelada')
								}
                                return(
                                    <Link to={`/transferencia/${transferencia.id}`} key={transferencia.id}>
                                        <div className="LinkTransfer">
                                            <div><label>Ref.</label>{transferencia.id.substring(6,0).toUpperCase()}</div>
                                            <div><label>Recibe</label><NumberFormat value={transferencia.transferencia.montoAud} displayType={'text'} thousandSeparator={','} 
                                                decimalSeparator={'.'} prefix={'$'} suffix={' AUD'} decimalScale={'2'}/></div>
                                            <div><label>Estado</label><span className={claseEstado}>&#9679;</span>{transferencia.estado}</div>
                                        </div>
                                    </Link>
                                )
                            })} </>:<div className="itemTransferEmpty">
                                    <p>Aún no tiene transferencias</p>
                                </div>
                        }
                        </>
                        }
                    </div>
                    <div className="CardBody">
                        <h3>Eliminar Destinatario</h3>
                        <button className="boton-general BtnLarge" onClick={AbrirDelete}>Eliminar Destinatario</button>
                    </div>
                </div>
            </div>}
        </>
    )

}

export default Destinatario;