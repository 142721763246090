import { db } from '../../firebase/firebaseConfig';

const AgregarComprobante = (props) => {
    const{
        id,
        estado,
        comprobantePago
    } = props;
    return db.collection('transferencias').doc(id).update({
        estado: estado,
        comprobantePago:comprobantePago
    });
}

export { AgregarComprobante };