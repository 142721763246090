import React,{ useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import './UserProfile.css';
import imgFotodePerfil from '../../../img/perfil-default.jpg';
import imgopciones from '../../../img/icons/chevron-down.svg';
import imgSalir from '../../../img/icons/log-out.svg';
import ListaAdmin from './data/MenuApp.json';
import ListaUser from './data/MenuUSer.json';
import DivLoading from '../loading/Loading';

const UserProfile = ({nombre, apellido, rol, photo}) => {

    const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

    const {cerrarSesion, cargando } = useAuth();

    const[perfil, setPerfil] = useState(imgFotodePerfil);

    useEffect(()=>{if(photo){setPerfil(photo);}},[photo])

    //OPCINES DE MENU
    const AbrirMenuProfile = () => {setOpciones(!opciones)}
    const [opciones, setOpciones] = useState(false);
    const LeaveMenu = (e) =>{setOpciones(false)};

    const RenderMenu = (menu) =>{
        return(
            menu.length > 0 && 
                menu.map((link) => {
                    if(link.id === 'nueva-transferencia'){
                        return null;
                    }else{
                        return(
                            <Link to={('/'+link.link)} key={link.id} className={splitLocation[1] === link.id || splitLocation[2] === link.id ? "dropLiA" : ""}>
                                <img src={link.img} alt={link.texto} />{link.texto}</Link>
                        )
                    }
                })
        )
    }

    return (
    <>
        {!cargando ?
        <>
            <div className="user-profile" onMouseLeave={LeaveMenu}>
                <div className="user-profile-div">
                    <div className="photoName">
                        <div className="user-profile__photo">
                            <img src={perfil === null ? imgFotodePerfil : perfil} alt="foto de perfil" />
                        </div>
                        <div className="user-profile-name">
                            <p>{nombre + ' '+ apellido}</p>
                            <span>{rol}</span>
                        </div>
                    </div>
                    <button className="boton-icono" onClick={AbrirMenuProfile}><img src={imgopciones} alt="opciones" /></button>
                </div>
                
                <div className={!opciones ? "dropdown-perfil" : "dropdown-perfil dropdown-active"} onClick={AbrirMenuProfile}>
                    {rol === "Administrador" ? <>{RenderMenu(ListaAdmin)}</> : <>{RenderMenu(ListaUser)}</>}
                    <button onClick={cerrarSesion}><img src={imgSalir} alt="Salir" />Salir</button>
                </div>
            </div>
        </>:
        <DivLoading />
        }
    </>
       
        
    )
}

export default UserProfile;

