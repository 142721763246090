import React, { useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import './menu.css';
import Arrow from '../../../img/icons/arrow-down.svg';
import ListaMenuAdmin from './data/MenuAdmin.json';
import ListaMenuUser from './data/MenuUSer.json';
import MenuMobile from './data/MenuMobile.json';
import MenuApp from './data/MenuApp.json';

const Menu = () => {

    const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

    const{ infoUser } = useAuth();
    const [admin, setAdmin] = useState();

    useEffect(() => {
        if(infoUser){
            if(infoUser.rol === 'Administrador'){
                setAdmin(true)
            }
        }
    }, [infoUser])

    const renderMenus = (menu) =>{
        return(
            menu.length > 0 &&
                menu.map((link) => {
                    if(link.link){
                        return(
                            <Link to={('/'+link.link)} key={link.id} className={splitLocation[1] === link.id ? "liActivo" : ""}>
                                <img src={link.img} alt={link.texto} />{link.texto}</Link>
                        )
                    }else{
                        return(
                            <li key={link.id} className={splitLocation[1] === link.id ? "liActivo" : ""}><img src={link.img} alt={link.texto} />{link.texto}<img src={Arrow} alt="Arrow" className='menuArrow' />
                                <ul>
                                    {link.submenu && <>
                                        {link.submenu.map((submenu)=>{
                                            return(
                                                <li key={submenu.id} className={splitLocation[2] === submenu.id ? "subActivo" : "liImg"}>
                                                    <Link to={('/'+submenu.link)}><img src={submenu.img} alt={submenu.texto} />{submenu.texto}</Link>
                                                </li>
                                            )
                                        })} 
                                    </>}
                                </ul>
                            </li>
                        )
                    }
                })
        )
    }

    const renderMobile = (menu) =>{
        return(menu.length > 0 &&
            menu.map((link) => {
                if(link.id === 'nueva-transferencia'){
                    return(
                        <Link key={link.id} to={('/'+link.link)} className="mobile-plus"><img src={link.img} alt={link.texto} /></Link>
                    )
                }else{
                    return(
                        <Link key={link.id} to={('/'+link.link)} className={splitLocation[1] === link.id || splitLocation[2] === link.id ? "liActivo" : ""}>
                            <img src={link.img} alt={link.texto} /><span>{link.texto}</span>
                        </Link>
                    )
                }
            })
        )
    }
	
    return(
        <>
            <div className="MenuGeneral">
                <div className="MenuCont">{admin ? <>{renderMenus(ListaMenuAdmin)}</> : <> {renderMenus(ListaMenuUser)} </>}</div>
            </div>
            <div className="dashboard-menu_mobile">
				<nav>{admin ? <>{renderMobile(MenuApp)}</> : <>{renderMobile(MenuMobile)}</>}</nav>
			</div>
        </>
    )
}

export default Menu;