import { db } from '../../firebase/firebaseConfig';

const AgregarTestimonio = (props) => {
    const{
        fecha,
        usuario,
        nombre,
        transfer,
        estado,
        stars,
        comment
    } = props;

    return db.collection('testimonios').add({
        fecha: fecha,
        usuario: usuario,
        nombre: nombre,
        transfer: transfer,
        estado: estado,
        stars: stars,
        comment: comment
    });
}

const CalificarTrans = (props) =>{
    const{
        id,
        calificada
    }= props;
    return db.collection('transferencias').doc(id).update({
        calificada: calificada,
    });
}

export { AgregarTestimonio, CalificarTrans};