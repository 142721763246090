import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route} from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ModalNuevo from './components/modales/ModalNuevo';
import Menu from './components/header/Menu';
import NewHeader from './components/header/NewHeader';
import HeaderLogin from './components/header/HeaderLogin';
import MenuLateral from './components/header/MenuLateral';
import Footer from './components/footer/Footer';
import InicioDashboard from '../client/InicioDashboard';
import Transferencias from '../client/views/transferencias/Transferencias';
import Transferencia from '../client/views/transferencias/Transferencia';
import Destinatarios from '../client/views/destinatarios/Destinatarios';
import Destinatario from '../client/views/destinatarios/Destinatario';
import Profile from '../client/views/profile/Profile';
import Whatsapp from '../img/icons/whatsapp.svg';

const Dashboard = () => {

    const [cargando, setCargando] = useState(true);
    const {infoUser} = useAuth();

    useEffect(() => {
        if(infoUser){ setCargando(false)}
    }, [infoUser]);

    const [well, setWell] = useState(true);
    const [abrirMenu, setAbrirMenu] = useState(false);

    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [componente, cambiarComponente] = useState('');
    const [modal, cambiarModal] = useState('modal-general');
    const [clases, cambiarClases] = useState('fonder');

    const OpenModalNuevo = (componenterecibido) => {
        cambiarEstadoModal(true);
        cambiarComponente(componenterecibido);
        setTimeout(() => {
            cambiarModal('modal-general modal-activo');
            cambiarClases('fonder fonder-activo');
        }, 400);
    }
    const CerrarModal = (e) => {
        e.preventDefault();
        cambiarClases('fonder')
        cambiarModal('modal-general')
        setTimeout(() => {
            cambiarEstadoModal(false)
        }, 600);
    }

    return (
        <> 
            {!cargando &&<>
                <Helmet><title>Dashboard</title></Helmet>
                <a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className="whatsapp">
                    <img src={Whatsapp} alt="Whatsapp" /></a>
                <div className="MenuDash">
                    <div className="MenuDashCont">
                        <NewHeader />
                        <HeaderLogin well={well} setWell={setWell} setAbrirMenu={setAbrirMenu} />
                        <MenuLateral setWell={setWell} setAbrirMenu={setAbrirMenu} abrirMenu={abrirMenu}/>
                    </div>
                </div>
                <Menu />
                <div className="ContDash">
                    <div className="ContentDash">
                        <Switch>
                            <Route path="/dashboard"><InicioDashboard OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/></Route> 
                            <Route path="/transferencias"><Transferencias /></Route>
                            <Route path="/transferencia/:id"><Transferencia OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/></Route>
                            <Route path="/destinatarios"><Destinatarios/></Route>
                            <Route path="/destinatario/:id"><Destinatario  OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} /></Route>
                            <Route path="/perfil"><Profile OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/></Route>
                        </Switch>
                    </div>
                    <Footer/>
                </div>
                <ModalNuevo
                    modal={modal}
                    CerrarModal={CerrarModal}
                    clases={clases}
                    componente={componente}
                    estadoModal={estadoModal} 
                />
            </>}
        </>
    );
}

export default Dashboard;