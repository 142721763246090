import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';

const useObtenerCuentas = () =>{

    const [cuentas, setCuentas] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const unsuscribe = db.collection('cuentas')
        .onSnapshot((snapshot) => {
            setLoading(false);
            setCuentas(snapshot.docs.map((cuenta) => {
                return {...cuenta.data(), id: cuenta.id}
            }));
        });
        return unsuscribe;
    },[]);

    return [cuentas, loading]

}

const useObtenerCuenta = (id) =>{

    const [load, setLoad] = useState(true);
    const [cuenta, setCuenta] = useState('');

    useEffect (() => {
        if(id){
            const unsuscribe = db.collection('cuentas').doc(id).get()
            .then((doc) => {
                if(doc.exists){
                    setLoad(false);
                    setCuenta(doc);
                } else{
                    setLoad(false);
                    setCuenta('');
                }
            })
            return unsuscribe;
        }
    }, [id]);

    return [cuenta, load];
}

    
export {useObtenerCuentas, useObtenerCuenta};