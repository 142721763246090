import React, {useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import ModalNuevo from '../components/modales/ModalNuevo';
import Ingresar from './Ingresar';
import Registro from './Registro';
import ForgotPass from './ForgotPass'
import './login.css';
import LogoCol from '../../img/logocolcambiosicon.svg';
import ImgFamily from '../../img/family.png';
import ImgFondo from '../../img/fondologin.png';

const LoginUsuarios = () => {

    const { usuario } = useAuth();

    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [componente, cambiarComponente] = useState('');
    const [modal, cambiarModal] = useState('modal-general');
    const [clases, cambiarClases] = useState('fonder');

    const OpenModalNuevo = (componenterecibido) => {
        cambiarEstadoModal(true);
        cambiarComponente(componenterecibido);
        setTimeout(() => {
            cambiarModal('modal-general modal-activo')
            cambiarClases('fonder fonder-activo')
        }, 400);
    }
    const CerrarModal = (e) => {
        e.preventDefault();
        cambiarClases('fonder')
        cambiarModal('modal-general')
        setTimeout(() => {
            cambiarEstadoModal(false)
        }, 600);
    }

    if (usuario) {
        return <Redirect to="/dashboard" />
    } else {
        return (
            <>
                <Helmet><title>Dashboard</title></Helmet>
                <div className="flexContainer">
                    <div className="divAuth">
                        <div className="divPublish">
                            <div className="IconLogin"><Link to="/"><img src={LogoCol} alt="" /></Link></div>
                            <div className="DivMesage">
                                <img src={ImgFamily} alt="family" className="ImgLogin"/>
                                <p>Una manera <span>Fácil</span> y <span>Económica</span> de enviar tu dinero de <b>Colombia</b> a <b>Australia</b></p>
                            </div>
                            <div className="fondoLogin"><img src={ImgFondo} alt="fondo" /></div>
                        </div>
                        <div className="inputLogin">
                            <Switch>
                                <Route path="/registrate"><Registro/></Route>
                                <Route path="/ingresar"><Ingresar /></Route>
                                <Route path="/recuperar-contrasena"><ForgotPass OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/></Route>
                            </Switch>
                        </div>
                    </div>
                </div>
                <ModalNuevo
                    modal={modal}
                    CerrarModal={CerrarModal}
                    clases={clases}
                    componente={componente}
                    estadoModal={estadoModal} 
                />
            </>
        );
    }

}

export default LoginUsuarios;