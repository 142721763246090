import React, {useState, useEffect} from 'react';
import './inputs.css';

const InputSearch = ({opciones, opcion, setOpcion, spnSpan, setSpan,label,alert}) =>{

    const [mostrarOpciones, setMostrarOpciones] = useState(false);
    const [filtro, setFiltro] = useState('');
    const [maps, setMaps] = useState(false);
    
    const handleClick = (e) => {setMostrarOpciones(false); setOpcion(e.currentTarget.dataset.id);}
    const clickInput = (e) =>{e.preventDefault(); setMostrarOpciones(false);}
    const handleChange = (e) =>{setMostrarOpciones(true); setFiltro(e.target.value); setOpcion(e.target.value); setSpan(false)}
    const handOVer = (e) =>{e.preventDefault(); setMostrarOpciones(false);}

    // const Generales = (opciones) =>{
    //     const nuevos = opciones.map((code)=>{
    //         return(
    //             <div key={code.id} className="searched" onClick={handleClick} data-id={code.texto}>
    //                 <span>{code.texto}</span>
    //             </div>
    //         )
    //     })
    //     return nuevos;
    // }

    const Filtradas = (opciones) =>{
        const resultados = opciones.filter(code => {
            return code.texto.toLowerCase().includes(filtro.toLowerCase());
        });

        const nuevos = resultados.map((code)=>{
            return(
                <div key={code.id} className="searched" onClick={handleClick} data-id={code.texto}>
                    <span>{code.texto}</span>
                </div>
            )
        })
        return nuevos;
    }

    useEffect(()=>{
        if(filtro){setMaps(true)}
        else{setMaps(false)}
    },[filtro])
    
    return(
        <div className={!spnSpan ? 'input-search' : 'input-search redLine'} onMouseLeave={handOVer}>
            <input type="text" name="opcion" placeholder=" " value={opcion} onChange={handleChange} onClick={clickInput}
                className={(mostrarOpciones ? 'BorderSelec' : '')+' '+(opcion!=='' ? 'BorderBlue':'' )} autoComplete="off" />
            <label>{label}</label>
            {spnSpan && <span className="spanAlert">{alert}</span>}
            {mostrarOpciones &&
            <div className="searchedDiv">{maps && Filtradas(opciones)}</div>}
        </div>
    );
}

export default InputSearch;