import React, {useState, useEffect} from 'react';
import './InputPhone.css';
import Imgarrow from '../../../img/icons/arrow-down.svg';

const SelectAndInput = ({fieldName, number, setNumber, spnSpan, setSpan, alert, options, setOpcion, opcion }) =>{

    const [showCodes, setShowCodes] = useState(false);
    const handleClick = (e) => {setShowCodes(false); setOpcion(e.currentTarget.dataset.name);}
    const clickInput = (e) => {e.preventDefault(); setShowCodes(false);}
    const clickOpcion = (e) => {e.preventDefault(); setShowCodes(true);setSpan(false);}
    const handleChange = (e) => {setNumber(e.target.value); setSpan(false)}
    const handleLeave = (e) => {e.preventDefault(); setShowCodes(false);}

    useEffect(() => {setSpan(false);}, [opcion, setSpan])

    return(
        <div className="SelectAndInput">
            <div className="SelectInput">
                <input type="text" name="number" placeholder="Número" value={number} onChange={handleChange} onClick={clickInput}
                    className={(showCodes ? 'BorderSelec' : null)+' '+(number!=='' ? 'BorderBlue': '' )+' '+(spnSpan ? 'redWarn' : '')} autoComplete="off"/>
                    <label className={spnSpan ? 'redSpan' : ''}>{fieldName}</label>
                    {spnSpan && <span className="spanAlert">{alert}</span>}
            </div>
            <div className="inputOpcion" onMouseLeave={handleLeave}>
                <div className="inputOpcionDiv" onClick={clickOpcion}>{opcion} <img className={showCodes ? 'inputArrow inputArrowRo' : 'inputArrow'} src={Imgarrow} alt="arrow" /></div>
                <div className={showCodes ? 'selectCodes' : 'selectCodesnull'}>
                    {options.map((item) => 
                        <div key={item.id} className="selectCode" onClick={handleClick} data-name={item.name}>
                            <span>{item.name}</span>
                        </div>
                    )}  
                </div>
            </div>
        </div>
    );
}

export default SelectAndInput;