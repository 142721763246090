import { useEffect, useState } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useHistory } from 'react-router';

const useObtenerContacto = (id) =>{

    const history = useHistory();
    const [contacto, setContacto] = useState('');
    const [load, setLoad] = useState(true);

    useEffect (()=>{
        const unsuscribe = db.collection('contactos').doc(id).get()
        .then((doc)=>{
            if(doc.exists){
                setContacto(doc);
                setLoad(false);
            }else{
                history.push('/dashboard');
            }
        })
        return unsuscribe;
    }, [history,id]);

    return[contacto, load]
}

export default useObtenerContacto;