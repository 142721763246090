import React, {useState} from 'react';
import Show from '../../../img/icons/eye.svg';
import Shown from '../../../img/icons/eye-slash.svg';

const InputPass = (props) =>{

    const {
        name,
        label,
        value,
        setValue,
        alt,
        spn,
        setSpn,
        fill 
    }= props;

    const [show, setShow] = useState(false);
    const handleShow = () =>{setShow(!show);}

    const handleChange = (e) =>{setValue(e.target.value); setSpn(false);}

    return(
        <div className={!spn ? 'input-general': 'input-general redLine'}>
            <input name={name} type={show ? 'text' : 'password'} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
            <label>{label}</label>
            {spn && <span className="spanAlert">{alt}</span>}
            <button className='buttonInput' onClick={handleShow} type="button"><img src={show ? Shown : Show} alt="Mostrar" /></button>
        </div>
    )
}

export default InputPass;