import React, {useState} from "react";
import useObtenerTestimonios from "../../hooks/useObtenerTestimonios";
import { fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import DivLoading from '../../../views/components/loading/Loading';
import ModalValidar from '../../../views/components/modales/MoldaValidar';
import { EliminarTestimonio, PublicarTestimonio } from "../../data/AdminTestimonios";
import { HuboUnError,CalificacionEliminada, CalificacionPublicada } from '../Mensajes';
import ImgCheck from '../../../img/icons/tick-square.svg'; 
import ImgDelete from '../../../img/icons/trash.svg';
import ImgStar from '../../../img/icons/star-1.svg';

const Testimonios = ({OpenModalNuevo,CerrarModal}) =>{

    const [testimonios, loading] = useObtenerTestimonios();
    const dateDistance = (fecha) => formatDistanceToNowStrict(fromUnixTime(fecha),{ locale: es });
    const [id, setId] = useState('');

    const Eliminar = () =>{
        OpenModalNuevo(<DivLoading />)
        EliminarTestimonio({
            id: id,
        }).then(() => {
            setId('')
            OpenModalNuevo(<CalificacionEliminada CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    const Publicar = () =>{
        OpenModalNuevo(<DivLoading />)
        PublicarTestimonio({
            id: id,
            estado: 'publicado'
        }).then(() => {
            setId('')
            OpenModalNuevo(<CalificacionPublicada CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    const AbrirPublicar = (e) =>{
        e.preventDefault();
        setId(e.currentTarget.dataset.id)
        OpenModalNuevo(
            <ModalValidar 
                accion={Publicar} 
                titulo={'Publicar Calificación'}
                mensaje={'¿Deseas Publicar la calificación seleccionada?'}
                botonName={'Sí, Publicar'}
                botonClass={'ButtonGreen'}
                CerrarModal={CerrarModal}
            />
        )
    }

    const AbrirEliminar = (e) =>{
        e.preventDefault();
        setId(e.currentTarget.dataset.id)
        OpenModalNuevo(
            <ModalValidar 
                accion={Eliminar} 
                titulo={'Eliminar Calificación'}
                mensaje={'¿Deseas Eliminar la calificación seleccionada?'}
                botonName={'Sí, Eliminar'}
                botonClass={'ButtonRed'}
                CerrarModal={CerrarModal}
            />
        )}
    
    return(
        <div className="col-12 p-0">
            <div className="dashboardCard">
                <p className="CardTitulo">Testimonios</p>
                {loading ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
                    : <>
                        {testimonios.length > 0 ? <>
                            <div className="divTableContent">
                                <div className="divTable-head">
                                    <div className="divTable-headRow" id="tableActions2">
                                        <div className="tdComment">Comentario</div>
                                        <div className="tdStars">Estrellas</div>
                                        <div className="tdInfo">Fecha</div>
                                        <div className="tdInfo">Usuario</div>
                                    </div>
                                </div>
                                {testimonios.map((testimonio) => {
                                    return(
                                        <div className="divTable-body" key={testimonio.id}>
                                            <div className="divTable-row" id="tableRate">
                                                <div className="tdComment"><label>Comentario</label>{testimonio.comment}</div>
                                                <div className="tdStars">
                                                    <label>Estrellas</label>
                                                    <div className="divStars">
                                                        {Array.from({ length: testimonio.stars }, (_, i) => 
                                                            <div className="DivStar" key={i}><img src={ImgStar} alt="estrellas" /></div>)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="tdInfo"><label>Fecha</label>{dateDistance(testimonio.fecha)}</div>
                                                <div className="tdInfo"><label>Nombre</label>{testimonio.nombre}</div>
                                            </div>
                                            <div className="tableActions">
                                                <div><button onClick={AbrirPublicar} data-id={testimonio.id}><img src={ImgCheck} alt="edit" /></button></div>
                                                <div><button onClick={AbrirEliminar} data-id={testimonio.id}><img src={ImgDelete} alt="delete" /></button></div>
                                            </div>
                                        </div>
                                    )})
                                }
                                </div>
                            </> :
                            <div className="itemTransferEmpty">
                                <p>No hay calificaciones recientes</p>
                            </div>
                        }
                    </>}
            </div>
        </div>
    )
}

export default Testimonios;