import { db } from '../../firebase/firebaseConfig';

const DeleteUser = async ({id})=> {
    return db.collection('usuarios').doc(id).delete();
}

const DeleteDestino = async ({id,contactos,usuario})=>{
    const cole = await db.collection('contactos').doc(id).update({activo: false});
    const cole2 = await db.collection('usuarios').doc(usuario).update({contactos: parseInt(contactos - 1)});
    return [cole, cole2]
}

export { DeleteUser, DeleteDestino };
