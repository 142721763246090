import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';

const useObtenerCodigos = () =>{

    const [codigos, setCodigos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const unsuscribe = db.collection('referidos')
        .onSnapshot((snapshot) => {
            setLoading(false);
            setCodigos(snapshot.docs.map((codigo) => {
                return {...codigo.data(), id: codigo.id}
            }));
        });
        return unsuscribe;
    },[]);

    return [codigos, loading]
}

const useObtenerCodigo = (id) =>{

    const [codigo, setCodigo] = useState('');
    const [load, setLoad] = useState(true);

    useEffect(()=>{
        if(id){
            const unsuscribe = db.collection('referidos').doc(id).get()
            .then((doc) => {
                if(doc.exists){
                    setLoad(false);
                    setCodigo(doc);
                } else{
                    setLoad(false);
                    setCodigo('');
                }
            })
            return unsuscribe;
        }
    },[id]);

    return [codigo, load]
}

const useObtenerReferido = (codigo) =>{

    const [referido, setReferido] = useState('');
    const [cargando, setCargando] = useState(true);

    useEffect(()=>{
        if(codigo){
            const unsuscribe = db.collection('referidos').doc(codigo).get()
            .then((doc) => {
                if(doc.exists){
                    setCargando(false);
                    setReferido(doc.data().negociacion);
                } else{
                    setCargando(false);
                    setReferido('');
                }
            })
            return unsuscribe;
        }
    },[codigo]);

    return [referido, cargando]
}

const useGetCodes = () =>{
    const [codigos, setCodigos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const unsuscribe = db.collection('codigos')
        .onSnapshot((snapshot) => {
            setLoading(false);
            setCodigos(snapshot.docs.map((codigo) => {
                return {...codigo.data(), id: codigo.id}
            }));
        });
        return unsuscribe;
    },[]);

    return [codigos, loading]
}

const useGetCode = (id) =>{
    const [code, setCode] = useState();
    const [codeLoad, setCodeLoad] = useState(true);

    useEffect(()=>{
        if(id){
            const unsuscribe = db.collection('codigos').doc(id).get()
            .then((doc) => {
                if(doc.exists){
                    setCode(doc);
                    setCodeLoad(false);
                } else{
                    setCodeLoad(false);
                    setCode('');
                }
            })
            return unsuscribe;
        }
    },[id]);


    return [code, codeLoad];
}

export  {
    useObtenerCodigos, 
    useObtenerCodigo, 
    useObtenerReferido,
    useGetCodes,
    useGetCode,
};
