import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';

const useObtenerUsuarios = (name,surname,idSearch,emailSearch) => {

    const {infoUser} = useAuth();
    const [ListadeUsuarios, setListadeUsuarios] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        if(infoUser.rol === 'Administrador'){
            if(name){
                const unsuscribe = db.collection('usuarios')
                .where('nombre.nombre','==', name)
                .orderBy('fechaRegistro', 'desc')
                .onSnapshot((snapshot) => {
                    setLoading(false);
                    setListadeUsuarios(snapshot.docs.map((cliente) => {
                        return { ...cliente.data(), id: cliente.id }
                    }));
                });
                return unsuscribe;
            }
            if(surname){
                const unsuscribe = db.collection('usuarios')
                .where('nombre.apellido','==', surname)
                .orderBy('fechaRegistro', 'desc')
                .onSnapshot((snapshot) => {
                    setLoading(false);
                    setListadeUsuarios(snapshot.docs.map((cliente) => {
                        return { ...cliente.data(), id: cliente.id }
                    }));
                });
                return unsuscribe;
            }
            if(idSearch){
                const unsuscribe = db.collection('usuarios')
                .where('usuario','==', idSearch)
                .orderBy('fechaRegistro', 'desc')
                .onSnapshot((snapshot) => {
                    setLoading(false);
                    setListadeUsuarios(snapshot.docs.map((cliente) => {
                        return { ...cliente.data(), id: cliente.id }
                    }));
                });
                return unsuscribe;
            }
            if(emailSearch){
                const unsuscribe = db.collection('usuarios')
                .where('email','==', emailSearch)
                .orderBy('fechaRegistro', 'desc')
                .onSnapshot((snapshot) => {
                    setLoading(false);
                    setListadeUsuarios(snapshot.docs.map((cliente) => {
                        return { ...cliente.data(), id: cliente.id }
                    }));
                });
                return unsuscribe;
            }else{
                const unsuscribe = db.collection('usuarios')
                .orderBy('fechaRegistro', 'desc')
                .onSnapshot((snapshot) => {
                    setLoading(false);
                    setListadeUsuarios(snapshot.docs.map((cliente) => {
                        return { ...cliente.data(), id: cliente.id }
                    }));
                });
                return unsuscribe;
            }
            
        }
    }, [infoUser,name,surname,idSearch,emailSearch]);

    return [ListadeUsuarios, loading];
}

export default useObtenerUsuarios;