import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase/firebaseConfig';
import InputPass from '../components/inputs/InputPass';
import Inputs from '../components/inputs/Inputs';

const Ingresar = ()=>{

    const[correo, setCorreo] = useState('');
    const[password, setPassword] = useState('');
    //ALERTAS
    const[altCorreo, setAltCorreo] = useState('');
    const[altPass, setAltPass] = useState('');
    const[spnCorreo, setSpnCorreo] = useState(false);
    const[spnPass, setSpnPass] = useState(false);
    //FORMATOS
    const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

    //FUNSIONES
    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if(correo === ''){setSpnCorreo(true); setAltCorreo('Por favor ingresa un correo electrónico válido'); return; }
        if(!expresionRegular.test(correo)){setSpnCorreo(true); setAltCorreo('Por favor ingresa un correo electrónico válido'); return;}
        if(password === ''){setSpnPass(true); setAltPass('Por favor ingresa tu contraseña'); return;}

        try{
            await auth.signInWithEmailAndPassword(correo,password)
        } catch(error){
            switch(error.code){
                case 'auth/wrong-password': setSpnPass(true); setAltPass('La contraseña no es correcta.'); break;
                case 'auth/user-not-found': setSpnCorreo(true); setAltCorreo('No se encontró ninguna cuenta con ese correo electrónico.'); break;
                default: setSpnPass(true); setAltPass('Hubo un error al intentar ingresar la cuenta.'); break;
            }
        }
    }
    return (
        <>
            <Helmet><title>Ingresar</title></Helmet>
            <div className="loginContainer">
                <h1>Login</h1>
                <div className="InnerLogin">
                    <form onSubmit={handleLoginSubmit}>
                        <Inputs name={'emaillogin'} type={'text'} label={'Correo Electrónico'} value={correo} setValue={setCorreo} 
                            alt={altCorreo} spn={spnCorreo} setSpn={setSpnCorreo} fill={'on'} />
                        
                        <InputPass name={'password'} label={'Contraseña'} value={password} setValue={setPassword} 
                            alt={altPass} spn={spnPass} setSpn={setSpnCorreo} fill={'off'} />

                        <Link to="/recuperar-contrasena" className="textForgot">Olvide mi Contraseña</Link>
                        <button type="submit" className="boton-general BtnLarge">Ingresar</button>
                    </form>
                    <p className="TextLink">¿No tienes una Cuenta?<br/><Link to="/registrate">Regístrate</Link></p>
                </div>
            </div>
        </>
        
    )
}

export default Ingresar;