import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Inicio from './views/Inicio';
import ComoFunciona from './views/ComoFunciona';
import PorQueElegirnos from './views/PorQueElegirnos';
import Ayuda from './views/Ayuda';
import TerminosyCondicines from './views/TerminosyCondiciones';
import PoliticasDePrivacidad from './views/PoliticasDePrivacidad';
import NewHeader from './views/components/header/NewHeader';
import HeaderLogin from './views/components/header/HeaderLogin';
import MenuLateral from './views/components/header/MenuLateral';
import ModalNuevo from './views/components/modales/ModalNuevo';
import Footer from './views/components/footer/Footer';
import Whatsapp from './img/icons/whatsapp.svg';
import ModalNotice from './views/components/modales/ModalNotice';

const App = () => {

    const [cargando, setCargando] = useState(true);
    const {usuario, infoUser} = useAuth();
    const [notice, setNotice] = useState(true);

    useEffect(() => {
        if(infoUser){setCargando(false);} 
        else{setCargando(false)}
    }, [usuario, infoUser])

    const [well, setWell] = useState(true);
    const [abrirMenu, setAbrirMenu] = useState(false);

    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [componente, cambiarComponente] = useState('');
    const [modal, cambiarModal] = useState('modal-general');
    const [clases, cambiarClases] = useState('fonder');

    const OpenModalNuevo = (componenterecibido) => {
        cambiarEstadoModal(true);
        cambiarComponente(componenterecibido);
        setTimeout(() => {
            cambiarModal('modal-general modal-activo');
            cambiarClases('fonder fonder-activo');
        }, 400);
    }
    const CerrarModal = (e) => {
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
        setTimeout(() => {
            cambiarEstadoModal(false);
        }, 600);
    }

    return (
        <>
            {cargando ? <div className="loadingContainer pageLoader"><div className="lds-ripple"><div></div><div></div></div></div> : <>
                <Helmet><title>Colcambios Australia</title></Helmet>
                <ModalNotice notice={notice} setNotice={setNotice} />
                <a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className="whatsapp">
                    <img src={Whatsapp} alt="Whatsapp" /></a>
                <div className="MenuDash">
                    <div className="MenuDashCont">
                        <NewHeader />
                        <HeaderLogin well={well} setWell={setWell} setAbrirMenu={setAbrirMenu} OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/>
                        <MenuLateral setWell={setWell} setAbrirMenu={setAbrirMenu} abrirMenu={abrirMenu} />
                    </div>
                </div>
                <div className="ContPaginas">
                    <Switch>
                        <Route path="/" exact={true}><Inicio OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/></Route>
                        <Route path="/como-funciona"><ComoFunciona OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} /></Route>
                        <Route path="/por-que-elegirnos"><PorQueElegirnos /></Route>
                        <Route path="/ayuda"><Ayuda /></Route>
                        <Route path="/terminos-y-condiciones"><TerminosyCondicines /></Route>
                        <Route path="/politicas-de-privacidad"><PoliticasDePrivacidad /></Route>
                    </Switch>
                    <Footer />
                </div>
                <ModalNuevo
                    modal={modal}
                    CerrarModal={CerrarModal}
                    clases={clases}
                    componente={componente}
                    estadoModal={estadoModal} 
                />
            </>}
        </>
    );
}

export default App;
