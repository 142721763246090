import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {useObtenerCodigos} from '../../hooks/useObtenerCodigos';
import TransferenciasReferidas from './components/TransferenciasReferidas';
import { EliminarReferido } from './components/EliminarCodigo';
import { AdminReferido } from './components/AdminCodigo';
import '../../../styles/tablaCuentas.css';
import ImgPlus from '../../../img/icons/plus.svg';
import ImgDelete from '../../../img/icons/trash.svg';
import ImgEdit from '../../../img/icons/edit.svg';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import ImgPdf from '../../../img/icons/document-text-1.svg';

const CodigosReferidos = ({OpenModalNuevo,CerrarModal}) =>{

    const [codigos, loading] = useObtenerCodigos();
    const [data, setData] = useState('');

    //PAGINACION
    const [pages, setPages] = useState('');
    const [dataLimit, setDataLimit] = useState('');
    const [pageLimit, setPageLimit] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationGroup, setPaginationGroup] = useState([]);

    //TRANSFORM DATA
    useEffect(()=>{
        if(codigos){
            const temp = codigos.map((codigo)=>{
                return [
                    codigo.id,
                    codigo.nombre,
                    codigo.tipoId,
                    codigo.numeroId,
                    codigo.estado,
                    codigo.negociacion
                ]
            });
            setData(temp);
        }
    },[codigos]);

    //DOWNLOAD PDF
    const DownloadPDF = async() =>{
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Código','Nombre','Tipo ID','Numero ID','Tipo de Negociación','Estado']],
            body: data
            });
        doc.save('codigos-referidos.pdf');
    }

    //PAGINACIÓN
	useEffect(()=>{
        if(codigos.length <= 10){
			setDataLimit(codigos.length);
        }else{
			setDataLimit(7);
		}
    },[codigos])

    useEffect(() => {
        setPages(Math.ceil(codigos.length / dataLimit));
        setPageLimit(4);
    },[codigos, dataLimit])

	useEffect(() => {
        if(pageLimit){
            const grupoNumeros = () => {
                let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
                return new Array(pageLimit).fill().map((_, idx) => start + idx + 1 );
            };
            setPaginationGroup(grupoNumeros);
        }
    }, [pageLimit, currentPage]);

    // useEffect(() => {
    //     window.scrollTo({ behavior: 'smooth', top: '0px' });
    // }, [currentPage]);

    const goToNextPage = () => {setCurrentPage((page) => page + 1);};
    const goToPreviousPage = () => {setCurrentPage((page) => page - 1);};
      
    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return codigos.slice(startIndex, endIndex);
    };

    const AbrirBorrar = (e) =>{OpenModalNuevo(<EliminarReferido OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} codigo={e.currentTarget.dataset.id} />)}
    const AbrirAdmin = (e) =>{OpenModalNuevo(<AdminReferido OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} id={e.currentTarget.dataset.id} />)}

    return(
        <>
            <Helmet><title>Códigos Referidos</title></Helmet>
            <div className="TituloPag"><p>Códigos Referidos</p></div>
            <TransferenciasReferidas />
            <div className="row m-0">
                <div className="col-12 p-0">
                    <div className="dashboardCard">
                        <p className="CardTitulo">Códigos Asesores</p>
                        <button className="boton-general imgButton-left" onClick={AbrirAdmin}>
                            <img src={ImgPlus} alt="Agregar"/>Agregar Referido</button>
                        {loading ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
                            : <>
                                {codigos.length > 0 ?
                                    <>
                                        <div className="divTableContent">
                                            <div className="divTable-head">
                                                <div className="divTable-headRow" id="tableActions2">
                                                    <div>Código</div>
                                                    <div>Nombre</div>
                                                    <div>Id</div>
                                                    <div>Estado</div>
                                                    <div>Negociación</div>
                                                </div>
                                            </div>
                                                
                                            {getPaginatedData().map((codigo) => {
                                                return(
                                                    <div className="divTable-body" key={codigo.id}>
                                                        <div className="divTable-row" id="table5td">
                                                            <div><label>Código</label>{codigo.id}</div>
                                                            <div><label>Nombre</label>{codigo.nombre}</div>
                                                            <div><label>Id</label>{codigo.tipoId} {codigo.numeroId}</div>
                                                            <div><label>Estado</label>{codigo.estado}</div>
                                                            <div><label>Negociación</label>{codigo.negociacion}</div>
                                                        </div>
                                                        <div className="tableActions">
                                                            <div><button onClick={AbrirAdmin} data-id={codigo.id}><img src={ImgEdit} alt="edit" /></button></div>
                                                            <div><button onClick={AbrirBorrar} data-id={codigo.id}><img src={ImgDelete} alt="delete" /></button></div>
                                                        </div>
                                                    </div>
                                                )})
                                            }
                                        </div>
                                        <div className="TableFooter">
                                            <div className="Btns-Export">
                                                <button className="BtnExport BtnPdf" onClick={DownloadPDF}><img src={ImgPdf} alt="" />Exportar a PDF</button>
                                            </div>
                                            <div className="paginacion">
                                                <button onClick={goToPreviousPage} className={`pagPrev ${currentPage === 1 ? 'pagDisabled' : ''}`}><img src={ImgPrev} alt="anterior" /></button>
                                                    {paginationGroup ? paginationGroup.map((item, index) => (
                                                        <button key={index} onClick={changePage} className={(item > pages ? 'pagBlock' : 'pagNumber') +' '+ (currentPage === item ? 'pagActive' : '')}>
                                                            <span>{item}</span>
                                                        </button>
                                                    )) : <></>}
                                                <button onClick={goToNextPage} className={`pagNext ${currentPage === pages ? 'pagDisabled' : ''}`}><img src={ImgNext} alt="siguiente" /></button>
                                            </div>
                                        </div>
                                    </> 
                                    :
                                    <div className="itemTransferEmpty">
                                        <p>No se encontraron Códigos</p>
                                        <button className="boton-general">Ok</button>
                                    </div>
                                }
                            </>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CodigosReferidos;