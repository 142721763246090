import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route} from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Menu from '../views/components/header/Menu';
import NewHeader from '../views/components/header/NewHeader';
import HeaderLogin from '../views/components/header/HeaderLogin';
import MenuLateral from '../views/components/header/MenuLateral';
import Footer from '../views/components/footer/Footer';
import ModalNuevo from '../views/components/modales/ModalNuevo';
import InicioAdmin from './InicioAdmin';
import Transacciones from './views/transferencias/Transferencias';
import Usuario from './views/usuarios/Usuario';
import Usuarios from './views/usuarios/Usuarios';
import Agencias from './components/agencias/Agencias';
import CodigosReferidos from './components/codigos/CodigosReferidos';
import CodigosPromocionales from './components/codigos/CodigosPromocionales';
import Profile from '../client/views/profile/Profile';
import Transferencia from './views/transferencias/Transferencia';

const DashboardAdmin = () => {
    
    const [cargando, setCargando] = useState(true);
    const {usuario, infoUser} = useAuth();

    useEffect(() => {
        if(usuario){
            if(infoUser){
                setCargando(false);
            }
        }
    },[usuario, infoUser])
        
    const [well, setWell] = useState(true);
    const [abrirMenu, setAbrirMenu] = useState(false);

    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [componente, cambiarComponente] = useState('');
    const [modal, cambiarModal] = useState('modal-general');
    const [clases, cambiarClases] = useState('fonder');

    const OpenModalNuevo = (componenterecibido) => {
        cambiarEstadoModal(true);
        cambiarComponente(componenterecibido);
        setTimeout(() => {
            cambiarModal('modal-general modal-activo');
            cambiarClases('fonder fonder-activo');
        }, 400);
    }
    
    const CerrarModal = (e) => {
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
        setTimeout(() => {
            cambiarEstadoModal(false)
        }, 600);
    }

    return (
        <> 
            {cargando ? <div className="loadingContainer pageLoader"><div className="lds-ripple"><div></div><div></div></div></div> :<>
                <Helmet><title>Dashboard Admin</title></Helmet>
                <div className="MenuDash">
                    <div className="MenuDashCont">
                        <NewHeader />
                        <HeaderLogin well={well} setWell={setWell} setAbrirMenu={setAbrirMenu}/>
                        <MenuLateral setWell={setWell} setAbrirMenu={setAbrirMenu} abrirMenu={abrirMenu}/>
                    </div>
                </div>
                <Menu />
                <div className="ContDash">
                    <div className="ContentDash">
                        <Switch>
                            <Route path="/dashboard"><InicioAdmin OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} /></Route>
                            <Route path="/transacciones"><Transacciones /></Route>
                            <Route path="/transferencia/:id"><Transferencia OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/></Route>
                            <Route path="/usuarios"><Usuarios /></Route>
                            <Route path="/usuario/:id"><Usuario OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/></Route>
                            <Route path="/perfil"><Profile OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/></Route>
                            <Route path="/codigos/codigos-promocionales"><CodigosPromocionales OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} /></Route>
                            <Route path="/codigos/codigos-referidos"><CodigosReferidos OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} /></Route>
                            <Route path="/agencias"><Agencias OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} /></Route>
                        </Switch>
                    </div>
                    <Footer/>
                </div>
                <ModalNuevo
                    modal={modal}
                    CerrarModal={CerrarModal}
                    clases={clases}
                    componente={componente}
                    estadoModal={estadoModal} 
                />
            </>}
        </>
    );
}

export default DashboardAdmin;