import React from "react";

const CardsNueva = (props) =>{

    const{
        fee,
        setFee,
        setSpnMinimo,
        ultraFee,
        servicio,
        setServicio,
        spnAltForPago,
        altForPago,
        impuestoBanco,
        setImpuestoBanco,
        pagarImpuesto,
        setPagarImpuesto,
        promoFee
    } = props;

    return(
        <>
            
            <div className="CardButtonsDiv">
                <p className="titleCardButton">1. Tipo de Servicio</p>
                {!promoFee ?
                    <div className="cards-buttons">
                        <div className={fee===4.99 ? 'card-fee cardActive' : 'card-fee'} onClick={()=>{setFee(4.99);setSpnMinimo(false);}} >
                            <label>Tradicional</label>
                            <p><span>4.99 AUD</span>
                            Una vez pagada la transferencia y adjuntado el comprobante,
                            la transferencia puede tardar hasta <b>24 horas hábiles.</b></p>
                        </div>
                        {!ultraFee ? 
                            <div className={fee===14.99 ? 'card-fee cardActive' : 'card-fee'} onClick={()=>{setFee(14.99);setSpnMinimo(false);}}>
                                <label>UltraRápido</label>
                                <p><span>14.99 AUD</span>
                                Una vez pagada la transferencia y adjuntado el comprobante,
                                la transferencia se efectua <b>de inmediato.</b></p>
                            </div>
                        :   <div className='card-fee cardUnabled'>
                                <label>UltraRápido</label>
                                <p><span>14.99 AUD</span>
                                Una vez pagada la transferencia y adjuntado el comprobante,
                                la transferencia se efectua <b>de inmediato.</b></p>
                            </div>
                        }
                    </div>
                :
                    <div className="cards-buttons">
                        <div className='card-fee cardActive cardsOne'>
                            <label>Tradicional</label>
                            <p><span className="spnTached">4.99 AUD</span>
                            Una vez pagada la transferencia y adjuntado el comprobante,
                            la transferencia puede tardar hasta <b>24 horas hábiles.</b></p>
                        </div>
                    </div>
                }
            </div>
            
            <div className="CardButtonsDiv">
                <p className="titleCardButton">2. Costos del servicio</p>
                {!promoFee ?
                    <div className="cards-buttons">
                        <div className={!servicio ? 'card-fee cardActive' : 'card-fee'} onClick={()=>{setServicio(false);}}>
                            <p><span>Servicio Incluído</span>Se descuenta el servicio del valor a recibir.</p>
                        </div>
                        <div className={servicio ? 'card-fee cardActive' : 'card-fee'} onClick={()=>{setServicio(true);}}>
                            <p><span>Adicionar el Servicio</span>El servicio se adiciona al valor que deseas transferir.</p>
                        </div>
                    </div>
                :
                <div className="cards-buttons">
                    <div className='card-fee cardActive cardsOne'>
                        <label>¡Gratis!</label>
                    </div>
                </div>
                }           
            </div>
        
            <div className={spnAltForPago ? "CardButtonsDiv ErrorButton" : "CardButtonsDiv"}>
                <p className="titleCardButton">3. Forma de Pago</p>
                <div className="cards-buttons">
                    <div className={impuestoBanco===false ? 'card-fee cardActive' : 'card-fee'} onClick={()=>{setImpuestoBanco(false)}}>
                        <p><span>Transferencia Electrónica</span>
                        Las transferencias electrónicas no generan costos adicionales.</p>
                    </div>
                    <div className={impuestoBanco ? 'card-fee cardActive' : 'card-fee'} onClick={()=>{setImpuestoBanco(true)}}>
                        <p><span>Otro Método de Pago</span>
                        Los métodos de pago diferentes a las transferencias electrónicas generan un costo adicional.</p>
                    </div>
                </div>
                {spnAltForPago && <span className="spanAlertCard">{altForPago}</span>}
            </div>

            {impuestoBanco &&
                <div className="CardButtonsDiv">
                    <p className="titleCardButton">4. Costos Bancarios</p>
                    <div className="cards-buttons">
                        <div className={pagarImpuesto ? 'card-fee cardActive' : 'card-fee'} onClick={()=>{setPagarImpuesto(true);}}>
                            <p><span>Incluir Costos</span>Se descuentan los costos bancarios del valor a enviar.</p>
                        </div>
                        <div className={!pagarImpuesto ? 'card-fee cardActive' : 'card-fee'} onClick={()=>{setPagarImpuesto(false);}}>
                            <p><span>Adicionar Costos</span>Se adicionan los costos bancarios al valor que envías.</p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CardsNueva;