import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams, Link } from 'react-router-dom';
import { format, fromUnixTime} from 'date-fns';
import { es } from 'date-fns/locale';
import { firebase } from '../../../firebase/firebaseConfig';
import axios from 'axios';
import { PDFDownloadLink } from '@react-pdf/renderer';
import NumberFormat from 'react-number-format';
import { useAuth } from '../../../context/AuthContext';
import useObtenerTransferecia from '../../hooks/useObtenerTransferencia'
import { AgregarComprobante } from '../../data/AgregarComprobante';
import { HuboUnError, ProcesadaOk } from '../../components/Mensajes';
import FileDocument from '../../../views/components/files/FileDocument';
import MyDocument from '../../components/factura/FacturaPDF';
import CountDownTrans from '../../components/countDown/CountDownTrans';
import CalificarServicio from './Components/CalificarServicio';
import './transferencia.css';
import '../../../styles/rating.css';
import ImgFile from '../../../img/icons/file-text.svg';
import Receipt from '../../../img/icons/receipt.svg';

const Transferencia = ({OpenModalNuevo,CerrarModal}) =>{

    const { usuario } = useAuth();
    const history = useHistory();

    //TRANSFERENCIA
    const { id } = useParams();
    const idNuevo = id.substring(0, 6).toUpperCase();
    const [transferencia, loading] = useObtenerTransferecia(id);

    const [fechaTrans, setFechaTrans] = useState();
    const [fechaEmail, setFechaEmail] = useState();
    const [fechaFactura, setFechaFactura] = useState();
    const [fechaPago, setFechaPago] = useState();
    const [estadoTrans, setEstadoTrans] = useState();
    const [razonTrans, setRazonTrans] =  useState();
    const [observaciones, setObservaciones] = useState();
    const [calificada, setCalificada] = useState(false);
    const [colcambios, setColcambios] = useState();
    
    //VALORES
    const [montoAud, setMontoAud] = useState();
    const [servicio, setServicio] = useState();
    const [total, setTotal] = useState();
    const [metodo, setMetodo] = useState();
    const [fee,setFee] = useState();
    const [cargos,setCargos] = useState();
    const [pagoImpuesto, setPagoImpuesto] = useState();
    const [tasadeCambio, setTasadeCambio] = useState();
    const [emailCop, setEmailCop] =  useState();
    const [emailAud, setEmailAud] =  useState();
    const [emailTasa, setEmailTasa] = useState();
    //REMITENTE
    const [autoEnvio, setAutoEnvio] = useState();
    const [rteAppId, setRteAppId] = useState();
    const [rteNombre, setRteNombre] = useState();
    const [rteApellido, setRteApellido] = useState();
    const [rteNoId, setRteNoId] = useState();
    const [rteEmail, setRteEmail] = useState();
    const [rteCelular, setRteCelular]  = useState();
    //DESTINATARIO
    const [dnoAppId, setDnoAppId] = useState();
    const [dnoNombre, setDnoNombre] = useState();
    const [dnoApellido, setDnoApellido] = useState();
    const [dnoTipoId, setDnoTipoId] = useState();
    const [dnoNoId, setDnoNoId] = useState();
    const [dnoCelular, setDnoCelular]  = useState();
    //CODIGO PROMOCIONAL
    const [proCodigo, setProCodigo] = useState('');
    const [proTipo, setProTipo] = useState('');
    const [proDescuento, setProDescuento] = useState('');
    const [proAgenciaId, setProAgenciaId] = useState('');
    //CUENTA
    const [titular, setTitular] = useState();
    const [nombreBanco, setNombreBanco] = useState();
    const [numeroBSB, setNumeroBSB] = useState();
    const [numerodeCuenta, setNumerodeCuenta] = useState();
    //DOCUMENTOS
    const [comprobantePago, setComprobantePago] = useState();
    const [factura, setFactura] = useState(false);

    //FUNCIONES
    const formatearFecha = (fecha) => {return format(fromUnixTime(fecha), "MMM dd',' yyyy '·' HH:mm a", { locale: es });}
    const formatDate = (fecha) => {return format(fromUnixTime(fecha), "dd 'de' MMMM 'de' yyyy", { locale: es });}
    const emailDate = (fecha) => {return format(fromUnixTime(fecha), "dd'/'MMM'/'yy", { locale: es });}
    
    useEffect(() => {
        if(transferencia){
            if(transferencia.data().usuario === usuario.uid){
                setFechaTrans(formatearFecha(transferencia.data().fecha));
                setFechaEmail(emailDate(transferencia.data().fecha));
                setFechaFactura(formatDate(transferencia.data().fecha));
                setFechaPago(transferencia.data().fechaMaxPago);
                setEstadoTrans(transferencia.data().estado);
                setRazonTrans(transferencia.data().razon);
                setColcambios(transferencia.data().colcambios);

                setMontoAud(transferencia.data().transferencia.montoAud);
                setServicio(transferencia.data().transferencia.servicio);
                setTotal(transferencia.data().transferencia.totalPagado);
                setMetodo(transferencia.data().transferencia.metodo);
                setFee(transferencia.data().transferencia.fee);
                setCargos(transferencia.data().transferencia.cargos);
                setPagoImpuesto(transferencia.data().transferencia.pagoImpuesto);
                setTasadeCambio(transferencia.data().transferencia.tasa);
                setEmailCop(transferencia.data().transferencia.emailCop);
                setEmailAud(transferencia.data().transferencia.emailAud);
                setEmailTasa(transferencia.data().transferencia.emailTasa);

                setAutoEnvio(transferencia.data().autoEnvio);
                setRteAppId(transferencia.data().usuario)
                setRteNombre(transferencia.data().remitente.nombre);
                setRteApellido(transferencia.data().remitente.apellido);
                setRteNoId(transferencia.data().remitente.numeroId);
                setRteEmail(transferencia.data().remitente.email);
                setRteCelular(transferencia.data().remitente.celular);

                setDnoAppId(transferencia.data().destinatarioId)
                setDnoNombre(transferencia.data().destinatario.nombre);
                setDnoApellido(transferencia.data().destinatario.apellido);
                setDnoTipoId(transferencia.data().destinatario.tipoId);
                setDnoNoId(transferencia.data().destinatario.numeroId);
                setDnoCelular(transferencia.data().destinatario.celular);

                setTitular(transferencia.data().cuenta.titular);
                setNombreBanco(transferencia.data().cuenta.banco);
                setNumeroBSB(transferencia.data().cuenta.bsb);
                setNumerodeCuenta(transferencia.data().cuenta.numeroCuenta);

                setObservaciones(transferencia.data().observaciones);
                setCalificada(transferencia.data().calificada);

                setComprobantePago(transferencia.data().comprobantePago);
                setFactura(transferencia.data().factura);
                // setPagoTrans(transferencia.data().pagoTrans);  

                if(transferencia.data().promocional){
                    setProTipo(transferencia.data().promocional.tipo);
                    setProCodigo(transferencia.data().promocional.codigo);
                    setProDescuento(transferencia.data().promocional.descuento);
                    setProAgenciaId(transferencia.data().promocional.agenciaId);
                }

            } else{
                history.push('/transferencias');
            }
        }
    }, [transferencia, usuario, history]);

    //SUBIR COMPROBANTE
    const [archivos, setArchivos] = useState('');
    const [altComprobante, setAltComprobante] = useState('');
    const [spnComprobante, setSpnComprobante] = useState(false);
    const [progress, setProgress] = useState(0);
    const [buttonComp, setButtonComp] = useState(false);

    useEffect(()=>{setSpnComprobante(false);},[archivos])

    const SubirComprobante = (e) =>{
        e.preventDefault();
        const fechaTrans = fechaEmail.toUpperCase();
        if(!archivos){setSpnComprobante(true); setAltComprobante('Por favor selecciona el comprobante'); return;}
        if(!archivos[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){setSpnComprobante(true); setAltComprobante('Archivo no válido, solo Imagen o PDF');return;}
        const size = Math.round((archivos[0].size / 1024));
        if(size >= 1024) {setSpnComprobante(true); setAltComprobante('El tamaño máximo del archivo es de 1mb'); return;}

        setButtonComp(true);
        const comprobante = archivos[0];
        const nombreArchivo = 'comprobante'+id+'.'+archivos[0].name.split('.').pop();
        const StorageRef = firebase.storage().ref(rteAppId + '/transferencias/' + nombreArchivo);
        const uploadTask = StorageRef.put(comprobante);
        uploadTask.on( "state_changed", snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            }, error => {
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                setButtonComp(false);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    AgregarComprobante({
                        id: id, 
                        comprobantePago: url, 
                        estado:'En Proceso'
                    }).then(async() =>{
                        await axios.post('https://server-colcambios.herokuapp.com/api/transferenproceso', 
                            {idNuevo,rteEmail,rteNombre,emailAud,emailTasa,fechaTrans,dnoNombre,dnoApellido,metodo,razonTrans})
                        .then(res =>{
                            if(res.data.success){
                                OpenModalNuevo(<ProcesadaOk CerrarModal={CerrarModal}/>);
                                setEstadoTrans('En Proceso'); 
                                setComprobantePago(url);
                            }
                        }).catch(() =>{
                            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                            setButtonComp(false);
                        })
                    })
                    .catch(() =>{
                        OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                        setButtonComp(false);
                    })
                })
            })
    }

    return(
        <>
            <Helmet><title>Transferencia</title></Helmet>
            <div className="TituloPag"><p>Transferencia</p></div>

            {!loading ? <>
                <div className="CardTransferencia">
                    <div><span>Ref. de Transferencia</span><p className="NTransfer">{idNuevo}</p></div>
                    <div><span>Metodo</span><p>{metodo}</p></div>
                    <div><span>Fecha</span><p className="FTransfer">{fechaTrans}</p></div>
                    <div><span>Estado</span><p>{estadoTrans}</p></div>
                </div>

                <div className="row m-0">
                    <div className="col-12 col-md-7 col-lg-8 p-0">
                        <div className="CardBody">
                            <h3>Información</h3>
                            <div className="row p-0 DatosTrans">
                                {!autoEnvio ? 
                                    <div className="col-12"><label>Destinatario</label>
                                        <Link to={`/destinatario/${dnoAppId}`}>{dnoNombre+ ' ' +dnoApellido}</Link>
                                    </div>:
                                    <div className="col-12"><p><label>Destinatario</label>AutoEnvio</p></div>
                                }
                                <div className="col-6 col-sm-4"><p><label>Tipo ID</label>{dnoTipoId}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Número de ID</label>{dnoNoId}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Celular</label>{dnoCelular}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Banco</label>{nombreBanco}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Titular</label>{titular}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Número BSB</label>{numeroBSB}</p></div>
                                <div className="col-6 col-sm-4"><p><label>No. de Cuenta</label>{numerodeCuenta}</p></div>
                            </div>
                        </div>

                        {estadoTrans === 'Registrada' &&
                            <div className="CardBody">
                                <h3>Documentos</h3>
                                {!comprobantePago && <>
                                    {fechaPago && <>
                                        <p className="textoTrans">Si el comprobante es cargado después de haber terminado el tiempo establecido, la transferencia estará sujeta a una recotización. 
                                        La información de la cuenta que elegiste para hacer el pago de tu transferencia es la siguiente:</p>
                                        <div className="Cuentas" style={{marginBottom: '15px'}}>
                                            <p className="CuentasBanco">{colcambios.banco}</p>
                                            <p className="CuentasTipo">{colcambios.tipoCuenta}<br/>{colcambios.numero}</p>
                                            <p><span>Titular</span>{colcambios.titular}</p>
                                            <p><span>Id</span>{colcambios.id}</p>
                                        </div>
                                        <CountDownTrans fechaPago={fechaPago} />
                                    </>} 
                                    <form onSubmit={SubirComprobante}>
                                        <FileDocument archivosSelec={setArchivos} spnAltArchivo={spnComprobante} altArchivo={altComprobante} />
                                        <div className="noSee"><progress value={progress} max="100"></progress></div>
                                        <button className="boton-general" type='submit' disabled={buttonComp}>
                                                {buttonComp ? 'Cargando...' : 'Subir Comprobante'}</button>
                                    </form>
                                </>}
                            </div>
                        }

                        {comprobantePago &&
                            <div className="CardBody">
                                <div className="TransDocuments">
                                <a href={comprobantePago} target="blank_" rel="noreferrer noopener">Comprobante<br/><img src={Receipt} alt="comprobante" /></a>
                                {/* {pagoTrans && <a href={pagoTrans} target="blank_" rel="noreferrer noopener" className="noSee">Pago<br/><img src={Receipt} alt="comprobante" /></a>} */}
                                {factura &&
                                    <PDFDownloadLink
                                        fileName={idNuevo + '.pdf'} 
                                        document={
                                            <MyDocument 
                                                fecha={fechaFactura}
                                                numerorecibo={idNuevo}
                                                nombre={rteNombre+' '+rteApellido}
                                                id={rteNoId}
                                                razon={razonTrans}
                                                telefono={rteCelular}
                                                banco={nombreBanco}
                                                titular={dnoNombre+' '+dnoApellido}
                                                numerobsb={numeroBSB}
                                                numerocuenta={numerodeCuenta}
                                                montocop={emailCop}
                                                cargos={cargos}
                                                pagoImpuesto={pagoImpuesto}
                                                tasadecambio={emailTasa}
                                                metodo={metodo}
                                                fee={fee}
                                                montoaud={emailAud}
                                                observaciones={observaciones}
                                            />
                                        }>
                                        {() =>
                                            <>Factura<br/>
                                            <img src={ImgFile} alt="comprobante" /></>
                                        }
                                    </PDFDownloadLink>
                                }
                                </div>
                            </div>
                        }

                        {proTipo && 
                        <div className="CardBody">
                            <h3>Códigos</h3>
                            <div className="row p-0 DatosTrans">
                                <div className="col-12"><h3>Promocional</h3></div>
                                <div className="col-6 col-sm-4"><p><label>Tipo</label>{proTipo}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Código</label>{proCodigo}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Descuento</label>{proDescuento}</p></div>
                                {proAgenciaId && <div className="col-6 col-sm-4"><p><label>Agencia</label>{proAgenciaId}</p></div>}
                            </div>
                        </div>
                        }

                        {observaciones &&
                            <div className="CardBody">
                                <h3>Observaciones</h3>
                                <div className="Observaciones">
                                    <p>{observaciones}</p>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="col-12 col-md-5 col-lg-4 p-0">
                        <div className="CardBody">
                            <h3>Resumen</h3>
                            <div className="ResumenTrans">
                                <div>
                                    <label>Valor Pagado</label>
                                    <NumberFormat value={total} displayType={'text'} thousandSeparator={'.'} 
                                    decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/>
                                </div>
                                <div>
                                    <label>Costos Bancarios</label>
                                    <p>{cargos !== 0 && '-'}&nbsp;
                                    <NumberFormat value={cargos} displayType={'text'} thousandSeparator={'.'} 
                                    decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={false}/></p>
                                </div>
                                <div>
                                    <label>Tasa de Cambio</label>
                                    <NumberFormat value={tasadeCambio} displayType={'text'} thousandSeparator={'.'} 
                                    decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/>
                                </div>
                                <div>
                                    <label>Servicio de Transferencia</label>
                                    <p>{!servicio && '-'}&nbsp;
                                    <NumberFormat value={fee} displayType={'text'} thousandSeparator={','} 
                                    decimalSeparator={'.'} prefix={'$'} suffix={' AUD'} decimalScale={'2'}/></p>
                                </div>
                                <div>
                                    <label>Valor a Recibir</label>
                                    <NumberFormat value={montoAud} displayType={'text'} thousandSeparator={','} 
                                    decimalSeparator={'.'} prefix={'$'} suffix={' AUD'} decimalScale={'2'}/>
                                </div>
                            </div>
                        </div>
                        
                        {estadoTrans === 'Procesada' && <>
                            {!calificada &&
                                <div className="CardBody">
                                    <CalificarServicio OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} rteAppId={rteAppId}
                                        id={id} rteNombre={rteNombre} rteApellido={rteApellido} setCalificada={setCalificada} />
                                </div>
                            }
                        </>}
                    </div>
                </div>
            </> : <div className="loadingContainer pageLoader"><div className="lds-ripple"><div></div><div></div></div></div>}
        </>
    )
}

export default Transferencia;