import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { firebase } from '../../../../firebase/firebaseConfig';
import { AgregarComprobante } from '../../../data/AgregarComprobante';
import { HuboUnError } from '../../../components/Mensajes';
import CountDown from '../../../components/countDown/CountDown';
import FileDocument from '../../../../views/components/files/FileDocument';
import Check from '../../../../img/icons/tick-square.svg';

const Comprobante = ({
        update,
        setUpdate,
        colcambios,
        fechaMaxPago,

        uidUsuario,
        docRef,
        rteNombre,
        rteEmail,
        dnoNombre,
        dnoApellido,
        emailAud,
        emailTasa,
        metodo,
        fechaTransfer,
        nuevaRazon,
        
        CerrarModal,
        OpenModalNuevo
    }) =>{

    //SUBIR COMPROBANTE
    const [archivos, setArchivos] = useState('');
    const [altComprobante, setAltComprobante] = useState('');
    const [spnComprobante, setSpnComprobante] = useState(false);
    const [progress, setProgress] = useState(0);
    const [buttonComp, setButtonComp] = useState(false);

    useEffect(()=>{setSpnComprobante(false);},[archivos])

    const SubirComprobante = (e) =>{
        e.preventDefault();
        const fecha = fechaTransfer;
        const selReason = nuevaRazon;
        const idNuevo = docRef.substring(0, 6).toUpperCase();
        if(!archivos){setSpnComprobante(true); setAltComprobante('Por favor selecciona el comprobante'); return;}
        if(!archivos[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){setSpnComprobante(true); setAltComprobante('Archivo no válido, solo Imagen o PDF');return;}
        const size = Math.round((archivos[0].size / 1024));
        if(size >= 1024) {setSpnComprobante(true); setAltComprobante('El tamaño máximo del archivo es de 1mb'); return;}

        setButtonComp(true);
        const comprobante = archivos[0];
        const nombreArchivo = 'comprobante'+docRef+'.'+archivos[0].name.split('.').pop();
        const StorageRef = firebase.storage().ref(uidUsuario + '/transferencias/' + nombreArchivo);
        const uploadTask = StorageRef.put(comprobante);
        uploadTask.on( "state_changed", snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            }, error => {
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                setButtonComp(false);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    AgregarComprobante({
                        id: docRef, 
                        comprobantePago: url, 
                        estado:'En Proceso'
                    }).then(async() =>{
                        await axios.post('https://server-colcambios.herokuapp.com/api/enproceso', 
                            {idNuevo,rteEmail,rteNombre,emailAud,emailTasa,fecha,dnoNombre,dnoApellido,metodo,selReason})
                        .then(res =>{
                            if(res.data.success){
                                setUpdate(true);
                            }
                        }).catch(() =>{
                            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                            setButtonComp(false);
                        })
                    })
                    .catch(() =>{
                        OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                        setButtonComp(false);
                    })
                })
            })
    }
    return(
        <>
            {!update ? <>
                <div className="formTitle">
                    <h1 className="text-title">Pago de Transferencia</h1>
                    <p>Si el comprobante es cargado después de haber terminado el tiempo establecido, la transferencia estará sujeta a una recotización.</p>
                </div>
                <div className="Cuentas" style={{marginBottom: '15px'}}>
                    <p className="CuentasBanco">{colcambios.banco}</p>
                    <p className="CuentasTipo">{colcambios.tipoCuenta}<br/>{colcambios.numero}</p>
                    <p><span>Titular</span>{colcambios.titular}</p>
                    <p><span>Id</span>{colcambios.id}</p>
                </div>
                <form onSubmit={SubirComprobante}>
                    {fechaMaxPago && <CountDown fechaPago={fechaMaxPago}/>}
                    <FileDocument archivosSelec={setArchivos} spnAltArchivo={spnComprobante} altArchivo={altComprobante}/>
                    <div className="noSee"><progress value={progress} max="100"></progress></div>
                    <button className="boton-general" type='submit' disabled={buttonComp}>
                        {buttonComp ? 'Cargando...' : 'Subir Comprobante'}</button>
                </form>
            </>
            :   <div className="Completado">
                    <div className="CompletadoImg"><img src={Check} alt="listo" /></div>
                    <p>El comprobante se ha cargado y <b>subido correctamente.</b></p>
                    <a href="/dashboard" className="boton-general">Ir al Inicio</a>
                </div> 
            }
        </>
    )
}

export default Comprobante;