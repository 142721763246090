import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DownloadExcel from './components/ExportExcel';
import {useObtenerCuentas} from '../../hooks/useObtenerCuentas';
import AdminCuenta from './components/AdminCuenta';
import EliminarCuenta from './components/EliminarCuenta';
import '../../../styles/tablaCuentas.css';
import ImgPlus from '../../../img/icons/plus.svg';
import ImgDelete from '../../../img/icons/trash.svg';
import ImgEdit from '../../../img/icons/edit.svg';
import ImgExcel from '../../../img/icons/document-download.svg';
import ImgPdf from '../../../img/icons/document-text-1.svg';

const Cuentas = ({OpenModalNuevo,CerrarModal}) =>{

    const [cuentas, loading] = useObtenerCuentas();
    const [data, setData] = useState('');

    //TRANSFORM DATA
    useEffect(()=>{
        if(cuentas){
            const temp = cuentas.map((cuenta)=>{
                return [
                    cuenta.nombreBanco,
                    cuenta.tipoCuenta,
                    parseFloat(cuenta.numeroCuenta),
                    cuenta.titularCuenta,
                    cuenta.tipoId + ' ' +cuenta.idTitular
                ]
            });
            setData(temp);
        }
    },[cuentas]);

    //DOWNLOAD PDF
    const DownloadPDF = async() =>{
        const doc = new jsPDF();
        doc.autoTable({
            head: [['Banco','Tipo de cuenta','Número de Cuenta','Titular de Cuenta','Id Titular']],
            body: data
            });
        doc.save('cuentas-colcambios.pdf');
    }

    const AbrirAdmin = (e) =>{OpenModalNuevo(<AdminCuenta OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} id={e.currentTarget.dataset.id}/>)}
    const AbrirEliminar = (e) =>{OpenModalNuevo(<EliminarCuenta OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} cuenta={e.currentTarget.dataset.id}/>)}

    return(
        <div className="row m-0">
            
            <div className="col-12 p-0">
                <div className="dashboardCard">
                    <p className="CardTitulo">Cuentas Bancarias</p>
                    <button className="boton-general imgButton-left" onClick={AbrirAdmin}><img src={ImgPlus} alt="agregar"/>Agregar Cuenta </button>
                    {loading ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
                    : <>
                        {cuentas.length > 0 ?
                            <>
                                <div className="divTableContent">
                                    <div className="divTable-head">
                                        <div className="divTable-headRow" id="tableActions2">
                                            <div>Banco</div>
                                            <div>Cuenta</div>
                                            <div>No. de cuenta</div>
                                            <div>Titular</div>
                                            <div>Id Titular</div>
                                        </div>
                                    </div>
                                        
                                    {cuentas.map((cuenta) => {
                                        return(
                                            <div className="divTable-body" key={cuenta.id}>
                                                <div className="divTable-row" id="table5td">
                                                    <div><label>Banco</label>{cuenta.nombreBanco}</div>
                                                    <div><label>Cuenta</label>{cuenta.tipoCuenta}</div>
                                                    <div><label>No. Cuenta</label>{cuenta.numeroCuenta}</div>
                                                    <div><label>Titular</label>{cuenta.titularCuenta}</div>
                                                    <div><label>Id Titular</label>{cuenta.tipoId+ ' ' + cuenta.idTitular}</div>
                                                </div>
                                                <div className="tableActions">
                                                    <div><button onClick={AbrirAdmin} data-id={cuenta.id}><img src={ImgEdit} alt="edit" /></button></div>
                                                    <div><button onClick={AbrirEliminar} data-id={cuenta.id}><img src={ImgDelete} alt="delete" /></button></div>
                                                </div>
                                            </div>
                                        )})
                                    }
                                </div>
                                <div className="TableFooterNormal">
                                    <button className="BtnExport BtnExcel" onClick={()=> DownloadExcel({data})}><img src={ImgExcel} alt="Excel" />Exportar a Excel</button>
                                    <button className="BtnExport BtnPdf" onClick={DownloadPDF}><img src={ImgPdf} alt="PDF" />Exportar a PDF</button>
                                </div>
                            </> 
                            :
                            <div className="itemTransferEmpty">
                                <p>Aún no hay Cuentas</p>
                                <button className="boton-general">Ok</button>
                            </div>
                        }
                    </>}
                </div>
            </div>
        </div>
    );
}

export default Cuentas;