import React, {useState, useEffect} from 'react';
import NumberFormat from 'react-number-format';
import ImgCoin from '../../../img/icons/coin-1.svg';
import SelectorSelec from '../../../views/components/inputs/Selector';

const Recotizar = (props) =>{

    const {
        AbrirRecotizar,
        pagado,
        cargos,
        tasa,
        metodo,
        fee,
        setNewSaldoTs,
        setNewCargosTs,
        setMontoCop,
        setNewTasaTs,
        setNewMetodo,
        setNewFeeTs,
        setRecibidoTs,
        setNewObserv,
        setNewEmailSaldo,
        setNewEmailTasa,
        setNewEmailAud
    } = props;
    
    const [newSaldo, setNewSaldo] = useState(pagado);
    const [newCargos, setNewCargos] = useState(cargos);
    const [newTasa, setNewTasa] = useState(tasa);
    const [newFee, setNewFee] = useState(fee);
    const [nuevoMetodo, setNuevoMetodo] = useState(metodo);
    const [recibido, setRecibido] = useState('');
    const [observaciones, setObservaciones] = useState('');

    const [altNewSaldo, setAltNewSaldo] = useState('');
    const [altNewCargos, setAltNewCargos] = useState('');
    const [altNewTasa, setAltNewTasa] = useState('');
    const [altNewFee, setAltNewFee] = useState('');
    const [altObservaciones, setAltObservaciones]= useState('');
    const [spnNewSaldo, setSpnNewSaldo] = useState(false);
    const [spnNewCargos, setSpnNewCargos] = useState(false);
    const [spnNewTasa, setSpnNewTasa] = useState(false);
    const [spnNewFee, setSpnNewFee] = useState(false);
    const [spnMetodo, setSpnMetodo] = useState(false);
    const [spnObservaciones, setSpnObservaciones]= useState(false);

    //METODOS
    const metodos = [
        { id: '1', texto: 'Tradicional' },
        { id: '2', texto: 'UltraRápido' },
    ]

    useEffect(()=>{
        setNewMetodo(nuevoMetodo)
    },[nuevoMetodo, setNewMetodo])

    //FUNCIONES
    const handleSaldo = (values) =>{
        setNewEmailSaldo(values.formattedValue);
        setNewSaldo(values.value);
        setNewSaldoTs(values.value);
        setMontoCop(values.value);
        setSpnNewSaldo(false);
    }

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'tasa' : setNewTasa(e.target.value); setNewTasaTs(e.target.value); setSpnNewTasa(false); break;
            case 'cargos' : setNewCargos(e.target.value); setNewCargosTs(e.target.value); setSpnNewCargos(false); break;
            case 'observ': setObservaciones(e.target.value); setNewObserv(e.target.value); setSpnObservaciones(false); break;
            default: break;
        }
    }

    const handleFee = (values) =>{setNewFee(values.value);setNewFeeTs(values.value);setSpnNewFee(false);}
    const handleEmailAud = (values) =>{setNewEmailAud(values.formattedValue);}
    const handleTasa = (values) =>{setNewEmailTasa(values.formattedValue);}

    useEffect(()=>{
        if(newTasa){ 
            setRecibido(Math.floor(((newSaldo - newCargos) / newTasa) - newFee));
            setRecibidoTs(Math.floor(((newSaldo - newCargos) / newTasa) - newFee));
        }
    },[newTasa, newFee, newSaldo, newCargos, setRecibidoTs]);

    const handleSubmit = (e) =>{
        e.preventDefault();
        if(newSaldo===''){setSpnNewSaldo(true);setAltNewSaldo('Ingresa el saldo');return;}
        if(newCargos===''){setSpnNewCargos(true);setAltNewCargos('Ingresa un Valor');return}
        if(newTasa===''){setSpnNewTasa(true); setAltNewTasa('Ingresa la nueva tasa'); return;}
        if(newFee===''){setSpnNewFee(true); setAltNewFee('Ingresa la nueva tasa'); return;}
        if(observaciones===''){setSpnObservaciones(true); setAltObservaciones('Ingresa la observación'); return;}
        AbrirRecotizar();
    }

    return(
        <div className="col-12 p-0">
            <form onSubmit={handleSubmit}>
                <div className="col-12"><div className={!spnNewSaldo ? 'input-general': 'input-general redLine'}>
                    <NumberFormat value={newSaldo} thousandSeparator={'.'} onValueChange={handleSaldo}
                        decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/>
                    <label>Valor Pagado</label>
                    {spnNewSaldo && <span className="spanAlert">{altNewSaldo}</span>}
                </div></div>
                <div className="col-12"><div className={!spnNewCargos ? 'input-general': 'input-general redLine'}>
                    <input name='cargos' type='numer' placeholder=' ' onChange={handleChange} value={newCargos} />
                    <label>Cargos</label>
                    {spnNewCargos && <span className="spanAlert">{altNewCargos}</span>}
                </div></div>
                <div className="col-12"><div className={!spnNewTasa ? 'input-general': 'input-general redLine'}>
                    <input name='tasa' type="number" placeholder=" " onChange={handleChange} value={newTasa} step="0.01" pattern="^\d*(\.\d{0,2})?$" />
                    <label>Nueva Tasa</label>
                    {spnNewTasa && <span className="spanAlert">{altNewTasa}</span>}
                    <div className="noSee">
                        <NumberFormat value={newTasa} thousandSeparator={'.'} placeholder=' ' onValueChange={handleTasa}
                            decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/>
                    </div>
                </div></div>
                <div className="col-12">
                    <SelectorSelec opciones={metodos} TipoOpcion={'Método'} opcion={nuevoMetodo} 
                        cambiarOpcion={setNuevoMetodo} spnRed={spnMetodo} setSpnRed={setSpnMetodo}/>
                </div>
                <div className="col-12"><div className={!spnNewFee ? 'input-general': 'input-general redLine'}>
                    <NumberFormat value={newFee} thousandSeparator={','} decimalSeparator={'.'}
                        prefix={'$'} suffix={' AUD'} decimalScale={'2'} onValueChange={handleFee}/>
                    <label>Fee</label>
                    {spnNewFee && <span className="spanAlert">{altNewFee}</span>}
                </div></div>
                <div className="col-12"><div className='input-disabled'>
                    <NumberFormat value={recibido} thousandSeparator={','} decimalSeparator={'.'} decimalScale={'2'} 
                        prefix={'$'} suffix={' AUD'} disabled={true} onValueChange={handleEmailAud}/>
                    <label>Recibe</label>
                </div></div>
                <div className="col-12"><div className={!spnObservaciones ? 'input-general': 'input-general redLine'}>
                    <textarea type="text" value={observaciones} name="observ" placeholder=" " id="textAreaContact" onChange={handleChange} />
                    <label>Observaciones</label>
                    {spnObservaciones && <span className="spanAlert">{altObservaciones}</span>}
                </div></div>
                <div className="col-12">
                    <button type="submit" className="boton-general BtnLarge imgButton-left">
                        <img src={ImgCoin} alt="coin" />Recotizar</button>
                </div>
            </form>
        </div>
    );
}

export default Recotizar;
