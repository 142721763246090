import React from 'react';
import './modales.css';

const ModalNuevo = ({ modal, CerrarModal, componente, clases, estadoModal}) => {
    return (
        <>
            {estadoModal &&
                <>
                    <div className={modal}>
                        {componente}
                    </div>
                    <div className={clases} onClick={CerrarModal}></div>
                </>
            }
        </>
    );
}

export default ModalNuevo;
