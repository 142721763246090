import { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { useIdleTimer } from 'react-idle-timer';

const useRate = () => {

    const [rate, setRate] = useState();
    const [tasa, setTasa] = useState();
    const [tasaPre, setTasaPre] = useState();
    const [loading, setLoading] = useState(true);
    
    useEffect(() =>{
        const unsuscribe = db.collection('config').doc('rate').get()
        .then(snapshot =>{
            setLoading(false);
            setRate(snapshot.data().rate);
            setTasa(snapshot.data().tasa);
            setTasaPre(snapshot.data().tasaPre);
        })
        
        return unsuscribe;
    },[]);

    const handleOnActive = () => {
        const tasa = db.collection('config').doc('rate').get()
        .then(snapshot =>{
            setRate(snapshot.data().rate);
            setTasa(snapshot.data().tasa);
            setTasaPre(snapshot.data().tasaPre);
        })
        return tasa;
    }

    useIdleTimer({
        onActive: handleOnActive,
        timeout: 900000,
    })
    
    return [rate, tasa, tasaPre, loading];
}

export default useRate;