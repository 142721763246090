import React, { useState } from 'react';
import './selector.css';
import arrowDown from '../../../img/icons/arrow-down.svg';

const DobleSelect = ({ opciones1, opciones2, TipoOpcion, opcion1, opcion2, cambiarOpcion1, cambiarOpcion2}) => {

    const [mostrarSelect1, cambiarMostrarSelect1] = useState(false);
    const [mostrarSelect2, cambiarMostrarSelect2] = useState(false);

    const Seleccion = (e) =>{e.preventDefault(); cambiarMostrarSelect1(!mostrarSelect1);}
    const LeaveSelect = (e) =>{e.preventDefault(); cambiarMostrarSelect1(false)}
    const Seleccion2 = (e) =>{ e.preventDefault(); cambiarMostrarSelect2(!mostrarSelect2);}
    const LeaveSelect2 = (e) =>{e.preventDefault(); cambiarMostrarSelect2(false)}

    const handleClick = (e) => {cambiarOpcion1(e.currentTarget.dataset.valor);}
    const handleClick2 = (e) => { cambiarOpcion2(e.currentTarget.dataset.valor);}

    return (
        <div className="DobleSelect">
            <label className='Label_out'>{TipoOpcion}</label>
            <div className="Selec" onClick={Seleccion} onMouseLeave={LeaveSelect}>
                <div className={!mostrarSelect1 ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue'}>
                    {opcion1}
                </div>
                <img className={!mostrarSelect1 ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} alt="Desplegar"/>
                {mostrarSelect1 &&
                    <div className="Selec_opciones">
                        {opciones1.map((opcion) => {
                            return <div 
                            key={opcion.id} 
                            data-valor={opcion.texto} 
                            onClick={handleClick} 
                            className="Select_opcion">
                            {opcion.texto}</div> 
                        })}
                    </div>
                }
            </div>
            <div className="Selec" onClick={Seleccion2} onMouseLeave={LeaveSelect2}>
                <div className={!mostrarSelect2 ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue'}>
                    {opcion2}
                </div>
                <img className={!mostrarSelect2 ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} alt="Desplegar"/>
                {mostrarSelect2 &&
                    <div className="Selec_opciones">
                        {opciones2.map((opcion) => {
                            return <div 
                            key={opcion.id} 
                            data-valor={opcion.texto} 
                            onClick={handleClick2} 
                            className="Select_opcion">
                            {opcion.texto}</div> 
                        })}
                    </div>
                }
            </div>
        </div>
    );
}

export default DobleSelect;