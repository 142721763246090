import React from 'react';
import Convertir from '../client/components/conversor/Convetir';
import Testimonios from './components/testimonios/Testimonios';

const Inicio = ({OpenModalNuevo, CerrarModal}) =>{

    return(
        <>
            <div className="banner-inicio">
                <div className="contCurrency">
                    <div className="containerCurrency">
                        <Convertir OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/>
                    </div>
                </div>
            </div>
            
            <div className="GeneralCont">
                <div className="Fees">
                    <h1 className="SectionTitle TxtCenter">Tipos Servicio</h1>
                    <p className="SectionText TxtCenter">En Colcambios Australia tú decides cómo enviar tu dinero.</p>

                    <div className="FeesContainer">
                        <div className="FeesDiv">
                            <p className="FeeName">Tradicional</p>
                            <div className="FeeText">
                                <p className="FeeValor">$4.99 <span>AUD</span></p>
                                <p className="FeeDesc">Una vez pagada la transferencia y enviado el comprobante, la transferencia puede tardar hasta <span>24 horas hábiles.</span></p>
                                <p className="FeeDesc">El valor máximo de envío por persona en el día es de <span>$9.999 AUD.</span></p>
                            </div>
                        </div>
                        <div className="FeesDiv">
                            <p className="FeeName">UltraRápido</p>
                            <div className="FeeText">
                                <p className="FeeValor">$14.99 <span>AUD</span></p>
                                <p className="FeeDesc">Una vez pagada la transferencia y enviado el comprobante, la transferencia <span>se efectúa de inmediato.</span></p>
                                <p className="FeeDesc">El valor máximo de envío por persona en el día es de <span>$4.999 AUD.</span></p>
                            </div>
                        </div>
                    </div>
                </div>

                <Testimonios />
            </div>
            
        </>
    )
}

export default Inicio;