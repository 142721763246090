import { db } from '../../firebase/firebaseConfig';

const AgregarCuenta = (props) => {
    const{
        id,
        numero,
        numContacto,
        banco,
        titular,
        bsb,
        cuenta,
    } = props;
    
    const ids = ('cuentas.cuenta'+(numero + 1));
    const cantidad = parseInt(numero + 1)
    const contactos = parseInt(numContacto + 1)

    return db.collection('usuarios').doc(id).update({
        toSelf: true,
        destino: true,
        contactos: contactos,
        numero: cantidad,
        [ids]:{
            banco: banco,
            titular: titular,
            bsb: bsb,
            cuenta: cuenta
        }
    });
}

export {AgregarCuenta};