import React,{ useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useIdleTimer } from 'react-idle-timer';

import App from './../App';
import LoginUsuarios from '../views/login/LoginUsuarios';
import Error404 from '../views/error/Error404';
import Dashboard from '../views/Dashboard';
import DashboardAdmin from '../admin/DashboardAdmin';
import TerminarRegistro from '../views/login/TerminarRegistro';
import VerificarEmail from '../views/login/VerificarEmail';

import NuevaTransferencia from '../client/views/transferencias/NuevaTransferencia';
import NuevoDestinatario from '../client/views/destinatarios/NuevoDestinatario';
import NuevaCuenta from '../client/views/destinatarios/NuevaCuenta';
import InfoToSelf from '../views/login/InfoToSelf';

import RutaPrivada from './RutaPrivada';
import RutaPrivadaAdmin from './RutasPrivadasAdmin';
// import VerificacionID from '../views/sumsub/VerificacionID';

const Router = () => {

    const {infoUser, usuario, cerrarSesion} = useAuth();
    const [admin,setAdmin]= useState(false);

    useEffect(()=>{
        if(infoUser){
            if(infoUser.rol === 'Administrador'){setAdmin(true)}
            else{setAdmin(false);}
        }
    },[infoUser])


    const handleOnIdle = () => {
        if(usuario){cerrarSesion();}
    }

    useIdleTimer({
        onIdle: handleOnIdle,
        timeout: 900000,
    })

    return (
        <>
            <Switch>
                {/*GENERALES*/}
                <Route path="/" exact={true} ><App Route="/"/></Route>
                <Route path="/como-funciona"><App Route="/como-funciona" /></Route>
                <Route path="/por-que-elegirnos"><App Route="/por-que-elegirnos"/></Route>
                <Route path="/ayuda"><App Route="/ayuda"/></Route>
                <Route path="/terminos-y-condiciones"><App Route="/terminos-y-condiciones"/></Route>
                <Route path="/politicas-de-privacidad"><App Route="/politicas-de-privacidad"/></Route>

                {/*USUARIOS*/}
                {/* <RutaPrivada path="/verificacion"><VerificacionID /></RutaPrivada> */}
                <RutaPrivada path="/terminar-registro"><TerminarRegistro /></RutaPrivada>
                <RutaPrivada path="/nueva-transferencia"><NuevaTransferencia /></RutaPrivada>
                <RutaPrivada path="/nuevo-destinatario"><NuevoDestinatario /></RutaPrivada>
                <RutaPrivada path="/nueva-cuenta-autoenvio"><NuevaCuenta /></RutaPrivada>
                <RutaPrivada path="/registro-autoenvio"><InfoToSelf /></RutaPrivada>
                
                {admin ? <RutaPrivadaAdmin path="/dashboard"><DashboardAdmin Route="/dashboard" /></RutaPrivadaAdmin> :
                <RutaPrivada path="/dashboard"><Dashboard Route="/dashboard"/></RutaPrivada>}
                <RutaPrivada path="/transferencias"><Dashboard Route="/transferencias"  /></RutaPrivada>
                {admin ? <RutaPrivadaAdmin path="/transferencia/:id"><DashboardAdmin Route="transferencia/:id" /></RutaPrivadaAdmin> : 
                <RutaPrivada path="/transferencia/:id"><Dashboard Route="/transferencia/:id"  /></RutaPrivada>}
                <RutaPrivada path="/destinatarios"><Dashboard Route="/destinatarios" /></RutaPrivada>
                <RutaPrivada path="/destinatario/:id"><Dashboard Route="/destinatario/:id"  /></RutaPrivada>
                {admin ? <RutaPrivadaAdmin path="/perfil"><DashboardAdmin Route="/perfil" /></RutaPrivadaAdmin> :
                <RutaPrivada path="/perfil"><Dashboard Route="/perfil" /></RutaPrivada>}

                {/*ADMIN*/}
                <RutaPrivadaAdmin path="/transacciones"><DashboardAdmin Route="/transacciones" /></RutaPrivadaAdmin>
                <RutaPrivadaAdmin path="/usuarios"><DashboardAdmin Route="/usuarios" /></RutaPrivadaAdmin>
                <RutaPrivadaAdmin path="/usuario/:id"><DashboardAdmin Route="/usuario/:id" /></RutaPrivadaAdmin>
                <RutaPrivadaAdmin path="/codigos/codigos-promocionales"><DashboardAdmin Route="/codigos-promocionales" /></RutaPrivadaAdmin>
                <RutaPrivadaAdmin path="/codigos/codigos-referidos"><DashboardAdmin Route="/codigos-codigos-referidos" /></RutaPrivadaAdmin>
                <RutaPrivadaAdmin path="/agencias"><DashboardAdmin Route="/agencias" /></RutaPrivadaAdmin>
                
                {/*LOGIN*/}
                <Route path="/ingresar"><LoginUsuarios Route="/ingresar" /></Route>
                <Route path="/recuperar-contrasena"><LoginUsuarios Route="/recuperar-contrasena" /></Route>
                <Route path="/registrate"><LoginUsuarios Route="/registrate" /></Route>
                <RutaPrivada path="/verificar-email" component={VerificarEmail} />
                <Route component={Error404} />
            </Switch>
        </>
    );
}

export default Router;
