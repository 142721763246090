import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSetAgencias } from '../../hooks/useAgencias';
import AdminAgencia from './components/AdminAgencia';
import Eliminar from './components/Eliminar';
import DivLoading from '../../../views/components/loading/Loading';
import Check from '../../../img/icons/tick-square.svg';
import ImgEdit from '../../../img/icons/edit.svg';
import ImgDelete from '../../../img/icons/trash.svg';

const Agencias = ({OpenModalNuevo,CerrarModal}) =>{

    const [agencias, loading] = useSetAgencias();
    const AbrirEliminar = (e) =>{OpenModalNuevo(<Eliminar OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} idDelete={e.currentTarget.dataset.id}/>)}
    const AbrirAdmin = (e) =>{OpenModalNuevo(<AdminAgencia OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} idAgencia={e.currentTarget.dataset.id}/>)}
    
    return(
        <>
            <Helmet><title>Agencias</title></Helmet>
            <div className="TituloPag"><p>Agencias</p></div>
            <div className="dashboardCard">
                <p className="CardTitulo">Administrar Agencias</p>
                <button className="boton-general imgButton-left" onClick={AbrirAdmin}>
                    <img src={Check} alt="Finalizar"/>Crear Agencia</button>
                {loading ? <DivLoading /> : <>
                    {agencias.length > 0 &&
                        <div className="divTableContent">
                            <div className="divTable-head">
                                <div className="divTable-headRow" id="tableActions2">
                                    <div>ABN</div>
                                    <div>Id</div>
                                    <div>Nombre</div>
                                    <div>Estado</div>
                                </div>
                            </div>
                            {agencias.map((agencia) => {
                                return(
                                    <div className="divTable-body" key={agencia.id}>
                                        <div className="divTable-row" id="table5td">
                                            <div><label>ABN</label>{agencia.abn}</div>
                                            <div><label>Referido</label>{agencia.id}</div>
                                            <div><label>Nombre</label>{agencia.nombre}<br/>{agencia.numeroId}</div>
                                            <div><label>Estado</label>{agencia.estado}</div>
                                        </div>
                                        <div className="tableActions">
                                            <div><button onClick={AbrirAdmin} data-id={agencia.id}><img src={ImgEdit} alt="edit" /></button></div>
                                            <div><button onClick={AbrirEliminar} data-id={agencia.id}><img src={ImgDelete} alt="delete" /></button></div>
                                        </div>
                                    </div>
                                )})
                            }
                        </div>
                    }</>}
            </div>
        </>
    )
}

export default Agencias;