import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { AgregarCuenta } from '../../data/AgregarCuenta';
import Footer from '../../../views/components/footer/Footer';
import '../../../styles/Procesos.css';
import '../../../views/components/modales/modales.css';
import ImgBank from '../../../img/icons/bank.svg';
import Close from '../../../img/icons/close-square.svg';
import Check from '../../../img/icons/tick-square.svg';
import imgWrong from '../../../img/icons/close-square.svg';
import LoadingPage from '../../../views/components/loading/LoadingPage';

const NuevaCuenta = () =>{
    
    const [cargando, setCargando] = useState(true);
    const [completo, setCompleto] = useState(false);
    const [validando, setValidando] = useState(false);
    const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}

    const [nombre, setNombre] = useState('');
    const [sdoNombre, setSdoNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [numero, setNumero] = useState('');
    const [numContact, setNumContact] = useState('');

    //USUARIO LOGUEADO 
    const { usuario, infoUser } = useAuth();
    const uidUsuario = usuario.uid

    useEffect(()=>{
        if(!infoUser){
            setCargando(false);
        }
        else{
            setNombre(infoUser.nombre.nombre);
            setSdoNombre(infoUser.nombre.segNombre);
            setApellido(infoUser.nombre.apellido);
            setNumero(infoUser.numero);
            setNumContact(infoUser.contactos);
            setCargando(false);
        }
    },[infoUser])

    //INFORMACION DE CUENTA
    const [banco, setBanco] = useState('');
    const [titular, setTitular] = useState('');
    const [bsb, setBsb] = useState('');
    const [bsb2, setBsb2] = useState('');
    const [numeroCuenta, setNumeroCuenta] = useState('');
    const [numeroCuenta2, setNumeroCuenta2] = useState('');

    //RECOMENDACIONES
    const[altNBanco, setAltNBanco] = useState();
    const[altTitular, setAltTitular] = useState();
    const[altBsb, setAltBsb] = useState();
    const[altBsb2, setAltBsb2] = useState();
    const[altNCuenta, setAltNCuenta] = useState();
    const[altNCuenta2, setAltNCuenta2] = useState();

    const[spnNBanco, setSpnNBanco] = useState(false);
    const[spnTitular, setSpnTitular] = useState(false);
    const[spnBsb, setSpnBsb] = useState(false);
    const[spnBsb2, setSpnBsb2] = useState(false);
    const[spnNCuenta, setSpnNCuenta] = useState(false);
    const[spnNCuenta2, setSpnNCuenta2] = useState(false);

    useEffect(()=>{
        if(nombre||sdoNombre||apellido){
            setTitular(nombre+' '+sdoNombre+' '+apellido);
        }
    },[nombre,sdoNombre,apellido]);

    //FUNCIONES
    const handleChange = (e) => {
        switch (e.target.name) {            
            case 'nombreBanco': setBanco(e.target.value); setSpnNBanco(false); break;
            case 'titular' : setTitular(e.target.value); setSpnTitular(false); break;
            case 'numeroBsb': setBsb(e.target.value); setSpnBsb(false); setSpnBsb2(false); break;
            case 'numeroBsb2': setBsb2(e.target.value); setSpnBsb(false); setSpnBsb2(false); break;
            case 'numeroCuenta': setNumeroCuenta(e.target.value); setSpnNCuenta(false); setSpnNCuenta2(false); break;
            case 'numeroCuenta2': setNumeroCuenta2(e.target.value); setSpnNCuenta(false); setSpnNCuenta2(false); break;
            default: break;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!banco){setSpnNBanco(true); setAltNBanco('Por favor ingresa el nombre del Banco'); return;}
        if (!titular){setSpnTitular(true); setAltTitular('Por favor ingresa el nombre del Titular'); return;}
        if (!bsb){setSpnBsb(true); setAltBsb('Por favor ingresa el número BSB'); return;}
        if (bsb.length !== 6){setSpnBsb(true); setAltBsb('El número debe ser de 6 dígitos'); return;}
        if (!bsb2){setSpnBsb2(true); setAltBsb2('Por confirma el número BSB'); return;}
        if (bsb2 !== bsb){setSpnBsb2(true); setAltBsb2('El número BSB no es igual'); return;}
        if (!numeroCuenta){setSpnNCuenta(true); setAltNCuenta('Por favor ingresa el número de la cuenta'); return;}
        if (!numeroCuenta2){setSpnNCuenta2(true); setAltNCuenta2('Por favor confirma el número de la cuenta'); return;}
        if (numeroCuenta2 !== numeroCuenta){setSpnNCuenta2(true); setAltNCuenta2('El número cuenta no es igual'); return;}

        toUp();
        setValidando(true);

        AgregarCuenta({
            id: uidUsuario,
            numero: numero,
            numContacto: numContact,
            banco: banco,
            titular: titular,
            bsb: bsb,
            cuenta: numeroCuenta,
        }).then(() => {
            setCompleto(true);
            setValidando(false);
        }).catch(() => {
            setValidando(false);
            openModal();
        })
    }

    //MODALES
    const [modal, cambiarModal] = useState('modal-general');
    const [clases, cambiarClases] = useState('fonder');

    const openModal = (e) =>{
        e.preventDefault();
        setTimeout(() => {
            cambiarModal('modal-general modal-activo')
            cambiarClases('fonder fonder-activo')
        }, 200);
    }

    const CerrarModal = (e) =>{
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
    }

    const CerrarModalNone = (e) =>{
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
    }

    return(
        <>{!cargando ? <>
            <div className="divContainer">
                <div className="divSubContainer">
                    <form onSubmit={handleSubmit} className="formData">
                        <div className="formProgress">
                            {completo ? <h3>Cuenta Registrada</h3>
                            : <>{validando ? <h3>Cargando...</h3> 
                                :  <ul><li className="formProgress-current"><span><img src={ImgBank} alt="Pago" /></span>
                                        <label>Información de</label>Cuenta</li></ul>
                            }</>}
                        </div>
                        <div className="formContent">
                            {!validando ? <> {completo ? 
                                <div className="Completado">
                                    <div className="CompletadoImg"><img src={Check} alt="listo" /></div>
                                    <p>Tu cuenta se ha <b>registrado con éxito.</b></p>
                                    <a href="/dashboard" className="boton-general">Ir al Inicio</a>
                                </div> 
                            :<>
                                <div className="formTitle">
                                    <h1 className="text-title">Nueva Cuenta para AutoEnvío</h1>
                                    <p>Recuerda que esta información debe ser precisa para evitar los contratiempos en las transferencias.
                                    <b> la cuenta debe ser una cuenta principal y todos los campos son obligatorios</b>.</p>
                                </div>
                                <div className="step step1 habil">
                                    <div className="row p-0">
                                        <div className="col-12 col-md-6"><div className={!spnNBanco ? 'input-general': 'input-general redLine'}>
                                            <input name="nombreBanco" type="text" placeholder=" " value={banco} onChange={handleChange}/>
                                            <label>Nombre del Banco</label>
                                            {spnNBanco && <span className="spanAlert">{altNBanco}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnTitular ? 'input-general': 'input-general redLine'}>
                                            <input name="titular" type="text" placeholder=" " value={titular} onChange={handleChange}/>
                                            <label>Nombre del titular</label>
                                            {spnTitular && <span className="spanAlert">{altTitular}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnBsb ? 'input-general': 'input-general redLine'}>
                                            <input name="numeroBsb" type="number" placeholder=" " value={bsb} onChange={handleChange} />
                                            <label>Número BSB</label>
                                            {spnBsb && <span className="spanAlert">{altBsb}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnBsb2 ? 'input-general': 'input-general redLine'}>
                                            <input name="numeroBsb2" type="number" placeholder=" " value={bsb2} onChange={handleChange} />
                                            <label>Confirmar Número BSB</label>
                                            {spnBsb2 && <span className="spanAlert">{altBsb2}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnNCuenta ? 'input-general': 'input-general redLine'}>
                                            <input name="numeroCuenta" type="number" placeholder=" " value={numeroCuenta} onChange={handleChange} />
                                            <label>Número de cuenta</label>
                                            {spnNCuenta && <span className="spanAlert">{altNCuenta}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnNCuenta2 ? 'input-general': 'input-general redLine'}>
                                            <input name="numeroCuenta2" type="number" placeholder=" " value={numeroCuenta2} onChange={handleChange} />
                                            <label>Confirmar Número de cuenta</label>
                                            {spnNCuenta2 && <span className="spanAlert">{altNCuenta2}</span>}
                                        </div></div>
                                    </div>
                                </div>
                                <div className="formButtons BtnsRigth">
                                    <button type="submit" className="boton-general imgButton-rigth">Registrar Cuenta<img src={Check} alt="Finalizar"/></button>
                                </div>
                            </> } </> :
                                <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>  
                            }
                        </div>
                    </form>
                    {!validando && <> {!completo &&
                        <div className="divSalir">
                            <p>¿Deseas agregar tu cuenta después?</p>
                            <Link to="/dashboard" className="boton-text imgButton-rigth">Cancelar<img src={Close} alt="Salir" /></Link> 
                        </div>
                    }</>}
                </div>
            </div>
            <Footer />
            <div className={modal}>
                <div className="InnerModal InnerWrong">
                    <div className="IconModal IconWrong">
                        <img src={imgWrong} alt="Wrong" />
                    </div>
                    <h2>Oops!</h2>
                    <p>Algo salió mal..<br/><b>Inténtalo de nuevo.</b></p>
                    <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
                </div>
            </div>
            <div className={clases} onClick={CerrarModalNone}></div>
        </> : 
            <LoadingPage />
        }</>
    )
}

export default NuevaCuenta;