import { db } from '../../firebase/firebaseConfig';

const RecotizarValor = (props) => {
    const{
        id,
        recotizado,
        transferencia,
        tasaAnterior,
        saldoAnterior,
        observaciones
    } = props;
    return db.collection('transferencias').doc(id).update({
        recotizado: recotizado,
        transferencia: transferencia,
        tasaAnterior: tasaAnterior,
        saldoAnterior: saldoAnterior,
        observaciones: observaciones
    });
}

const EliminarComprobante = (props) => {
    const{
        id,
    } = props;
    return db.collection('transferencias').doc(id).update({
        estado: 'Registrada',
        comprobantePago:''
    });
}

const AgregarPago = (props) => {
    const{
        id,
        estado,
        factura,
        pagoTrans,
        pagoMetodo
    } = props;
    return db.collection('transferencias').doc(id).update({
        estado: estado,
        factura: factura,
        pagoTrans:pagoTrans,
        pagoMetodo: pagoMetodo
    });
}

const EditarTransferencia = (props) => {
    const{
        id,
        estado,
        factura,
    } = props;
    return db.collection('transferencias').doc(id).update({
        estado: estado,
        factura: factura
    });
}

const ActualizarTransferencia = (props) => {
    const{
        id,
        estado,
    } = props;
    return db.collection('transferencias').doc(id).update({
        estado: estado,
    });
}

const AgregarComprobante = (props) => {
    const{
        id,
        estado,
        comprobantePago
    } = props;
    return db.collection('transferencias').doc(id).update({
        estado: estado,
        comprobantePago:comprobantePago
    });
}

const AgregarReferido = (props) =>{
    const{
        id, referido
    } = props;
    return db.collection('transferencias').doc(id).update({
        referido: referido
    });
}

export { RecotizarValor, EliminarComprobante, AgregarPago, EditarTransferencia, ActualizarTransferencia, AgregarComprobante, AgregarReferido};