import React from 'react';
import { Helmet } from 'react-helmet-async';
import SaludoDashboard from '../views/components/SaludoDashboard';
import Cuentas from './components/cuentas/Cuentas';
import Testimonios from './components/testimonios/Testimonios';
import Rate from './components/rates/Rate';
import ConfigFees from './components/fees/ConfigFees';

const InicioAdmin = ({OpenModalNuevo,CerrarModal}) => {
    return (
        <>
            <Helmet><title>Dashboard Admin</title></Helmet>
            <SaludoDashboard/>
            <Rate OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/>
            <ConfigFees OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/>
            <Testimonios OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/>
            <Cuentas OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/>
        </>
    );
}

export default InicioAdmin;