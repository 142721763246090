import React, {useState, useEffect} from 'react';
import NumberFormat from 'react-number-format';

const FixValues = ({setEmailCop, setEmailAud, setEmailTasa, totalaPagar, totalRecibido, tasaEmail}) =>{

    const [emailCop, mailCop] = useState(0);
    const [emailAud, mailAud] = useState(0);
    const [emailTasa, mailtasa] = useState(0);

    useEffect(()=>{
        if(totalaPagar){
            mailCop(totalaPagar);
        }
    },[totalaPagar])

    useEffect(()=>{
        if(totalRecibido){
            mailAud(totalRecibido);
        }
    },[totalRecibido])

    useEffect(()=>{
        if(tasaEmail){
            mailtasa(tasaEmail)
        }
    },[tasaEmail])

    const handleCop = (values) =>{setEmailCop(values.formattedValue);}
    const handleAud = (values) =>{setEmailAud(values.formattedValue);}
    const handleTasa = (values) =>{setEmailTasa(values.formattedValue);}

    return(
        <div className="noSee">
            <NumberFormat value={emailCop} thousandSeparator={'.'} decimalScale={'2'} decimalSeparator={','} 
                prefix={'$'} suffix={' COP'} disabled={true} onValueChange={handleCop}/>
            <NumberFormat value={emailAud} thousandSeparator={','} decimalSeparator={'.'} decimalScale={'2'}
                prefix={'$'} suffix={' AUD'} disabled={true} onValueChange={handleAud}/>
            <NumberFormat value={emailTasa} thousandSeparator={'.'} decimalScale={'2'} decimalSeparator={','} 
                prefix={'$'} suffix={' COP'} disabled={true} onValueChange={handleTasa}/>
        </div>
    )
}

export default FixValues;