import React from 'react';
import { Document, Page, Text, Font, Image, View, StyleSheet } from '@react-pdf/renderer';
import NumberFormat from 'react-number-format';
import LightBarlow from "../../../fonts/Barlow-ExtraLight.ttf";
import RegularBarlow from "../../../fonts/Barlow-Regular.ttf";
import SemiBarlow from "../../../fonts/Barlow-Bold.ttf";
import BoldBarlow from "../../../fonts/Barlow-Black.ttf";
import Banner from "../../../img/receiptbaner1.jpg";
import Membrete from "../../../img/receiptback.jpg";

Font.register({
    family: "Barlow",
    fontStyle: "normal",
    fontWeight: "normal",
    fonts: [
      {
        src:  RegularBarlow,
      },
      {
        src: LightBarlow,
        fontWeight: "light",
      },
      {
        src: BoldBarlow,
        fontWeight: "black",
      },
      {
        src: SemiBarlow,
        fontWeight: "bold",
      },
    ],
  });

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#FFFFFF',
    },
    sectionone:{
      width: 230,
    },
    section: {
      position: 'relative',
      paddingHorizontal: 0,
      paddingVertical: 0,
      flexGrow: 1,
    },
    title:{
        fontFamily: "Barlow",
        fontWeight: "bold",
        fontSize: 38,
        color: '#FFFFFF',
    },
    Textnormal:{
        fontFamily: "Barlow",
        fontWeight: "light",
        paddingVertical: 2,
        fontSize: 11,
        color: '#1E1E1E',
    },
    TextBold:{
        fontFamily: "Barlow",
        fontWeight: "bold",
        paddingVertical: 2,
        fontSize: 11,
        color: '#929bac',
    },
    TextTitle:{
        paddingTop: 10,
        paddingBottom: 5,
        fontFamily: "Barlow",
        fontWeight: "black",
        fontSize: 16,
        color: '#929bac',
    },
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    containerText: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        paddingTop: 130,
        paddingLeft: 30,
    },
    ImagenMembrete:{
        position: 'absolute',
        height: '100%',
        bottom: 0,
        padding: 0,
    },
    contenedortabla:{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        paddingTop: 120,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    tabla:{
        borderRadius: 5,
        overflow: 'hidden',
    },
    Item:{
        backgroundColor:'#0018b4',
        width: 320,
        flexDirection: 'row',
        margin: 0,
        marginTop: -.5,
        padding: 0,
    },
    ItemRow:{
        backgroundColor: '#FFFFFF',
        width: 160,
        color: '#1E1E1E',
        fontFamily: "Barlow",
        fontWeight: "light",
        paddingVertical: 8,
        paddingHorizontal: 10,
        fontSize: 11,
        textAlign: 'right',
        border: '1px solid #ececec',
    }, 
    ItemColum:{
        backgroundColor:'#0018b4',
        width: 160,
        color: '#FFFFFF',
        fontFamily: "Barlow",
        fontWeight: "bold",
        paddingVertical: 8,
        paddingHorizontal: 10,
        fontSize: 11,
        border: '1px solid #00157f',
    },
    ItemTotal:{
      backgroundColor:'#081990',
      width: 160,
      color: '#FFEC00',
      fontFamily: "Barlow",
      fontWeight: "bold",
      paddingVertical: 8,
      paddingHorizontal: 10,
      fontSize: 11,
      border: '1px solid #00157f',
    },
    bold:{
        fontFamily: "Barlow",
        fontWeight: "bold",
    },
    grey:{
      fontFamily: "Barlow",
      fontWeight: "bold",
      color: '#929BAC',
    },
    observaciones:{
      position: 'absolute',
      top: 600,
      bottom: 0,
      left: 0,
      right: 0,
      paddingLeft: 260,
      width: 550
    }
  });

  // Create Document Component
  const MyDocument = ({
    fecha,
    numerorecibo,
    nombre,
    id,
    razon,
    telefono,
    titular,
    banco,
    numerobsb,
    numerocuenta,
    montocop,
    cargos,
    tasadecambio,
    metodo,
    fee,
    montoaud,
    observaciones
  }) =>(
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.sectionone}>
          <Image src={Banner}></Image>
        </View>
        <View style={styles.section}>
            <View style={styles.container}>
                <Image style={styles.ImagenMembrete}src={Membrete}></Image>
            </View>
            <View style={styles.containerText}>
                <Text style={styles.TextBold}>Fecha: <Text style={styles.Textnormal}>{fecha}.</Text></Text>
                <Text style={styles.TextBold}>Referencia: <Text style={styles.Textnormal}>{numerorecibo}.</Text></Text>
                <Text style={styles.TextTitle}>Remite</Text>
                <Text style={styles.TextBold}>Nombre: <Text style={styles.Textnormal}>{nombre}.</Text></Text>
                <Text style={styles.TextBold}>Id: <Text style={styles.Textnormal}>{id}.</Text></Text>
                <Text style={styles.TextBold}>Teléfono: <Text style={styles.Textnormal}>{telefono}.</Text></Text>
                <Text style={styles.TextBold}>Razón del envío: <Text style={styles.Textnormal}>{razon}.</Text></Text>
                <Text style={styles.TextTitle}>Destinatario</Text>
                <Text style={styles.TextBold}>Titular de la cuenta: <Text style={styles.Textnormal}>{titular}.</Text></Text>
                <Text style={styles.TextBold}>Banco: <Text style={styles.Textnormal}>{banco}.</Text></Text>
                <Text style={styles.TextBold}>Número BSB: <Text style={styles.Textnormal}>{numerobsb}.</Text></Text>
                <Text style={styles.TextBold}>Número de la Cuenta: <Text style={styles.Textnormal}>{numerocuenta}.</Text></Text>
            </View>
        </View>
        
        <View style={styles.contenedortabla}>
            <View style={styles.tabla}>
                <View style={styles.Item}>
                    <View style={styles.ItemColum}><Text>Valor Pagado</Text></View>
                    <View style={styles.ItemRow}><Text>{montocop}</Text></View>
                </View>
                <View style={styles.Item}>
                    <View style={styles.ItemColum}><Text>Costos Bancarios</Text></View>
                    <View style={styles.ItemRow}><Text>{cargos !== 0 && '-'}<NumberFormat value={cargos} displayType={'text'} thousandSeparator={'.'} 
                      decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={false} /></Text></View>
                </View>
                <View style={styles.Item}>
                    <View style={styles.ItemColum}><Text>Tasa de Cambio</Text></View>
                    <View style={styles.ItemRow}><Text>1 AUD = {tasadecambio} COP</Text></View>
                </View>
                <View style={styles.Item}>
                    <View style={styles.ItemColum}><Text>Servicio de Transferencia</Text></View>
                    <View style={styles.ItemRow}><Text style={styles.grey}>{metodo}</Text><Text>${fee} AUD</Text></View>
                </View>
                <View style={styles.Item}>
                    <View style={styles.ItemTotal}><Text>Valor Recibido</Text></View>
                    <View style={styles.ItemRow}><Text style={styles.bold}>{montoaud}</Text></View>
                </View>
            </View>
        </View>

        {observaciones &&
            <View style={styles.observaciones}>
              <Text style={styles.TextBold}>Observaciones</Text>
              <Text style={styles.Textnormal}>{observaciones}</Text>
            </View>
        }
        
      </Page>
    </Document>
  );

  export default MyDocument;

