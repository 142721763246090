import React, {useState, useEffect} from 'react';
import useObtenerContactos from '../../hooks/useObtenerContactos';
import { useTrans } from '../../../context/TransferContext';
import { useAuth } from '../../../context/AuthContext';
import { Link } from 'react-router-dom';
import './destinatariosInicio.css';
import FotodePerfil from '../../../img/perfil-default.jpg';
import ImgPlus from '../../../img/icons/plus.svg';
import Send from '../../../img/icons/send.svg';

const DestinatariosInicio = () => {

	const {infoUser, usuario} = useAuth();
	const {FijarDestino} = useTrans();
	const [destino, setDestino] = useState();

	const destinos = infoUser.contactos;
	const cuentasN = infoUser.numero;

	useEffect(() => {
		if(destinos === 0){
			setDestino(false);
		}else{
			setDestino(true);
		}
	}, [destinos])
	
	const [contactos] = useObtenerContactos();

	return (
		<div className="dashboardCard">
			<p className="CardTitulo">Mis Destinatarios</p>
			{cuentasN > 0 && <>
				<div className="item-contact" key={infoUser.id}>
					<div className="item-contact__info">
						<div className="item-contact__photo">
							<img src={!usuario.photoURL ? FotodePerfil : usuario.photoURL} alt="imagen-destinatario" />
						</div>
						<div><p>{infoUser.nombre.nombre} {infoUser.nombre.apellido}<br />
							<span>ID: {infoUser.documento.numeroDocumento}</span></p>
						</div>
					</div>
					<div className="item-contact__button">
						<Link to="nueva-transferencia" onClick={()=> FijarDestino(infoUser.usuario)} className="boton-general imgButton-rigth">
							AutoEnvío<img src={Send} alt="Enviar" /></Link>
					</div>
				</div>
			</>
			}			
			{contactos.length > 0 && <>
				{contactos.map((contacto) => {
					return (
						<div className="item-contact" key={contacto.id}>
							<div className="item-contact__info">
								<div className="item-contact__photo">
									<img src={!contacto.imgprofile ? FotodePerfil : contacto.imgprofile} alt="imagen-destinatario" />
								</div>
								<div><p>{contacto.nombre.nombre} {contacto.nombre.apellido}<br />
									<span>ID: {contacto.documento.numeroDocumento}</span></p>
								</div>
							</div>
							<div className="item-contact__button">
								<Link to="nueva-transferencia" onClick={()=> FijarDestino(contacto.id)} className="boton-general  imgButton-rigth">
									Enviar<img src={Send} alt="Enviar" /></Link>
							</div>
						</div>
					);
				})}
				</>
			}
			{!destino ?
				<div className="itemTransferEmpty">
					<p>Aún no tienes Destinatarios</p>
					<span>Tus destinatarios frecuentes apareceran aquí.</span>
					<Link to="/nuevo-destinatario" className="boton-general imgButton-left"><img src={ImgPlus} alt="" />Agregar Destinatario</Link>
					<Link to="/nueva-cuenta-autoenvio" className="boton-general">Agregarme cómo Destinatario</Link>
				</div>
				:
				<>
					<Link to="/nuevo-destinatario" className="boton-general imgButton-left"><img src={ImgPlus} alt="" />Agregar Destinatario</Link>
					{infoUser.toSelf ? <Link to="/nueva-cuenta-autoenvio" className="boton-general">Agregar Cuenta Autoenvio</Link>
					: <Link to="/nueva-cuenta-autoenvio" className="boton-general">Agregarme cómo Destinatario</Link>}
				</>
			}
		</div>
	);
}

export default DestinatariosInicio;