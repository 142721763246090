import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NewHeader.css';
import Logo from '../../../img/logocolcambiosicon.svg';

const NewHeader = () =>{

	const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

    return(
		<div className="newHeader">
			<div className="navbarV">
				<nav className="navbarVi">
					<Link to="/" className="logoIndex"><img src={Logo} alt="Inicio"/></Link>
					<div className="MainMenu">
						<Link to="/" className={splitLocation[1] === "" ? "homeActive" : ""}>Inicio</Link>
						<Link to="/por-que-elegirnos" className={splitLocation[1] === "por-que-elegirnos" ? "homeActive" : ""}>¿Por qué elegirnos?</Link>
						<Link to="/como-funciona" className={splitLocation[1] === "como-funciona" ? "homeActive" : ""}>Cómo funciona</Link>
						<Link to="/ayuda" className={splitLocation[1] === "ayuda" ? "homeActive" : ""}>Ayuda</Link>
					</div>
				</nav>
			</div>
		</div>
    );
}

export default NewHeader;