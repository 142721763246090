import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import 'jspdf-autotable';
import {useObtenerDestinos} from '../../hooks/useObtenerDatos';
import './../../../views/components/paginacion/Paginacion.css';
import '../../../styles/tablaCuentas.css';
import '../../../client/views/transferencias/transferencia.css';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
// import ImgExcel from '../../../img/icons/document-download.svg';
// import ImgPdf from '../../../img/icons/document-text-1.svg';

const DestinatariosUsuario = ({id}) => {

    const [destinos, loading] = useObtenerDestinos(id);

    const [pages, setPages] = useState('');
    const [dataLimit, setDataLimit] = useState('');
    const [pageLimit, setPageLimit] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationGroup, setPaginationGroup] = useState([]);

    useEffect(()=>{
        if(destinos.length <= 10){
            setDataLimit(destinos.length);
        }else{
            setDataLimit(10);
        }
    },[destinos])

    useEffect(() => {
        setPages(Math.ceil(destinos.length / dataLimit));
        setPageLimit(4);
    },[destinos, dataLimit])

    useEffect(() => {
        if(pageLimit){
            const grupoNumeros = () => {
                let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
                return new Array(pageLimit).fill().map((_, idx) => start + idx + 1 );
            };
            setPaginationGroup(grupoNumeros);
        }
    }, [pageLimit, currentPage]);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage]);

    const goToNextPage = () => {
        setCurrentPage((page) => page + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage((page) => page - 1);
    };
      
    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return destinos.slice(startIndex, endIndex);
    };

    return(
        <>
        {loading ? 
            <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
            :   <>
            {destinos.length > 0 ? 
                <>
                    <div className="divTableContent">
                        <div className="divTable-head">
                            <div className="divTable-headRow" id="table9td">
                                <div>Tipo</div>
                                <div>Nombres</div>
                                <div>Apellidos</div>
                                <div>Número Documento</div>
                                <div>País</div>
                                <div>Celular</div>
                                <div>Email</div>
                            </div>
                        </div>
                    {getPaginatedData().map((contacto) => {
                        let tipo 
                        if(!contacto.tipo){tipo='Persona'}else{tipo=contacto.tipo}
                        return(
                            <Link to={`/destinatario/${contacto.id}`} className="divTable-body" key={contacto.id}>
                                <div className="divTable-row" id="table9td">
                                    <div><label>Tipo</label>{tipo}</div>
                                    <div><label>Nombres</label>{contacto.nombre.nombre+' '+contacto.nombre.segNombre}</div>
                                    <div><label>Apellidos</label>{contacto.nombre.apellido+' '+contacto.nombre.segApellido}</div>
                                    <div><label>Número Documento</label>{contacto.documento.numeroDocumento}</div>
                                    <div><label>País</label>{contacto.direccion.pais}</div>
                                    <div><label>Celular</label>{contacto.celular}</div>
                                    <div><label>Email</label>{contacto.email}</div>
                                </div>
                            </Link>
                            )
                        })}
                </div>
                <div className="TableFooter">
                    {/* <div className="Btns-Export">
                        <button className="BtnExport BtnExcel"><img src={ImgExcel} alt="" />Exportar a Excel</button>
                        <button className="BtnExport BtnPdf" ><img src={ImgPdf} alt="" />Exportar a PDF</button>
                    </div> */}
                    <div className="paginacion">
                        <button onClick={goToPreviousPage} className={`pagPrev ${currentPage === 1 ? 'pagDisabled' : ''}`}><img src={ImgPrev} alt="anterior" /></button>
                            {paginationGroup ? paginationGroup.map((item, index) => (
                            <button key={index} onClick={changePage} className={(item > pages ? 'pagBlock' : 'pagNumber') +' '+ (currentPage === item ? 'pagActive' : '')}>
                            <span>{item}</span>
                            </button>
                            )) : <></>}
                        <button onClick={goToNextPage} className={`pagNext ${currentPage === pages ? 'pagDisabled' : ''}`}><img src={ImgNext} alt="siguiente" /></button>
                    </div>
                </div>
                </> :   
                <div className="itemTransferEmpty">
                    <p>Aún no tiene Destinatarios</p>
                    <span>El usuario no ha registrado destinatarios.</span>
                </div>
            }
                </>
        }
        </>
    )
}

export default DestinatariosUsuario;