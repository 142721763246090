import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Route, Redirect } from 'react-router-dom';
import LoadingPage from '../views/components/loading/LoadingPage';

const RutaPrivada = ({children, ...restoDePropiedades}) => {
    
    const {usuario, cargando} = useAuth();

    if(usuario){
        return <Route {...restoDePropiedades}>{children}</Route>
    }else{
        return <>{cargando ? <LoadingPage /> : <Redirect to="/ingresar" />} </>
    }
}

export default RutaPrivada;