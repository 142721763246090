import React from 'react';
import { Helmet } from 'react-helmet-async';

const PoliticasDePrivacidad = () =>{
    return (
        <>
            <Helmet><title>Políticas de Privacidad</title></Helmet>
            <div className="contGeneral">
                <h1 className="MainTitle">Privacy Policy</h1>
                <p className="TextGeneral">Colcambios Australia, ("COLCAMBIOS AUSTRALIA" or "we" or “us”) care about your privacy and are committed to processing your personal 
                information in accordance with fair information practices and applicable data protection laws to better serve you.<br/>
                We are bound by the Australian Privacy Principles ('APPs') established under the Privacy Act 1998 (the 'Act'), which require you to be 
                informed about how we handle your personal information. This Privacy Policy ('Privacy') describes how Colcambios Australia collects, 
                holds, uses and discloses your personal information and how the information is protected.<br/>
                All third parties (including customers, suppliers, sub-contractors, and agents) that have access to or use personal information 
                collected and held by us must abide by this Policy.</p>

                <h2 className="SubTitle">1. How do we protect your personal information</h2>
                <ul className="ulGeneral">
                    <li>We are serious about guarding the security of your personal information and use a secure server to store your personal information. 
                    All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using Transport Layer Security technology.</li>
                    <li>As you will know, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal 
                    data, we cannot guarantee the security of your data during transmission, and any transmission is at your own risk. Once we have received your information, 
                    we will use strict procedures and security features to try to prevent unauthorised access.</li>
                    <li>We continuously educate and train our employees about the importance of confidentiality and privacy of customer information. We maintain 
                    physical, electronic and procedural safeguards that comply with the relevant laws and regulations to protect your personal information from unauthorised access.</li>
                </ul>

                <h2 className="SubTitle">2. How we collect the information</h2>
                <p className="TextGeneral">We generally collect your personal information:</p>
                <ul className="ulGeneral">
                    <li>When you complete the Colcambios Australia online registration form when you request/submit an online money transfer request or airtime service through your
                    Colcambios Australia online account.</li>
                    <li>From information disclosed to us by you on the phone, or by sending us correspondence (including letters, facebook messages, whatsapp messages and emails) 
                    or visiting us in person.</li>
                    <li>From a variety of third party sources, including our business contacts, identity verification service providers, financial institutions, education agencies, etc. </li>
                    <li>Directly from applications, questionnaires and other forms you may complete when you wish to use our services.</li>
                </ul>
                <p className="TextGeneral">Please note that you do not have the option of dealing with us anonymously, or by using a pseudonym. This is because it is illegal for us to deal with individuals who are not identified.<br/>
                If you do not provide us with the personal information we request, we may not be able to provide you with our products or services, or process your application to access 
                our products or services, process your funds transfer request.</p>

                <h2 className="SubTitle">3. Information we may collect from you</h2>
                <p className="TextGeneral">We may collect and use the following data about you information you give us:</p>
                <ul className="ulGeneral">
                    <li>You may give us information about you when you sign up to use our service, e.g. when you provide us with your personal details, such as your name and email address. This also 
                        includes information you provide through your continued use of our Services, participate in discussion boards or other social media functions on our Website or App, enter a 
                        competition, promotion or survey, and when you report a problem with our Services. The information you give us may include your name, address, e-mail address, phone number, 
                        financial information (including credit card, debit card, or bank account information), payment reason, geographical location, ID number, personal description and photograph.</li>
                    <li>We may also need additional commercial and/or identification information from your e.g. if you send or receive certain high-value or high volume transactions or as needed to comply 
                    with our anti-money laundering obligations under applicable law.</li>
                    <li>In providing the personal data of any individual (other than yourself) to us during your use of our Services, you promise that you have obtained consent from such individual 
                    to disclose his/her personal data to us, as well his/her consent to our collection, use and disclosure of such personal data, for the purposes set out in this Privacy Policy.</li>
                </ul>

                <h2 className="SubTitle">4. Uses made of the information</h2>
                <p className="TextGeneral">We use your information in the following ways:</p>
                <ul className="ulGeneral">
                    <li>to carry out our obligations relating to your contract with us and to provide you with the information, products and services.</li>
                    <li>to comply with any applicable legal and/or regulatory requirements.</li>
                    <li>to notify you about changes to our Services.</li>
                    <li>as part of our efforts to keep our Services safe and secure</li>
                    <li>to improve our Services and to ensure that they are presented in the most effective manner.</li>
                    <li>to measure or understand the effectiveness of advertising we serve and to deliver relevant advertising to you.</li>
                    <li>to allow you to participate in interactive features of our Services, when you choose to do so.</li>
                    <li>to combine information we receive from other sources with the information you give to us and information we collect 
                    about you. We may use this information and the combined information for the purposes set out above (depending on the types of information we receive).</li>
                    <li>Fulfil your order, send your order confirmation and to manage our customer service communications with you.</li>
                    <li>Verify your identity in order to provide you with the product(s) or service(s) you have requested.</li>
                    <li>Comply with our various regulatory and legal obligations including but not limited to the Anti-Money Laundering and Counter Terrorism Financing Act 2006 (Cth).</li>
                </ul>

                <h2 className="SubTitle">5. Disclosure of your personal information and sending information overseas</h2>
                <p className="TextGeneral">We disclose your personal information to:</p>
                <ul className="ulGeneral">
                    <li>Our related entities, including our operating Headquarters Colcambios Australia in Sydney, Australia.</li>
                    <li>Our correspondent partners, disbursement and payment provider partners for the purposes of processing your requested transaction.</li>
                    <li>The Identification Verification agencies used to confirm your identity and meet the applicable identification and verification regulatory requirements.</li>
                    <li>To government agencies, law enforcement bodies and courts where required by law.</li>
                    <li>To third parties as permitted and required law; and Anyone to whom you authorise us to disclose to.</li>
                </ul>
                <p className="TextGeneral">Information disclosed to third parties will only be information required for the supply and fulfilment of the Colcambios Australia product and service, 
                or for customer assistance purposes or as required by law. Strict confidentiality agreements will be adhered to by the respective receiving third party. 
                We are likely to disclose your information to third parties located in Australia and Colombia.<br/>
                Colcambios Australia is committed to the protection of your information and only deal with ethical suppliers and business partners who demonstrate 
                similar values and are bound by privacy laws of the same standard as Australia.<br/>
                We will not disclose your personal information to overseas recipients without your consent unless:</p>
                <ul className="ulGeneral">
                    <li>We have taken reasonable steps to ensure that the recipient does not breach the Privacy Act, or the APPs; or</li>
                    <li>The recipient is subject to a similar information privacy regime.</li>
                </ul>

                <h2 className="SubTitle">6. Data Retention</h2>
                <p className="TextGeneral">Your personal information will be retained in accordance with statutory periods contained in regulations applicable to financial transactions 
                    including those in anti-money laundering, antiterrorist financing and other laws applicable to us. Otherwise, we will retain your information only if 
                    necessary for the specific purposes it was collected, or to resolve any query you may raise. We will typically keep your information for no longer than 
                    the purpose(s) for which it was obtained, unless we have a legal obligation to keep it for a longer period or retention is advisable for Colcambios Australia’s 
                    legal position (for example, regarding applicable statutes of limitations, litigation or regulatory investigations).
                </p>

                <h2 className="SubTitle">7. Quality of your personal information</h2>
                <p className="TextGeneral">We are committed to ensuring that the personal information we collect, hold, use and disclose is
                relevant, accurate, complete and up-to-date.<br/>
                Before we use or disclose your information, we will take reasonable steps to ensure that your personal information is accurate, complete, relevant, 
                up-to-date and is not misleading. We encourage you to regularly review your personal information and update your personal information in your Colcambios Australia profile.<br/>
                We encourage you to contact us if any personal information we hold about you needs to be updated. If we correct information that has previously been disclosed 
                to another entity, we will take reasonable steps notify the other entity of the correction. Where we are satisfied information is inaccurate, we will take reasonable 
                steps to correct the information within 30 days, unless you agree otherwise. We will not charge you for correcting your personal information.<br/>
                </p>

                <h2 className="SubTitle">8. Your rights</h2>
                <ul className="ulGeneral">
                    <li>You have the right to ask us not to contact you for marketing purposes by contacting us at info@colcambiosaustralia.com</li>
                    <li>You have the right to correct any personal information We hold on you that is inaccurate, incorrect, or out of date.</li>
                    <li>You have the right to ask us to delete your data when it is no longer necessary, or no longer subject to a legal obligation to which Colcambios Australia is subject to.</li>
                    <li>You have the right to transfer your personal data between data controllers, for example, to move your account details from one online platform to another.</li>
                    <li>Our Services may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, 
                        please note that these websites have their own privacy policies and that we do not accept any responsibility for them. Please check these policies before you submit any 
                    personal data to these websites.</li>
                </ul>

                <h2 className="SubTitle">9. Access to information</h2>
                <p className="TextGeneral">Subject to applicable laws, you may have the right to access information we held about you. Your
                right of access can be exercised in accordance with the relevant data protection legislation.</p>
                
                <h2 className="SubTitle">10. Changes to our privacy policy</h2>
                <p className="TextGeneral">Any changes we may make to our privacy policy will be posted on this page and, where appropriate, notified to you by e-mail. 
                Please check back frequently to see any updates or changes to our privacy policy.</p>
                <h2 className="SubTitle">Contact</h2>
                <p className="TextGeneral">Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to info@colcambiosaustralia.com.</p>
            </div>
        </>
    )
}

export default PoliticasDePrivacidad;