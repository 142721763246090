import React,{useEffect, useState} from 'react';
import NumberFormat from 'react-number-format';

const Resumen = (props) =>{
    const {
        servicio,
        copValue,
        fee, 
        metodo,
        pagarImpuesto,
        totalRecibido, 
        tasadeCambio,
        tasa,
        codigo,
        descuento,
        valorImpuesto,
        TotalaPagar,
        abrirResumen
    } = props;

    const [negative, setNegative] = useState(false);

    const [preferencial, setPreferencial] = useState(false);
    const [freeFee, setFreeFee] = useState(false);

    useEffect(()=>{
        if(totalRecibido < 100){setNegative(true);}
        else{setNegative(false); }
    },[totalRecibido]);

    useEffect(()=>{
        if(codigo){
            if(descuento === 'Tasa'){setPreferencial(true)}
            if(descuento === 'Fee'){setFreeFee(true)}
        }else{
            setPreferencial(false); setFreeFee(false);
        }
    },[codigo, descuento])

    return(
        <>
            <div onClick={abrirResumen}>
                <div className="totales">
                    <span>Pagas</span>
                    {TotalaPagar ? 
                        <NumberFormat value={TotalaPagar} displayType={'text'} thousandSeparator={'.'} 
                        decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'} />
                        : <span>$0</span>
                    }
                </div>
                <div className={negative ? "totales negativo" : "totales"}>
                    {negative && <div className='valorMinimo'><p>El valor mínimo es de $100 AUD.</p></div>}
                    <span>Reciben</span>
                    {totalRecibido ?
                        <NumberFormat value={totalRecibido} displayType={'text'} thousandSeparator={','} 
                            decimalSeparator={'.'} prefix={'$'} suffix={' AUD'} decimalScale={'2'} />
                        : <span>$0</span>
                    }
                </div>
            </div>
            
            <div className="nueva-resumen">
                <p>Resumen</p>
                <div className="ResumenTransferencia">
                    <div className="DatosIndividuales">
                        <span>Envías</span>
                        <NumberFormat value={copValue} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/>
                    </div>
                    <div className="DatosIndividuales">
                        <span>Costos Bancarios</span>
                        <span>{pagarImpuesto && '-'}
                        <NumberFormat value={valorImpuesto} displayType={'text'} thousandSeparator={'.'} 
                        decimalSeparator={false} prefix={'$'} suffix={' COP'} decimalScale={'0'}/></span>
                    </div>
                    {!preferencial ?
                        <div className="DatosIndividuales">
                            <span>Tasa de Cambio</span>
                            <NumberFormat value={tasadeCambio} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/>
                        </div>
                    :
                        <div className="DatosIndividuales">
                            <span>Tasa de Cambio<br/><b>Preferencial</b></span>
                            <div className='DatosPromo'>
                                <NumberFormat value={tasa} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/>
                                <NumberFormat value={tasadeCambio} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/>
                            </div>
                        </div>
                    }
                    {!freeFee ?
                        <div className="DatosIndividuales">
                            <span>Método de Envío <br/>{metodo}</span>
                            <NumberFormat value={fee} displayType={'text'} thousandSeparator={','}
                            decimalSeparator={'.'} prefix={!servicio ? '-$':'$'} suffix={' AUD'} decimalScale={'2'}/>
                        </div>
                    :
                        <div className="DatosIndividuales">
                            <span>Método de Envío <br/><b>{metodo}</b></span>
                            <div className='DatosPromo'>
                                <NumberFormat value={4.99} displayType={'text'} thousandSeparator={','}
                                decimalSeparator={'.'} prefix={'$'} suffix={' AUD'} decimalScale={'2'}/><br/>
                                <span>$0</span>
                            </div>
                        </div>
                    }
                    
                    <div className="DatosIndividuales">
                        <span>Reciben</span>
                        {totalRecibido ?
                            <NumberFormat value={totalRecibido} displayType={'text'} thousandSeparator={','} 
                            decimalSeparator={'.'} prefix={'$'} suffix={' AUD'} decimalScale={'2'} className={negative ? "valorNegativo" : "valorRecibido"}/>
                            :<span>$0</span>
                        }
                    </div>
                    <div className="DatosIndividuales TotalPagar">
                        <span>Total a Pagar</span>
                        {TotalaPagar ?
                            <NumberFormat value={TotalaPagar} displayType={'text'} thousandSeparator={'.'} 
                                decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'} />
                            : <span>$0</span>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Resumen;