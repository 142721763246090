import saveAs from 'file-saver';
import logoCol from '../../../../img/ColLogo';
import {Workbook} from 'exceljs';

const ExportReferidos = async({data})=>{

    const title = 'Transferencias Referidas Colcambios Australia';
    const header = ["Referencia","Método","Fecha","Remitente","Destinatario","Razón del envio","Pagado",
    "Cargos","Tasa","Servicio","Reciben","Estado","Banco Destino","BSB","Número de Cuenta","Titular","Asesor"];

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Referidos');

    let titleRow = worksheet.addRow([title], 'B1:B2');
    titleRow.font = { size: 16, bold: true };
    worksheet.addRow([]);

    let logo = workbook.addImage({
        base64: logoCol,
        extension: 'png',
    });
    worksheet.addImage(logo, 'A1:A2');
    worksheet.mergeCells('A1:Q2');

    worksheet.addRow([]);

    const headerRow = worksheet.addRow(header);

    headerRow.font = {color:{argb: 'FFFFFFFF'},bold: true }
    headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0018B4' },
        };
      });

    worksheet.autoFilter = {
        from: {
            row: 4,
            column: 1
        },
        to: {
            row: 4,
            column: header.length
        }
    };

    await data.forEach(d => {
        const row = worksheet.addRow(d);
        const nm5 = row.getCell(5);
        const nm6 = row.getCell(6);
        const nm7 = row.getCell(7);
        row.eachCell((cell, number) => {
                cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: 'FFFFFFFF'
                };
                cell.border = { color:{ argb: 'FF000000' }, top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            });
        nm5.numFmt = '$#,##0.00;[Red]-$#,##0.00';
        nm6.numFmt = '$#,##0.00;[Red]-$#,##0.00';
        nm7.numFmt = '$#,##0.00;[Red]-$#,##0.00';
    });

    // const row = worksheet.addRow(d);
    // const qty = row.getCell(5);
    // let color = 'FF99FF99';
    // if (+qty.value < 500) {
    //     color = 'FF9999';
    // }
    // qty.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: color }
    // };

    worksheet.getRow(1).height=70;
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 18;
    worksheet.getColumn(3).width = 18;
    worksheet.getColumn(4).width = 22;
    worksheet.getColumn(5).width = 22;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 18;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 18;
    worksheet.getColumn(10).width = 12;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 18;
    worksheet.getColumn(13).width = 20;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(15).width = 15;
    worksheet.getColumn(16).width = 22;
    worksheet.getColumn(17).width = 25;
    worksheet.addRow([]);

    const footerRow = worksheet.addRow(['Exportado desde Colcambios Australia']);
    footerRow.getCell(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECECEC' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    worksheet.mergeCells(`A${footerRow.number}:Q${footerRow.number}`);

    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Transferencias Referidas Colcambios.xlsx');
    });
      
}

export default ExportReferidos;