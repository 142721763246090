import React, { useEffect, useState } from 'react'
import AttachSquare from '../../../img/icons/attach-square.svg'
import './file.css'

const File = ({setImagen,spnArchivo,altArchivo}) =>{
 
    const[classHover, setClassHover] = useState(''); 
    const[hayArchivo, setHayArchivo] = useState(false);
    const[nameFile, setNameFile] = useState('');
    const[invalidDoc, setIvalidDoc] = useState(false);

    const[archivo, setArchivo] = useState();

    const dropHandler = (e) => {
        e.preventDefault();
        let files;
        setClassHover('#1890ff')
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
          } else if (e.target) {
            files = e.target.files;
          }
        setArchivo(files);
        setImagen(files);
    }

    const clickHandler = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
          } else if (e.target) {
            files = e.target.files;
          }
        setArchivo(files)
        setImagen(files)
    }

    useEffect(()=>{
        if(!archivo===''){
            setHayArchivo(true);
            setNameFile(archivo[0].name);
            if (!archivo[0].name.match(/\.(jpg|jpeg|png|gif|pdf|heic|heif)$/)){
                setIvalidDoc(true);
            }
        }
    },[archivo])

    const dragOverHandler = (e) => {
        setClassHover('#1890ff')
        e.preventDefault();
    }

    return (
        <div className={(hayArchivo ? "drop_zone drop_zone_active" : "drop_zone")+' '+(classHover ? 'drop_hover' : 'drop_out')+' '+(spnArchivo ? 'dashedRed' : '')
            +' '+(invalidDoc ? 'dashedRed' : '')} onDrop={dropHandler} onDragOver={dragOverHandler}>
            <div className="fileIcon"><img src={AttachSquare} alt="File Text" /><p>{hayArchivo ? 'Imagen seleccionada' : 'Sube tu Imagen'}</p></div>
            <p>{!hayArchivo ? 'Da click o arrastra el archivo que deseas cargar, solo se admite Imagen o PDF' : nameFile}</p>
            <input onChange={clickHandler} className='fileClick' type="file" accept="image/*, .heic, .heif"/>
            {spnArchivo && <span className="spanAlertDocument">{altArchivo}</span>}
            {invalidDoc && <span className="spanAlertDocument">Archivo no válido, solo Imagen</span>}
        </div>
    )
}

export default File;