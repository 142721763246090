import React from "react";
import { useAuth } from '../../../../context/AuthContext';
import ModalValidar from "../../../../views/components/modales/MoldaValidar";
import { DeleteDestino} from "../../../data/DeleteUser";
import DivLoading from "../../../../views/components/loading/Loading";
import { DestinoEliminado, HuboUnError } from "../../../components/Mensajes";
import { useHistory } from 'react-router-dom';

const BorrarDestino = (props) =>{

    const {
        OpenModalNuevo,
        CerrarModal,
        id,
        contactos,
    } = props

    const history = useHistory();
    const {usuario, infoUser, setInfoUser} = useAuth();

    const BorrarDestinatario = () =>{
        OpenModalNuevo(<DivLoading />);
        DeleteDestino({
            id: id,
            contactos: contactos,
            usuario: usuario
        }).then(()=>{
            OpenModalNuevo(<DestinoEliminado CerrarModal={CerrarModal}/>);
            setInfoUser({...infoUser, contactos: contactos - 1});
            history.push('/destinatarios');
        }).catch(()=>{
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    return(
        <ModalValidar 
            accion={BorrarDestinatario} 
            titulo={'Eliminar Destinatario'}
            mensaje={'¿Seguro deseas Eliminar este Destinatario?'}
            botonName={'Sí, Eliminar'}
            botonClass={'ButtonRed'}
            CerrarModal={CerrarModal}
        />
    )
}

export default BorrarDestino;