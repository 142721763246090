import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';

const useSetAgencias = () =>{
    const [agencias, setAgencias] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const unsuscribe = db.collection('agencias')
        .onSnapshot((snapshot) => {
            setLoading(false);
            setAgencias(snapshot.docs.map((agencia) => {
                return {...agencia.data(), id: agencia.id}
            }));
        });
        return unsuscribe;
    },[]);

    return [agencias, loading]
}

const useSetAgencia = (id) =>{
    const [agencia, setAgencia] = useState('');
    const [load, setLoad] = useState(true);

    useEffect(()=>{
        if(id){
            const unsuscribe = db.collection('agencias').doc(id).get()
            .then((doc) => {
                if(doc.exists){
                    setLoad(false);
                    setAgencia(doc);
                } else{
                    setLoad(false);
                    setAgencia('');
                }
            })
            return unsuscribe;
        }
    },[id]);

    return [agencia, load]
}

const useSetAgenciasActivas = () =>{
    const [agencias, setAgencias] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const unsuscribe = db.collection('agencias')
        .where('estado', '==', 'Activa')
        .onSnapshot((snapshot) => {
            setLoading(false);
            setAgencias(snapshot.docs.map((agencia) => {
                return {...agencia.data(), id: agencia.id}
            }));
        });
        return unsuscribe;
    },[]);

    return [agencias, loading]
}



export  {
    useSetAgencias, 
    useSetAgencia,
    useSetAgenciasActivas
};
