import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useTrans } from '../../../context/TransferContext';
import DivLoading from '../../../views/components/loading/Loading';
import ModalValidar from '../../../views/components/modales/MoldaValidar';
import { AgregarRate} from '../../data/AdminRate';
import { HuboUnError, RateAgregado, TasaAgregada, RateEliminado, TasaEliminada} from '../Mensajes';
import PreferencialManual from './components/PreferencialManual';
import './rate.css';

const Rate = ({OpenModalNuevo,CerrarModal}) =>{

    const {generalRate, tasadeCambio, tasaOriginal, tasaFija} = useTrans();
    const [equal, setEqual] = useState('');
    const [rate, setRate] = useState('');
    const [porcentaje, setPorcentaje] = useState('');
    const [tasa, setTasa] = useState('');
    const [tasaManual, setTasaManual] = useState('');
    const [deleteTrue, setDeleteTrue] = useState(null);
    const [deleteManual, setDeleteManual] = useState(null);
    const [spnTasa, setSpnTasa] = useState(false);
    const [spnRate, setSpnRate] = useState(false);
    const [altTasa, setAltTasa] = useState('');
    const [altRate, setAltRate] = useState('');

    const truncate = (n, digits) => {
        var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
            m = n.toString().match(re);
        return m ? parseFloat(m[1]) : n.valueOf();
    };

    useEffect(()=>{
        if(generalRate){
            setRate(generalRate)
            setEqual(generalRate * 100)
            setDeleteTrue(true);
        }
        if(tasaFija){
            setTasaManual(tasaFija)
            setDeleteManual(true);
        }
    },[generalRate, tasaFija])

    useEffect(()=>{
        if(tasadeCambio){
            setTasa(tasadeCambio)
        }
    },[tasadeCambio])

    useEffect(()=>{
        if(rate){
            if(tasaOriginal){
                const plus = tasaOriginal * rate; 
                setTasa(tasaOriginal + truncate(plus,2))
            }
        }else{
            if(tasaOriginal){
                const plus = tasaOriginal * 0.02; 
                setTasa(tasaOriginal + truncate(plus,2))
            }
        }
    },[rate, tasaOriginal])

    const handlePorcentaje = (values) => {
        setPorcentaje(values.value);
        setEqual(values.value);
        setRate((values.value)/100);
        setSpnRate(false);
	}

    const handleTasaManual = (e) =>{
        switch (e.target.name) {
            case 'tasa' : setTasaManual(e.target.value); setSpnTasa(false); break;
            default: break;
        }
    }

    const handleSubmit = () =>{
        OpenModalNuevo(<DivLoading />)
        AgregarRate({
            rate: rate,
            tasa: '',
        }).then(() => {
            setDeleteTrue(true);
            setDeleteManual(false);
            setTasaManual('');
            OpenModalNuevo(<RateAgregado CerrarModal={CerrarModal}/>);
            setEqual(rate * 100);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    const handleManual = () =>{
        OpenModalNuevo(<DivLoading />)
        AgregarRate({
            rate: '',
            tasa: tasaManual
        }).then(() => {
            setDeleteManual(true);
            setDeleteTrue(false);
            OpenModalNuevo(<TasaAgregada CerrarModal={CerrarModal}/>);
            setRate('');
            setEqual('');
            setPorcentaje('');
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    const EliminarRate = () =>{
        OpenModalNuevo(<DivLoading />)
        AgregarRate({
            rate:'',
            tasa: '',
        }).then(() => {
            OpenModalNuevo(<RateEliminado CerrarModal={CerrarModal}/>);
            setDeleteTrue(false);
            setTasaManual('');
            setRate('');
            setEqual('');
            setPorcentaje('');
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    const EliminarTasa = () =>{
        OpenModalNuevo(<DivLoading />)
        AgregarRate({
            rate:'',
            tasa: '',
        }).then(() => {
            OpenModalNuevo(<TasaEliminada CerrarModal={CerrarModal}/>);
            setTasaManual('');
            setRate('');
            setEqual('');
            setPorcentaje('');
            setDeleteManual(false);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    const AbrirEliminarManual = (e) =>{
        e.preventDefault();
        OpenModalNuevo(
            <ModalValidar 
            accion={EliminarTasa} 
            titulo={'Eliminar Tasa Manual'}
            mensaje={'¿Deseas Eliminar la tasa manual?'}
            botonName={'Sí, Eliminar'}
            botonClass={'ButtonRed'}
            CerrarModal={CerrarModal}
        />)
    }

    const AbrirEliminarRate = (e) =>{
        e.preventDefault();
        OpenModalNuevo(
            <ModalValidar 
            accion={EliminarRate} 
            titulo={'Eliminar Rate'}
            mensaje={'¿Deseas eliminar el porcentaje de la tasa?'}
            botonName={'Sí, Eliminar'}
            botonClass={'ButtonRed'}
            CerrarModal={CerrarModal}
        />)
    }

    const AbrirManual = (e) =>{
        e.preventDefault();
        if(!tasaManual){setSpnTasa(true); setAltTasa('Ingresa la Tasa'); return}
        OpenModalNuevo(
            <ModalValidar 
            accion={handleManual} 
            titulo={'Tasa Manual'}
            mensaje={'¿Deseas Definir la tasa Manual?'}
            botonName={'Sí, Actualizar'}
            botonClass={'ButtonGreen'}
            CerrarModal={CerrarModal}
        />)
    }

    const AbrirAutomatica = (e) =>{
        e.preventDefault();
        if(!rate){setSpnRate(true); setAltRate('Ingresa el Rate'); return}
        OpenModalNuevo(
            <ModalValidar 
            accion={handleSubmit} 
            titulo={'Tasa Automática'}
            mensaje={'¿Deseas Definir la tasa Automática más el Rate?'}
            botonName={'Sí, Actualizar'}
            botonClass={'ButtonGreen'}
            CerrarModal={CerrarModal}
        />)
    }

    return(
        <>
            <div className="dashboardCard">
                <p className="CardTitulo">Configuración de Tasas</p>
                <div className="row p-0">
                    <div className="col-12 col-lg-6">
                        <p className="CardTitulo">Tasa General Manual</p>
                        <form onSubmit={AbrirManual} className="RateContainer">
                            <div className="inputTasa">
                                <div className={!spnTasa ? 'input-general': 'input-general redLine'}>
                                    <input name='tasa' type="number" placeholder=" " onChange={handleTasaManual} value={tasaManual} step="0.01" pattern="^\d*(\.\d{0,2})?$" />
                                    <label>Tasa</label>
                                    {spnTasa && <span className="spanAlert">{altTasa}</span>}
                                </div>
                            </div>
                            {deleteManual && 
                                <div className="totalManual">
                                    <p><NumberFormat value={tasaManual} displayType={'text'} thousandSeparator={'.'}
                                        decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/></p>
                                </div>
                            }
                            <div className="botonesTasa">
                                <button className="boton-general" type="submit">Actualizar</button>
                                {deleteManual && <button className="boton-general" onClick={AbrirEliminarManual} type="button">Eliminar</button>}
                            </div>
                        </form>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <PreferencialManual  OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} />
                    </div>
                </div>
            </div>

            <div className="dashboardCard">
                <p className="CardTitulo">Tasa con Porcentaje</p>
                <form onSubmit={AbrirAutomatica} className="RateContainer">
                    <div className="inputRate">
                        <div className={!spnRate ? 'input-general': 'input-general redLine'}>
                            <NumberFormat value={porcentaje} suffix={'%'} decimalSeparator={'.'} onValueChange={handlePorcentaje} />
                            <label>Porcentaje</label>
                            {spnRate && <span className="spanAlert">{altRate}</span>}
                        </div>
                    </div>
                    <div className="RateOperation">
                        <div><span>Tasa</span>
                            <p><NumberFormat value={tasaOriginal} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/></p></div>
                        <div><p>+</p></div>
                        <div> <span>Porcentaje</span>
                            <p>{equal ? (equal + '%') : '2%'}</p></div>
                        <div className="igualRate"><p>=</p></div>
                    </div>
                    <div className="totalRate">
                        <p><NumberFormat value={tasa} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/></p>
                    </div>
                    <div className="botonesRate">
                        <button className="boton-general" type="submit">Actualizar</button>
                        {deleteTrue && <button className="boton-general" onClick={AbrirEliminarRate} type="button">Eliminar</button>}
                    </div>
                </form>
            </div>
        </>
        
    )
}

export default Rate;
