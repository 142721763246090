import { db } from '../../firebase/firebaseConfig';

const AgregarContacto = (props) => {
    const{
        activo,
        celular,
        cuenta,
        direccion,
        documento,
        email,
        fecha,
        nombre,
        tipo,
        usuario,
    } = props;

    return db.collection('contactos').add({
        activo: activo,
        celular: celular,
        cuenta: cuenta,
        direccion: direccion,
        documento: documento,
        email: email,
        fecha: fecha,
        nombre: nombre,
        tipo: tipo,
        usuario: usuario
    });
}

const ActualizarInfo = (props) =>{
    const{
        id,
        destino,
        contactos
    } = props;

    return db.collection('usuarios').doc(id).update({
        destino: destino,
        contactos: parseInt(contactos + 1)
    })
}

export {AgregarContacto,ActualizarInfo};