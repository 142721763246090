import React, { useState , useEffect}  from 'react';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import { useAuth } from '../../context/AuthContext';

const SaludoDashboard = () => {

    const fecha = new Date();
    const formatear = format(fecha, "dd 'de' MMMM 'de' yyyy", { locale: es });
    const [nombre, setNombre] = useState();

    const { infoUser } = useAuth();

    useEffect(() => {
        if(infoUser){
            setNombre(infoUser.nombre.nombre)
        }
    }, [infoUser])
    
    return (
        <div className="Saludo">
            <p>{formatear}</p>
            <h1>Hola {nombre},<br />
            <span>Nos alegra verte de nuevo.</span></h1>
        </div>
    );
}

export default SaludoDashboard;