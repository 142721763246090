import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams} from 'react-router-dom';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import es from 'date-fns/locale/es';
import { useAuth } from '../../../context/AuthContext';
import useObtenerUsuario from '../../hooks/useObtenerUsuario';
import { useObtenerDestinos } from '../../hooks/useObtenerDatos';
import TransferenciasUsario from './TransferenciasUsuario';
import DestinatariosUsuario from './DestinatariosUsuario';
import DocId from '../../../img/icons/documentid.svg';
import SuspenderUsuario from './components/SuspenderUsuario';

const Usuario = ({OpenModalNuevo,CerrarModal}) =>{

    const {infoUser} = useAuth();
    const history = useHistory();
    const formatDate = (fecha) => {return format(fromUnixTime(fecha), "dd 'de' MMMM 'de' yyyy", { locale: es });}

    //ADMINISTRADOR
    const [rol, setRol] = useState(false);
    useEffect(()=>{ if(infoUser.rol === 'Administrador'){setRol(true);}},[infoUser])

    const {id} = useParams();
    const [cliente, load] = useObtenerUsuario(id);
    const [destinos] = useObtenerDestinos(id);
    
    const [nombre, setNombre] = useState();
    const [segundoNombre, setSegundoNombre] = useState();
    const [apellido, setApellido] = useState();
    const [segundoApellido, setSegundoApellido] = useState();
    
    const [documento, setDocumento] = useState();
    const [tipoDoc, setTipoDoc] = useState();
    const [numeroDoc, setNumeroDoc] = useState();
    const [fechaExp, setFechaExp] = useState();
    const [fechaNac, setFechaNac] = useState();
   
    const [email, setEmail] = useState();
    const [celular, setCelular] = useState();
    const [pais, setPais] = useState();
    const [ciudad, setCidudad] = useState();
    const [direccion, setDireccion] = useState();
    const [contactos, setContactos] = useState();
    const [facebook, setFacebook] = useState();
    const [instagram, setInstagram] = useState();
    const [perfil, setPerfil] = useState();

    const [fechaReg, setFechaReg] = useState();
    const [lastSing, setLastSing] = useState();
    const [desactivado, setDesactivado] = useState();
    const [toSelf, setToSelf] = useState();
    
    const [urlDocumentoD, setUrlDocumentoD] = useState();
    const [paisD, setPaisD] = useState();
    const [cuentas, setCuentas] = useState();

    useEffect(() => {
        if(cliente){
            if(rol){
                setNombre(cliente.data().nombre.nombre);
                setSegundoNombre(cliente.data().nombre.segNombre);
                setApellido(cliente.data().nombre.apellido);
                setSegundoApellido(cliente.data().nombre.segApellido);
                setDocumento(cliente.data().documento.urlDocumento);
                setTipoDoc(cliente.data().documento.tipoDocumento);
                setNumeroDoc(cliente.data().documento.numeroDocumento);
                setFechaExp(cliente.data().documento.fechaExpedicion);
                setFechaNac(cliente.data().fechaNacimiento);
                setEmail(cliente.data().email);
                setCelular(cliente.data().celular);
                setPais(cliente.data().direccion.pais);
                setCidudad(cliente.data().direccion.ciudad);
                setDireccion(cliente.data().direccion.direccion);
                setContactos(cliente.data().contactos)
                setFacebook(cliente.data().redes.facebook);
                setInstagram(cliente.data().redes.instagram);
                setPerfil(cliente.data().rol);
                setFechaReg(formatDate(cliente.data().fechaRegistro));
                setLastSing(formatDate(cliente.data().lastSign));
                setToSelf(cliente.data().toSelf);
                setDesactivado(cliente.data().suspendido);
                if(cliente.data().toSelf){
                    setUrlDocumentoD(cliente.data().documento.urlDocumento);
                    setPaisD(cliente.data().direccion.pais);
                    setCuentas(cliente.data().cuentas);
                } 
            } else{
                history.push('/usuarios');
            }
        }
    }, [cliente, history, rol]);



    return(
        <>
            <Helmet><title>Usuario</title></Helmet>
            <div className="TituloPag"><p>Usuario</p></div>

            {load ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
            : <>
                <div className="row m-0">
                    <div className="col-12 col-md-7 col-lg-8 p-0">
                        <div className="CardBody">
                            <h3>Información</h3>
                            <div className="row p-0 DatosTrans">
                                <div className="col-6 col-sm-4"><p><label>Nombres</label>{nombre+' '+segundoNombre}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Apellidos</label>{apellido+' '+segundoApellido}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Rol</label>{perfil}</p></div>

                                <div className="col-6 col-sm-4"><p><label>Tipo de Documento</label>{tipoDoc}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Número de Documento</label>{numeroDoc}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Fecha de Expedición</label>{fechaExp}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Fecha de Nacimiento</label>{fechaNac}</p></div>

                                <div className="col-12 col-sm-8"><p><label>Email</label>{email}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Celular</label>{celular}</p></div>
                                <div className="col-6 col-sm-4"><p><label>País</label>{pais}</p></div>
                                {ciudad && <div className="col-6 col-sm-4"><p><label>Ciudad</label>{ciudad}</p></div>}
                                <div className="col-6 col-sm-4"><p><label>Dirección</label>{direccion}</p></div>
                                {facebook && <div className="col-6 col-sm-4"><p><label>Facebook</label>{facebook}</p></div>}
                                {instagram && <div className="col-6 col-sm-4"><p><label>Instagram</label>{instagram}</p></div>}
                            </div>
                        </div>

                        <div className="CardBody">
                            <h3>Documentos</h3>
                            <div className="TransDocuments">
                                <a href={documento} target="blank_">Id {pais}<br/><img src={DocId} alt="Documento" /></a>
                                {toSelf &&<a href={urlDocumentoD} target="blank_">Id {paisD}<br/><img src={DocId} alt="Documento" /></a>}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-4 p-0">
                        <div className="CardBody">
                            <h3>Registros</h3>
                            <div className="row p-0 DatosTrans">
                                <div className="col-12"><p><label>Fecha de Registro</label>{fechaReg}</p></div>
                                <div className="col-12"><p><label>Última Conexión</label>{lastSing}</p></div>
                            </div>
                        </div>
                        <SuspenderUsuario OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} 
                            setDesactivado={setDesactivado} desactivado={desactivado} id={id} email={email} nombre={nombre} />
                    </div>
                </div>

                {contactos !== 0 && <>
                <div className="CardBody">
                    <h3>Cuentas Autoenvio</h3>
                    {toSelf===true &&
                        <div className="divTableContent">
                            <div className="divTable-head">
                                <div className="divTable-headRow" id="tdCuentas">
                                    <div>Banco</div>
                                    <div>Titular</div>
                                    <div>Bsb</div>
                                    <div>Cuenta</div>
                                </div>
                            </div>
                        {Object.keys(cuentas).map((cuenta,i) => {
                            return(
                                <div className="divTable-body" key={cuenta}>
                                    <div className="divTable-row" id="tdCuentas">
                                        <div><label>Banco</label>{cuentas[cuenta].banco}</div>
                                        <div><label>Titular</label>{cuentas[cuenta].titular}</div>
                                        <div><label>Bsb</label>{cuentas[cuenta].bsb}</div>
                                        <div><label>Cuenta</label>{cuentas[cuenta].cuenta}</div>
                                    </div>
                                </div>
                                )
                            })
                        }
                        </div>
                    }
                </div>
                </>}

                <div className="CardBody">
                    <h3>Destinatarios</h3>
                    {destinos.length > 0 && <DestinatariosUsuario id={id} />}
                </div>

                <div className="CardBody">
                    <h3>Transferencias</h3>
                    <TransferenciasUsario id={id} />
                </div>
                </>
            }
        </>
    );
}

export default Usuario;