import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { firebase } from '../../../firebase/firebaseConfig';
import { getUnixTime } from 'date-fns';
import {AgregarContacto, ActualizarInfo} from '../../data/AgregarContacto';
import banks from '../../../data/banks.json';
import LoadingPage from '../../../views/components/loading/LoadingPage';
import AdrresAu from '../../../views/components/inputs/AddressAu';
import FileDocument from '../../../views/components/files/FileDocument';
import SelectorSelec from '../../../views/components/inputs/Selector';
import InputPhone from '../../../views/components/inputs/InputPhone';
import InputSearch from '../../../views/components/inputs/InputSearch';
import Footer from '../../../views/components/footer/Footer';
import '../../../styles/Procesos.css';
import ImgDestinatario from '../../../img/icons/user.svg';
import ImgBank from '../../../img/icons/bank.svg';
import ImgInfo from '../../../img/icons/call.svg';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import Close from '../../../img/icons/close-square.svg';
import Check from '../../../img/icons/tick-square.svg';

const NuevoDestinatario = () => {
    const [cargando, setCargando] = useState(true);
    const [completo, setCompleto] = useState(false);
    const [validando, setValidando] = useState(false);
    const [contactos, setContactos] = useState('');
    const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
    const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}

    //USUARIO LOGUEADO 
    const { usuario, infoUser } = useAuth();
    const uidUsuario = usuario.uid

    useEffect(()=>{
        if(!infoUser){setCargando(false);}
        else{ setCargando(false); setContactos(infoUser.contactos) }
    },[infoUser])

    //FECHA
    const fecha = new Date();

    //DATOS
    const [tipo, setTipo] = useState('');
    const [persona, setPersona] = useState('');
    const [nombre, setNombre] = useState('');
    const [sdoNombre, setSdoNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [sdoApellido, setSdoApellido] = useState('');
    const [tipoDeDocumento, setTipoDeDocumento] = useState('');
    const [typeDoc, setTypeDoc] = useState(false);
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [documento, setDocumento] = useState('');
    const [progress, setProgress] = useState('');

    //CONTACTO
    const [pais, setPais] = useState('');
    const [direccion, setDireccion] = useState('');
    const [code, setCode ] = useState('+57');
    const [celular, setCelular] = useState('');
    const [correo, setCorreo] = useState('');

    //INFORMACION DE CUENTA
    const [banco, setBanco] = useState('');
    const [titular, setTitular] = useState('');
    const [bsb, setBsb] = useState('');
    const [bsb2, setBsb2] = useState('');
    const [numeroCuenta, setNumeroCuenta] = useState('');
    const [numeroCuenta2, setNumeroCuenta2] = useState('');

    //PASO A PASO
    const [paso1, setPaso1] = useState(true);
    const [paso2, setPaso2] = useState(false);
    const [paso3, setPaso3] = useState(false);
    const [comp1, setComp1] = useState('formProgress-current');
    const [comp2, setComp2] = useState();
    const [comp3, setComp3] = useState();

    //RECOMENDACIONES
    const[altTipo, setAltTipo] = useState('');
    const[altName, setAltName] = useState('')
    const[altApellido, setAltApellido] = useState('')
    const[altDocumento, setAltDocumento] = useState('')
    const[altTipodoc, setAltTipodoc] = useState('');
    const[altArchivo, setAltArchivo] = useState('');
    const[altPais,setAltPais] = useState();
    const[altDir,setAltDir] = useState();
    const[altCelular,setAltCelular] = useState();
    const[altCorreo,setAltCorreo] = useState();
    const[altNBanco, setAltNBanco] = useState();
    const[altTitular, setAltTitular] = useState();
    const[altBsb, setAltBsb] = useState();
    const[altBsb2, setAltBsb2] = useState();
    const[altNCuenta, setAltNCuenta] = useState();
    const[altNCuenta2, setAltNCuenta2] = useState();

    const[spnTipo, setSpnTipo] = useState(false);
	const[spnName, setSpnName] = useState(false);
    const[spnApellido, setSpnApellido] = useState(false);
    const[spnTipoDoc,setSpnTipoDoc] = useState(false);
    const[spnAltDocumento, setSpnAltDocumento] = useState(false);
    const[spnArchivo,setSpnArchivo] = useState(false);
    const[spnPais,setSpnPais] = useState(false);
    const[spnDir,setSpnDir] = useState(false);
    const[spnCelular,setSpnCelular] = useState(false);
    const[spnCorreo,setSpnCorreo] = useState(false);
    const[spnNBanco, setSpnNBanco] = useState(false);
    const[spnTitular, setSpnTitular] = useState(false);
    const[spnBsb, setSpnBsb] = useState(false);
    const[spnBsb2, setSpnBsb2] = useState(false);
    const[spnNCuenta, setSpnNCuenta] = useState(false);
    const[spnNCuenta2, setSpnNCuenta2] = useState(false);

    //OPCIONES
    const tiposdocs = [{ id: '1', texto: 'Pasaporte' }, { id: '2', texto: 'ABN' }]
    const tiposDestino = [{ id: '1', texto: 'Persona Natural' }, { id: '2', texto: 'Agencia / College / Empresa' }]
    const paises = [{ id: '1', texto: 'Australia' }]

    useEffect(()=>{
        if(nombre||sdoNombre||apellido){
            setTitular(nombre+' '+sdoNombre+' '+apellido);
        }else{ setTitular('');}
    },[nombre,sdoNombre,apellido]);

    useEffect(()=>{
        if(tipo){
            if(tipo === 'Persona Natural'){setPersona('Persona')}
            if(tipo === 'Agencia / College / Empresa'){
                setPersona('Empresa');
                setSdoNombre('');
                setApellido('');
                setSdoApellido('');
            }}
    },[tipo])

    useEffect(()=>{
        if(persona === 'Empresa'){setTipoDeDocumento('ABN');
        }if(persona === 'Persona'){setTipoDeDocumento('Pasaporte');}
    },[persona])

    //FUNCIONES
    const handleChange = (e) => {
        switch (e.target.name) {
            case 'primerNombre': setNombre(e.target.value); setSpnName(false); break;
            case 'segundoNombre': setSdoNombre(e.target.value); break;
            case 'primerApellido': setApellido(e.target.value); setSpnApellido(false); break;
            case 'segundoApellido': setSdoApellido(e.target.value); break;
            case 'numeroDocumento': setNumeroDocumento(e.target.value); setSpnAltDocumento(false); break;

            case 'celular': setCelular(e.target.value); setSpnCelular(false); break;
            case 'correo': setCorreo(e.target.value); setSpnCorreo(false); break;
            
            case 'nombreBanco': setBanco(e.target.value); setSpnNBanco(false); break;
            case 'titular' : setTitular(e.target.value); setSpnTitular(false); break;
            case 'numeroBsb': setBsb(e.target.value); setSpnBsb(false);setSpnBsb2(false); break;
            case 'numeroBsb2': setBsb2(e.target.value); setSpnBsb(false);setSpnBsb2(false); break;
            case 'numeroCuenta': setNumeroCuenta(e.target.value); setSpnNCuenta(false); setSpnNCuenta2(false); break;
            case 'numeroCuenta2': setNumeroCuenta2(e.target.value); setSpnNCuenta(false); setSpnNCuenta2(false); break;

            default: break;
        }
    }

    //VERIFICACIÓN DE SELECTS Y FILES
    useEffect(() => {if(tipoDeDocumento==='Pasaporte'){setTypeDoc(true)}else{setTypeDoc(false)};}, [tipoDeDocumento]);
    useEffect(() => {setSpnTipoDoc(false);}, [tipoDeDocumento])
    useEffect(() => {setSpnArchivo(false);}, [documento])
    useEffect(() => {setSpnDir(false);}, [direccion])

    const PasoUno = (e) =>{
        e.preventDefault();
        if(persona === ''){setSpnTipo(true); setAltTipo('Por favor selecciona'); return;}
        if(persona === 'Empresa'){
            if (!nombre) {setSpnName(true); setAltName('Por favor ingresa un nombre'); return;}
        }
        if(persona === 'Persona'){
            if (!nombre) {setSpnName(true); setAltName('Por favor ingresa un nombre'); return;}
            if (!apellido) {setSpnApellido(true); setAltApellido('Por favor ingresa un apellido'); return;}
        }
        if (!tipoDeDocumento) {setSpnTipoDoc(true); setAltTipodoc('Por favor selecciona el tipo de documento'); return;}
        if (!numeroDocumento) {setSpnAltDocumento(true); setAltDocumento('Por favor ingresa el número del documento'); return;}
        if (!documento) {setSpnArchivo(true); setAltArchivo('Por favor adjunta el archivo'); return;} 
        if (!documento[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){setSpnArchivo(true); setAltArchivo('Archivo no válido, solo Imagen o PDF'); return;}
        const size = Math.round((documento[0].size / 1024));
        if(size >= 1024) {setSpnArchivo(true); setAltArchivo('El tamaño máximo del archivo es de 1mb'); return;}
        else{
            toUp(); setPaso1(false); setPaso2(true); setComp1('formProgress-active'); setComp2('formProgress-current')
        }
    }

    const BackStepOne = (e) =>{e.preventDefault(); toUp();setPaso1(true); setPaso2(false); setComp1('formProgress-current'); setComp2('')}

    const PasoDos = (e) =>{
        e.preventDefault();
        if (!pais) {setSpnPais(true); setAltPais('Por favor selecciona'); return;}
        if (!celular) {setSpnCelular(true); setAltCelular('Por favor ingresa el número de celular'); return;}
        if (code ==='+57'){if(celular.length !== 10){setSpnCelular(true); setAltCelular('El número debe ser de 10 dígitos'); return;}}
        if (code ==='+61'){if(celular.length !== 9){setSpnCelular(true); setAltCelular('El número debe ser de 9 dígitos'); return;}}
        if (!direccion){setSpnDir(true); setAltDir('Por favor ingresa la dirección'); return;}
        if (!correo) {setSpnCorreo(true); setAltCorreo('Por favor ingresa el correo electrónico'); return;}
        if (!expresionRegular.test(correo)){setSpnCorreo(true); setAltCorreo('Por favor ingresa un correo válido'); return;}
        else{
            toUp(); setPaso2(false); setPaso3(true); setComp2('formProgress-active'); setComp3('formProgress-current')
        }
    }

    const BackStepTwo = (e) =>{ e.preventDefault(); toUp(); setPaso2(true); setComp2('formProgress-current'); setPaso3(false);setComp3('')}

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!documento) {setSpnArchivo(true); setAltArchivo('Por favor adjunta el archivo'); return;} 
        if (!documento[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){setSpnArchivo(true); setAltArchivo('Archivo no válido, solo Imagen o PDF'); return;}
        const size = Math.round((documento[0].size / 1024));
        if (size >= 1024) {setSpnArchivo(true); setAltArchivo('El tamaño máximo del archivo es de 1mb'); return;}
        if (!banco) {setSpnNBanco(true); setAltNBanco('Por favor ingresa el nombre del Banco'); return;}
        if (!titular) {setSpnTitular(true); setAltTitular('Por favor ingresa el nombre del Titular'); return;}
        if (!bsb){setSpnBsb(true); setAltBsb('Por favor ingresa el número BSB'); return;}
        if (bsb.length !== 6){setSpnBsb(true); setAltBsb('El número debe ser de 6 dígitos'); return;}
        if (!bsb2){setSpnBsb2(true); setAltBsb2('Por confirma el número BSB'); return;}
        if (bsb2 !== bsb){setSpnBsb2(true); setAltBsb2('El número BSB no es igual'); return;}
        if (!numeroCuenta) {setSpnNCuenta(true); setAltNCuenta('Por favor ingresa el número de la cuenta'); return;}
        if (!numeroCuenta2) {setSpnNCuenta2(true); setAltNCuenta2('Por favor confirma el número de la cuenta'); return;}
        if (numeroCuenta2 !== numeroCuenta){setSpnNCuenta2(true); setAltNCuenta2('El número cuenta no es igual'); return;}
        
        toUp();
        setValidando(true)
        const docID = documento[0];
        const nombreArchivo = usuario.uid+'.'+documento[0].name.split('.').pop();
        const StorageRef = firebase.storage().ref(usuario.uid + '/destinatarios/' + nombreArchivo);
        const uploadTask = StorageRef.put(docID);

        uploadTask.on( "state_changed", snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            }, error => {
                setValidando(false);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL()
                    .then(function(downloadURL) {
                        ActualizarInfo({
                            id: uidUsuario,
                            destino: true,
                            contactos: contactos,
                        });
                        AgregarContacto({
                            activo: true,
                            celular: code+' '+celular,
                            cuenta:{
                                banco: banco,
                                titular: titular,
                                bsb: bsb,
                                numeroCuenta: numeroCuenta,
                            },
                            direccion:{
                                pais: pais,
                                direccion: direccion,
                            },
                            documento: {
                                tipoDocumento: tipoDeDocumento,
                                numeroDocumento: numeroDocumento,
                                urlDocumento: downloadURL,
                            },
                            email: correo,
                            fecha: getUnixTime(fecha),
                            nombre:{
                                nombre: nombre,
                                apellido: apellido,
                                segApellido: sdoApellido,
                                segNombre: sdoNombre,
                            },
                            tipo: persona,
                            usuario: uidUsuario
                        }).then(() => {
                            setCompleto(true);
                            setValidando(false);
                        }).catch((error) => {
                            setValidando(false);
                        })
                    });
            })
    }

    //MODALES
    const [modal, cambiarModal] = useState('modal-general');
    const [clases, cambiarClases] = useState('fonder');

    const openModal = (e) =>{
        e.preventDefault();
        setTimeout(() => {
            cambiarModal('modal-general modal-activo')
            cambiarClases('fonder fonder-activo')
        }, 200);
    }

    const CerrarModal = (e) =>{
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
    }

    const CerrarModalNone = (e) =>{
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
        setDireccion('');
    }

    return (
        <> {!cargando ? <>
            <div className="divContainer">
                <div className="divSubContainer">
                    <form onSubmit={handleSubmit} className="formData">
                            <div className="formProgress">
                                {completo ? <h3>Registro Completo</h3>
                                :   <>{validando ? <h3>Cargando...</h3> 
                                    :   <ul>
                                            <li className={comp1}><span><img src={ImgDestinatario} alt="Valor" /></span><label>Paso 1</label>Información</li>
                                            <li className={comp2}><span><img src={ImgInfo} alt="Destinatario" /></span><label>Paso 2</label>Contacto</li>
                                            <li className={comp3}><span><img src={ImgBank} alt="Pago" /></span><label>Paso 3</label>Cuenta</li>
                                        </ul>
                                }</>}
                            </div>
                            <div className="formContent">
                                    {!validando ? <> {completo ? 
                                        <div className="Completado">
                                            <div className="CompletadoImg"><img src={Check} alt="listo" /></div>
                                            <p>Tu destinatario se ha <b>registrado con éxito.</b></p>
                                            <a href="/dashboard" className="boton-general">Ir al Inicio</a>
                                        </div> 
                                    :   <>
                                        <div className="formTitle">
                                            <h1 className="text-title">Nuevo Destinatario</h1>
                                            <p>Recuerda que esta información debe ser precisa para evitar los contratiempos en las transferencias. <b>La cuenta debe ser una cuenta principal y 
                                            los campos marcados con * son obligatorios</b></p>
                                        </div>
                                        <div className={paso1 ? 'step step1 habil' : 'step step1'}>
                                            <div className="row p-0">
                                                <div className="col-12"><div className="input-select">
                                                    <SelectorSelec opciones={tiposDestino} TipoOpcion={'Tipo de Destinatario*'} opcion={tipo} 
                                                        cambiarOpcion={setTipo} spnRed={spnTipo} setSpnRed={setSpnTipo} />
                                                        {spnTipo && <span className="spanAlert">{altTipo}</span>}
                                                </div></div>
                                            </div>
                                            {persona === 'Empresa' ?
                                                <div className="row p-0">
                                                    <div className="col-12"><div className={!spnName ? 'input-general': 'input-general redLine'}>
                                                        <input name="primerNombre" type="text" placeholder=" " value={nombre} onChange={handleChange} autoComplete="off" />
                                                        <label>Agencia / Empresa *</label>
                                                        {spnName && <span className="spanAlert">{altName}</span>}
                                                    </div></div>
                                                </div>
                                            :<>
                                                <div className="row p-0">
                                                    <div className="col-12 col-md-6"><div className={!spnName ? 'input-general': 'input-general redLine'}>
                                                        <input name="primerNombre" type="text" placeholder=" " value={nombre} onChange={handleChange} autoComplete="off" />
                                                        <label>Primer Nombre *</label>
                                                        {spnName && <span className="spanAlert">{altName}</span>}
                                                    </div></div>
                                                    <div className="col-12 col-md-6"><div className="input-general">
                                                        <input name="segundoNombre" type="text" placeholder=" " value={sdoNombre} onChange={handleChange} autoComplete="off"/>
                                                        <label>Segundo Nombre</label>
                                                    </div></div>
                                                </div>
                                                <div className="row p-0">
                                                    <div className="col-12 col-md-6"><div className={!spnApellido ? 'input-general': 'input-general redLine'}>
                                                        <input name="primerApellido" type="text" placeholder=" " value={apellido} onChange={handleChange} autoComplete="off"/>
                                                        <label>Primer Apellido *</label>
                                                        {spnApellido && <span className="spanAlert">{altApellido}</span>}
                                                    </div></div>
                                                    <div className="col-12 col-md-6"><div className="input-general">
                                                        <input name="segundoApellido" type="text" placeholder=" " value={sdoApellido} onChange={handleChange} autoComplete="off"/>
                                                        <label>Segundo Apellido</label>
                                                    </div></div>
                                                </div>
                                            </>}
                                            
                                            <div className="row p-0">
                                                <div className="col-12 col-md-6"><div className="input-select">
                                                    <SelectorSelec opciones={tiposdocs} TipoOpcion={'Tipo de Documento *'} opcion={tipoDeDocumento} 
                                                        cambiarOpcion={setTipoDeDocumento} spnRed={spnTipoDoc} setSpnRed={setSpnTipoDoc} />
                                                    {spnTipoDoc && <span className="spanAlert">{altTipodoc}</span>}
                                                </div></div>
                                                <div className="col-12 col-md-6"><div className={!spnAltDocumento ? 'input-general': 'input-general redLine'}>
                                                    <input name="numeroDocumento" type={!typeDoc ? "number" : "text"} placeholder=" " value={numeroDocumento} onChange={handleChange} autoComplete="off" />
                                                    <label>Número de Documento *</label>
                                                    {spnAltDocumento && <span className="spanAlert">{altDocumento}</span>}
                                                </div></div>
                                            </div>
                                            <FileDocument archivosSelec={setDocumento} spnAltArchivo={spnArchivo} altArchivo={altArchivo} />
                                        </div>
                                        <div className={paso2 ? 'step step2 habil' : 'step step2'}>
                                            <div className="row p-0">
                                                <div className="col-12 col-md-6"><div className="input-select">
                                                    <SelectorSelec opciones={paises} TipoOpcion={'País *'} opcion={pais} 
                                                        cambiarOpcion={setPais} spnRed={spnPais} setSpnRed={setSpnPais} />
                                                    {spnPais && <span className="spanAlert">{altPais}</span>}
                                                </div></div>
                                                <div className="col-12 col-md-6">
                                                    <InputPhone code={code} setCode={setCode} phone={celular} setCelular={setCelular} 
                                                        spnSpan={spnCelular} setSpan={setSpnCelular} alert={altCelular} pais={pais} />
                                                </div>
                                            </div>

                                            <div className={!spnDir ? 'input-general': 'input-general redLine'} onClick={openModal}>
                                                <input type="text" name="addressAu" placeholder=" " value={direccion} readOnly autoComplete="off"/>
                                                <label>Dirección*</label>
                                                {spnDir && <span className="spanAlert">{altDir}</span>}
                                            </div>
                                            <div className={!spnCorreo ? 'input-general': 'input-general redLine'}>
                                                <input type="email" name="correo"  placeholder=" " value={correo} onChange={handleChange} autoComplete="off"/>
                                                <label>Correo Electrónico *</label>
                                                {spnCorreo && <span className="spanAlert">{altCorreo}</span>}
                                            </div>
                                        </div>
                                        <div className={paso3 ? 'step step3 habil' : 'step step3'}>
                                            <div className="row p-0">
                                                <div className="col-12 col-md-6">
                                                    <InputSearch opciones={banks} opcion={banco} setOpcion={setBanco} spnSpan={spnNBanco} 
                                                        setSpan={setSpnNBanco} label={'Nombre del Banco*'} alert={altNBanco}/>
                                                </div>
                                                <div className="col-12 col-md-6"><div className={!spnTitular ? 'input-general': 'input-general redLine'}>
                                                    <input name="titular" type="text" placeholder=" " value={titular} onChange={handleChange}/>
                                                    <label>Nombre del titular*</label>
                                                    {spnTitular && <span className="spanAlert">{altTitular}</span>}
                                                </div></div>
                                                <div className="col-12 col-md-6"><div className={!spnBsb ? 'input-general': 'input-general redLine'}>
                                                    <input name="numeroBsb" type="number" placeholder=" " value={bsb} onChange={handleChange} />
                                                    <label>Número BSB*</label>
                                                    {spnBsb && <span className="spanAlert">{altBsb}</span>}
                                                </div></div>
                                                <div className="col-12 col-md-6"><div className={!spnBsb2 ? 'input-general': 'input-general redLine'}>
                                                    <input name="numeroBsb2" type="number" placeholder=" " value={bsb2} onChange={handleChange} />
                                                    <label>Confirmar Número BSB*</label>
                                                    {spnBsb2 && <span className="spanAlert">{altBsb2}</span>}
                                                </div></div>
                                                <div className="col-12 col-md-6"><div className={!spnNCuenta ? 'input-general': 'input-general redLine'}>
                                                    <input name="numeroCuenta" type="number" placeholder=" " value={numeroCuenta} onChange={handleChange} />
                                                    <label>Número de cuenta*</label>
                                                    {spnNCuenta && <span className="spanAlert">{altNCuenta}</span>}
                                                </div></div>
                                                <div className="col-12 col-md-6"><div className={!spnNCuenta2 ? 'input-general': 'input-general redLine'}>
                                                    <input name="numeroCuenta2" type="number" placeholder=" " value={numeroCuenta2} onChange={handleChange} />
                                                    <label>Confirmar Número de cuenta*</label>
                                                    {spnNCuenta2 && <span className="spanAlert">{altNCuenta2}</span>}
                                                </div></div>
                                            </div>
                                        </div>
                                        {paso1 && 
                                            <div className="formButtons BtnsRigth">
                                                <button type="submit" onClick={PasoUno} className="boton-general imgButton-rigth">Siguiente<img src={ImgNext} alt="Siguiente" /></button>
                                            </div>
                                            }
                                        {paso2 && 
                                            <div className="formButtons BtnsSpace">
                                                <button className="boton-general imgButton-left" onClick={BackStepOne} type="button"><img src={ImgPrev} alt="Regresar" />Regresar</button>
                                                <button type="submit" onClick={PasoDos} className="boton-general imgButton-rigth">Siguiente<img src={ImgNext} alt="Siguiente" /></button>
                                            </div>
                                        }
                                        {paso3 && 
                                            <div className="formButtons BtnsSpace">
                                                <button className="boton-general" type='button' onClick={BackStepTwo}>Regresar</button>
                                                <button type="submit" className="boton-general imgButton-rigth">Registrar Destinatario <img src={Check} alt="Finalizar"/></button>
                                            </div>
                                        }
                                        </>}
                                    </> :
                                        <>
                                            <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
                                            <div style={{display: "none"}}><p>{progress}</p></div>
                                        </>
                                    }
                            </div>
                    </form>
                    {!validando && <> {!completo &&
                        <div className="divSalir">
                            <p>¿Deseas agregar el destinatario después?</p>
                            <Link to="/dashboard" className="boton-text imgButton-rigth">Cancelar<img src={Close} alt="Salir" /></Link> 
                        </div>
                    }</>}
                </div>
            </div>
            <Footer />
            <div className={modal}><AdrresAu setDireccion={setDireccion} CerrarModal={CerrarModal}/></div>
            <div className={clases} onClick={CerrarModalNone}></div>
        </> : 
            <LoadingPage />
        }</>
    )
}

export default NuevoDestinatario;