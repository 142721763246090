import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useHistory } from 'react-router';

const useObtenerUsuario = (id) =>{

    const history = useHistory();
    const [load, setLoad] = useState(true);
    const [cliente, setCliente] = useState('');

    useEffect (() => {
        const unsuscribe = db.collection('usuarios').doc(id).get()
        .then((doc) => {
            if(doc.exists){
                setLoad(false);
                setCliente(doc);
            } else{
                history.push('/usuarios');
            }
        })
        return unsuscribe;
    }, [history, id]);

    return [cliente, load];
}

export default useObtenerUsuario;
