import React from "react";
import DivLoading from "../../../../views/components/loading/Loading";
import ModalValidar from "../../../../views/components/modales/MoldaValidar";
import { HuboUnError, CodigoEliminado } from "../../Mensajes";
import { DeleteCode, EliminarCodigos } from "../../../data/AdminCodigos";

const EliminarCodigo = ({OpenModalNuevo, CerrarModal, codigo}) =>{

    const BorrarCodigo = () =>{
        OpenModalNuevo(<DivLoading />);
        DeleteCode({
            id: codigo
        }).then(() => {
            OpenModalNuevo(<CodigoEliminado CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    return(
        <ModalValidar 
            accion={BorrarCodigo} 
            titulo={'Eliminar Código'}
            mensaje={'¿Deseas Eliminar esta código de la lista?'}
            botonName={'Sí, Eliminar'}
            botonClass={'ButtonRed'}
            CerrarModal={CerrarModal}
        />
    )
}

const EliminarReferido = ({OpenModalNuevo, CerrarModal, codigo}) =>{

    const BorrarCodigo = (e) =>{
        OpenModalNuevo(<DivLoading />);
        EliminarCodigos({
            id: codigo
        }).then(() => {
            OpenModalNuevo(<CodigoEliminado CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    return(
        <ModalValidar 
            accion={BorrarCodigo} 
            titulo={'Eliminar Código'}
            mensaje={'¿Deseas Eliminar esta código de la lista?'}
            botonName={'Sí, Eliminar'}
            botonClass={'ButtonRed'}
            CerrarModal={CerrarModal}
        />
    )
}

export {
    EliminarCodigo,
    EliminarReferido
};