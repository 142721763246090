import React from 'react';
import { Helmet } from 'react-helmet-async';

const ComoFunciona = () =>{
    return(
        <>
            <Helmet><title>Cómo Funciona</title></Helmet>
            <div className="contGeneral">
                <h1 className="MainTitle TxtCenter">Cómo Registrarse en la Plataforma</h1>
                <div className='video-responsive'>
                    <iframe src="https://www.youtube.com/embed/502bQBVH_fY" frameborder="0" allowfullscreen title='Cómo Registrarse'></iframe>
                </div>
                <h1 className="SectionTitle TxtCenter">Cómo Registrar tus Destinatario</h1>
                <div className='video-responsive'>
                    <iframe src="https://www.youtube.com/embed/TPi9pJG_UOI" frameborder="0" allowfullscreen title='Cómo Registrarse'></iframe>
                </div>
                <h1 className="SectionTitle TxtCenter">Cómo Registrar tus Transferencias</h1>
                <div className='video-responsive'>
                    <iframe src="https://www.youtube.com/embed/e_KtxQv525c" frameborder="0" allowfullscreen title='Cómo Registrarse'></iframe>
                </div>
            </div>
        </>
    )
}

export default ComoFunciona;