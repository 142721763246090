import React from 'react';
import NumberFormat from 'react-number-format';
import { format, fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import useObtenerTransferencias from '../../hooks/useObtenerTrasferencias';
import './transfersInicio.css';
import ImgPlus from '../../../img/icons/plus.svg';
import ImgMoneyRte from '../../../img/icons/money-send.svg';
import ImgMoneyDno from '../../../img/icons/money-recive.svg';
import ImgConverter from '../../../img/icons/coin-convert.svg';
import ImgEstado from '../../../img/icons/route-square.svg';

const TrasnfersInicio = () => {
	
	const [transferencias, loading] = useObtenerTransferencias();

	const formatearFecha = (fecha) => {
		return format(fromUnixTime(fecha), "MMM dd',' yyyy '·' HH:mm a", { locale: es });
	}

	return (
		<>
			<div className="dashboardCard ">
				<p className="CardTitulo">Transferencias en Proceso</p>
				<div className="InicioTransferencia">
				{loading ? 
					<div className="loadingContainer"><div className="lds-ripple">
						<div></div><div></div>
					</div></div>
				: <>
				{transferencias.length > 0 ? 
					<>
					{
						transferencias.map((transferencia) => {
							return(
								<div className="itemTransfer" key={transferencia.id}>
									<Link to={`/transferencia/${transferencia.id}`} >
										<h3 className="numberTransfer">{transferencia.id.substring(0, 6)}</h3>
										<p className="dateTransfer">{formatearFecha(transferencia.fecha)}</p>
										<p className="destinoTransfer">{transferencia.destinatario.nombre +' '+ transferencia.destinatario.apellido}</p>
										
										<div className="divFlex-60">
											<div className="coinTransfer">
												<img src={ImgMoneyRte} alt="Dinero Enviado" />
												<p><label>Valor Pagado</label>
												<NumberFormat value={transferencia.transferencia.totalPagado} displayType={'text'} thousandSeparator={'.'} 
												decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/></p>
											</div>
											<div className="coinTransfer">
												<img src={ImgMoneyDno} alt="Dinero Recibido" />
												<p><label>Reciben</label>
												<NumberFormat value={transferencia.transferencia.montoAud} displayType={'text'} thousandSeparator={','} 
												decimalSeparator={'.'} prefix={'$'} suffix={' AUD'} decimalScale={'2'}/></p>
											</div>
											<div className="coinTransfer">
												<img src={ImgConverter} alt="Tasa de Cambio" />
												<p><label>Tasa de Cambio</label>
												<NumberFormat value={transferencia.transferencia.tasa} displayType={'text'} thousandSeparator={'.'} 
												decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/></p>
											</div>
										</div>
										<div className="divFlex-40">
											<div className="coinTransfer">
												<img src={ImgEstado} alt="Dinero Recibido" />
												<p><label>Estado</label>{transferencia.estado}</p>
											</div>
										</div>
									</Link>
								</div>
							);
						})
					}
					</>
					: 
					<div className="itemTransferEmpty">
						<p>No tienes transferencias en Proceso</p>
						<span>Te mostraremos tus transferencias en proceso aquí.</span>
						<Link to="/nueva-transferencia" className="boton-general imgButton-left">
							<img src={ImgPlus} alt="Nueva" />Nueva Transferencia
						</Link>
					</div>
				}
				</>
				}
				</div>
			</div>
		</>
	);
}

export default TrasnfersInicio;