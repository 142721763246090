import { db } from '../../firebase/firebaseConfig';

const AgregarAgencia = async(props) => {
    const{
        id,
        nombre,
        abn,
        estado
    } = props;
    return await db.collection('agencias').doc(id).set({
        nombre: nombre,
        abn: abn,
        estado: estado
    })
}

const EditarAgencia = async(props) => {
    const{
        id,
        nombre,
        abn,
        estado
    } = props;
    return await db.collection('agencias').doc(id).update({
        nombre: nombre,
        abn: abn,
        estado: estado
    })
}


const BorrarAgencia = async ({id}) => {
    return await db.collection('agencias').doc(id).delete()
}

export { 
    AgregarAgencia,
    EditarAgencia,
    BorrarAgencia
};