import React from 'react';
import imgMail from '../../../img/icons/sms-tracking.svg';
import imgWrong from '../../../img/icons/sms-wrong.svg';
import imgProfile from '../../../img/icons/profile-circle.svg';
import imgLock from '../../../img/icons/lock.svg';
import imgError from '../../../img/icons/close-square.svg';


const CorreoEnviado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgMail} alt="Mail Send" />
            </div>
            <h2>Listo!</h2>
            <p>Su mensaje fue enviado<br/><b>Correctamente</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    )
}

const HuboUnError = ({CerrarModal}) =>{
    return(
        <div className="InnerModal InnerWrong">
            <div className="IconModal IconWrong">
                <img src={imgWrong} alt="Mail Send" />
            </div>
            <h2>Oops!</h2>
            <p>Su mensaje no pudo ser enviado<br/><b>Inténtalo de nuevo</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const AlgoSalioMal = ({CerrarModal}) =>{
    return(
        <div className="InnerModal InnerWrong">
            <div className="IconModal IconWrong">
                <img src={imgError} alt="Mail Send" />
            </div>
            <h2>Oops!</h2>
            <p>Algo salió mal<br/><b>Inténtalo de nuevo</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const PerfilActualizado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgProfile} alt="Perfil" />
            </div>
            <h2>Listo!</h2>
            <p>Imagen de Perfil Actualizada<br/><b>Correctamente</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    )
}

const PerfilNoActualizado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal InnerWrong">
            <div className="IconModal IconWrong">
                <img src={imgProfile} alt="Perfil" />
            </div>
            <h2>Oops!</h2>
            <p>Su foto de perfil no se pudo actualizar<br/><b>Inténtalo nuevamente</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    )
}

const ContrasenaActualizada = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgLock} alt="Perfil" />
            </div>
            <h2>Listo!</h2>
            <p>Su contraseña fue actualizada<br/><b>Correctamente</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    )
}

const ContrasenaNoActualizada = ({CerrarModal}) =>{
    return(
        <div className="InnerModal InnerWrong">
            <div className="IconModal IconWrong">
                <img src={imgLock} alt="Perfil" />
            </div>
            <h2>Oops!</h2>
            <p>Su contraseña no pudo ser actualizada<br/><b>Inténtalo nuevamente</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    )
}

export {CorreoEnviado, HuboUnError, PerfilActualizado, PerfilNoActualizado, ContrasenaActualizada, ContrasenaNoActualizada, AlgoSalioMal};