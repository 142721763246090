import React from 'react';
import imgCheck from '../../img/icons/tick-square.svg';
import imgWrong from '../../img/icons/close-square.svg';
import imgQuestions from '../../img/icons/messages-1.svg';
import imgWarning from '../../img/icons/warning-2.svg';
import Whatsapp from '../../img/icons/whatsapp.svg';

const HuboUnError = ({CerrarModal}) =>{
    return(
        <div className='modalX'>
            <div className="InnerModal InnerWrong">
                <div className="IconModal IconWrong">
                    <img src={imgWrong} alt="Wrong" />
                </div>
                <h2>Oops!</h2>
                <p>Algo salió mal..<br/><b>Inténtalo de nuevo.</b></p>
                <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
            </div>
        </div>
    );
}

const TransRegistrada = ({CerrarModal}) =>{
    return(
        <div className='modalX'>
            <div className="InnerModal">
                <div className="IconModal">
                    <img src={imgCheck} alt="Check" />
                </div>
                <h2>Listo!</h2>
                <p>La transferencia ha sido <b>registrada.</b></p>
                <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
            </div>
        </div>
    );
}

const ProcesadaOk = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>Se ha subido el comprobante<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const CalificacionOk = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>La calificación fue registrada<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const TransaccionMayor = ({CerrarModal}) =>{
    return(
        <div className='modalX'>
            <div className="InnerModal">
                <div className="IconModal">
                    <img src={imgQuestions} alt="Mail Send" />
                </div>
                <h2>¡Contáctanos!</h2>
                <p>Para transferencias superiores a <b>$10,000 AUD</b> debes de <b>contáctarnos.</b></p>
                <a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className="boton-general imgButton-left">
                    <img src={Whatsapp} alt="Wsapp" />Escríbenos</a>
                <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
            </div>
        </div>
    );
}

const MinimoPermitido = ({CerrarModal}) =>{
    return(
        <div className='modalX'>
            <div className="InnerModal InnerWrong">
                <div className="IconModal IconWrong">
                    <img src={imgWarning} alt="Wrong" />
                </div>
                <h2>Oops!</h2>
                <p>El Valor Máximo en método UltraRápido<br/>por día es de <b>$4,999 AUD</b></p>
                <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
            </div>
        </div>
    );
}

const DestinoEliminado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>El Destinatario se ha eliminado<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}


export {HuboUnError, TransRegistrada, ProcesadaOk, CalificacionOk, TransaccionMayor, MinimoPermitido, DestinoEliminado}