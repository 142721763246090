import { useState, useEffect } from 'react';
import { db } from '../../../firebase/firebaseConfig';

const useFees = () => {

    const [ultraRate, setUltraRate] = useState();
    const [loading, setLoading] = useState(true);
    
    useEffect(() =>{
        const unsuscribe = db.collection('config').doc('fees').get()
        .then(snapshot =>{
            setUltraRate(snapshot.data().ultrarapido);
            setLoading(false);
        })
        
        return unsuscribe;
    },[]);
    
    return [ultraRate,loading];
}

export default useFees;