import { useEffect, useState } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useHistory } from 'react-router-dom';

const useObtenerTransferencia = (id) =>{

    const [transferencia, setTransferencia] = useState('');
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect (()=>{
        const cancelarSuscripcion = db.collection('transferencias').doc(id).get()
        .then((doc)=>{
            if(doc.exists){
                setLoading(false);
                setTransferencia(doc);
            }else{
                history.push('/transferencias');
            }
        })
        return cancelarSuscripcion;
    }, [id, history]);

    return[transferencia, loading]
}

export default useObtenerTransferencia;