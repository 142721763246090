import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

import './error404.css';

const Error404 = () =>{
    const {usuario} = useAuth();
    return(
        <div className="contError404">
            <div className="Error404">
                <h1>Error 404</h1>
                <h3>Página no encontrada</h3>
                <p>La página que buscas no existe o fue cambiada de lugar.</p>
                <Link to={usuario ? '/dashboard': '/' }>Volver al Inicio</Link>
            </div>
        </div>
    );
}

export default Error404;