import React, {useState, useEffect} from "react";
import ModalValidar from "../../../../views/components/modales/MoldaValidar";
import { HuboUnError, CambioRegistrado } from "../../../components/Mensajes";
import { ActualizarTransferencia } from "../../../data/AdminTransfer";
import SelectorSelec from "../../../../views/components/inputs/Selector";
import DivLoading from "../../../../views/components/loading/Loading";

const ActualizarEstado = ({OpenModalNuevo, CerrarModal, setEstadoTrans, id, estadoTrans}) =>{

    const [estadoOp,setEstadoOp] = useState('Seleccionar');
    const [spnEstado,setSpnEstado] = useState(false);
    
    const estados = [
        { id: '1', texto: 'Registrada'},
        { id: '2', texto: 'En Revisión'},
        { id: '3', texto: 'En Proceso'},
        { id: '4', texto: 'Procesada'},
        { id: '5', texto: 'Cancelada'},
    ]

    useEffect(()=>{
        if(estadoTrans){
            setEstadoOp(estadoTrans)
        }
    },[estadoTrans])

    const ActualizarTrans = () =>{
        OpenModalNuevo(<DivLoading />)
        ActualizarTransferencia({
            id: id,
            estado: estadoOp,
        }).then(() =>{
            setEstadoTrans(estadoOp)
            OpenModalNuevo(<CambioRegistrado CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }
    
    const AbrirActualizar = (e) =>{
        e.preventDefault();
        OpenModalNuevo(
            <ModalValidar 
                accion={ActualizarTrans} 
                titulo={'Actualizar Estado'}
                mensaje={'¿Estás seguro de actualizar el estado de la transferencia?'}
                botonName={'Sí, Actualizar'}
                botonClass={'ButtonGreen'}
                CerrarModal={CerrarModal}
            />
        )
    }

    return(
        <>
            <div className="CardBody">
                <h3>Cambiar Estado Sin Correo</h3>
                <SelectorSelec opciones={estados} TipoOpcion={'Definir Estado'} opcion={estadoOp} 
                    cambiarOpcion={setEstadoOp} spnRed={spnEstado} setSpnRed={setSpnEstado} />
                <button className="boton-general BtnLarge" onClick={AbrirActualizar}>Actualizar Estado</button>
            </div>
        </>
    )
}

export default ActualizarEstado;