import React,{useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { firebase } from '../../firebase/firebaseConfig';
import { CorreoVerificacion, HuboUnError } from './Correos';
import ModalNuevo from '../components/modales/ModalNuevo';
import './Template.css';
import LogoCol from '../../img/logocolcambiosicon.svg';
import Salir from '../../img/icons/log-out.svg';

const VerificarEmail = () =>{

    const history = useHistory();
    const {usuario, cerrarSesion} = useAuth();
    const [verificado, setVerificado] = useState(false);
    
    useEffect(() => {
        if(usuario){
            if(usuario.emailVerified){
                setVerificado(true);
                const timer1 = setTimeout(()=>{
                    history.push('/terminar-registro');
                }, 50000);
                return () => {clearTimeout(timer1);};  
            }else{
                setVerificado(false);
            }
        }
    }, [usuario, history])

    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [componente, cambiarComponente] = useState('');
    const [modal, cambiarModal] = useState('modal-general');
    const [clases, cambiarClases] = useState('fonder');

    const OpenModalNuevo = (componenterecibido) => {
        cambiarEstadoModal(true);
        cambiarComponente(componenterecibido);
        setTimeout(() => {
            cambiarModal('modal-general modal-activo');
            cambiarClases('fonder fonder-activo');
        }, 400);
    }

    const CerrarModal = (e) => {
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
        setTimeout(() => {
            cambiarEstadoModal(false)
        }, 600);
    }

    const EnviarCorreo = () =>{
        firebase.auth().currentUser.sendEmailVerification().then(()=>{
            OpenModalNuevo(<CorreoVerificacion CerrarModal={CerrarModal}/>)
        }).catch(() =>{
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>)
        });
    }
    
    return(
        <>
            <div className="ContLog">
                <div className="ContLogBd">
                    <div className="ContVerify">
                        <div className="ContHeads">
                            <div className="IconContext">
                                <img src={LogoCol} alt="email"/>
                            </div>
                        </div>
                        {!verificado ? <>
                            <h3>Verifica tu Email</h3>
                            <p>Hemos enviado un correo electrónico para verificar tu Email.<br/>
                            ¿No lo has recibido? <button className="" onClick={()=>{EnviarCorreo()}}>Reenviar Correo</button></p>
                            <button className="boton-general" onClick={()=>{window.location.reload();}}>Verificar Email</button>
                        </>:<>
                            <h3 className="TextGreen">Email Verificado</h3>
                            <p>Termina de completar tu registro</p>
                            <Link to="/terminar-registro" className='boton-general'>Completar Registro</Link>
                        </>}
                    </div>
                    <div className="divSalir">
                        <p>¿Deseas verificar tu correo luego?</p>
                        <button type="button" onClick={cerrarSesion} className="boton-text imgButton-rigth">Salir<img src={Salir} alt="Salir" /></button>  
                    </div>
                </div>
                
            </div>
            <ModalNuevo
                modal={modal}
                CerrarModal={CerrarModal}
                clases={clases}
                componente={componente}
                estadoModal={estadoModal} 
            />
        </>
    )
}

export default VerificarEmail;