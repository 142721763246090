import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../context/AuthContext';
import { useTrans } from '../../../context/TransferContext';
import { getUnixTime,format, fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { TransaccionMayor, MinimoPermitido,HuboUnError,TransRegistrada } from '../../../client/components/Mensajes';
import useObtenerDestinatario from '../../hooks/useObtenerDestinatario';
import Convertir from '../../components/conversor/Convetir';
import Modal from './Components/Modal';
import {AgregarTransferencia, AsignarCodigo, AsignarEspecial, AsignarNuevo} from '../../data/AgregarTransferencia';
import CuentaSuspendida from './Components/CuentaSuspendida';
import CardsNueva from './Components/CardsNueva';
import Resumen from './Resumen';
import FixValues from './FixValues';
import Comprobante from './Components/Comprobante';
import BuscarPromo from './Components/BuscarPromo';
import BuscarReferido from './Components/BuscarReferido';
import Footer from '../../../views/components/footer/Footer';
import DivLoading from '../../../views/components/loading/Loading';
import LoadingPage from '../../../views/components/loading/LoadingPage';
import Inputs from '../../../views/components/inputs/Inputs';
import SelectDestinatario from '../../components/inputs/SelectDestinatario';
import SelectCuenta from '../../components/inputs/SelectCuenta';
import SelectorCuentas from '../../../views/components/inputs/SelectorCuentas';
import '../../../styles/Procesos.css';
import './nueva.css';
import ImgDestinatario from '../../../img/icons/users.svg';
import ImgValor from '../../../img/icons/dollar-sign.svg';
import ImgPago from '../../../img/icons/credit-card.svg';
import Close from '../../../img/icons/close-square.svg';
import Check from '../../../img/icons/tick-square.svg';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import ImgWarning from '../../../img/icons/warning-2.svg';
import Receipt from '../../../img/icons/arrow-up-1.svg';

const NuevaTransferencia = () => {

    const fecha = new Date();
    const [fechaTransfer, setFechaTransfer] = useState();
    const formatearFecha = (fecha) => {return format(fromUnixTime(fecha), "dd'/'MMM'/'yy", { locale: es }).toUpperCase();}

    const [cargando, setCargando] = useState(true);
    const [validando, setValidando] = useState(false);
    const [registrada, setRegistrada] = useState(false);

    const {destinoAu, tasadeCambio, tasaPre, feeMethod, feeInclude, FijarDestino, FijarValor, ultrarapido} = useTrans();
    const [tasaEstable, setTasaEstable] = useState();
    useEffect(()=>{if(tasadeCambio){setTasaEstable(tasadeCambio);}},[tasadeCambio])

    //FUNCIONES
    const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}

    //TRANSFERENCIA
    const [copValue, setMontoCop] = useState();
    const [audValue, setMontoAud] = useState();
    const [valorImpuesto, setValorImpuesto] = useState(0);
    const [totalaPagar, setTotalaPagar] = useState(0);
    const [totalRecibido, setTotalRecibido ] = useState(0);
    //EMAIL
    const [emailCop, setEmailCop] =  useState();
    const [emailAud, setEmailAud] =  useState();
    const [emailTasa, setEmailTasa] = useState();
    const [emailData, setEmailData] = useState(false);
    //SERVICIO
    const [fee, setFee] = useState(4.99);
    const [metodo, setMetodo] = useState('Tradicional');
    const [ultraFee, setUltraFee] = useState();
    const [servicio, setServicio] = useState(false);
    const [impuestoBanco, setImpuestoBanco] = useState(null);
    const [pagarImpuesto, setPagarImpuesto] = useState(null);
    //INFORMACION
    const [nuevaRazon, setNuevaRazon] = useState('');
    const [cuentaCol, setCuentaCol] = useState();
    const [colcambios, setColcambios] = useState();
    const [receiver, cambiarDestinatario] = useState();
    const [fechaMaxPago, setFechaMaxPago] = useState();
    const [docRef, setDocRef] = useState();
    //REMITENTE
    const { infoUser } = useAuth();
    const [suspendido, setSuspendido] = useState();
    const [destino, setDestino] = useState();
    const [uidUsuario, setUidUsuario] = useState('');
    const [rteNombre, setRteNombre] = useState('');
    const [rteSdoNombre, setRteSdoNombre ] = useState('');
    const [rteApellido, setRteApellido] = useState('');
    const [rteSdoApellido, setRteSdoApellido ] = useState('');
    const [rteTipoId, setRteTipoId] = useState('');
    const [rteNumeroId, setRteNumeroId] = useState('');
    const [rteDocumento, setRteDocumento] = useState('');
    const [rteEmail, setRteEmail] = useState('');
    const [rteCelular, setRteCelular] = useState('');
    const [rtePais, setRtePais] = useState('');
    const [rteCiudad, setRteCiudad] = useState('');
    const [rteAddress, setRteAddress] = useState('');
    const [cuentas, setCuentas] = useState([]);
    //DESTINATARIO
    const [idSelec, setIdSelect] = useState();
    const [contacto] = useObtenerDestinatario(idSelec);
    const [autoId, setAutoId] = useState();
    const [autoEnvio, setAutoEnvio] = useState(false);
    const [cuentaSel, setCuentaSel] = useState('');
    const [datosCuenta, setDatosCuenta] = useState('');

    const [tipoDno, setTipoDno] = useState('');
    const [dnoNombre, setDnoNombre] = useState('');
    const [dnoSdoNombre, setDnoSdoNombre] = useState('');
    const [dnoApellido, setDnoApellido] = useState('');
    const [dnoSdoApellido, setDnoSdoApellido] = useState('');
    const [dnoTipoId, setDnoTipoId] = useState('');
    const [dnoNumeroId, setDnoNumeroId] = useState('');
    const [dnoDocumento, setDnoDocumento] = useState('');
    const [dnoEmail, setDnoEmail] = useState('');
    const [dnoCelular, setDnoCelular]  = useState('');
    const [dnoPais, setDnoPais] = useState('');
    const [dnoCiudad, setDnoCiudad] = useState('');
    const [dnoAddress, setDnoAddress] = useState('');
    //CUENTA DESTINATARIO
    const [banco, setBanco] = useState('');
    const [titular, setTitular] = useState('');
    const [bsb, setBsb] = useState('');
    const [numeroCuenta, setNumeroCuenta] = useState('');
    //CODIGOS
    const [referido, setReferido] = useState('');
    const [codigoPromo, setCodigoPromo] = useState('');
    const [tipoCode, setTipoCode] = useState('');
    const [descuento, setDescuento] = useState('');
    const [userCod, setUserCod] = useState('');
    const [agenciaId, setAgenciaId] = useState('');
    const [promoFee, setPromoFee] = useState(false);

    //SET REMITENTE
    useEffect(()=>{
        if(infoUser){
            setUidUsuario(infoUser.usuario)
            setRteNombre(infoUser.nombre.nombre)
            setRteSdoNombre(infoUser.nombre.segNombre)
            setRteApellido(infoUser.nombre.apellido)
            setRteSdoApellido(infoUser.nombre.segApellido)
            setRteTipoId(infoUser.documento.tipoDocumento)
            setRteNumeroId(infoUser.documento.numeroDocumento)
            setRteDocumento(infoUser.documento.urlDocumento)
            setRteEmail(infoUser.email)
            setRteCelular(infoUser.celular)
            setRtePais(infoUser.direccion.pais)
            setRteCiudad(infoUser.direccion.ciudad)
            setRteAddress(infoUser.direccion.direccion)
            if(infoUser.contactos){
                if(infoUser.contactos > 0){setDestino(true);
                }else{setDestino(false);}
            }
            setCuentas(infoUser.cuentas);
            setSuspendido(infoUser.suspendido);
            setCargando(false);
        }
    },[infoUser])

    //SET ULTRARÁPIDO
    useEffect(()=>{
        if(ultrarapido){setUltraFee(ultrarapido)}
        else{setUltraFee(false);}
    },[ultrarapido])

    //SET DESTINATARIO
    useEffect(()=>{
        if(destinoAu){
            if(destinoAu === uidUsuario){
                setAutoId(destinoAu);setIdSelect();setAutoEnvio(true);
            }else{
                setIdSelect(destinoAu);setAutoId();setAutoEnvio(false);
            }
        }
    },[destinoAu, uidUsuario])

    useEffect(()=>{
        if(autoId){
            setDnoNombre(infoUser.nombre.nombre)
            setDnoSdoNombre(infoUser.nombre.segNombre)
            setDnoApellido(infoUser.nombre.apellido)
            setDnoSdoApellido(infoUser.nombre.segApellido)
            setDnoTipoId(infoUser.documento.tipoDocumento)
            setDnoNumeroId(infoUser.documento.numeroDocumento)
            setDnoDocumento(infoUser.documento.urlDocumento)
            setDnoEmail(infoUser.email)
            setDnoCelular(infoUser.celular)
            setDnoPais(infoUser.direccion.pais)
            setDnoCiudad('')
            setDnoAddress(infoUser.direccion.direccion)
        }else{
            if(contacto){
                setDnoNombre(contacto.data().nombre.nombre)
                setDnoSdoNombre(contacto.data().nombre.segNombre)
                setDnoApellido(contacto.data().nombre.apellido)
                setDnoSdoApellido(contacto.data().nombre.segApellido)
                setDnoTipoId(contacto.data().documento.tipoDocumento)
                setDnoNumeroId(contacto.data().documento.numeroDocumento)
                setDnoDocumento(contacto.data().documento.urlDocumento)
                setDnoEmail(contacto.data().email)
                setDnoCelular(contacto.data().celular)
                setDnoPais(contacto.data().direccion.pais)
                setDnoCiudad(contacto.data().direccion.ciudad)
                setDnoAddress(contacto.data().direccion.direccion)
                setBanco(contacto.data().cuenta.banco)
                setBsb(contacto.data().cuenta.bsb)
                setTitular(contacto.data().cuenta.titular)
                setNumeroCuenta(contacto.data().cuenta.numeroCuenta)
                if(contacto.data().tipo){
                    setTipoDno(contacto.data().tipo);
                }else{
                    setTipoDno('Persona');
                }
		    }
        }
    },[contacto, autoId,infoUser])

    useEffect(()=>{
        if(autoId){
            if(datosCuenta){
                setBanco(datosCuenta.banco);
                setBsb(datosCuenta.bsb);
                setTitular(datosCuenta.titular);
                setNumeroCuenta(datosCuenta.numeroCuenta);
            }
        }
    },[datosCuenta, autoId])

    const ChangeReceiver = (e) =>{e.preventDefault();setBanco('');setBsb('');setTitular('');setNumeroCuenta('');}

    //SET FEE EXTERNO
    useEffect(()=>{
        if(feeMethod){
            if(feeMethod==='Tradicional'){setMetodo(feeMethod);
            }else{setMetodo(feeMethod);} 
    }},[feeMethod])

    useEffect(() =>{
        if(fee === 4.99){setMetodo('Tradicional');} 
        if(fee === 14.99){setMetodo('UltraRápido');}
        if(fee === 0){setMetodo('Tradicional');}
    },[fee])

    useEffect(()=>{
        if(feeInclude){
            if(feeInclude==='Servicio Incluído'){setServicio(false);
            }else{setServicio(true);} 
    }},[feeInclude]);

    //SET CODIGOS
    useEffect(()=>{
        if(codigoPromo){
            if(descuento === 'Tasa'){setTasaEstable(tasaPre);}
        }else{setTasaEstable(tasadeCambio);}
    },[codigoPromo, descuento, tasaPre, tasadeCambio]);

    useEffect(()=>{
        if(codigoPromo){
            if(descuento === 'Fee'){setFee(0);setPromoFee(true);}
        }else{setFee(4.99);setPromoFee(false);}
    },[codigoPromo, descuento])

    //IMPUESTO
    useEffect(()=>{
        if(impuestoBanco){setValorImpuesto(20000);} 
        else{setValorImpuesto(0);setPagarImpuesto(null)}
        setSpnAltForPago(false);
    },[impuestoBanco]);

    //CALCULO TRANSFERENCIA TOTAL
    useEffect(()=>{
        if(!servicio){
            if(pagarImpuesto){
                setTotalaPagar(Math.ceil(parseFloat(copValue))); //TOTAL A PAGAR
                if(descuento === 'Tasa'){
                    setTotalRecibido(Math.floor(((parseFloat(copValue) - parseFloat(valorImpuesto)) - (parseFloat(fee) * tasadeCambio)) / tasaEstable));
                }else{
                    setTotalRecibido(Math.floor((parseFloat(copValue) - parseFloat(valorImpuesto)) / tasaEstable - parseFloat(fee))); //TOTAL A RECIBIR -SERVICIO -IMPUESTOS
                }
            }else{
                setTotalaPagar(Math.ceil(parseFloat(copValue) + parseFloat(valorImpuesto))); //TOTAL A PAGAR + IMPUESTO
                if(descuento === 'Tasa'){
                    setTotalRecibido(Math.floor(((parseFloat(audValue) * tasaEstable) - (parseFloat(fee) * tasadeCambio)) / tasaEstable));
                }else{
                    setTotalRecibido(Math.floor(parseFloat(audValue) - parseFloat(fee))); //TOTAL A RECIBIR -SERVICIO
                }
            }
        }else{
            if(pagarImpuesto){
                setTotalRecibido(Math.floor((parseFloat(copValue) - parseFloat(valorImpuesto)) / tasaEstable));
                if(descuento === 'Tasa'){
                    setTotalaPagar(Math.ceil((parseFloat(audValue) * tasaEstable) + (parseFloat(fee) * tasadeCambio)));
                }else{
                    setTotalaPagar(Math.ceil(((parseFloat(audValue) + parseFloat(fee)) * tasaEstable))); //TOTAL A PAGAR +SERVICIO
                }
            }else{
                setTotalRecibido(Math.floor(parseFloat(audValue))); //TOTAL A RECIBIR 
                if(descuento === 'Tasa'){
                    setTotalaPagar(Math.ceil(((parseFloat(audValue) * tasaEstable) + (parseFloat(fee) * tasadeCambio)) + parseFloat(valorImpuesto)));
                }else{
                    setTotalaPagar(Math.ceil(((parseFloat(audValue) + parseFloat(fee)) * tasaEstable) + parseFloat(valorImpuesto))); //TOTAL A PAGAR +SERVICIO +IMPUESTO 
                }
            } 
        }
    },[audValue,copValue,valorImpuesto,fee,servicio,tasaEstable,tasadeCambio,pagarImpuesto, descuento]);

    //PASO A PASO
    const [comp1, setComp1] = useState('formProgress-current');
    const [comp2, setComp2] = useState();
    const [comp3, setComp3] = useState();
    const [paso1, setPaso1] = useState(true);
    const [paso2, setPaso2] = useState(false);
    const [paso3, setPaso3] = useState(false);

    //SUBIR COMPROBANTE
    const [update, setUpdate] = useState(false);

    //RECOMENDACIONES Y ALERTAS
    const [spnAltForPago, setSpnAltForPago] = useState(false);
    const [spnAltDno, setSpnAltDno] = useState(false);
    const [spnNRazon, setSpnNRazon] = useState(false);
    const [spnMinimo, setSpnMinimo] = useState(false);
    const [spnCuenta, setSpnCuenta] = useState(false);
    const [spnAutoCuenta, setSpnAutoCuenta] = useState(false);

    const [altForPago, setAltForPago] = useState('');
    const [altDno, setAltDno] = useState('');
    const [altNRazon, setAltNRazon] = useState('');
    const [altMinimo, setAltMinimo] = useState('');
    const [altCuenta, setAltCuenta] = useState('');
    const [altAutoCuenta, setAltAutoCuenta] = useState('');

    const StepOne = (e) =>{
        e.preventDefault();
        if (totalRecibido < 100){ toUp(); return;}
        if (totalRecibido > 5000){if(fee===14.99){AbrirModal(<MinimoPermitido CerrarModal={CerrarModal}/>); setAltMinimo('El valor mínimo es de $100 AUD'); toUp(); return;}}
        if (totalRecibido > 10000){AbrirModal(<TransaccionMayor CerrarModal={CerrarModal}/>); toUp(); return;}
        if (impuestoBanco === null){setSpnAltForPago(true); setAltForPago('Por favor selecciona una forma de pago'); return;}
        else{toUp(); setPaso1(false);setPaso2(true);setComp1('formProgress-active');setComp2('formProgress-current');}
    }

    const BackStepOne = (e) =>{e.preventDefault(); toUp(); setPaso1(true);setPaso2(false);setComp1('formProgress-current');setComp2();}

    const StepTwo = (e) =>{
        e.preventDefault();
        if (!nuevaRazon){setSpnNRazon(true); setAltNRazon('Por ingresa la razón del envío'); return;}
        if (nuevaRazon.length < 5){setSpnNRazon(true); setAltNRazon('Debe contener más de 5 letras'); return;}
        if (!receiver){setSpnAltDno(true); setAltDno('Selecciona un destinatario'); return;}
        if (!banco){setSpnAutoCuenta(true); setAltAutoCuenta('Por favor selecciona la cuenta'); return;}
        else{toUp();setPaso2(false); setPaso3(true); setComp2('formProgress-active'); setComp3('formProgress-current'); setEmailData(true);}
    }

    const BackStepTwo = (e) =>{ e.preventDefault(); toUp(); setPaso2(true); setPaso3(false); setComp2('formProgress-current'); setComp3(); setEmailData(false)}

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!cuentaCol){setSpnCuenta(true); setAltCuenta('Selecciona la cuenta de pago'); return;}
        toUp();
        setValidando(true);
        setFechaMaxPago((new Date().getTime()) + 900000);
        setFechaTransfer(formatearFecha(getUnixTime(fecha)));
        const fechaTransfer = formatearFecha(getUnixTime(fecha))
        const selReason = nuevaRazon
        if(tipoCode === 'Promocional'){
            if(userCod === 'first'){
                if(agenciaId){
                    AsignarNuevo({
                        usuario: uidUsuario,
                        codigo: codigoPromo,
                        codEstado: 'ReUse'
                    })
                }else{
                    AsignarNuevo({
                        usuario: uidUsuario,
                        codigo: codigoPromo,
                        codEstado: 'Usado'
                    })
                }
                
            }
            if(userCod === 'second'){
                if(agenciaId){
                    AsignarCodigo({
                        usuario: uidUsuario,
                        codigo: codigoPromo,
                        codEstado: 'ReUse'
                    })
                }else{
                    AsignarCodigo({
                        usuario: uidUsuario,
                        codigo: codigoPromo,
                        codEstado: 'Usado'
                    })
                }
            }
        }
        if(tipoCode === 'Especial'){AsignarEspecial({ codigo: codigoPromo})}
        AgregarTransferencia({
            fecha: getUnixTime(fecha),
            fechaMaxPago: ((new Date().getTime()) + 900000),
            estado: 'Registrada',
            usuario: uidUsuario,
            destinatarioId: idSelec || autoId,
            razon: nuevaRazon,
            colcambios: colcambios,
            autoEnvio: autoEnvio,
            transferencia:{
                montoCop: copValue,
                montoAud: totalRecibido,
                servicio: servicio,
                totalPagado: totalaPagar,
                metodo: metodo,
                fee: fee,
                cargos: valorImpuesto,
                pagoImpuesto: pagarImpuesto,
                tasa: tasaEstable,
                emailCop: emailCop,
                emailAud: emailAud,
                emailTasa: emailTasa,
            },
            remitente:{
                nombre: rteNombre,
                segNombre: rteSdoNombre,
                apellido: rteApellido,
                segApellido: rteSdoApellido,
                tipoId: rteTipoId,
                numeroId: rteNumeroId,
                documento: rteDocumento,
                email: rteEmail,
                celular: rteCelular,
                pais: rtePais,
                ciudad: rteCiudad,
                direccion: rteAddress
            },
            destinatario:{
                tipo: tipoDno,
                nombre: dnoNombre,
                segNombre: dnoSdoNombre,
                apellido: dnoApellido,
                segApellido: dnoSdoApellido,
                tipoId: dnoTipoId,
                numeroId: dnoNumeroId,
                documento: dnoDocumento,
                email: dnoEmail,
                celular: dnoCelular,
                pais: dnoPais,
                ciudad: dnoCiudad || '',
                direccion: dnoAddress
            },
            cuenta:{
                banco: banco,
                titular: titular,
                bsb: bsb,
                numeroCuenta: numeroCuenta
            },
            referido: referido,
            promocional:{
                codigo: codigoPromo,
                tipo: tipoCode,
                descuento: descuento,
                agenciaId: agenciaId,
            }
        }).then(async(docRef) => {
            const noTransfer = docRef.id.substring(0, 6).toUpperCase();
            setDocRef(docRef.id);
            await axios.post('https://server-colcambios.herokuapp.com/api/transferencia', 
                {rteEmail,noTransfer,fechaTransfer,dnoNombre,dnoApellido,emailCop,emailAud,emailTasa,selReason,metodo,rteNombre})
                .then(res =>{
                    if(res.data.success){
                        AbrirModal(<TransRegistrada CerrarModal={CerrarModal}/>);
                        setRegistrada(true);
                        setValidando(false);
                        FijarDestino('')
                        FijarValor('','');
                    } else{
                        setValidando(false);
                    }
                }).catch(err =>{
                    AbrirModal(<HuboUnError CerrarModal={CerrarModal}/>);
                    setValidando(false);
                })
        }).catch(() => {
            AbrirModal(<HuboUnError CerrarModal={CerrarModal}/>);
            setValidando(false);
        })
    }

    //MODALES
    const [statusModal, setStatusModal] = useState(false);
    const [ModaType, setModalType] = useState('');
    const AbrirModal = (componenteModal) =>{setStatusModal(true); setModalType(componenteModal)}
    const CerrarModal = (e) =>{ e.preventDefault(); setTimeout(() => {setStatusModal(false)}, 100);}

    //RESUMEN
    const [resumen, setResumen] = useState(false);
    const abrirResumen = (e) =>{e.preventDefault();setResumen(!resumen);}

    return (
        <>{!cargando ? <> 
            <Helmet><title>Nueva Transferencia</title></Helmet>
            <div className="divContainer">
                <div className="divSubContainer">
                    {suspendido ? 
                        <CuentaSuspendida />: 
                        <>
                            <div className="formData">
                            <div className="formProgress">
                                {!destino ? <h3>Agrega un Destinatario</h3>
                                :<>
                                    {registrada ? <>{!update ? <h3>Transferencia Registrada</h3> : <h3>Comprobante Cargado</h3>} </>
                                    :   <>{validando ? <h3>Cargando...</h3> 
                                        :   <ul>
                                                <li className={comp1}><span><img src={ImgValor} alt="Valor" /></span><label>Paso 1</label>Valor</li>
                                                <li className={comp2}><span><img src={ImgDestinatario} alt="Destinatario" /></span><label>Paso 2</label>Destinatario</li>
                                                <li className={comp3}><span><img src={ImgPago} alt="Pago" /></span><label>Paso 3</label>Pago</li>
                                            </ul>
                                    }</>}
                                </>}
                            </div>
                            <div className="formContent">
                                {!validando ? <> {registrada ? 
                                    <Comprobante update={update} setUpdate={setUpdate} colcambios={colcambios} fechaMaxPago={fechaMaxPago} uidUsuario={uidUsuario}
                                        docRef={docRef} rteNombre={rteNombre} rteEmail={rteEmail} dnoNombre={dnoNombre} dnoApellido={dnoApellido}
                                        emailAud={emailAud} emailTasa={emailTasa} metodo={metodo} fechaTransfer={fechaTransfer} nuevaRazon={nuevaRazon}
                                        CerrarModal={CerrarModal} OpenModalNuevo={AbrirModal} /> 
                                    : <>
                                    {!destino ? 
                                        <div className="Completado">
                                            <div className="CompletadoImg"><img src={ImgDestinatario} alt="listo" /></div>
                                            <p>Para poder realizar una transferencia, debes de tener registrado un destinatario.</p>
                                            <Link to="nuevo-destinatario" className="boton-general">Registrar Destinatario</Link>
                                        </div>
                                    : 
                                    <form onSubmit={handleSubmit}>
                                        <div className="formTitle">
                                            <h1 className="text-title">Nueva Transferencia</h1>
                                            {paso1 && <p>Ingresa el valor a enviar y ajusta el servicio de acuerdo a tu necesidad.</p>}
                                            {paso2 && <p>Selecciona la razón de tu transferencia y el destinatario.</p>}
                                            {paso3 && <p>Selecciona una de nuestras cuentas dónde realizarás el pago.</p>}
                                        </div>
                                        <div className="row p-0">
                                            <div className="col-md-7">
                                                <div className={paso1 ? 'step step1 habil' : 'step step1'}>
                                                    <Convertir setMontoCop={setMontoCop} setMontoAud={setMontoAud} servicio={servicio} spnMinimo={spnMinimo} 
                                                        setSpnMinimo={setSpnMinimo} altMinimo={altMinimo} codigo={codigoPromo} descuento={descuento}/>
                                                    <CardsNueva fee={fee} setFee={setFee} setSpnMinimo={setSpnMinimo} ultraFee={ultraFee} servicio={servicio} 
                                                        setServicio={setServicio} spnAltForPago={spnAltForPago} altForPago={altForPago} impuestoBanco={impuestoBanco}
                                                        setImpuestoBanco={setImpuestoBanco} pagarImpuesto ={pagarImpuesto} setPagarImpuesto={setPagarImpuesto} promoFee={promoFee}/>
                                                </div>
                                                <div className={paso2 ? 'step step2 habil' : 'step step2'}>
                                                    <Inputs name={'nuevaRazon'} type={'text'} label={'Razón del Envío'} value={nuevaRazon} setValue={setNuevaRazon} 
                                                        alt={altNRazon} spn={spnNRazon} setSpn={setSpnNRazon} fill={'on'} />
                                                    <div className="input-select">
                                                        <SelectDestinatario receiver={receiver} setDnoId={setIdSelect} setAutoId={setAutoId} ChangeReceiver={ChangeReceiver}
                                                            cambiarDestinatario={cambiarDestinatario} spnRed={spnAltDno} setSpnRed={setSpnAltDno} setAutoEnvio={setAutoEnvio}/>
                                                            {spnAltDno && <span className="spanAlert">{altDno}</span>}
                                                    </div>
                                                    {receiver === 'Auto Envio' &&
                                                        <div className="input-select">
                                                            <SelectorCuentas opciones={cuentas} TipoOpcion={'Cuentas'} opcion={cuentaSel} setDatosCuenta={setDatosCuenta}
                                                                cambiarOpcion={setCuentaSel} spnRed={spnAutoCuenta} setSpnRed={setSpnAutoCuenta}/>
                                                                {spnAutoCuenta && <span className="spanAlert">{altAutoCuenta}</span>}
                                                        </div>
                                                    }
                                                    {receiver && 
                                                        <div className="row p-0">
                                                            <div className="col-12 col-sm-6">
                                                                <div className="inputDisabled"><label  className={dnoTipoId ? 'listaAzul' : ''}>
                                                                    Tipo de Identifiación</label><p>{dnoTipoId}</p></div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="inputDisabled"><label className={dnoNumeroId ? 'listaAzul' : ''}>
                                                                    Número de Identificación</label><p>{dnoNumeroId}</p></div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="inputDisabled"><label className={banco ? 'listaAzul' : ''}>
                                                                    Nombre del Banco</label><p>{banco}</p></div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="inputDisabled"><label className={titular ? 'listaAzul' : ''}>
                                                                    Titular de la Cuenta</label><p>{titular}</p></div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="inputDisabled"><label className={numeroCuenta ? 'listaAzul' : ''}>
                                                                    Número de Cuenta</label><p>{numeroCuenta}</p></div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="inputDisabled"><label className={bsb ? 'listaAzul' : ''}>
                                                                    Número Bsb</label><p>{bsb}</p></div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={paso3 ? 'step step3 habil' : 'step step3'}>
                                                    <div>
                                                        <div className="input-select">
                                                            <SelectCuenta cuenta={cuentaCol} setCuenta={setCuentaCol} spnRed={spnCuenta} 
                                                                setSpnRed={setSpnCuenta} setColcambios={setColcambios}/>
                                                                {spnCuenta && <span className="spanAlert">{altCuenta}</span>}
                                                        </div>

                                                        <div className="Recomendacion">
                                                            <div><img src={ImgWarning} alt='Importante'/></div>
                                                            <div>
                                                                <span>Importante:</span>
                                                                <p>Para evitar contratiempos con el método <b>UltraRápido</b>, es indispensable que selecciones y realices el pago de tu transferencia entre cuentas del mismo banco o en efectivo.</p>
                                                            </div>
                                                        </div>
                                                        <BuscarReferido OpenModalNuevo={AbrirModal} CerrarModal={CerrarModal} setReferido={setReferido} />
                                                        {emailData &&
                                                            <FixValues setEmailCop={setEmailCop} setEmailAud={setEmailAud} setEmailTasa={setEmailTasa}
                                                            totalaPagar={totalaPagar} totalRecibido={totalRecibido} tasaEmail={tasaEstable} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-5">
                                                <div className={!resumen ? 'containerResumen' : 'containerResumen resumenActivo'}>
                                                    <img src={Receipt} alt="Recibo" className={!resumen ? 'botonResumen' : 'botonResumen rotateArrow'} onClick={abrirResumen}/>
                                                    <Resumen servicio={servicio} copValue={copValue} audValue={audValue} fee={fee} metodo={metodo} pagarImpuesto={pagarImpuesto}
                                                        totalRecibido={totalRecibido} tasadeCambio={tasaEstable} tasa={tasadeCambio} valorImpuesto={valorImpuesto} TotalaPagar={totalaPagar} 
                                                        codigo={codigoPromo} descuento={descuento} abrirResumen={abrirResumen}/> 
                                                        <BuscarPromo OpenModalNuevo={AbrirModal} CerrarModal={CerrarModal} usuario={uidUsuario} setCodigoPromo={setCodigoPromo} 
                                                            setTipoCode={setTipoCode} setDescuento={setDescuento} setAgenciaId={setAgenciaId} setUserCod={setUserCod} />
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {paso1 && 
                                            <div className="formButtons BtnsRigth">
                                                <button className="boton-general imgButton-rigth" onClick={StepOne} type="submit">Siguiente<img src={ImgNext} alt="Siguiente" /></button>
                                            </div>
                                        }
                                        {paso2 && 
                                            <div className="formButtons BtnsSpace">
                                                <button className="boton-general imgButton-left" onClick={BackStepOne} type="button"><img src={ImgPrev} alt="Regresar" />Regresar</button>
                                                <button className="boton-general imgButton-rigth" onClick={StepTwo} type="submit">Siguiente<img src={ImgNext} alt="Siguiente" /></button>
                                            </div>
                                        }
                                        {paso3 && 
                                            <div className="formButtons BtnsSpace">
                                                <button className="boton-general imgButton-left" onClick={BackStepTwo} type="button"><img src={ImgPrev} alt="Regresar" />Regresar</button>
                                                <button className="boton-general imgButton-rigth" type='submit'>Confirmar<img src={Check} alt="Ok" /></button>
                                            </div>
                                        }
                                    </form>
                                    }
                                </>}
                                </> : <DivLoading />
                                }
                            </div>
                        </div>
                        <div className="divSalir">
                            {!validando && <> {!registrada && <>
                                <p>¿Deseas realizar la transferencia después?</p>
                                <Link to="/dashboard" className="boton-text imgButton-rigth">Cancelar<img src={Close} alt="Salir" /></Link>
                            </>}</>}
                            {registrada && <> {!update && 
                                <div className="divSalir">
                                    <p>¿Deseas subir el comprobante ahora?</p>
                                    <Link to="/dashboard" className="boton-text imgButton-rigth">Ir al Inicio<img src={Check} alt="Ok" /></Link> 
                                </div>
                            }</>}
                        </div>
                        
                        </>
                    }
                </div>
            </div>
            <Footer />
            {statusModal && <Modal componente={ModaType} statusModal={statusModal} CerrarModal={CerrarModal} />}
            </>: 
            <LoadingPage />    
        }</>
    )
}

export default NuevaTransferencia;