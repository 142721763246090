  
import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Route, Redirect } from 'react-router-dom';

const RutaPrivadaAdmin = ({ children, ...restoDePropiedades }) => {
    const { usuario, infoUser } = useAuth();
    const [admin, setAdmin] = useState(false);
    const [cargando, setCargando] = useState(true);

    useEffect(()=>{
        if(usuario){
            if(infoUser){
                if(infoUser.rol === 'Administrador'){
                    setAdmin(true)
                    setCargando(false)
                }
            }
        }
    },[usuario, infoUser])

    if (usuario) {
        if (admin) {
            return <>{!cargando && <Route {...restoDePropiedades}>{children}</Route>}</>
        } else {
            return <>{!cargando && <Redirect to="/dashboard" />}</>
        }
    } else {
        return <>{!cargando && <Redirect to="/" />}</>
    }

}

export default RutaPrivadaAdmin;