import React from 'react';

const InputComp = ({
        cols,
        spn,
        setSpn,
        alt,
        setValue,
        value,
        type,
        label,
        fill
    }) =>{

    const handleChange = (e) =>{setValue(e.target.value); setSpn(false);}

    return(
        <div className={cols}><div className={!spn ? 'input-general': 'input-general redLine'}>
            <input name="value" type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
            <label>{label}</label>
            {spn && <span className="spanAlert">{alt}</span>}
        </div></div>
    )
}

export default InputComp;