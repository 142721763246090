import React from "react";
import axios from 'axios';
import { firebase } from '../../../../firebase/firebaseConfig';
import { EliminarComprobante } from "../../../data/AdminTransfer";
import { DeletedComprobante, HuboUnError } from "../../../components/Mensajes";
import ModalValidar from "../../../../views/components/modales/MoldaValidar";
import DivLoading from "../../../../views/components/loading/Loading";

const BorrarComprobante = (props) =>{

    const {
        id,
        idNuevo,
        rteAppId,
        rteEmail,
        rteNombre,
        dnoNombre,
        dnoApellido,
        metodo,
        razonTrans,
        emailAud,
        emailTasa,
        fechaTrans,
        comprobantePago,
        OpenModalNuevo,
        CerrarModal,
        setEstadoTrans,
        setComprobantePago,
    } = props;

    const Eliminar = () =>{
        OpenModalNuevo(<DivLoading />)
        const name = (comprobantePago.split('%2F').pop()).split('?').splice(0,1)
        const storageRef = firebase.storage().ref();
        const desertRef = storageRef.child(rteAppId+ '/transferencias/' +  name);
        desertRef.delete().then(() => {
            EliminarComprobante({
                id: id, 
            }).then(async()=>{
                await axios.post('https://server-colcambios.herokuapp.com/api/errorcomprobante',
                {idNuevo,rteEmail,rteNombre,emailAud,emailTasa,fechaTrans,dnoNombre,dnoApellido,metodo,razonTrans})
                .then(res =>{
                    if(res.data.success){
                        OpenModalNuevo(<DeletedComprobante CerrarModal={CerrarModal}/>);
                        setEstadoTrans('Registrada'); 
                        setComprobantePago();
                }
                }).catch(() =>{
                    OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                })    
            }).catch(() => {
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
            });
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    return(
        <>
            <ModalValidar 
               accion={Eliminar} 
               titulo={'Borrar Comprobante'}
               mensaje={'¿Estás seguro de eliminar este comprobante?'}
               botonName={'Sí, Eliminar'}
               botonClass={'ButtonRed'}
               CerrarModal={CerrarModal} 
            />
        </>
    )
}

export default BorrarComprobante;