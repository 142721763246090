import React from "react";
import '../../../../views/components/modales/modales.css';

const Modal = ({componente,statusModal,CerrarModal}) =>{
    
    return(
        <>
            {statusModal &&
                <div className="modalContainer">
                    <div className="modalNuevo">{componente}</div>
                    <div className="modalFondo" onClick={CerrarModal}></div>
                </div>
                
            }
        </>
    )
}

export default Modal;