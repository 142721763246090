import React from 'react';

const ImputButton = ({
        spn,
        setSpn,
        alt,
        setValue,
        value,
        type,
        label,
        fill,
        action,
        actionName
    }) =>{

    const handleChange = (e) =>{
        setValue(e.target.value);
        setSpn(false);
    }

    return(
        <div className='inputButtonDiv'>
            <div className={'inputButton '+ (!spn ? '': 'redLine')}>
                <input name="value" type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
                <label>{label}</label>
                {spn && <span className="spnAlert">{alt}</span>}
            </div>
            <button onClick={action} type="button">{actionName}</button>
        </div>
    )
}

export default ImputButton;