import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams, Link } from 'react-router-dom';
import { format, fromUnixTime, getUnixTime} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { es, enAU } from 'date-fns/locale';
import { firebase } from '../../../firebase/firebaseConfig';
import axios from 'axios';
import { PDFDownloadLink } from '@react-pdf/renderer';
import NumberFormat from 'react-number-format';
import useObtenerTransferecia from '../../../client/hooks/useObtenerTransferencia'
import { useObtenerReferido } from '../../hooks/useObtenerCodigos';
import { AgregarComprobante, AgregarReferido, EditarTransferencia } from '../../data/AdminTransfer';
import { RecotizarValor } from '../../data/AdminTransfer';
import { HuboUnError, RecotizadaOk, CanceladaOk, ProcesadaOk, ProcesadaCheck,ReferidoAgregado} from '../../components/Mensajes';
import Recotizar from './Recotizacion';
import FileDocument from '../../../views/components/files/FileDocument';
import BorrarComprobante from './components/BorrarComprobante';
import MyDocument from '../../../client/components/factura/FacturaPDF';
import CountDownTrans from '../../../client/components/countDown/CountDownTrans';
import '../../../client/views/transferencias/transferencia.css';
import ImgFile from '../../../img/icons/document-download.svg';
import Receipt from '../../../img/icons/receipt.svg';
import ImgOk from '../../../img/icons/tick-square.svg';
import ImgX from '../../../img/icons/close-square.svg';
import DocId from '../../../img/icons/documentid.svg';
import ActualizarEstado from './components/ActualizarEstado';
import ModalValidar from '../../../views/components/modales/MoldaValidar';
import DivLoading from '../../../views/components/loading/Loading';
import SubirFactura from './components/SubirFactura';
import BuscarReferido from '../../../client/views/transferencias/Components/BuscarReferido';

const Transferencia = ({OpenModalNuevo,CerrarModal}) =>{

    const history = useHistory();
    const fecha = new Date();

    //TRANSFERENCIA
    const { id } = useParams();
    const idNuevo = id.substring(0, 6).toUpperCase();
    const [transferencia, loading] = useObtenerTransferecia(id);
    
    const [fechaTrans, setFechaTrans] = useState();
    const [fechaCo, setFechaCo] = useState();
    const [fechaCol, setFechaCol] = useState();
    const [fechaEmail, setFechaEmail] = useState();
    const [fechaFactura, setFechaFactura] = useState();
    const [fechaPago, setFechaPago] = useState();
    const [estadoTrans, setEstadoTrans] = useState();
    const [razonTrans, setRazonTrans] =  useState();
    
    //VALORES
    const [montoCop, setMontoCop] = useState();
    const [montoAud, setMontoAud] = useState();
    const [servicio, setServicio] = useState();
    const [total, setTotal] = useState();
    const [metodo, setMetodo] = useState();
    const [fee, setFee] = useState();
    const [cargos, setCargos] = useState();
    const [tasadeCambio, setTasadeCambio] = useState();
    const [emailCop, setEmailCop] =  useState();
    const [emailAud, setEmailAud] =  useState();
    const [emailTasa, setEmailTasa] = useState();
    //REMITENTE
    const [autoEnvio, setAutoEnvio] = useState();
    const [rteAppId, setRteAppId] = useState();
    const [rteNombre, setRteNombre] = useState();
    const [rteApellido, setRteApellido] = useState();
    const [rteTipoId, setRteTipoId] = useState();
    const [rteNoId, setRteNoId] = useState();
    const [rteDocumento, setRteDocumento] = useState();
    const [rteEmail, setRteEmail] = useState();
    const [rteCelular, setRteCelular]  = useState();
    //DESTINATARIO
    const [dnoTipo, setDnoTipo] = useState('Persona');
    const [dnoAppId, setDnoAppId] = useState();
    const [dnoNombre, setDnoNombre] = useState();
    const [dnoApellido, setDnoApellido] = useState();
    const [dnoTipoId, setDnoTipoId] = useState();
    const [dnoNoId, setDnoNoId] = useState();
    const [dnoDocumento, setDnoDocumento] = useState();
    const [dnoCelular, setDnoCelular]  = useState();
    const [dnoEmail, setDnoEmail] = useState();
    //CUENTA
    const [titular, setTitular] = useState();
    const [nombreBanco, setNombreBanco] = useState();
    const [numeroBSB, setNumeroBSB] = useState();
    const [numerodeCuenta, setNumerodeCuenta] = useState();
    //CODIGOS REFERIDO
    const [referency, setReferido] = useState('');
    const [codigoRef, setCodigoRef] = useState();
    const [negociacion, setNegociacion] = useState();
    const [referido] = useObtenerReferido(codigoRef);
    //CODIGO PROMOCIONAL
    const [proCodigo, setProCodigo] = useState('');
    const [proTipo, setProTipo] = useState('');
    const [proDescuento, setProDescuento] = useState('');
    const [proAgenciaId, setProAgenciaId] = useState('');
    //RECOTIZAR
    const [observaciones, setObservaciones] = useState();
    //DOCUMENTOS
    const [comprobantePago, setComprobantePago] = useState();
    const [factura, setFactura] = useState(false);
    const [pagoTrans, setPagoTrans] = useState();
    //FUNCIONES
    const formatearFecha = (fecha) => {return format(fromUnixTime(fecha), "MMMM dd',' yyyy '·' HH:mm a", { locale: enAU });}
    const formatDate = (fecha) => {return format(fromUnixTime(fecha), "dd 'de' MMMM 'de' yyyy", { locale: es });}
    const emailDate = (fecha) => {return format(fromUnixTime(fecha), "dd'/'MMM'/'yy", { locale: es });}
    const horaColombia = (fecha) => { return fromUnixTime(fecha).toISOString();}

    useEffect(()=>{
        if(fechaCo){
            setFechaCol(formatInTimeZone(fechaCo, 'America/Bogota', 'MMMM dd, yyyy · HH:mm a'))
        }
    },[fechaCo])
    
    useEffect(() => {
        if(transferencia){
            setFechaTrans(formatearFecha(transferencia.data().fecha));
            setFechaCo(horaColombia(transferencia.data().fecha))
            setFechaEmail(emailDate(transferencia.data().fecha));
            setFechaFactura(formatDate(transferencia.data().fecha));
            setFechaPago(transferencia.data().fechaMaxPago);
            setEstadoTrans(transferencia.data().estado);
            setRazonTrans(transferencia.data().razon);

            setMontoCop(transferencia.data().transferencia.montoCop);
            setMontoAud(transferencia.data().transferencia.montoAud);
            setServicio(transferencia.data().transferencia.servicio);
            setTotal(transferencia.data().transferencia.totalPagado);
            setMetodo(transferencia.data().transferencia.metodo);
            setFee(transferencia.data().transferencia.fee);
            setCargos(transferencia.data().transferencia.cargos);
            setTasadeCambio(transferencia.data().transferencia.tasa);
            setEmailCop(transferencia.data().transferencia.emailCop);
            setEmailAud(transferencia.data().transferencia.emailAud);
            setEmailTasa(transferencia.data().transferencia.emailTasa);

            setAutoEnvio(transferencia.data().autoEnvio);
            setRteAppId(transferencia.data().usuario);
            setRteNombre(transferencia.data().remitente.nombre);
            setRteApellido(transferencia.data().remitente.apellido);
            setRteTipoId(transferencia.data().remitente.tipoId);
            setRteNoId(transferencia.data().remitente.numeroId);
            setRteDocumento(transferencia.data().remitente.documento);
            setRteEmail(transferencia.data().remitente.email);
            setRteCelular(transferencia.data().remitente.celular);
            
            if(transferencia.data().destinatario.tipo){
                setDnoTipo(transferencia.data().destinatario.tipo);
            }
            setDnoAppId(transferencia.data().destinatarioId)
            setDnoNombre(transferencia.data().destinatario.nombre);
            setDnoApellido(transferencia.data().destinatario.apellido);
            setDnoTipoId(transferencia.data().destinatario.tipoId);
            setDnoNoId(transferencia.data().destinatario.numeroId);
            setDnoDocumento(transferencia.data().destinatario.documento);
            setDnoCelular(transferencia.data().destinatario.celular);
            setDnoEmail(transferencia.data().destinatario.email);

            setTitular(transferencia.data().cuenta.titular);
            setNombreBanco(transferencia.data().cuenta.banco);
            setNumeroBSB(transferencia.data().cuenta.bsb);
            setNumerodeCuenta(transferencia.data().cuenta.numeroCuenta);
            
            setObservaciones(transferencia.data().observaciones);

            setComprobantePago(transferencia.data().comprobantePago);
            setFactura(transferencia.data().factura);
            setPagoTrans(transferencia.data().pagoTrans);
            
            if(transferencia.data().referido){
                setCodigoRef(transferencia.data().referido)
            }
            
            if(transferencia.data().promocional){
                setProTipo(transferencia.data().promocional.tipo);
                setProCodigo(transferencia.data().promocional.codigo);
                setProDescuento(transferencia.data().promocional.descuento);
                setProAgenciaId(transferencia.data().promocional.agenciaId);
            }
        }
    }, [transferencia, history])

    useEffect(()=>{if(referido){setNegociacion(referido);}},[referido])

    //SUBIR COMPROBANTE
    const [archivos, setArchivos] = useState('');
    const [altComprobante, setAltComprobante] = useState('');
    const [spnComprobante, setSpnComprobante] = useState(false);
    const [progress, setProgress] = useState(0);
    const [buttonComp, setButtonComp] = useState(false);
    
    useEffect(()=>{setSpnComprobante(false);},[archivos]);

    const SubirComprobante = (e) =>{
        e.preventDefault();
        const fechaTrans = fechaEmail.toUpperCase();
        if(!archivos){setSpnComprobante(true); setAltComprobante('Por favor selecciona el comprobante'); return;}
        if(!archivos[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){setSpnComprobante(true); setAltComprobante('Archivo no válido, solo Imagen o PDF');return;}
        const size = Math.round((archivos[0].size / 1024));
        if(size >= 1024) {setSpnComprobante(true); setAltComprobante('El tamaño máximo del archivo es de 1mb'); return;}

        setButtonComp(true);
        const comprobante = archivos[0];
        const nombreArchivo = 'comprobante'+id+'.'+archivos[0].name.split('.').pop();
        const StorageRef = firebase.storage().ref(rteAppId + '/transferencias/' + nombreArchivo);
        const uploadTask = StorageRef.put(comprobante);
        uploadTask.on( "state_changed", snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            }, error => {
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                setButtonComp(false);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    AgregarComprobante({
                        id: id, 
                        comprobantePago: url, 
                        estado:'En Proceso'
                    }).then(async() =>{
                        await axios.post('https://server-colcambios.herokuapp.com/api/transferenproceso', 
                            {idNuevo,rteEmail,rteNombre,emailAud,emailTasa,fechaTrans,dnoNombre,dnoApellido,metodo,razonTrans})
                        .then(res =>{
                            if(res.data.success){
                                OpenModalNuevo(<ProcesadaOk CerrarModal={CerrarModal}/>);
                                setEstadoTrans('En Proceso'); 
                                setComprobantePago(url);
                            }
                        }).catch(() =>{
                            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                            setButtonComp(false);
                        })
                    })
                    .catch(() =>{
                        OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                        setButtonComp(false);
                    })
                })
            })
    }

    //APROBAR TRANSFERENCIA
    const AbrirProcesar = (e)=>{
        e.preventDefault();
        OpenModalNuevo(
            <ModalValidar 
                accion={TransProcesada} 
                titulo={'Procesar Transferencia'}
                mensaje={'¿Estás seguro de Procesar esta transferencia?'}
                botonName={'Sí, Procesar'}
                botonClass={'ButtonBlue'}
                CerrarModal={CerrarModal}
            />
        )
    }

    const TransProcesada = () =>{
        OpenModalNuevo(<DivLoading />)
        const fechaTrans = fechaEmail.toUpperCase();
        EditarTransferencia({
            id: id,
            estado:'Procesada',
            factura: true
        }).then(async() =>{
            await axios.post('https://server-colcambios.herokuapp.com/api/exito',
                {idNuevo,rteEmail,rteNombre,emailAud,emailTasa,fechaTrans,dnoNombre,dnoApellido,metodo,razonTrans})
            .then(res =>{
                if(res.data.success){
                    axios.post('https://server-colcambios.herokuapp.com/api/notice', 
                        {idNuevo, dnoEmail, dnoNombre, rteNombre, rteApellido, emailAud, fechaTrans, razonTrans})
                    OpenModalNuevo(<ProcesadaCheck CerrarModal={CerrarModal}/>);
                    setFactura(true); 
                    setEstadoTrans('Procesada');
                }
            }).catch(() =>{
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
            });
        }).catch(() =>{
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    //CANCELAR TRANSFERENCIA
    const AbrirCancelar = (e)=>{
        e.preventDefault();
        OpenModalNuevo(
            <ModalValidar 
                accion={TransCancelada} 
                titulo={'Cancelar Transferencia'}
                mensaje={'¿Estás seguro de Cancelar esta transferencia?'}
                botonName={'Sí, Cancelar'}
                botonClass={'ButtonRed'}
                CerrarModal={CerrarModal}
            />
        )
    }

    const TransCancelada = () =>{
        OpenModalNuevo(<DivLoading />)
        const fechaTrans = fechaEmail.toUpperCase();
        EditarTransferencia({
            id: id,
            estado: 'Cancelada',
            factura: false 
        }).then(async() =>{
            await axios.post('https://server-colcambios.herokuapp.com/api/cancelada', 
                {idNuevo,rteEmail,rteNombre,emailAud,emailTasa,fechaTrans,dnoNombre,dnoApellido,metodo,razonTrans})
            .then(res =>{
                if(res.data.success){
                    OpenModalNuevo(<CanceladaOk CerrarModal={CerrarModal}/>);
                    setEstadoTrans('Cancelada');
                    setFactura(false);
                }
            }).catch(() =>{
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
            });
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    //RECOTIZACIÓN
    const [newSaldo, setNewSaldo] = useState();
    const [newCargos, setNewCargos] = useState();
    const [newTasa, setNewTasa] = useState();
    const [newMetodo, setNewMetodo] = useState();
    const [newFee, setNewFee] = useState();
    const [recibido, setRecibido] = useState('');

    const [newObserv, setNewObserv] = useState('');
    const [newEmailAud, setNewEmailAud] = useState(emailAud);
    const [newEmailTasa, setNewEmailTasa] = useState(emailTasa);
    const [newEmailSaldo, setNewEmailSaldo] = useState(emailCop);

    useEffect(()=>{
        setNewSaldo(total)
        setNewCargos(cargos)
        setNewTasa(tasadeCambio)
        setNewFee(fee)
        setNewMetodo(metodo)
        setNewEmailAud(emailAud)
        setNewEmailTasa(emailTasa)
        setNewEmailSaldo(emailCop)
    },[total,cargos,tasadeCambio,fee,metodo,emailAud,emailTasa,emailCop])

    const AbriRecotizar = ()=>{
        OpenModalNuevo(
            <ModalValidar 
                accion={TransRecotizada} 
                titulo={'Recotizar Transferencia'}
                mensaje={'¿Estás seguro de recotizar los valores de esta transferencia?'}
                botonName={'Sí, Recotizar'}
                botonClass={'ButtonGreen'}
                CerrarModal={CerrarModal}
            />
        )
    }

    const TransRecotizada = () =>{
        OpenModalNuevo(<DivLoading />)
        const fechaEmail =  emailDate(getUnixTime(fecha))
        const fechaTransfer = fechaEmail.toUpperCase();
        
        RecotizarValor({
            id: id,
            recotizado: true,
            transferencia:{
                montoCop: montoCop,
                montoAud: recibido,
                servicio: servicio,
                totalPagado: newSaldo,
                metodo: newMetodo,
                fee: newFee,
                cargos: newCargos,
                pagoImpuesto: true,
                tasa: newTasa,
                emailCop: newEmailSaldo,
                emailAud: newEmailAud,
                emailTasa: newEmailTasa
            },
            tasaAnterior: tasadeCambio,
            saldoAnterior:{
                totalPagado: total,
                cargos: cargos,
                metodo: metodo,
                fee: fee,
                recibido: montoAud,
            },
            observaciones: newObserv,
        }).then(async() => {
            await axios.post('https://server-colcambios.herokuapp.com/api/recotizar', {idNuevo,rteEmail,rteNombre,newEmailAud,newEmailTasa,fechaTransfer,dnoNombre,dnoApellido,newMetodo,razonTrans,newObserv})
            .then(res =>{
                if(res.data.success){
                    OpenModalNuevo(<RecotizadaOk CerrarModal={CerrarModal}/>);
                    setTotal(newSaldo);
                    setCargos(newCargos);
                    setTasadeCambio(newTasa);
                    setMetodo(newMetodo);
                    setFee(newFee);
                    setMontoAud(recibido);
                    setObservaciones(newObserv);
                    setEmailCop(newEmailSaldo );
                    setEmailAud(newEmailAud);
                    setEmailTasa(newEmailTasa);
                }
            }).catch(() =>{
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
            });
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    //ASIGNAR REFERIDO
    const AbrirReferido = (e) =>{
        e.preventDefault();
        if(!referency){return;}
        OpenModalNuevo(
            <ModalValidar 
                accion={GuardarReferido} 
                titulo={'Asignar Referido'}
                mensaje={'¿Estás seguro de asignar la referencia?'}
                botonName={'Sí, Referenciar'}
                botonClass={'ButtonGreen'}
                CerrarModal={CerrarModal}
            />
        )
    }

    const GuardarReferido = () =>{
        OpenModalNuevo(<DivLoading />)
        AgregarReferido({
            id: id,
            referido: referency
        }).then(() =>{
            OpenModalNuevo(<ReferidoAgregado CerrarModal={CerrarModal}/>)
            setCodigoRef(referency)
        }).catch(() =>{
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    //ELIMINAR COMPROBANTE

    const AbrirEliminar = () =>{
        OpenModalNuevo(<BorrarComprobante 
            id={id} idNuevo={idNuevo} rteAppId={rteAppId} rteEmail={rteEmail} rteNombre={rteNombre}
            dnoNombre={dnoNombre} dnoApellido={dnoApellido} metodo={metodo} razonTrans={razonTrans}
            emailAud={emailAud} emailTasa={emailTasa} fechaTrans={fechaTrans} comprobantePago={comprobantePago}
            OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} setEstadoTrans={setEstadoTrans}
            setComprobantePago={setComprobantePago} />)
    }

    return(
        <>
            <Helmet><title>Transferencia</title></Helmet>
            <div className="TituloPag"><p>Transferencia</p></div>

            {!loading ? <>
                <div className="CardTransferencia">
                    <div><span>Ref. de Transferencia</span><p className="NTransfer">{idNuevo}</p></div>
                    <div><span>Método</span><p>{metodo}</p></div>
                    <div><span>Fecha en Colombia</span><p className="FTransfer">{fechaCol}</p></div>
                    <div><span>Fecha en Australia</span><p className="FTransfer">{fechaTrans}</p></div>
                    <div><span>Estado</span><p>{estadoTrans}</p></div>
                </div>

                <div className="row m-0">
                    <div className="col-12 col-md-7 col-lg-8 p-0">
                        <div className="CardBody">
                            <h3>Información</h3>
                            <div className="row p-0 DatosTrans">
                                <div className="col-12"><label>Remitente</label>
                                    <Link to={`/usuario/${rteAppId}`}>{rteNombre+ ' ' +rteApellido}</Link></div>
                                <div className="col-6 col-sm-4"><p><label>Tipo ID</label>{rteTipoId}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Número de ID</label>{rteNoId}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Celular</label>{rteCelular}</p></div>
                                <div className="col-12"><p><label>Razón del envío</label>{razonTrans}</p></div>
                            </div>

                            <div className="row p-0 DatosTrans">
                                    {!autoEnvio ? <>
                                            <div className="col-6 col-sm-4"><label>Destinatario</label>
                                                <Link to={`/destinatario/${dnoAppId}`}>{dnoNombre+ ' ' +dnoApellido}</Link>
                                            </div>
                                            <div className="col-6"><p><label>Tipo Destinatario</label>{dnoTipo}</p></div>
                                            <div className="col-6 col-sm-4"><p><label>Tipo ID</label>{dnoTipoId}</p></div>
                                            <div className="col-6 col-sm-4"><p><label>Número de ID</label>{dnoNoId}</p></div>
                                            <div className="col-6 col-sm-4"><p><label>Celular</label>{dnoCelular}</p></div>
                                        </>
                                        :
                                        <div className="col-12"><p><label>Destinatario</label>AutoEnvio</p></div>
                                    }  
                                    
                                <div className="col-6 col-sm-4"><p><label>Titular</label>{titular}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Banco</label>{nombreBanco}</p></div>
                                <div className="col-6 col-sm-4"><p><label>Número BSB</label>{numeroBSB}</p></div>
                                <div className="col-6 col-sm-4"><p><label>No. de Cuenta</label>{numerodeCuenta}</p></div>     
                            </div>

                        </div>

                        <div className="CardBody">
                            <h3>Documentos</h3>
                            {estadoTrans ==='Registrada' && <>
                                {!comprobantePago && <> 
                                    {fechaPago && <CountDownTrans fechaPago={fechaPago} />} 
                                    <form onSubmit={SubirComprobante}>
                                        <FileDocument archivosSelec={setArchivos} spnAltArchivo={spnComprobante} altArchivo={altComprobante}/>
                                        <div className="noSee"><progress value={progress} max="100"></progress></div>
                                        <button className="boton-general" type='submit' disabled={buttonComp}>
                                            {buttonComp ? 'Cargando...' : 'Subir Comprobante'}</button>
                                    </form> 
                                </>}
                            </>}

                            <div className="TransDocuments">
                                <a href={rteDocumento} target="blank_" rel="noreferrer noopener">Id Remitente<br/><img src={DocId} alt="Documento" /></a>
                                <a href={dnoDocumento} target="blank_" rel="noreferrer noopener">Id Destinatario<br/><img src={DocId} alt="Documento" /></a>
                                {comprobantePago && <a href={comprobantePago} target="blank_" rel="noreferrer noopener">Comprobante<br/><img src={Receipt} alt="comprobante" /></a>}
                                {pagoTrans && <a href={pagoTrans} target="blank_" rel="noreferrer noopener">Pago<br/><img src={Receipt} alt="comprobante" /></a>}

                                {!pagoTrans && <>{comprobantePago && 
                                    <button className="boton-general BtnLarge" onClick={AbrirEliminar}>Eliminar Comprobante</button>
                                }</>}
                            </div>
                        </div>

                        {!pagoTrans && <div className="CardBody">
                            <SubirFactura 
                                OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} fechaEmail={fechaEmail} id={id} rteAppId={rteAppId}
                                idNuevo={idNuevo} rteEmail={rteEmail} rteNombre={rteNombre} emailAud={emailAud} emailTasa={emailTasa}
                                dnoNombre={dnoNombre} dnoApellido={dnoApellido} metodo={metodo} razonTrans={razonTrans} dnoEmail={dnoEmail}
                                rteApellido={rteApellido} setPagoTrans={setPagoTrans} setFactura={setFactura} setEstadoTrans={setEstadoTrans}
                            />
                        </div>}

                        <div className="CardBody">
                            <h3>Códigos</h3>
                            {proTipo && 
                                <div className="row p-0 DatosTrans">
                                    <div className="col-12"><h3>Promocional</h3></div>
                                    <div className="col-6 col-sm-4"><p><label>Tipo</label>{proTipo}</p></div>
                                    <div className="col-6 col-sm-4"><p><label>Código</label>{proCodigo}</p></div>
                                    <div className="col-6 col-sm-4"><p><label>Descuento</label>{proDescuento}</p></div>
                                    {proAgenciaId && <div className="col-6 col-sm-4"><p><label>Agencia</label>{proAgenciaId}</p></div>}
                                </div>
                            }
                             
                                <div className="row p-0 DatosTrans">
                                    <div className="col-12"><h3>Referido</h3></div>
                                    {codigoRef && 
                                    <>
                                        <div className="col-6 col-sm-4"><p><label>Código</label>{codigoRef}</p></div>
                                        <div className="col-6 col-sm-4"><p><label>Negociación</label>{negociacion}</p></div>
                                    </>}
                                </div>
                                {!codigoRef && <>
                                    <BuscarReferido setReferido={setReferido} />
                                    <div>{referency && <button className="boton-general BtnLarge" onClick={AbrirReferido}>Guardar Cambios</button>}</div>
                                </>}
                        </div>

                        {observaciones && 
                            <div className="CardBody">
                                <h3>Observaciones</h3>
                                <div className="Observaciones">
                                    <p>{observaciones}</p>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="col-12 col-md-5 col-lg-4 p-0">
                        <div className="CardBody">
                            <h3>Resumen</h3>
                            <div className="ResumenTrans">
                                <div>
                                    <label>Valor Pagado</label>
                                    <NumberFormat value={total} displayType={'text'} thousandSeparator={'.'} 
                                    decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/>
                                </div>
                                <div>
                                    <label>Cargos del Banco</label>
                                    <p>{cargos !== 0 && '-'}&nbsp;
                                    <NumberFormat value={cargos} displayType={'text'} thousandSeparator={'.'} 
                                    decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={false}/></p>
                                </div>
                                <div>
                                    <label>Tasa de Cambio</label>
                                    <NumberFormat value={tasadeCambio} displayType={'text'} thousandSeparator={'.'} 
                                    decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/>
                                </div>
                                <div>
                                    <label>Servicio de Transferencia</label>
                                    <p>{!servicio && '-'}&nbsp;
                                    <NumberFormat value={fee} displayType={'text'} thousandSeparator={','} 
                                    decimalSeparator={'.'} prefix={'$'} suffix={' AUD'} decimalScale={'2'}/></p>
                                </div>
                                <div>
                                    <label>Valor a Recibir</label>
                                    <NumberFormat value={montoAud} displayType={'text'} thousandSeparator={','} 
                                    decimalSeparator={'.'} prefix={'$'} suffix={' AUD'} decimalScale={'2'}/>
                                </div>
                            </div>
                        </div>

                        {factura &&
                            <div className="CardBody">
                                <PDFDownloadLink 
                                    fileName={idNuevo + '.pdf'}
                                    className="boton-general imgButton-rigth"
                                    document={
                                        <MyDocument 
                                            fecha={fechaFactura}
                                            numerorecibo={idNuevo}
                                            nombre={rteNombre+' '+rteApellido}
                                            id={rteNoId}
                                            razon={razonTrans}
                                            telefono={rteCelular}
                                            banco={nombreBanco}
                                            titular={dnoNombre+' '+dnoApellido}
                                            numerobsb={numeroBSB}
                                            numerocuenta={numerodeCuenta}
                                            montocop={emailCop}
                                            cargos={cargos}
                                            tasadecambio={emailTasa}
                                            metodo={metodo}
                                            fee={fee}
                                            montoaud={emailAud}
                                            observaciones={observaciones}
                                        />
                                    }>
                                    {({loading}) =>
                                        loading ? 'Cargando Factura...' :
                                            <>Factura
                                            <img src={ImgFile} alt="comprobante" /></>
                                    }
                                </PDFDownloadLink>
                            </div>
                        }
                    
                        {estadoTrans !== 'Procesada' && <>
                            <div className="CardBody CardInput">
                                <h3>Recotizar</h3>
                                {newSaldo && <Recotizar
                                    AbrirRecotizar={AbriRecotizar}
                                    pagado={total}
                                    cargos={cargos} 
                                    tasa={tasadeCambio}
                                    metodo={metodo} 
                                    fee={fee}  
                                    setNewSaldoTs={setNewSaldo}
                                    setNewCargosTs={setNewCargos}
                                    setMontoCop={setMontoCop}
                                    setNewTasaTs={setNewTasa}
                                    setNewMetodo={setNewMetodo}
                                    setNewFeeTs={setNewFee}
                                    setRecibidoTs={setRecibido} 
                                    setNewObserv={setNewObserv} 
                                    setNewEmailSaldo={setNewEmailSaldo}
                                    setNewEmailTasa={setNewEmailTasa}
                                    setNewEmailAud={setNewEmailAud} 
                                />}
                            </div>
                        </>}

                        <div className="CardBody">
                            <button className="boton-general BtnLarge imgButton-left" onClick={AbrirCancelar}>
                                <img src={ImgX} alt="Cancelar" />Cancelar Transferencia</button>
                            <button className="boton-general BtnLarge imgButton-left" onClick={AbrirProcesar}>
                                <img src={ImgOk} alt="Procesar" />Procesar Transferencia</button>
                        </div>
                        
                        <ActualizarEstado 
                            OpenModalNuevo={OpenModalNuevo} 
                            CerrarModal={CerrarModal}
                            estadoTrans={estadoTrans}
                            setEstadoTrans={setEstadoTrans}
                            id={id}
                        />
                        
                    </div>
                </div>
            </> : <div className="loadingContainer pageLoader"><div className="lds-ripple"><div></div><div></div></div></div>}
        </>
    )
}

export default Transferencia;