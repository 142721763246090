import React from 'react';
import ImgOpening from '../../img/icons/opening.svg';
import ImgQuestions from '../../img/icons/questions.svg';
import ImgHowWork from '../../img/icons/howorks.svg';
import ImgToSelf from '../../img/icons/toself.svg';

const RegistroCompleto = () =>{
    return(
        <>
            <div className="formTitle">
                <h1 className="text-title">Tu registro ha sido completado</h1>
                <p>Gracias por considerarnos como su opción de transferencias <b>COP/AUD.</b><br/>A continuación podrás encontrar un menú de ayuda inicial.</p>
            </div>

            <div className="row p-0">
                <div className="col-12 col-md-6"><div className="welcomeDiv">
                    <div className="welcomeImg">
                        <img src={ImgHowWork} alt="howWork" />
                    </div>
                    <div className="welcomeText">
                        <p><b>¿Ya conoces cómo funciona?</b><br/>Si aún no conoces nuestro proceso de transferencia no te preocupes, aquí te explicamos.</p>
                        <a href="/como-funciona" className="boton-general">Ver Guía</a>
                    </div>
                </div></div>
                <div className="col-12 col-md-6"><div className="welcomeDiv">
                    <div className="welcomeImg">
                        <img src={ImgQuestions} alt="Questions" />
                    </div>
                    <div className="welcomeText">
                        <p><b>¿Tienes alguna pregunta?</b><br/>Puedes contactarnos a nuestro whatsapp o consultar las preguntas frecuentes.</p>
                        <a href="/ayuda" className="boton-general">Preguntas Frecuentes</a>
                    </div>
                </div></div>
                <div className="col-12 col-md-6"><div className="welcomeDiv">
                    <div className="welcomeImg">
                        <img src={ImgToSelf} alt="AutoEnvio" />
                    </div>
                    <div className="welcomeText">
                        <p><b>¿Estás en Australia?</b><br/>Si deseas realizar autoenvíos puedes registrar tu cuenta.</p>
                        <a href="/nueva-cuenta-autoenvio" className="boton-general">Registrarme</a>
                    </div>
                </div></div>
                <div className="col-12 col-md-6"><div className="welcomeDiv">
                    <div className="welcomeImg">
                        <img src={ImgOpening} alt="Bienvenido" />
                    </div>
                    <div className="welcomeText">
                        <p><b>¡Comencemos!</b><br/>Ya puedes realizar tu primer transferencia, recuerda registrar almenos un destinatario.</p>
                        <a href="/dashboard" className="boton-general">Ir a la plataforma</a>
                    </div>
                </div></div>
            </div>
        </>
    )
}

export default RegistroCompleto;