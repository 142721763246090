import React, {useState} from 'react';
import axios from 'axios';
import ModalValidar from '../../../../views/components/modales/MoldaValidar';
import DivLoading from '../../../../views/components/loading/Loading';
import { SuspenderUser, HabilitarUser } from '../../../data/AdminUsers';
import { HuboUnError, UsuarioSuspendido, UsuarioHabilitado} from '../../../components/Mensajes';

const SuspenderUsuario = (props) =>{

    const {
        OpenModalNuevo, 
        CerrarModal, 
        setDesactivado, 
        desactivado, 
        id,
        email,
        nombre,
    } = props;
    
    const [razonBlq, setRazonBlq] = useState();
    const [spnRazonBlq, setSpnRazonBlq] = useState(false);
    const [altRazonBlq, setAltRazonBlq] = useState('');

    const handleChange = (e) =>{
        setRazonBlq(e.target.value); setSpnRazonBlq(false);
    }

    const Suspender = () =>{
        OpenModalNuevo(<DivLoading />)
        SuspenderUser({
            id: id,
            razonBlq: razonBlq
        }).then(async() =>{
            await axios.post('https://server-colcambios.herokuapp.com/api/cuentasuspendida', 
                {email,nombre,razonBlq})
            .then(res =>{
                if(res.data.success){
                    OpenModalNuevo(<UsuarioSuspendido CerrarModal={CerrarModal}/>);
                    setDesactivado(true)
                    setRazonBlq('');
                }
            }).catch(() =>{
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
            })
        })
        .catch(() =>{
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })  
    }

    const Habilitar = () =>{
        OpenModalNuevo(<DivLoading />)
        HabilitarUser({
            id: id,
        }).then(()=>{
            OpenModalNuevo(<UsuarioHabilitado CerrarModal={CerrarModal}/>);
            setDesactivado(false)
        }).catch(()=>{
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    const AbrirSuspender = (e) =>{
        e.preventDefault();
        if(!razonBlq){setAltRazonBlq('Ingresa la Razón'); setSpnRazonBlq(true);return;}
        OpenModalNuevo(
            <ModalValidar 
                accion={Suspender} 
                titulo={'Suspender Usuario'}
                mensaje={'¿Estás seguro de suspender este usuario?'}
                botonName={'Sí, Suspender'}
                botonClass={'ButtonRed'}
                CerrarModal={CerrarModal}
            />
        )
    }
    const AbrirHabilitar = (e) =>{
        e.preventDefault();
        OpenModalNuevo(
            <ModalValidar 
                accion={Habilitar} 
                titulo={'Habilitar Usuario'}
                mensaje={'¿Estás seguro de habilitar este usuario?'}
                botonName={'Sí, Habilitar'}
                botonClass={'ButtonGreen'}
                CerrarModal={CerrarModal}
            />
        )
    }

    return(
        <div className="CardBody">
            <h3>Suspender Usuario</h3>
            {!desactivado ? <>
                <div className={!spnRazonBlq ? 'input-general': 'input-general redLine'}>
                    <textarea type="text" value={razonBlq} name="razonBloqueo" placeholder=" " id="textAreaContact" onChange={handleChange} />
                    <label>Razón</label>
                    {spnRazonBlq && <span className="spanAlert">{altRazonBlq}</span>}
                </div>
                <button className="boton-general BtnLarge" onClick={AbrirSuspender}>Suspender Usuario</button>
            </>
            : <button className="boton-general BtnLarge" onClick={AbrirHabilitar}>Habilitar Usuario</button>}
        </div>
    )

}

export default SuspenderUsuario;