import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';

const useObtenerDestinatarios = () => {

    const {usuario} = useAuth();
    const [contactos, setContactos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        const unsuscribe = db.collection('contactos')
        .where('usuario', '==', usuario.uid)
        .where('activo','==', true)
        .onSnapshot((snapshot) => {
            setLoading(false);
            setContactos(snapshot.docs.map((contacto) => {
                return {...contacto.data(), id: contacto.id}
            }));
        });

        return unsuscribe;
    }, [usuario]);
    
    return [contactos, loading];
}

export default useObtenerDestinatarios;