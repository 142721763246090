import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useAuth } from '../../context/AuthContext';
import { firebase } from '../../firebase/firebaseConfig';
import { AutoEnvio } from '../../firebase/AgregarRegistro';
import Footer from '../components/footer/Footer';
import SelectorSelec from '../components/inputs/Selector';
import FileDocument from '../components/files/FileDocument';
import InputPhone from '../components/inputs/InputPhone';
import '../../styles/Procesos.css';
import '../../views/components/modales/modales.css';
import ImgBank from '../../img/icons/bank.svg';
import ImgDestinatario from '../../img/icons/user.svg';
import ImgNext from '../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../img/icons/arrow-left-1.svg';
import Check from '../../img/icons/tick-square.svg';
import Close from '../../img/icons/close-square.svg';
import AdrresAu from '../components/inputs/AddressAu';

const InfoToSelf = () =>{

    const history = useHistory();
    const [progress, setProgress] = useState('');
    const [completo, setCompleto] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [toSelf, setToSelf] = useState(true);
    const [contactos, setContactos] = useState();
    const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}

    const { usuario, infoUser } = useAuth();
    const uidUsuario = usuario.uid
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [documento, setDocumento] = useState('');
    const [tipoDoc, setTipoDoc] = useState('');
    const [typeDoc, setTypeDoc] = useState(false);
    const [numeroDoc, setNumeroDoc] = useState('');
    const [pais, setPais] = useState('');
    const [adress, setAdress] = useState('');
    const [code, setCode ] = useState('+57');
    const [celular, setCelular] = useState('');
    const [banco, setBanco] = useState('');
    const [bsb, setBsb] = useState('');
    const [bsb2, setBsb2] = useState('');
    const [numCuenta, setNumCuenta] = useState('');
    const [numCuenta2, setNumCuenta2] = useState('');
    const [titular, setTitular] = useState('');

    const tiposdocs = [{id:'1', texto:'Pasaporte'}]
    const paises = [{id:'1', texto:'Australia'}]

    useEffect(()=>{
        if(infoUser){
            if(infoUser.toSelf === true){
                history.push('/dashboard')
            }else{
                const timer1 = setTimeout(()=>{
                    setToSelf(false);
                    setNombre(infoUser.nombre.nombre);
                    setApellido(infoUser.nombre.apellido);
                    setContactos(infoUser.contactos);
                }, 1000);
                return () => {clearTimeout(timer1);};
            } 
        }
    },[infoUser, history])

    //PASO A PASO
    const [paso1, setPaso1] = useState(true);
    const [paso2, setPaso2] = useState(false);
    const [comp1, setComp1] = useState('formProgress-current');
    const [comp2, setComp2] = useState();

    //VALIDACIONES
    const [altArchivo, setAltArchivo] = useState();
    const [altTipDoc, setAltTipDoc] = useState();
    const [altNumDoc, setAltNumDoc] = useState();
    const [altAdress, setAltAdress] = useState();
    const [altCelular, setAltCelular] = useState();
    const [altPais, setAltPais] = useState();
    const [altBanco, setAltBanco] = useState();
    const [altTitular, setAltTitular] = useState();
    const [altBsb, setAltBsb] = useState();
    const [altBsb2, setAltBsb2] = useState();
    const [altNCuenta, setAltNCuenta] = useState();
    const [altNCuenta2, setAltNCuenta2] = useState();

    const [spnArchivo, setSpnArchivo] = useState(false);
    const [spnTipDoc, setSpnTipDoc] = useState(false);
    const [spnNumDoc, setSpnNumDoc] = useState(false);
    const [spnPais, setSpnPais] = useState(false);
    const [spnAdress, setSpnAdress] = useState(false);
    const [spnCelular, setSpnCelular] = useState(false);
    const [spnBanco, setSpnBanco] = useState(false);
    const [spnTitular, setSpnTitular] = useState(false);
    const [spnBsb, setSpnBsb] = useState(false);
    const [spnBsb2, setSpnBsb2] = useState(false);
    const [spnNCuenta, setSpnNCuenta] = useState(false);
    const [spnNCuenta2, setSpnNCuenta2] = useState(false);

    //FUNCIONES
    const handleChange = (e) => {
        switch (e.target.name) {
            case 'documento': setNumeroDoc(e.target.value); setSpnNumDoc(false); break;
            case 'banco': setBanco(e.target.value); setSpnBanco(false); break;
            case 'titular': setTitular(e.target.value); setSpnTitular(false); break;
            case 'bsb': setBsb(e.target.value); setSpnBsb(false); setSpnBsb2(false); break;
            case 'bsb2': setBsb2(e.target.value); setSpnBsb(false); setSpnBsb2(false); break;
            case 'numeroCuenta': setNumCuenta(e.target.value); setSpnNCuenta(false); setSpnNCuenta2(false); break;
            case 'numeroCuenta2': setNumCuenta2(e.target.value); setSpnNCuenta(false); setSpnNCuenta2(false); break;
            default: break;
        }
    }

    useEffect(()=>{
        if(nombre||apellido){setTitular(nombre+' '+apellido);
        }else{setTitular('');}
    },[nombre,apellido]);

    //VERIFICACIÓN DE SELECTS Y FILES
    useEffect(() => {if(tipoDoc==='Pasaporte'){setTypeDoc(true)}else{setTypeDoc(false)};}, [tipoDoc]);
    useEffect(() => {setSpnTipDoc(false);}, [tipoDoc]);
    useEffect(() => {setSpnArchivo(false);}, [documento]);
    useEffect(() => {setSpnPais(false);}, [pais]);
    useEffect(() => {setSpnAdress(false);}, [adress])

    //PASO A PASO
    const PasoUno = (e) => {
        e.preventDefault();
        if(pais === ''){setSpnPais(true); setAltPais('Por favor selecciona'); return;}
        if(celular === ''){setSpnCelular(true); setAltCelular('Por favor ingresa tu celular'); return;}
        if(code ==='+57'){if(celular.length !== 10){setSpnCelular(true); setAltCelular('El número debe ser de 10 dígitos'); return;}}
        if(code ==='+61'){if(celular.length !== 9){setSpnCelular(true); setAltCelular('El número debe ser de 9 dígitos'); return;}}
        if(adress === ''){setSpnAdress(true); setAltAdress('Por favor ingresa la dirección'); return;}
        if(tipoDoc === ''){setSpnTipDoc(true); setAltTipDoc('Por favor selecciona'); return;}
        if(numeroDoc === ''){setSpnNumDoc(true); setAltNumDoc('Por favor ingresa tu número de documento'); return;}

        if(!documento) {setSpnArchivo(true); setAltArchivo('Por favor adjunta el archivo'); return;} 
        if(!documento[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){setSpnArchivo(true); setAltArchivo('Archivo no válido, solo Imagen o PDF'); return;}
        const size = Math.round((documento[0].size / 1024));
        if(size >= 1024) {setSpnArchivo(true); setAltArchivo('El tamaño máximo del archivo es de 1mb'); return;}
        else{
            toUp(); setPaso1(false); setComp1('formProgress-active'); setPaso2(true); setComp2('formProgress-current')
        }
    }

    const BackPasoUno = (e) => { e.preventDefault(); toUp(); setPaso1(true); setComp1('formProgress-current'); setPaso2(false); setComp2('')}
 
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(pais === ''){setSpnPais(true); setAltPais('Por favor selecciona'); return;}
        if(celular === ''){setSpnCelular(true); setAltCelular('Por favor ingresa tu celular'); return;}
        if(code ==='+57'){if(celular.length !== 10){setSpnCelular(true); setAltCelular('El número debe ser de 10 dígitos'); return;}}
        if(code ==='+61'){if(celular.length !== 9){setSpnCelular(true); setAltCelular('El número debe ser de 9 dígitos'); return;}}
        if(adress === ''){setSpnAdress(true); setAltAdress('Por favor ingresa la dirección'); return;}
        if(tipoDoc === ''){setSpnTipDoc(true); setAltTipDoc('Por favor selecciona'); return;}
        if(numeroDoc === ''){setSpnNumDoc(true); setAltNumDoc('Por favor ingresa tu número de documento'); return;}

        if(!documento) {setSpnArchivo(true); setAltArchivo('Por favor adjunta el archivo'); return;} 
        if(!documento[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){setSpnArchivo(true); setAltArchivo('Archivo no válido, solo Imagen o PDF'); return;}
        const size = Math.round((documento[0].size / 1024));
        if(size >= 1024) {setSpnArchivo(true); setAltArchivo('El tamaño máximo del archivo es de 1mb'); return;}

        if(banco === ''){setSpnBanco(true); setAltBanco('Por favor ingresa el Banco'); return;}
        if(titular === ''){setSpnTitular(true); setAltTitular('Por favor ingresa el Titular'); return;}
        if(bsb === ''){setSpnBsb(true); setAltBsb('Por favor ingresa el número BSB'); return;}
        if(bsb.length !== 6){setSpnBsb(true); setAltBsb('El número debe ser de 6 dígitos'); return;}
        if(bsb2 === ''){setSpnBsb2(true); setAltBsb2('Por favor confirma el número BSB'); return;}
        if(numCuenta === ''){setSpnNCuenta(true); setAltNCuenta('Por favor ingresa el número de cuenta'); return;}
        if(numCuenta2 === ''){setSpnNCuenta2(true); setAltNCuenta2('Por favor confirma el número de cuenta'); return;}

        toUp();
        setCargando(true);
        const docID = documento[0];
        const nombreArchivo = numeroDoc+'.'+documento[0].name.split('.').pop();
        const StorageRef = firebase.storage().ref(usuario.uid + '/destinatarios/' + nombreArchivo)
        const uploadTask = StorageRef.put(docID);

        uploadTask.on("state_changed", snapshot =>{
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(progress);
        }, error => {
            setCargando(false);
        },
        async () => {
            await uploadTask.snapshot.ref.getDownloadURL()
                .then(function(downloadURL) {
                    AutoEnvio({
                        usuario: uidUsuario,
                        toSelf: true,
                        destino: true,
                        contactos: contactos+1,
                        autoEnvio: {
                            documento:{
                                urlDocumento: downloadURL,
                                tipoDocumento: tipoDoc,
                                numeroDocumento: numeroDoc,
                            },
                            contacto:{
                                celular: code+' '+celular,
                                pais: pais,
                                direccion: adress,
                            },
                            cuenta:{
                                banco: banco,
                                bsb: bsb,
                                titular: titular,
                                numeroCuenta: numCuenta
                            }
                        }
                    }).then(() => {
                        setCompleto(true);
                        setCargando(false);
                    }).catch(() => {
                        setCargando(false);
                    })
                });
            }
        )
    }

    //MODALES
    const [modal, cambiarModal] = useState('modal-general');
    const [clases, cambiarClases] = useState('fonder');

    const openModal = (e) =>{
        e.preventDefault();
        setTimeout(() => {
            cambiarModal('modal-general modal-activo')
            cambiarClases('fonder fonder-activo')
        }, 200);
    }

    const CerrarModal = (e) =>{
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
    }

    const CerrarModalNone = (e) =>{
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
        setAdress('');
    }

    return( 
        <>{!toSelf ? <>  
            <div className="divContainer">
                <div className='divSubContainer'>
                    <form onSubmit={handleSubmit} className="formData">
                        <div className="formProgress">
                            {completo ? <h3>Registro Completo</h3>
                            :   <>{cargando ? <h3>Cargando...</h3> 
                                :<ul>
                                    <li className={comp1}><span><img src={ImgDestinatario} alt="Datos" /></span><label>Paso 1</label>Datos</li>
                                    <li className={comp2}><span><img src={ImgBank} alt="Contacto" /></span><label>Paso 2</label>Cuenta</li>
                                </ul>
                            }</>}
                        </div>
                        <div className="formContent">
                            {!cargando ? <> {completo ? 
                                <div className="Completado">
                                    <div className="CompletadoImg"><img src={Check} alt="listo" /></div>
                                    <p>Tu registro cómo destinatario se ha <b>completado.</b></p>
                                    <Link to="/dashboard" className="boton-general">Ir al Inicio</Link>
                                </div> 
                            : <>
                                <div className="formTitle">
                                    <h1 className="text-title">Información de AutoEnvío</h1>
                                    <p><b>Los campos marcados con * son obligatorios</b>, recuerda que esta información debe ser la del país de destino, además debe ser 
                                    precisa para evitar los contratiempos en las transferencias.<br/><b>la cuenta que registres debe ser una cuenta principal.</b></p>
                                </div>
                        
                                <div className={paso1 ? 'step step1 habil' : 'step step1'}>
                                    <div className="row p-0">
                                        <div className="col-12 col-md-6"><div className="input-select">
                                            <SelectorSelec opciones={paises} TipoOpcion={'País *'} opcion={pais} 
                                                cambiarOpcion={setPais} spnRed={spnPais} setSpnRed={setSpnPais} />
                                            {spnPais && <span className="spanAlert">{altPais}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6">
                                            <InputPhone code={code} setCode={setCode} phone={celular} setCelular={setCelular} 
                                            spnSpan={spnCelular} setSpan={setSpnCelular} alert={altCelular} pais={pais}/>
                                        </div>
                                        <div className="col-12"><div className={!spnAdress ? 'input-general': 'input-general redLine'} onClick={openModal}>
                                            <input type="text" name="addressAu" placeholder=" " value={adress} autoComplete="off" readOnly/>
                                            <label>Dirección*</label>
                                            {spnAdress && <span className="spanAlert">{altAdress}</span>}
                                        </div></div>
                                    </div>
                                    <div className="row p-0">
                                        <div className="col-12 col-md-6"><div className="input-select">
                                            <SelectorSelec opciones={tiposdocs} TipoOpcion={'Tipo de Documento*'} opcion={tipoDoc} 
                                                cambiarOpcion={setTipoDoc} spnRed={spnTipDoc} setSpnRed={setSpnTipDoc} />
                                            {spnTipDoc && <span className="spanAlert">{altTipDoc}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnNumDoc ? 'input-general': 'input-general redLine'}>
                                            <input name="documento" type={!typeDoc ? "number" : "text"} placeholder=" " value={numeroDoc} onChange={handleChange}/>
                                            <label>Número de Documento*</label>
                                            {spnNumDoc && <span className="spanAlert">{altNumDoc}</span>}
                                        </div></div>
                                    </div>
                                    <FileDocument archivosSelec={setDocumento} spnAltArchivo={spnArchivo} altArchivo={altArchivo}/>
                                </div>

                                <div className={paso2 ? 'step step2 habil' : 'step step2'}>
                                    <div className="row p-0">
                                        <div className="col-12 col-md-6"><div className={!spnBanco ? 'input-general': 'input-general redLine'}>
                                            <input name="banco" type="text" placeholder=" " value={banco} onChange={handleChange}/>
                                            <label>Nombre del Banco*</label>
                                            {spnBanco && <span className="spanAlert">{altBanco}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnTitular ? 'input-general': 'input-general redLine'}>
                                            <input name="titular" type="text" placeholder=" " value={titular} onChange={handleChange} />
                                            <label>Titular*</label>
                                            {spnTitular && <span className="spanAlert">{altTitular}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnBsb ? 'input-general': 'input-general redLine'}>
                                            <input name="bsb" type="number" placeholder=" " value={bsb} onChange={handleChange} />
                                            <label>Número BSB*</label>
                                            {spnBsb && <span className="spanAlert">{altBsb}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnBsb2 ? 'input-general': 'input-general redLine'}>
                                            <input name="bsb2" type="number" placeholder=" " value={bsb2} onChange={handleChange} autoComplete="off"/>
                                            <label>Confirmar Número BSB*</label>
                                            {spnBsb2 && <span className="spanAlert">{altBsb2}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnNCuenta ? 'input-general': 'input-general redLine'}>
                                            <input name="numeroCuenta" type="number" placeholder=" " value={numCuenta} onChange={handleChange} />
                                            <label>Número de la cuenta*</label>
                                            {spnNCuenta && <span className="spanAlert">{altNCuenta}</span>}
                                        </div></div>
                                        <div className="col-12 col-md-6"><div className={!spnNCuenta2 ? 'input-general': 'input-general redLine'}>
                                            <input name="numeroCuenta2" type="number" placeholder=" " value={numCuenta2} onChange={handleChange} autoComplete="off"/>
                                            <label>Confirmar Número de cuenta*</label>
                                            {spnNCuenta2 && <span className="spanAlert">{altNCuenta2}</span>}
                                        </div></div>
                                    </div>
                                </div>

                                {paso1 && 
                                    <div className="formButtons BtnsRigth">
                                        <button type="submit" onClick={PasoUno} className="boton-general imgButton-rigth">Siguiente<img src={ImgNext} alt="Siguiente" /></button>
                                    </div>
                                }
                                {paso2 && 
                                    <div className="formButtons BtnsSpace">
                                        <button className="boton-general imgButton-left" onClick={BackPasoUno} type="button"><img src={ImgPrev} alt="Regresar" />Regresar</button>
                                        <button type="submit" className="boton-general imgButton-rigth">Finalizar Registro<img src={Check} alt="Finalizar"/></button>
                                    </div>
                                } </>}
                            </> :
                                <div className="">
                                    <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
                                    <div style={{display: "none"}}><p>{progress}</p></div>
                                </div>
                            }
                        </div>
                    </form>
                    
                    {!cargando && <> {!completo &&
                        <div className="divSalir">
                            <p>¿Deseas registrarte cómo destinatario después?</p>
                            <Link to="/dashboard" className="boton-text imgButton-rigth">Cancelar<img src={Close} alt="Salir" /></Link> 
                        </div>
                    }</>}
                </div>
            </div>
            <Footer />
            <div className={modal}><AdrresAu setDireccion={setAdress} CerrarModal={CerrarModal}/></div>
            <div className={clases} onClick={CerrarModalNone}></div>
        </>: 
            <div className="loadingContainer pageLoader"><div className="lds-ripple"><div></div><div></div></div></div>
        }</>
    )
}

export default InfoToSelf;