import React from 'react';
import { Helmet } from 'react-helmet-async';
import SaludoDashboard from '../views/components/SaludoDashboard';
import Convertir from './components/conversor/Convetir';
import TrasnfersDashboard from './components/inicio/TransfersInicio';
import ContactsDashboard from './components/inicio/DestinatariosInicio';

const InicioDashboard = ({OpenModalNuevo,CerrarModal}) => {
    return (
        <>
            <Helmet><title>Dashboard</title></Helmet>
            <div className="col-12">
                <SaludoDashboard/>
            </div>
            <div className="col-12 p-0">
                <div className="dashboardCard">
                    <Convertir OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/>
                </div>
            </div>

            <div className="col-12">
                <div className="row p-0">
                    <div className="col-12 col-md-6 col-lg-7 col-xl-8 p-0">
                        <TrasnfersDashboard/>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 col-xl-4 p-0">
                        <ContactsDashboard />
                    </div>
                </div>
            </div>
        </>
    );
}

export default InicioDashboard;