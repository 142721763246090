import React, { useState, useEffect } from 'react';
import './selector.css';
import arrowDown from '../../../img/icons/arrow-down.svg';

const SelectorSelec = ({ opciones, TipoOpcion, opcion, cambiarOpcion, spnRed, setSpnRed }) => {

    const [mostrarSelect, cambiarMostrarSelect] = useState(false);
    const [label, setLabel] = useState(false);

    const Seleccion = (e) =>{cambiarMostrarSelect(!mostrarSelect);setSpnRed(false)}
    const LeaveSelect = (e) =>{cambiarMostrarSelect(false)}
    
    useEffect(() => {
        if(opcion){setLabel(true);}
        else{setLabel(false);}
    }, [opcion])
    
    const handleClick = (e) => {setLabel(true); cambiarOpcion(e.currentTarget.dataset.valor);}

    return (
        <div className="Selec" onClick={Seleccion} onMouseLeave={LeaveSelect}>
            <div className={(!mostrarSelect ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(label ? 'Selec_blue' : '')+' '+(spnRed ? 'redWarn' : '')}>
                <label className={!label ? 'Label_in' : 'Label_out'}>{TipoOpcion}</label>{opcion}
            </div>
            <img className={!mostrarSelect ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} alt="Desplegar"/>
            {mostrarSelect &&
                <div className="Selec_opciones">
                    {opciones.map((opcion) => {
                        return <div 
                        key={opcion.id} 
                        data-valor={opcion.texto} 
                        onClick={handleClick} 
                        className="Select_opcion">
                        {opcion.texto}</div> 
                    })}
                </div>
            }
        </div>
    );
}

export default SelectorSelec;