import React, {useState} from "react";
import { getUnixTime } from 'date-fns';
import DivLoading from '../../../../views/components/loading/Loading';
import ModalValidar from '../../../../views/components/modales/MoldaValidar';
import { HuboUnError, CalificacionOk } from '../../../components/Mensajes';
import { AgregarTestimonio, CalificarTrans } from "../../../data/AgregarTestimonio";
import ImgStart from '../../../../img/icons/star.svg';

const CalificarServicio = (props) =>{

    const{
        OpenModalNuevo,
        CerrarModal,
        rteAppId,
        id,
        rteNombre,
        rteApellido,
        setCalificada
    }=props;

    const fecha = new Date();

    const [stars, setStars] = useState('');
    const [calificacion, setCalificacion]= useState('');
    const [altStars, setAltStars] = useState('');
    const [altCalificacion, setAltCalificacion]= useState('');
    const [spnStars, setSpnStars] = useState(false);
    const [spnCalificacion, setSpnCalificacion]= useState(false);
    
    const handleChange = (e) =>{setCalificacion(e.target.value); setSpnCalificacion(false);}
    const starChange = (e) =>{setStars(e.target.value); setSpnStars(false);}

    const Publicar = (e) =>{
        OpenModalNuevo(<DivLoading />);
        AgregarTestimonio({
            fecha: getUnixTime(fecha),
            usuario: rteAppId,
            transfer: id,
            nombre: (rteNombre+' '+rteApellido),
            estado: 'pendiente',
            stars: stars,
            comment: calificacion
        }).then(() => {
            CalificarTrans({
                id: id,
                calificada:true
            });
            setCalificada(true);
            OpenModalNuevo(<CalificacionOk CerrarModal={CerrarModal}/>);
        }).catch((error) => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    const validar = (e) =>{
        e.preventDefault();
        if(stars===''){setSpnStars(true); setAltStars('Por favor selecciona'); return}
        if(calificacion===''){setSpnCalificacion(true); setAltCalificacion('Ingresa un Comentario'); return;}
        OpenModalNuevo(<ModalValidar 
            accion={Publicar} 
            titulo={'Enviar Calificación'}
            mensaje={'¿Seguro deseas enviar esta Calificación?'}
            botonName={'Sí, Enviar'}
            botonClass={'ButtonGreen'}
            CerrarModal={CerrarModal}/>
        )
    }

    return(
        <>
            <h3>Califícanos</h3>
            <form onSubmit={validar}>
                <div class="rating" onChange={starChange}>
                    <input type="radio" id="star5" name="rating" value="5"/>
                    <label class="star" for="star5" title="Awesome" aria-hidden="true"></label>
                    <input type="radio" id="star4" name="rating" value="4" />
                    <label class="star" for="star4" title="Great" aria-hidden="true"></label>
                    <input type="radio" id="star3" name="rating" value="3" />
                    <label class="star" for="star3" title="Very good" aria-hidden="true"></label>
                    <input type="radio" id="star2" name="rating" value="2" />
                    <label class="star" for="star2" title="Good" aria-hidden="true"></label>
                    <input type="radio" id="star1" name="rating" value="1" />
                    <label class="star" for="star1" title="Bad" aria-hidden="true"></label>
                </div>
                {spnStars && <span className="spanAlertCheck">{altStars}</span>}
                <div className="col-12 p-0"><div className={!spnCalificacion ? 'input-general': 'input-general redLine'}>
                    <textarea type="text" value={calificacion} name="observ" placeholder=" " id="textAreaContact" onChange={handleChange} />
                    <label>Comentario</label>
                    {spnCalificacion && <span className="spanAlert">{altCalificacion}</span>}
                </div></div>
                <button type="submit" className="boton-general BtnLarge imgButton-left"><img src={ImgStart} alt="estrellas" />Calificar</button>
            </form>
        </>
        
    )
}

export default CalificarServicio;   