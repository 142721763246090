import React, {useState, useEffect} from 'react';
import './InputPhone.css';
import Co from '../../../img/icons/co.svg';
import Au from '../../../img/icons/au.svg'; 

const InputPhone = ({code, setCode, phone, setCelular, spnSpan, setSpan, alert, pais}) =>{

    const [showCodes, setShowCodes] = useState(false);
    const [codeImg, setCodeImg] = useState(Co);
    
    const handleClick = (e) => {setShowCodes(false); setCode(e.currentTarget.dataset.id);setCodeImg(e.currentTarget.dataset.img);}
    const clickInput = (e) =>{e.preventDefault(); setShowCodes(false);}
    const handleChangeCode = (e) =>{e.preventDefault();setShowCodes(true);}
    const handleChange = (e) =>{setCelular(e.target.value); setSpan(false)}
    const handOVer = (e) =>{e.preventDefault();setShowCodes(false);}

    const codes = [
        { id: '1', img:Co, texto: '+57' },
        { id: '2', img:Au, texto: '+61'}
    ]

    useEffect(()=>{
        if(pais){ 
            if(pais === 'Australia'){setCodeImg(Au); setCode('+61');}
            if(pais === 'Colombia'){setCodeImg(Co); setCode('+57');}
        }else{setCodeImg(Co);}
    },[pais,setCode])

    const dial = codes.map((code)=>{
        return(
            <div key={code.id} className="selectCode" onClick={handleClick} data-id={code.texto} data-img={code.img}>
                <img src={code.img} alt="country" /><span>{code.texto}</span>
            </div>
        )
    })

    return(
        <div className={!spnSpan ? 'input-phone': 'input-phone redLine'}>
            <div className={!spnSpan ? 'inputPhone': 'inputPhone redPhoneWarn'}>
                <input type="number" name="phone" placeholder="Número" value={phone} onChange={handleChange} onClick={clickInput}
                    className={(showCodes ? 'BorderSelec' : '')+' '+(phone!=='' ? 'BorderBlue':'' )} autoComplete="off"/>
                    <label>Celular*</label>
                    {spnSpan && <span className="spanAlert">{alert}</span>}
            </div>
            <div className="inputCode">
                <button onClick={handleChangeCode}><img src={codeImg} alt="country"/>{code}</button>
                <div className={showCodes ? 'selectCodes' : 'selectCodesnull'} onMouseLeave={handOVer}>{dial}</div>
            </div>
        </div>
    );
}

export default InputPhone;