import React,{useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import './NewHeader.css'
import UserProfile from './UserProfile';
import Menu from '../../../img/icons/menu.svg';
import DivLoading from '../loading/Loading';

const HeaderLogin = ({setAbrirMenu, well, setWell}) => {
	
    const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

	const {usuario, infoUser, cargando} = useAuth();
    const [admin, setAmin] = useState(false);

	const [nombre, setNombre] = useState();
    const [apellido, setApellido] = useState();
    const [rol, setRol] = useState();
	const [photo, setPhoto] = useState();
	const [loader, setLoader] = useState(true);
	
	const HandleAbrir = (e) =>{
		e.preventDefault();
        setAbrirMenu(true);
        setWell(false)
    }

	useEffect(()=>{
		if(usuario){
			setPhoto(usuario.photoURL);
		}
		if(infoUser){
			setNombre(infoUser.nombre.nombre);
            setApellido(infoUser.nombre.apellido);
            setRol(infoUser.rol);
            if(infoUser.rol === 'Administrador')
			{setAmin(true);}
			setLoader(false)
        }
	},[usuario, infoUser])

	return (
		<div className="singFlex">
			{!cargando ? <>
				{!usuario ?
					<>
						{splitLocation[1] === "ingresar" || splitLocation[1] ==="registrate" || splitLocation[1] ==="recuperar-contrasena" ?
							<></>:
							<>
								<Link to="/ingresar" className="buttonIngresar">Ingresar</Link>
								<Link to="/registrate" className="boton-general">Regístrate</Link>
							</>
						}
					</>
				:
					<>
					{!loader ? <>
						{!admin && <Link to="/nueva-transferencia" className="boton-general" id="desktop-plus">Nueva Transferencia</Link>}
						<UserProfile nombre={nombre} apellido={apellido} rol={rol} photo={photo}/>
						</>
					: <DivLoading />}
					</>
				}
				</>:
				<DivLoading />
			}
			<div className="botonMenu" onClick={HandleAbrir}>{well && <img src={Menu} alt="Menu" />}</div>
		</div>
	);
}
 
export default HeaderLogin;