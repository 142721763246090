import React, {useState, useEffect} from 'react';
import { AgregarAgencia, EditarAgencia } from '../../../data/AdminAgencias';
import { useSetAgencia } from '../../../hooks/useAgencias';
import InputComp from '../../../../views/components/inputs/InputComp';
import SelectorSelec from '../../../../views/components/inputs/Selector';
import Check from '../../../../img/icons/tick-square.svg';
import { CuentaEliminada, HuboUnError } from '../../Mensajes';
import ModalValidar from '../../../../views/components/modales/MoldaValidar';
import DivLoading from '../../../../views/components/loading/Loading';

const AdminAgencia = ({OpenModalNuevo, CerrarModal, idAgencia}) =>{

    const [id, setId] = useState('');
    const [agencia] = useSetAgencia(id);
    const [name, setName] = useState('');
    const [abn, setAbn] = useState('');
    const [estado, setEstado] = useState('');
    const [spnName, setSpnName] = useState(false);
    const [spnAbn, setSpnAbn] = useState(false);
    const [spnEstado, setSpnEstado] = useState(false);
    const [altName, setAltName] = useState();
    const [altAbn, setAltAbn] = useState();
    const [altEstado, setAltEstado] = useState();
    const idTransform = (nombre) =>{ const id = nombre.replace(/\s+/g, '').toLocaleLowerCase(); return id;}
    const [editar, setEditar] = useState(false);
    const [cargando, setCargando] = useState(true);

    const tiposEstados = [
        {id: '1', texto: 'Activo'},
        {id: '2', texto: 'Inactivo'}
    ]

    useEffect(()=>{
        if(idAgencia){
            setId(idAgencia)
            setEditar(true)
            setCargando(false)
        }else{
            setCargando(false)
        }
    },[idAgencia])

    useEffect(()=>{
        if(agencia){
            setName(agencia.data().nombre);
            setAbn(agencia.data().abn);
            setEstado(agencia.data().estado);
        }
    },[agencia])

    const handleSubmit = () =>{
        OpenModalNuevo(<DivLoading />)
        AgregarAgencia({
            id: idTransform(name),
            nombre: name,
            abn: abn,
            estado: 'Activa',
        }).then(() => {
            OpenModalNuevo(<CuentaEliminada CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    const handleEdit = () =>{
        OpenModalNuevo(<DivLoading />)
        EditarAgencia({
            id: idTransform(name),
            nombre:name,
            abn:abn,
            estado:estado
        }).then(() => {
            OpenModalNuevo(<CuentaEliminada CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }


    const validar = () =>{
        if (!name) {setSpnName(true); setAltName('Ingresa el nombre'); return;} 
        if (!abn) {setSpnAbn(true); setAltAbn('Ingresa el ABN'); return;}
        if (!estado) {setSpnEstado(true); setAltEstado('Selecciona un Estado'); return;}
        if (editar){
            OpenModalNuevo(<ModalValidar 
                accion={handleEdit} 
                titulo={'Editar Agencia'}
                mensaje={'¿Deseas Actualizar la informaciónd de la Agencia?'}
                botonName={'Sí, Editar'}
                botonClass={'ButtonRed'}
                CerrarModal={CerrarModal}/>)
        }else{
            OpenModalNuevo(<ModalValidar 
                accion={handleSubmit} 
                titulo={'Crear Agencia'}
                mensaje={'¿Estás seguro de Agregar la Agencia?'}
                botonName={'Sí, Agregar'}
                botonClass={'ButtonGreen'}
                CerrarModal={CerrarModal}/>)
        }
    }

    return(
        <>
            {cargando ? <DivLoading /> : <>
                <div className="row p-0">
                    <InputComp cols={'col-12 col-md-6'} spn={spnName} setSpn={setSpnName} alt={altName} 
                        setValue={setName} value={name} type={'text'} label={'Nombre*'} fill={'off'} />
                    <InputComp cols={'col-12 col-md-6'} spn={spnAbn} setSpn={setSpnAbn} alt={altAbn} 
                        setValue={setAbn} value={abn} type={'text'} label={'ABN*'} fill={'off'} />
                    <div className="col-12 col-sm-6"><div className="input-select">
                        <SelectorSelec opciones={tiposEstados} TipoOpcion={'Estado *'} opcion={estado} 
                            cambiarOpcion={setEstado} spnRed={spnEstado} setSpnRed={setSpnEstado}/>
                        {spnEstado && <span className="spanAlert">{altEstado}</span>}
                    </div></div>
                </div>
                <div className="col-12 p-0">
                    <button  onClick={validar} className="boton-general BtnLarge imgButton-left" >
                        <img src={Check} alt="Finalizar"/>{editar ? 'Editar Agencia' : 'Crear Agencia'}</button>
                    <button onClick={CerrarModal} className="btn-text">Cancelar</button>
                </div> 
            </>
            }
        </>
    )
}

export default AdminAgencia;