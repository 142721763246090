import { db } from '../../firebase/firebaseConfig';

const AgregarRate = async(props) => {
    const{
        rate,
        tasa,
    } = props;
    return await db.collection('config').doc('rate').update({
        rate: rate,
        tasa: tasa
    });
}

const UpdatePre = async({tasaPre}) => {
    return await db.collection('config').doc('rate').update({
        tasaPre: tasaPre
    });
}

const EstadoUltra = async({ultrarapido}) =>{
    return await db.collection('config').doc('fees').update({
        ultrarapido: ultrarapido
    });
}

export {
    AgregarRate, 
    EstadoUltra,
    UpdatePre
};
