import { db } from '../../firebase/firebaseConfig';

const AgregarCuentas = async(props) => {
    const{
        nombreBanco,
        tipoCuenta,
        numeroCuenta,
        titularCuenta,
        tipoId,
        idTitular
    } = props;
    return await db.collection('cuentas').add({
        nombreBanco: nombreBanco,
        tipoCuenta: tipoCuenta,
        numeroCuenta: numeroCuenta,
        titularCuenta: titularCuenta,
        tipoId: tipoId,
        idTitular: idTitular
    });
}

const EditarCuentas = async(props) => {
    const{
        id,
        nombreBanco,
        tipoCuenta,
        numeroCuenta,
        titularCuenta,
        tipoId,
        idTitular
    } = props;
    return await db.collection('cuentas').doc(id).update({
        nombreBanco: nombreBanco,
        tipoCuenta: tipoCuenta,
        numeroCuenta: numeroCuenta,
        titularCuenta: titularCuenta,
        tipoId: tipoId,
        idTitular: idTitular
    });
}

const EliminarCuentas = async ({id}) => {
    return await db.collection('cuentas').doc(id).delete()
}

export { AgregarCuentas, EditarCuentas, EliminarCuentas };