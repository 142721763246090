import React, { useState, useEffect } from 'react';
import { useTrans } from '../../../../context/TransferContext';
import { UpdatePre } from '../../../data/AdminRate';
import DivLoading from '../../../../views/components/loading/Loading';
import NumberFormat from 'react-number-format';
import ModalValidar from '../../../../views/components/modales/MoldaValidar';
import { TasaPreActualizada, HuboUnError } from '../../Mensajes';
import '../rate.css';

const PreferencialManual = ({OpenModalNuevo,CerrarModal}) =>{

    const {tasaPre} = useTrans();
    const [tasaPreN, setTasaPreN] = useState('');
    const [spnTasaPre, setSpnTasaPre] = useState(false);
    const [altTasaPre, setAltTasaPre] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        if(tasaPre){
            setTasaPreN(tasaPre)
            setLoading(false)
        }
    },[tasaPre])

    const handleChange = (e) =>{
        setTasaPreN(e.target.value);
        setSpnTasaPre(false)
    }

    const handleSubmit = () =>{
        OpenModalNuevo(<DivLoading />);
        UpdatePre({
            tasaPre: tasaPreN
        })
        .then(() => {
            OpenModalNuevo(<TasaPreActualizada CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    const validar = (e) =>{
        e.preventDefault();
        if(!tasaPreN){setSpnTasaPre(true); setAltTasaPre('Ingresa la tasa'); return;}
        OpenModalNuevo(
            <ModalValidar 
                accion={handleSubmit} 
                titulo={'Tasa Preferencial'}
                mensaje={'¿Deseas Actualizar la tasa preferencial?'}
                botonName={'Sí, Actualizar'}
                botonClass={'ButtonGreen'}
                CerrarModal={CerrarModal}
            />
        )
    }

    return(
        <>
        {loading ? <DivLoading /> :
            <div>
                <p className="CardTitulo">Tasa Preferencial Manual</p>
                <form onSubmit={validar} className="RateContainer">
                    <div className="inputTasa">
                        <div className={!spnTasaPre ? 'input-general': 'input-general redLine'}>
                            <input name='tasa' type="number" placeholder=" " onChange={handleChange} value={tasaPreN} step="0.01" pattern="^\d*(\.\d{0,2})?$" />
                            <label>Tasa</label>
                            {spnTasaPre && <span className="spanAlert">{altTasaPre}</span>}
                        </div>
                        
                    </div>
                    <div className="totalManual">
                            <p><NumberFormat value={tasaPreN} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'}/></p>
                        </div>
                    <div className="botonesTasa">
                        <button className="boton-general" type="submit">Actualizar</button>
                    </div>
                </form>
            </div>
        }
        </>
    )
}

export default PreferencialManual;