import {useState, useEffect} from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';

const useObtenerTransferencias = () => {

    const {usuario} = useAuth();
    const [transferencias, setTransferencias] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() =>{
        const unsuscribe = db.collection('transferencias')
        .where('usuario', '==', usuario.uid)
        .where('estado', 'in', ['Registrada', 'En Revisión', 'En Proceso'])
        .orderBy('fecha','desc')
        .limit(3)
        .onSnapshot((snapshot) => {
            setLoading(false);
            setTransferencias(snapshot.docs.map((transferencia) => {
                return {...transferencia.data(), id: transferencia.id}
            }));
        });

        return unsuscribe;
    }, [usuario]);
    
    return [transferencias, loading];
}

export default useObtenerTransferencias;