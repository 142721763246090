import React from 'react';
import imgCheck from '../../img/icons/tick-square.svg';
import imgWrong from '../../img/icons/close-square.svg';

const CorrectSet = ({accion, mensaje, estado}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>{mensaje}<br/><b>{estado}.</b></p>
            <button className="btn-text" onClick={accion}>Cerrar</button>
        </div>
    );
}

const UltraRapidoActivo = ({CerrarModal}) =>{
    const accion = CerrarModal;
    const mensaje = 'El método UltraRápido ha sido Activado';
    const estado = 'Correctamente';
    return CorrectSet({accion,mensaje,estado})
}

const UltraRapidoInactivo = ({CerrarModal}) =>{
    const accion = CerrarModal;
    const mensaje = 'El método UltraRápido ha sido Desactivado';
    const estado = 'Correctamente';
    return CorrectSet({accion,mensaje,estado})
}

const TasaPreActualizada = ({CerrarModal}) =>{
    const accion = CerrarModal;
    const mensaje = 'La tasa ha sido actualizada';
    const estado = 'Correctamente';
    return CorrectSet({accion,mensaje,estado})
}

const HuboUnError = ({CerrarModal}) =>{
    return(
        <div className="InnerModal InnerWrong">
            <div className="IconModal IconWrong">
                <img src={imgWrong} alt="Wrong" />
            </div>
            <h2>Oops!</h2>
            <p>Algo salió mal..<br/><b>Inténtalo de nuevo.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const CuentaEliminada = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>La cuenta ha sido eliminada<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const CalificacionEliminada = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>La calificación ha sido eliminada<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const CalificacionPublicada = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>La calificación ha sido publicada<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const CuentaEditada = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>La cuenta ha sido editada<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const CuentaCreada = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>La cuenta ha sido Agregada<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const RecotizadaOk = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>La transferencia fue recotizada<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const CanceladaOk = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>La transferencia fue Cancelada<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const ProcesadaOk = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>Se ha subido el comprobante<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const ProcesadaCheck = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>Se ha Procesado la transferencia<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const DeletedComprobante = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>Se ha Eliminado el Comprobante<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const CambioRegistrado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>Se ha camiado el estado<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const RateAgregado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>Se ha actualizado el Rate<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const TasaAgregada = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>Se ha actualizado la Tasa<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const RateEliminado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>Se ha eliminado el Rate<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const TasaEliminada = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>Se ha eliminado la tasa manual<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

//CODIGOS
const CodigoEliminado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal"><img src={imgCheck} alt="Check" /></div>
            <h2>Listo!</h2>
            <p>El código ha sido eliminado<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}
const CodigoEditado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal"><img src={imgCheck} alt="Check" /></div>
            <h2>Listo!</h2>
            <p>El código ha sido editado<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}
const CodigoCreado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal"><img src={imgCheck} alt="Check" /></div>
            <h2>Listo!</h2>
            <p>El código ha sido Agregado<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

//USUARIOS
const UsuarioSuspendido = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>El Usuario se ha suspendio<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const UsuarioHabilitado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>El Usuario se ha activado<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const ReferidoAgregado = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgCheck} alt="Check" />
            </div>
            <h2>Listo!</h2>
            <p>El Referido se ha agregado<br/><b>Correctamente.</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

export {
    HuboUnError, 
    CuentaEliminada, 
    CuentaEditada, 
    CuentaCreada, 
    RecotizadaOk,
    CanceladaOk,
    ProcesadaOk,
    CalificacionEliminada, 
    TasaAgregada,
    CalificacionPublicada,
    ProcesadaCheck,
    DeletedComprobante,
    RateAgregado,
    RateEliminado,
    TasaEliminada,
    CodigoEliminado,
    CodigoEditado,
    CodigoCreado,
    CambioRegistrado,
    UsuarioSuspendido,
    UsuarioHabilitado,
    ReferidoAgregado,
    TasaPreActualizada,
    UltraRapidoActivo,
    UltraRapidoInactivo
};