import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';

const useTransxDest = (id) => {

    const [transferencias, setTransferencias] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() =>{
        if(id){
            const unsuscribe = db.collection('transferencias')
            .where('destinatarioId', '==', id)
            .onSnapshot((snapshot) => {
                setLoading(false);
                setTransferencias(snapshot.docs.map((transferencia) => {
                    return {...transferencia.data(), id: transferencia.id}
                }));
            });
            return unsuscribe;
        }
    }, [id]);
    
    return [transferencias, loading];
}

export default useTransxDest;