import React from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import useObtenerTestimonios from '../../../hooks/useObtenerTestimonios';
import ImgStar from '../../../img/icons/star-1.svg';

const Testimonios = () =>{

    const [testimonios, loading] = useObtenerTestimonios();
    const dateDistance = (fecha) => formatDistanceToNowStrict(fromUnixTime(fecha),{ locale: es });

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 900 },
          items: 4,
          paritialVisibilityGutter: 0
        },
        tablet: {
          breakpoint: { max: 900, min: 400 },
          items: 2,
          paritialVisibilityGutter: 50
        },
        mobile: {
          breakpoint: { max: 400, min: 0 },
          items: 1,
          paritialVisibilityGutter: 100
        }
    };

    return(
        <div className="testimonios-inicio">
            <h1 className="SectionTitle TxtCenter">Testimonios</h1>
            <p className="SectionText TxtCenter">Estos son los comentarios de nuestros clientes.</p>
            {loading ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div> : <>
                {testimonios.length > 0 &&
                    <Carousel
                        partialVisible
                        itemClass="testimonios"
                        responsive={responsive}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        >
                        {testimonios.map((testimonio) => {
                            return(
                                <div className="testimonio" key={testimonio.id}>
                                    <div className="rate-coment"><p>"{testimonio.comment}"</p></div>
                                    <div className="rate-name"><p>{testimonio.nombre}</p></div>
                                    <div className="rate-stars">
                                        {Array.from({ length: testimonio.stars }, (_, i) => 
                                            <div className="DivStar" key={i}><img src={ImgStar} alt="estrellas" /></div>)
                                        }
                                    </div>
                                    <div className="rate-date"><p>{dateDistance(testimonio.fecha)}</p></div>
                                </div>
                            );
                        })}
                    </Carousel>
                }
            </>}
        </div>
    )
}

export default Testimonios;