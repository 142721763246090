import React,{useState, useEffect} from 'react';
import { useAuth } from '../../../context/AuthContext';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import ListaMenuAdmin from './data/MenuAdmin.json';
import ListaMenuUser from './data/MenuUSer.json';
import './MenuLateral.css';
import Logo from '../../../img/logocolcambiosicon.svg';
import Default from '../../../img/perfil-default.jpg';
import imgSalir from '../../../img/icons/log-out.svg';
import Austrac from '../../../img/austrac.svg';
import Arrow from '../../../img/icons/arrow-down.svg';

const MenuLateral = ({setWell, setAbrirMenu, abrirMenu}) =>{

    const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

    const {infoUser,usuario,cerrarSesion } = useAuth();
    const [login, setLogin] = useState(false)
    const [rol, setRol] = useState(false);
    const [roles, setRoles] = useState(false);

    const [nombre, setNombre] = useState();
    const [apellido, setApellido ]= useState();
    const [profile, setProfile] = useState();

    useEffect(() => {
        if(infoUser){
            setNombre(infoUser.nombre.nombre);
            setApellido(infoUser.nombre.apellido);
            setRoles(infoUser.rol);
            if(infoUser.rol === 'Administrador'){
                setRol(true);
            }
        }else{
            setRol(false);
			setNombre();
            setApellido();
		}
    }, [infoUser])

	useEffect(()=>{
		if(usuario){
            setProfile(usuario.photoURL);
			setLogin(true);
		}else{
			setLogin(false)
            setProfile();
		}
	},[usuario])

    const HandleCerrar = (e) =>{
        e.preventDefault();
        setAbrirMenu(false);
        setWell(true);
    }

    const HandleSelect = (e) =>{
        e.preventDefault();
        window.scrollTo({ behavior: 'smooth', top: '0px' });
        setAbrirMenu(false);
        setWell(true);
    }

    const renderMenus = (menu) =>{
        return(
        menu.length > 0 &&
            menu.map((link) => {
                if(link.link){
                    return(
                        <li onClick={HandleCerrar} key={link.id}>
                            <Link to={('/'+link.id)} className={splitLocation[1] === link.id ? "homeLActive" : ""} >
                                <img src={link.img} alt={link.texto} />{link.texto}</Link>
                        </li>
                    )
                }else{
                    return(
                        <li key={link.id} className={splitLocation[1] === link.id ? "homeLActive" : ""}><img src={link.img} alt={link.texto} />{link.texto}<img src={Arrow} alt="Arrow" className='menuLArrow' />
                            <ul>
                                {link.submenu && <>
                                    {link.submenu.map((submenu)=>{
                                        return(
                                            <li key={submenu.id} onClick={HandleCerrar}><Link to={('/'+submenu.link)} className={splitLocation[2] === submenu.id ? "homeLActive" : "subLUn"}>
                                                <img src={submenu.img} alt={submenu.texto} />{submenu.texto}</Link></li>
                                        )
                                    })} 
                                </>}
                            </ul>
                        </li>
                    )
                }
            })
        )
    }

    return(
        <>
            <div className={abrirMenu ? "MenuLateral" : "NoneMenu"}>
                <div className="MenuLatCont">
                    {!login ? 
                        <div className="cardProfile">
                            <div className="cardProfileImg cardLogo">
                                <img src={Logo} alt="logo"/>
                            </div>
                            <p>COLCAMBIOS AUSTRALIA</p>
                        </div> :
                        <div className="cardProfile">
                            <div className="cardProfileImg">
                                <img src={profile ? profile : Default} alt="user"/>
                            </div>
                            <p>{nombre + ' ' + apellido}</p>
                            <span>{roles}</span>
                        </div>
                    }

                    <div className="cardMenus" >
                        {login && <>
                            {rol ?
                                <div className="cardMenu">
                                    {renderMenus(ListaMenuAdmin)}
                                    <button onClick={cerrarSesion}><img src={imgSalir} alt="perfil" />Salir</button>
                                </div>
                                :
                                <div className="cardMenu" onClick={HandleSelect}>
                                    {renderMenus(ListaMenuUser)}
                                    <button onClick={cerrarSesion}><img src={imgSalir} alt="perfil" />Salir</button>
                                </div>
                            }
                        </>}
                        <div className="cardMenu" id="lineTop" onClick={HandleSelect}>
                            <li><Link to="/" className={splitLocation[1] === "" ? "homeLActive" : ""}>Inicio</Link></li>
                            <li><Link to="/por-que-elegirnos" className={splitLocation[1] === "por-que-elegirnos" ? "homeLActive" : ""}>¿Por qué elegirnos?</Link></li>
                            <li><Link to="/como-funciona" className={splitLocation[1] === "como-funciona" ? "homeLActive" : ""}>Cómo funciona</Link></li>
                            <li><Link to="/ayuda" className={splitLocation[1] === "ayuda" ? "homeLActive" : ""}>Ayuda</Link></li>
                            <li><Link to="/terminos-y-condiciones" className={splitLocation[1] === "terminos-y-condiciones" ? "homeLActive" : ""}>Términos y condiciones</Link></li>
                            <li><Link to="/politicas-de-privacidad" className={splitLocation[1] === "politicas-de-privacidad" ? "homeLActive" : ""}>Políticas de Privacidad</Link></li>
                        </div>
                        <div className="cardMenuAustrac" id="lineTop">
                            <a href="https://www.austrac.gov.au" target="_blank" rel="noreferrer"><img src={Austrac} alt="Austrac"/></a>
                        </div>      
                    </div>
                </div>
                <div className="" onClick={HandleCerrar}>cerrar</div>
                <div className="backgroundBlack" onClick={HandleCerrar}></div>
            </div>
        </>
    );
}

export default MenuLateral;