import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../context/AuthContext';
import { format, fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';

import useNumeroTransferencias from '../../hooks/useNumeroTransferencias';
import './profile.css';
import FotodePerfil from '../../../img/perfil-default.jpg';
import ImgProfile from '../../../img/icons/profile-circle.svg';
import ImgDoc from '../../../img/icons/documentid.svg';
import UpdatePhoto from './UpdatePhoto';
import CambioPass from './CambioPass';
import DeleteAccount from './DeleteAccount';

const Profile = ({OpenModalNuevo, CerrarModal}) => {

    const { usuario, infoUser } = useAuth();
    const perfil = usuario.photoURL;
    const [transferencias] = useNumeroTransferencias();

    const [fecharegistro, setFechaRegistro] = useState('')
    const [email, setEmail] = useState('')
    const [nombre, setNombre] = useState('')
    const [segundonombre, setSegundonNombre] = useState('')
    const [apellido, setApellido] = useState('')
    const [segundoapellido, setSegundoApellido] = useState('')
    const [tipodocumento, setTipoDocumento] = useState('')
    const [numerodocumento, setNumeroDocumento] = useState('')
    const [documento, setDocumento] = useState('')
    const [fechaexpedicion, setFechaExpedicion] = useState('')
    const [fechanacimiento, setFechaNacimiento] = useState('')
    const [celular, setCelular] = useState('')
    const [pais, setPais] = useState('')
    const [ciudad, setCiudad] = useState('')
    const [direccion, setDireccion] = useState('')
    // const [toSelf, setToSelf] = useState('');
    // const [autoEnvio, setAutoEnvio] = useState('');

    useEffect(()=>{
        if(infoUser){
            setFechaRegistro(infoUser.fechaRegistro);
            setEmail(infoUser.email);
            setNombre(infoUser.nombre.nombre);
            setSegundonNombre(infoUser.nombre.segNombre);
            setApellido(infoUser.nombre.apellido);
            setSegundoApellido(infoUser.nombre.segApellido);
            setTipoDocumento(infoUser.documento.tipoDocumento);
            setNumeroDocumento(infoUser.documento.numeroDocumento);
            setDocumento(infoUser.documento.urlDocumento);
            setFechaExpedicion(infoUser.documento.fechaExpedicion);
            setFechaNacimiento(infoUser.fechaNacimiento);
            setCelular(infoUser.celular);
            setPais(infoUser.direccion.pais);
            setCiudad(infoUser.direccion.ciudad);
            setDireccion(infoUser.direccion.direccion);
            // setToSelf(infoUser.toSelf);
            // setAutoEnvio(infoUser.autoEnvio);
        }
    },[infoUser])

    const formatearFecha = (fecha) => {return format(fromUnixTime(fecha), "dd 'de' MMMM 'de' yyyy", { locale: es });}

    return (
        <> 
            <Helmet><title>Perfil</title></Helmet>
            <div className="TituloPag">
				<p>Mi Cuenta</p>
			</div>
            <div className="row m-0">
                <div className="col-12 col-md-7 col-lg-8 p-0">
                    <div className="CardTransferencia">
                        <div className="disPlayCard">
                            <div className="ImagenPerfil">
                                <img src={perfil === null ? FotodePerfil : perfil} alt="Foto de Perfil" />
                                <div className="PerfilButton">
                                    <button onClick={()=>{OpenModalNuevo(<UpdatePhoto OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal}/>)}}><img src={ImgProfile} alt="Img"/><span>Cambiar Foto<br/>de Perfil</span></button>
                                </div>
                            </div>
                            <div className="displayName">
                                <p>{nombre+' '+segundonombre}</p>
                                <span>{apellido+' '+segundoapellido}</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className="CardBody">
                        <h3>Información</h3>
                        <div className="row p-0 DatosTrans">
                            <div className="col-6 col-sm-6 col-md-4"><p><label>Tipo de Documento</label>{tipodocumento}</p></div>
                            <div className="col-6 col-sm-6 col-md-4"><p><label>Número de Documento</label>{numerodocumento}</p></div>
                            <div className="col-6 col-sm-6 col-md-4"><p><label>Fecha de Expedición</label>{fechaexpedicion}</p></div>
                            <div className="col-6 col-sm-6 col-md-4"><p><label>Fecha de Nacimiento</label>{fechanacimiento}</p></div>
                            <div className="col-12 col-sm-6 col-md-4"><p><label>Fecha de Registro</label>{formatearFecha(fecharegistro)}</p></div>
                            <div className="col-12 col-sm-6 col-md-4"><p><label>Celular</label>{celular}</p></div>
                            <div className="col-12 col-sm-6 col-md-8"><p><label>Email</label>{email}</p></div>
                            <div className="col-6 col-sm-6 col-md-4"><p><label>País</label>{pais}</p></div>
                            {ciudad && <div className="col-6 col-sm-6 col-md-4"><p><label>Ciudad</label>{ciudad}</p></div>}
                            <div className="col-12 col-sm-6 col-md-6"><p><label>Dirección</label>{direccion}</p></div>
                            <div className="col-12 col-sm-6 col-md-4"><p><label>Número de Transferencias</label>{transferencias.length}</p></div>
                        </div>
                    </div>

                    <div className="CardBody">
                        <h3>Documentos</h3>
                        <div className="TransDocuments">
                            <a href={documento} target="_blank" rel="noreferrer">Documento<img src={ImgDoc} alt="Documento"/></a>
                            {/* {toSelf &&
                                <a href={documento.urlDocumento} target="_blank" rel="noreferrer">{toSelf ? 'Id Australia' : 'Documento'}<img src={ImgDoc} alt="Documento"/></a>
                            } */}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5 col-lg-4 p-0">
                    <div className="CardBody">
                        <h3>Cambio de Contraseña</h3>
                        <button className="boton-general BtnLarge" onClick={()=>{OpenModalNuevo(<CambioPass CerrarModal={CerrarModal} OpenModalNuevo={OpenModalNuevo}/>)}}>Cambiar Contraseña</button>
                    </div>
                    <div className="CardBody">
                        <h3>Eliminar Cuenta</h3>
                        <button className="boton-general BtnLarge" onClick={()=>{OpenModalNuevo(<DeleteAccount CerrarModal={CerrarModal} OpenModalNuevo={OpenModalNuevo}/>)}}>Eliminar mi cuenta</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;