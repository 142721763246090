import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useTrans } from '../../../context/TransferContext';
import { useHistory, useLocation } from 'react-router';
import { TransaccionMayor, MinimoPermitido } from '../../../views/login/Correos';
import './currencies.css';
import australia from '../../../img/icons/au.svg';
import colombia from '../../../img/icons/co.svg';
import ImgCalcular from '../../../img/icons/calculator.svg';
import ImgTransfer from '../../../img/icons/send.svg';
import ImgChange from '../../../img/icons/arrow-2.svg';
import DobleSelect from '../../../views/components/inputs/DobleSelector';
import DivLoading from '../../../views/components/loading/Loading';

const Convertir = (props) => {
    const{
        setMontoAud, 
        setMontoCop,
        servicio,
        spnMinimo,
        setSpnMinimo,
        altMinimo,
        OpenModalNuevo,
        CerrarModal,
        codigo,
        descuento
    }= props;

    //LOCATION AND HISTORY
    const history = useHistory();
    const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

    //CONTEXT
    const {tasadeCambio,tasaPre, FijarValor, ammountAud, FijarServicio, ultrarapido} = useTrans();
    const [reverseCoin, setReverseCoin] = useState();
    const [valor, setValor] = useState(true);
    const [toAmount, setToAmount] = useState();
    const [fromAmount, setFromAmount] = useState(1);
    const [fee, setFee] = useState(4.99);
    const [service, setService] = useState('Tradicional');
    const [tiposFees, setTiposFees] = useState([{ id: '1', texto: 'Tradicional' },{ id: '2', texto: 'UltraRápido' }])
    const [comision, setComision] = useState('Servicio Incluído');
    const comisiones = [{ id: '1', texto: 'Servicio Incluído' },{ id: '2', texto: 'Servicio Adicionado' }]
    const [calcular, setCalcular] = useState(false);
    const [labelStar, setLabelStar] = useState(true);
    const [tasaEstable, setTasaEstable] = useState();
    const [cargando, setCargando] = useState(true);
    
    useEffect(()=>{
        if(tasadeCambio){
            setTasaEstable(tasadeCambio);
            setTimeout(() => {
                setCargando(false);
            }, 1000);
        }
    },[tasadeCambio])

    const truncate = (n, digits) => {
        var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
            m = n.toString().match(re);
        return m ? parseFloat(m[1]) : n.valueOf();
    };
    const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}

    //CALCULO
    const [pagas, setPagas] = useState();
    const [reciben, setReciben] = useState();
    
    useEffect(()=>{
        if(ultrarapido){
            setTiposFees([{id: '1', texto: 'Tradicional'}])
        }
    },[ultrarapido])

    useEffect(()=>{
        if(comision === 'Servicio Incluído'){
            setReciben(Math.floor(fromAmount - fee));
            setPagas(Math.ceil(toAmount));
            setSpnAlertReciben(false)
        }
        if(comision === 'Servicio Adicionado'){
            setReciben(Math.floor(fromAmount));
            setPagas(Math.ceil(truncate((parseFloat(fromAmount) + parseFloat(fee)) * tasaEstable),2))
            setSpnAlertReciben(false)
        }
    },[fee, comision, fromAmount, toAmount, tasaEstable])

    //VALIDACIONES Y ALERTAS
	const [alertTo, setAlerTo] = useState('');
    const [alertFrom, setAlertFrom] = useState('');
    const [alertReciben, setAlertReciben] = useState('');
    const [spanAlertTo, setSpanAlertTo] = useState(false);
    const [spanAlertFrom, setSpanAlertFrom] = useState(false);
    const [spanAlertReciben, setSpnAlertReciben] = useState(false);

    //REVERSE
    useEffect(()=>{if(servicio){setReverseCoin(servicio)}else{setReverseCoin(false)}},[servicio]);
    const ChangeConverter = () =>{setReverseCoin(!reverseCoin);}

    //SERVICIO
    useEffect(()=>{
        if(service === 'Tradicional'){  
                setFee(4.99);
                setSpanAlertFrom(false);
                setSpnAlertReciben(false)
            }
        if(service === 'UltraRápido'){   
                setFee(14.99); 
                setSpanAlertFrom(false);
                setSpnAlertReciben(false)
            }
    },[service]);
    
    //NUEVA TRANSFERENCIAS ALERTAS Y SETS
    useEffect(()=>{
        if(splitLocation[1] === 'nueva-transferencia'){
            if(spnMinimo){setAlertFrom(altMinimo);setSpanAlertFrom(spnMinimo);}
        }
    },[spnMinimo,altMinimo,splitLocation])

    useEffect(()=>{
        if(splitLocation[1] === 'nueva-transferencia'){
            setLabelStar(false)}
    },[splitLocation])

    useEffect(()=>{
        if(splitLocation[1] === 'nueva-transferencia'){setSpnMinimo(false);}
    },[toAmount,fromAmount,splitLocation,setSpnMinimo])

    useEffect(()=>{
        if(splitLocation[1] === 'nueva-transferencia'){setMontoCop(toAmount);setMontoAud(fromAmount);}
    },[toAmount,fromAmount, setMontoAud, setMontoCop, splitLocation])

    useEffect(()=>{
        if(splitLocation[1] === 'nueva-transferencia'){
            if(codigo){
                if(descuento === 'Tasa'){
                    setTasaEstable(tasaPre)
                }
            }else{
                setTasaEstable(tasadeCambio)
            }
        }
    },[splitLocation,codigo,descuento,tasaPre,tasadeCambio])

    //CARGAS
    useEffect(()=>{
        if(ammountAud){
            setFromAmount(ammountAud);
        }else{
            setFromAmount(1);
        }
    },[ammountAud]);

    //FUNCIONES
    const handleToAmountChange = (values) => {
        setToAmount(values.value);setSpanAlertTo(false);setSpanAlertFrom(false);setSpnAlertReciben(false);
	}

    const handleFromAmountChange = (values) => {
        setFromAmount(values.value);setSpanAlertTo(false);setSpanAlertFrom(false);setSpnAlertReciben(false);
	}

    const cambiarInputCop = (e) =>{e.preventDefault(); setValor(false);}
    const cambiarInputAud = (e) =>{e.preventDefault(); setValor(true);}
   
    useEffect(()=>{
        if(!valor){
            setFromAmount((toAmount / tasaEstable));
        }else{
            setToAmount((fromAmount * tasaEstable));
        }
    },[toAmount, fromAmount, valor, tasaEstable])

    const handleCalcular = (e) =>{
        e.preventDefault();
        if(fromAmount < 100){setAlertFrom('El valor mínimo es de $100 AUD'); setSpanAlertFrom(true); return;}
        if(fromAmount === 0){setAlertFrom('Ingresa un valor'); setSpanAlertFrom(true); return;}
        else{
            setCalcular(true);
            setPagas(Math.ceil(toAmount));
            setReciben(Math.floor(fromAmount - fee));
        }
    }

    const setDatos = () =>{
        if(toAmount === 0){setAlerTo('Ingresa un valor'); setSpanAlertTo(true); return;}
        if(fromAmount === 0){setAlertFrom('Ingresa un valor'); setSpanAlertFrom(true); return;}
        if(reciben < 100){setAlertReciben('El valor mínimo es de $100 AUD'); setSpnAlertReciben(true); return;}
        if(fee === 14.99){ if(reciben > 5000){OpenModalNuevo(<MinimoPermitido CerrarModal={CerrarModal}/>);return;}}
        if(reciben > 10000){OpenModalNuevo(<TransaccionMayor CerrarModal={CerrarModal}/>);return;}
        toUp();
        FijarValor(parseFloat(toAmount), parseFloat(fromAmount));
        FijarServicio(service, comision);
        history.push('/nueva-transferencia');
    }

	return (
        <>
            <div className="cambio-divisas">
                {cargando ? <DivLoading /> : <>
                <div className={(!reverseCoin ? 'cambioDivisas' : 'cambioReverse')+' '+ (splitLocation[1] === "nueva-transferencia" ? 'marginZero' : '' )}>
                    <div className="DivisaDiv">
                        <div className={!valor ? 'input-divisa' : 'input-divisaDi'}>
                            <NumberFormat value={toAmount} thousandSeparator={'.'}  decimalScale={'2'} decimalSeparator={','} prefix={'$'} onClick={cambiarInputCop}
                                suffix={' COP'} placeholder={"$0 COP"} onValueChange={handleToAmountChange} />
                            {labelStar && <label>{!reverseCoin ? 'De' : 'A'}</label> }
                            {spanAlertTo && <span className="spanAlert">{alertTo}</span>}
                            <img src={colombia} alt="col" className="CountryImg"/>
                        </div>
                    </div>
                        
                    {splitLocation[1] === "nueva-transferencia" ? 
                    <div className="divisonWidth"></div>
                    :
                        <div className="reverseButton">
                            <button onClick={ChangeConverter}><img src={ ImgChange} alt="Change" /></button>
                        </div> 
                    }
                    
                    <div className="DivisaDiv">
                        <div className={!valor ? 'input-divisaDi' : 'input-divisa'}>
                            <NumberFormat value={fromAmount} thousandSeparator={','} prefix={'$'}  decimalSeparator={'.'} onClick={cambiarInputAud}
                                suffix={' AUD'} decimalScale={'2'} placeholder={'$0 AUD'} onValueChange={handleFromAmountChange} />
                            {labelStar && <label>{!reverseCoin ? 'A' : 'De'}</label> }
                            {spanAlertFrom && <span className="spanAlert">{alertFrom}</span>}
                            <img src={australia} alt="aud" className="CountryImg" />
                        </div>
                    </div> 
                </div>
                {splitLocation[1] === "nueva-transferencia" ? <></> :
                    <div className="DivsaCalc">
                        <button className="boton-general BtnLarge imgButton-left" onClick={handleCalcular} disabled={calcular} id={calcular ? 'BtnGray': ''}>
                            <img src={ImgCalcular} alt="" />Calcular Transferencia</button>
                    </div>
                }
                </>}
            </div>

            {splitLocation[1] === "nueva-transferencia" ? <></> :<>
                {calcular && <>
                    <div className="row p-0">
                        <div className="col-12 col-sm-6 col-lg-2">
                            <div className="input-disabled">
                                <NumberFormat value={tasadeCambio} thousandSeparator={'.'} disabled={true}
                                decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'} />
                                <label>Tasa de Cambio</label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            <div className="input-fix">
                                <DobleSelect TipoOpcion={'Costos del Servicio'}
                                opciones1={tiposFees} opcion1={service} cambiarOpcion1={setService}
                                opciones2={comisiones} opcion2={comision} cambiarOpcion2={setComision} />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <div className="input-disabled">
                                <NumberFormat value={pagas} thousandSeparator={'.'} disabled={true}
                                decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'} />
                                <label>Pagas</label>
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-6 col-lg-3">
                            <div className={spanAlertReciben ? "input-disabled inpDisNegative" : "input-disabled"}>
                                <NumberFormat value={reciben} thousandSeparator={','} decimalSeparator={'.'} 
                                prefix={'$'} suffix={' AUD'} decimalScale={'2'} disabled={true}/>
                                <label>Reciben</label>
                                {spanAlertReciben && <span className="spanAlert">{alertReciben}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="noticeCovert">
                        <p>El costo del servicio ya sea Tradicional o UltraRápido, puedes incluirlo o adicionarlo en el valor que vas a pagar.</p>
                    </div>
                    <div className="noticeCovert warning1">
                        <p>Las transferencias procesadas los fines de semana o festivos se reflejan al día hábil siguiente en Australia.</p>
                    </div>
                    <button className="boton-general BtnLarge imgButton-left" onClick={setDatos}><img src={ImgTransfer} alt="" />Transferir</button>
                </>}
            </>}   
        </>
	);
}

export default Convertir;