import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';

const useObtenerAllTransfer = (filtro) => {

    const {usuario} = useAuth();
    const [transferencias, setTransferencias] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        const unsuscribe = db.collection('transferencias')
        .where('usuario', '==', usuario.uid)
        .where('estado', 'in', filtro)
        .orderBy('fecha','desc')
        .onSnapshot((snapshot) => {
            setLoading(false);
            setTransferencias(snapshot.docs.map((transferencia) => {
                return {...transferencia.data(), id: transferencia.id}
            }));
        });

        return unsuscribe;
    }, [usuario, filtro]);
    
    return [transferencias, loading];
}

export default useObtenerAllTransfer;