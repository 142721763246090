import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { firebase } from '../../firebase/firebaseConfig';
import { getUnixTime } from 'date-fns';
import { useHistory } from 'react-router';
import { AgregarRegistro } from '../../firebase/AgregarRegistro';
import SelectorSelec from '../components/inputs/Selector';
import FileDocument from '../components/files/FileDocument';
import InputPhone from '../components/inputs/InputPhone';
import AdrresAu from '../components/inputs/AddressAu';
import Footer from '../components/footer/Footer';
import RegistroComleto from './RegistroCompleto';
import '../../styles/Procesos.css';
import Salir from '../../img/icons/log-out.svg';
import ImgDestinatario from '../../img/icons/user.svg';
import ImgInfo from '../../img/icons/call.svg';
import ImgNext from '../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../img/icons/arrow-left-1.svg';
import Check from '../../img/icons/tick-square.svg';
import Face from '../../img/icons/facebook.svg';
import Insta from '../../img/icons/instagram.svg';
import LoadingPage from '../components/loading/LoadingPage';

const TerminarRegistro = () => {

    const history = useHistory();
    const [registrado, setRegistrado] = useState(true);
    const [progress, setProgress] = useState('');
    const [registroCompleto, setRegistroCompleto] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [download, setDownload] = useState('');

    const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}

    const fecha = new Date();
    const tiposdocs = [{ id: '1', texto: 'Cédula de Ciudadanía' },{ id: '2', texto: 'Pasaporte' }]

    //INFORMACION DE USUARIO
    const { usuario, cerrarSesion, infoUser, setInfoUser } = useAuth();
    const uidUsuario = usuario.uid
    const email = usuario.email

    useEffect(()=>{
        if(infoUser){
            history.push('/dashboard')
        }else{
            const timer1 = setTimeout(()=>{
                setRegistrado(false); 
            }, 1000);
            return () => {clearTimeout(timer1);};
        }
    },[infoUser, history])

    const paises = [{ id: '1', texto: 'Colombia' }, { id: '2', texto: 'Australia' }]

    //DATOS PERSONALES
    const [nombre, setNombre] = useState('');
    const [sdoNombre, setSdoNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [sdoApellido, setSdoApellido] = useState('');
    const [documento, setDocumento] = useState('');
    const [tipoDeDoc, setTipoDeDoc] = useState('');
    const [typeDoc, setTypeDoc] = useState(false);
    const [numeroDoc, setNumeroDoc] = useState('');
    const [fechaExp, setFechaExp] = useState('');
    const [fechaNac, setFechaNac] = useState('');
    const [pep, setPep] = useState('');
    
    //INFORMACION DE CONTACTO
    const [code, setCode ] = useState('+57');
    const [celular, setCelular] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [pais, setPais] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [direccion, setDireccion] = useState('');

    //PASO A PASO
    const [paso1, setPaso1] = useState(true);
    const [paso2, setPaso2] = useState(false);
    const [comp1, setComp1] = useState('formProgress-current');
    const [comp2, setComp2] = useState();

    //VALIDACIONES
    const [altNombre, setAltNombre] = useState('');
    const [altApellido, setAltApellido] = useState('');
    const [altTipDoc, setAltTipDoc] = useState('');
    const [altNumDoc, setAltNumDoc] = useState('');
    const [altFecNac, setAltFecNac] = useState('');
    const [altFecExp, setAltFecExp] = useState('');
    const [altArchivo, setAltArchivo] = useState('');
    const [altCelular, setAltCelular] = useState('');
    const [altPais, setAltPais] = useState('');
    const [altCiudad, setAltCiudad] = useState('');
    const [altDir, setAltDir] = useState('');
    const [altPep, setAltPep] = useState('');

    //SPANS
    const [spnNombre, setSpnNombre] = useState(false);
    const [spnApellido, setSpnApellido] = useState(false);
    const [spnTipoDoc, setSpnTipoDoc] = useState(false);
    const [spnNumDoc, setSpnNumDoc] = useState(false);
    const [spnFecNac, setSpnFecNac] = useState(false);
    const [spnFecExp, setSpnFecExp] = useState(false);
    const [spnArchivo, setSpnArchivo] = useState(false);
    const [spnCelular, setSpnCelular] = useState(false);
    const [spnPais, setSpnPais] = useState(false);
    const [spnCiudad, setSpnCiudad] = useState(false);
    const [spnDir, setSpnDir] = useState(false);
    const [spnPep, setSpnPep] = useState(false);

    //FUNCIONES
    const handleChange = (e) => {
        switch (e.target.name) {
            case 'nombre': setNombre(e.target.value); setSpnNombre(false); break;
            case 'nombre2': setSdoNombre(e.target.value); break;
            case 'apellido': setApellido(e.target.value); setSpnApellido(false); break;
            case 'apellido2': setSdoApellido(e.target.value); break;
            case 'documento': setNumeroDoc(e.target.value); setSpnNumDoc(false); break;
            case 'fechaNacimiento': setFechaNac(e.target.value); setSpnFecNac(false); break;
            case 'fechaExpedicion': setFechaExp(e.target.value); setSpnFecExp(false); break;

            case 'celular': setCelular(e.target.value); setSpnCelular(false); break;
            case 'facebook' : setFacebook(e.target.value); break;
            case 'instagram' : setInstagram(e.target.value); break;
            case 'ciudad': setCiudad(e.target.value); setSpnCiudad(false); break;
            case 'direccion': setDireccion(e.target.value); setSpnDir(false); break;
            case 'pep': setPep(e.target.value); setSpnPep(false); break;

            default: break;
        }
    }
 
    //VERIFICACIÓN DE SELECTS Y FILES
    useEffect(() => {if(tipoDeDoc==='Pasaporte'){setTypeDoc(true)}else{setTypeDoc(false)};}, [tipoDeDoc]);
    useEffect(() => {setSpnTipoDoc(false);}, [tipoDeDoc]);
    useEffect(() => {setSpnArchivo(false);}, [documento]);
    useEffect(() => {setSpnPais(false);}, [pais]);
    useEffect(() => {setSpnDir(false);}, [direccion]);

    //PASO A PASO
    const PasoUno = (e) => {
        e.preventDefault();
        if(nombre === ''){setSpnNombre(true); setAltNombre('Por favor ingresa tu nombre'); return;}
        if(apellido === ''){setSpnApellido(true); setAltApellido('Por favor ingresa tu apellido'); return;}
        if(tipoDeDoc === ''){setSpnTipoDoc(true); setAltTipDoc('Por favor selecciona'); return;}
        if(numeroDoc === ''){setSpnNumDoc(true); setAltNumDoc('Por favor ingresa tu número de documento'); return;}
        if(fechaExp === ''){setSpnFecExp(true); setAltFecExp('Por favor ingresa la fecha de expedición'); return;}
        if(fechaNac === ''){setSpnFecNac(true); setAltFecNac('Por favor ingresa tu fecha de nacimiento'); return;}
        if(pep === ''){setSpnPep(true); setAltPep('Por favor selecciona'); return;}

        if(!documento) {setSpnArchivo(true); setAltArchivo('Por favor adjunta el archivo'); return;} 
        if(!documento[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){setSpnArchivo(true); setAltArchivo('Archivo no válido, solo Imagen o PDF'); return;}
        const size = Math.round((documento[0].size / 1024));
        if(size >= 1024) {setSpnArchivo(true); setAltArchivo('El tamaño máximo del archivo es de 1mb'); return;}
        else{
            toUp();setPaso1(false); setComp1('formProgress-active'); setPaso2(true);  setComp2('formProgress-current')
        }
    }

    const BackPasoUno = (e) => {e.preventDefault(); toUp(); setPaso1(true); setComp1('formProgress-current'); setPaso2(false); setComp2('')}
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(nombre === ''||apellido === ''|| tipoDeDoc === ''||numeroDoc === ''||fechaExp === ''||fechaNac === ''||!documento ){
            setPaso1(true); setComp1('formProgress-current'); setPaso2(false); setComp2('') 
        }
        if(pais === ''){setSpnPais(true); setAltPais('Por favor selecciona'); return;}
        if(pais === 'Colombia'){if(ciudad === ''){setSpnCiudad(true); setAltCiudad('Por favor ingresa tu ciudad'); return;}}
        if(direccion === ''){setSpnDir(true); setAltDir('Por favor ingresa tu dirección'); return;}
        if(code ===''){setSpnCelular(true); setAltCelular('Por favor ingresa el código'); return;}
        if(celular === ''){setSpnCelular(true); setAltCelular('Por favor ingresa tu celular'); return;}
        if(code ==='+57'){if(celular.length !== 10){setSpnCelular(true); setAltCelular('Debe ser de 10 digitos'); return;}}
        if(code ==='+61'){if(celular.length !== 9){setSpnCelular(true); setAltCelular('Debe ser de 9 digitos'); return;}}

        toUp();
        setCargando(true);
        const docID = documento[0];
        const nombreArchivo = usuario.uid+'.'+documento[0].name.split('.').pop();
        const StorageRef = firebase.storage().ref(usuario.uid + '/' + nombreArchivo);
        const uploadTask = StorageRef.put(docID);

        uploadTask.on("state_changed", snapshot =>{
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            }, error => {
                setCargando(false);
            },
            async () => {
                await uploadTask.snapshot.ref.getDownloadURL()
                    .then(function(downloadURL) {
                        setDownload(downloadURL);
                        AgregarRegistro({
                            fechaRegistro: getUnixTime(fecha),
                            usuario: uidUsuario,
                            pep: pep,
                            rol: 'Usuario',
                            toSelf: false,
                            destino: false,
                            contactos: 0,
                            fechaNacimiento: fechaNac,
                            email: email,
                            celular: code+' '+celular,
                            numero: 0,
                            nombre: {
                                nombre: nombre,
                                segNombre: sdoNombre,
                                apellido: apellido,
                                segApellido: sdoApellido,
                            },
                            documento:{
                                urlDocumento: downloadURL,
                                tipoDocumento: tipoDeDoc,
                                numeroDocumento: numeroDoc,
                                fechaExpedicion: fechaExp,
                            },
                            redes: {
                                facebook: facebook,
                                instagram: instagram,
                            },
                            direccion: {
                                pais: pais,
                                ciudad: ciudad,
                                direccion: direccion,
                            },
                            lastSign: getUnixTime(fecha)
                        }).then(async() => {
                            await axios.post('https://server-colcambios.herokuapp.com/api/welcome', {nombre, email})
                            setRegistroCompleto(true);
                            setCargando(false);
                        }).catch((error) => {
                            setCargando(false);
                        })
                    });
               
            }
        )
    }

    const UpdateContext = () =>{
        setInfoUser(
            {
                fechaRegistro: getUnixTime(fecha),
                usuario: uidUsuario,
                rol: 'Usuario',
                pep: pep,
                toSelf: false,
                destino: false,
                contactos: 0,
                fechaNacimiento: fechaNac,
                email: email,
                celular: code+' '+celular,
                nombre: {
                    nombre: nombre,
                    segNombre: sdoNombre,
                    apellido: apellido,
                    segApellido: sdoApellido,
                },
                documento:{
                    urlDocumento: download,
                    tipoDocumento: tipoDeDoc,
                    numeroDocumento: numeroDoc,
                    fechaExpedicion: fechaExp,
                },
                redes: {
                    facebook: facebook,
                    instagram: instagram,
                },
                direccion: {
                    pais: pais,
                    ciudad: ciudad,
                    direccion: direccion,
                },
                lastSign: getUnixTime(fecha)
            }
        )
    }

    //MODALES
    const [modal, cambiarModal] = useState('modal-general');
    const [clases, cambiarClases] = useState('fonder');

    const openModal = (e) =>{
        e.preventDefault();
        setTimeout(() => {
            cambiarModal('modal-general modal-activo')
            cambiarClases('fonder fonder-activo')
        }, 200);
    }

    const CerrarModal = (e) =>{
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
    }

    const CerrarModalNone = (e) =>{
        e.preventDefault();
        cambiarClases('fonder');
        cambiarModal('modal-general');
        setDireccion('');
    }

    return (
        <>{!registrado ? <>
            <div className="divContainer">
                <div className="divSubContainer">
                    
                    <form onSubmit={handleSubmit} className="formData">
                        <div className="formProgress">
                            {registroCompleto ? <h3>{nombre}, <span>te damos la Bienvenida<br/>a Colcambios Australia</span></h3>
                            :   <ul>
                                    <li className={comp1}><span><img src={ImgDestinatario} alt="Datos" /></span><label>Paso 1</label>Datos</li>
                                    <li className={comp2}><span><img src={ImgInfo} alt="Contacto" /></span><label>Paso 2</label>Contacto</li>
                                </ul>}
                        </div>

                        <div className="formContent">
                            {!cargando ? <> {registroCompleto ? 
                                <> <RegistroComleto UpdateContext={UpdateContext}/></> 
                                : <> 
                                    <div className="formTitle">
                                        <h1 className="text-title">Completa tu Registro</h1>
                                        <p>Gracias por considerarnos como su opción de transferencias <b>COP/AUD.</b> Para brindarte el mejor servicio, 
                                        complete el formulario de registro. <b>Los campos marcados con * son obligatorios</b>, recuerda que esta información debe ser precisa para
                                        no tener contratiempos en las transferencias.</p>
                                    </div>
                                    <div className={paso1 ? 'step step1 habil' : 'step step1'}>
                                        <div className="row p-0">
                                            <div className="col-12 col-sm-6"><div className={!spnNombre ? 'input-general': 'input-general redLine'}>
                                                <input name="nombre" type="text" placeholder=" " value={nombre} onChange={handleChange} autoComplete="off"/>
                                                <label>Primer Nombre *</label>
                                                {spnNombre && <span className="spanAlert">{altNombre}</span>}
                                            </div></div>
                                            <div className="col-12 col-sm-6"><div className="input-general">
                                                <input name="nombre2" type="text" placeholder=" " value={sdoNombre} onChange={handleChange} autoComplete="off" />
                                                <label>Segundo Nombre</label>
                                            </div></div>
                                        </div>
                                        <div className="row p-0">
                                            <div className="col-12 col-sm-6"><div className={!spnApellido ? 'input-general': 'input-general redLine'}>
                                                <input name="apellido" type="text" placeholder=" " value={apellido} onChange={handleChange} autoComplete="off"/>
                                                <label>Primer Apellido *</label>
                                                {spnApellido && <span className="spanAlert">{altApellido}</span>}
                                            </div></div>
                                            <div className="col-12 col-sm-6"> <div className="input-general">
                                                <input name="apellido2" type="text" placeholder=" " value={sdoApellido} onChange={handleChange} autoComplete="off" />
                                                <label>Segundo Apellido</label>
                                            </div></div>
                                        </div>
                                        <div className="row p-0">
                                            <div className="col-12 col-sm-6"><div className="input-select">
                                                <SelectorSelec opciones={tiposdocs} TipoOpcion={'Tipo de Documento *'} opcion={tipoDeDoc} 
                                                    cambiarOpcion={setTipoDeDoc} spnRed={spnTipoDoc} setSpnRed={setSpnTipoDoc} />
                                                {spnTipoDoc && <span className="spanAlert">{altTipDoc}</span>}
                                            </div></div>
                                            <div className="col-12 col-sm-6"><div className={!spnNumDoc ? 'input-general': 'input-general redLine'}>
                                                <input name="documento" type={!typeDoc ? "number" : "text"} placeholder=" " value={numeroDoc} onChange={handleChange} autoComplete="off"/>
                                                <label>Número de Documento *</label>
                                                {spnNumDoc && <span className="spanAlert">{altNumDoc}</span>}
                                            </div></div>
                                        </div>
                                        <div className="row p-0">
                                            <div className="col-12 col-sm-6"><div className={!spnFecExp ? 'input-date': 'input-general redLine'}>
                                                <input className={(fechaExp!=='' ? 'BorderBlue' : '')} name="fechaExpedicion" type="date" placeholder=" " 
                                                    value={fechaExp} onChange={handleChange} autoComplete="off" />
                                                <label className={fechaExp!=='' ? 'ligthBlue' : ''}>Fecha de Expedición *</label>
                                                {spnFecExp && <span className="spanAlert">{altFecExp}</span>}
                                            </div></div>
                                            <div className="col-12 col-sm-6"><div className={!spnFecNac ? 'input-date': 'input-general redLine'}>
                                                <input className={(fechaNac!=='' ? 'BorderBlue' : '')} name="fechaNacimiento" type="date" placeholder=" " 
                                                    value={fechaNac} onChange={handleChange} autoComplete="off" />
                                                <label className={fechaNac!=='' ? 'ligthBlue' : ''}>Fecha de Nacimiento *</label>
                                                {spnFecNac && <span className="spanAlert">{altFecNac}</span>}
                                            </div></div>
                                        </div>
                                        
                                        <div className={spnPep ? 'divPepWarn': 'divPep'}>
                                            <p>¿Se considera una persona expuesta políticamente (PEP)? </p>
                                            <div className='checkboxContent'>
                                                <div  className="checkbox">
                                                    <input type="radio" name="pep" value="Sí" onChange={handleChange} />
                                                    <label></label>
                                                    <div className="checkboxlabel checkboxBtm">Sí</div>
                                                </div>
                                                <div  className="checkbox">
                                                    <input type="radio" name="pep" value="No" onChange={handleChange} /> 
                                                    <label></label>
                                                    <div className="checkboxlabel checkboxBtm">No</div>
                                                </div>
                                                
                                            </div>
                                            {spnPep && <span className="spanAlert">{altPep}</span>}
                                        </div>
                                        <FileDocument archivosSelec={setDocumento} spnAltArchivo={spnArchivo} altArchivo={altArchivo}/>
                                    </div>
                                    <div className={paso2 ? 'step step2 habil' : 'step step2'}>
                                        <div className="row p-0">
                                            <div className="col-12 col-md-6"><div className="input-select">
                                                <SelectorSelec opciones={paises} TipoOpcion={'País *'} opcion={pais} 
                                                    cambiarOpcion={setPais} spnRed={spnPais} setSpnRed={setSpnPais} />
                                                {spnPais && <span className="spanAlert">{altPais}</span>}
                                            </div></div>
                                            {pais && <>
                                                {pais === 'Colombia' &&
                                                    <div className="col-12 col-md-6"><div className={!spnCiudad ? 'input-general': 'input-general redLine'}>
                                                        <input name="ciudad" type="text" placeholder=" " value={ciudad} onChange={handleChange} autoComplete="off" />
                                                        <label>Ciudad*</label>
                                                        {spnCiudad && <span className="spanAlert">{altCiudad}</span>}
                                                    </div></div>
                                            }</>}
                                        </div>
                                        {pais && <>
                                            {pais === 'Colombia' ?
                                            <div className={!spnDir ? 'input-general': 'input-general redLine'}>
                                                <input name="direccion" type="text" placeholder=" " value={direccion} onChange={handleChange} autoComplete="off" />
                                                <label>Dirección*</label>
                                                {spnDir && <span className="spanAlert">{altDir}</span>}
                                            </div> 
                                            : 
                                            <div className={!spnDir ? 'input-general': 'input-general redLine'} onClick={openModal}>
                                                <input type="text" name="addressAu" placeholder=" " value={direccion} autoComplete="off" readOnly/>
                                                <label>Dirección*</label>
                                                {spnDir && <span className="spanAlert">{altDir}</span>}
                                            </div>}
                                        </> }

                                        <div className="row p-0">
                                            <div className="col-12 col-md-6">
                                                <InputPhone code={code} setCode={setCode} phone={celular} setCelular={setCelular} 
                                                spnSpan={spnCelular} setSpan={setSpnCelular} alert={altCelular} pais={pais} />
                                            </div>
                                            <div className="col-12 col-md-6"><div className="input-disabled">
                                                <input type="email" placeholder=" " value={email} disabled />
                                                <label>Email</label>
                                            </div></div>
                                        </div>
                                        <div className="row p-0">
                                            <div className="col-12">
                                                <p className="titleSpecial">Redes sociales <span>*opcionales</span></p>
                                            </div>
                                            <div className="col-12 col-md-6"><div className="input-general">
                                                <input name="instagram" type="text" placeholder=" " value={instagram} onChange={handleChange} autoComplete="off" />
                                                <label>Instagram</label>
                                                <img className="input-img" src={Insta} alt="Instagram" />
                                            </div></div>
                                            <div className="col-12 col-md-6"><div className="input-general">
                                                <input name="facebook" type="text" placeholder=" " value={facebook} onChange={handleChange} autoComplete="off" />
                                                <label>Facebook</label>
                                                <img className="input-img" src={Face} alt="Facebook" />
                                            </div></div>
                                        </div>
                                    </div>
                                    {paso1 && 
                                        <div className="formButtons BtnsRigth">
                                            <button type="submit" onClick={PasoUno} className="boton-general imgButton-rigth">Siguiente<img src={ImgNext} alt="Siguiente" /></button>
                                        </div>
                                        }
                                    {paso2 && 
                                        <div className="formButtons BtnsSpace">
                                            <button className="boton-general imgButton-left" onClick={BackPasoUno} type="button"><img src={ImgPrev} alt="Regresar" />Regresar</button>
                                            <button type="submit" onClick={handleSubmit} className="boton-general imgButton-rigth">Finalizar Registro<img src={Check} alt="Finalizar"/></button>
                                        </div>
                                    }
                                </>
                            }
                            </> : 
                                <>
                                    <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
                                    <div style={{display: "none"}}><p>{progress}</p></div>
                                </>
                            }
                        </div>
                    </form>
                    {!cargando && <> {!registroCompleto &&
                        <div className="divSalir">
                            <p>¿Deseas completar tu registro después?</p>
                            <button type="button" onClick={cerrarSesion} className="boton-text imgButton-rigth">Salir<img src={Salir} alt="Salir" /></button>  
                        </div>
                    }</>}
                </div> 
            </div>
            <Footer />
            <div className={modal}><AdrresAu setDireccion={setDireccion} CerrarModal={CerrarModal}/></div>
            <div className={clases} onClick={CerrarModalNone}></div>
        </>: 
            <LoadingPage />
        }</>
    );
}

export default TerminarRegistro;