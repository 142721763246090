import React, {useState} from 'react';
import {firebase} from '../../../firebase/firebaseConfig';
import {ContrasenaActualizada, ContrasenaNoActualizada} from '../../../views/components/modales/Correos';

const CambioPass = ({OpenModalNuevo,CerrarModal}) =>{

    const [claveActual, setClaveActual] = useState('');
    const [nuevaClave, setNuevaClave] = useState('');
    const [nuevaClaveC, setNuevaClaveC] = useState('');

    //VERIFICACIONES
    const [spnClaveActual, setSpnClaveActual] = useState(false);
    const [spnClave, setSpnClave] = useState(false);
    const [spnClaveC, setSpnClaveC] = useState(false);
    const [altClaveActual, setAltClaveActual] = useState('');
    const [altClave, setAltClave] = useState('');
    const [altClaveC, setAltClaveC] = useState('');

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'claveActual' : setClaveActual(e.target.value); setSpnClaveActual(false); break;
            case 'nuevaClave' : setNuevaClave(e.target.value); setSpnClave(false); break;
            case 'nuevaClaveC' : setNuevaClaveC(e.target.value); setSpnClaveC(false); break;
            default: break;
        }
    }

    const reauthenticate = (currentPassword) => {
        const user = firebase.auth().currentUser;
        const cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
    }
    
    const handleSubmit = (e) =>{
        e.preventDefault();
        if(claveActual === ''){setSpnClaveActual(true); setAltClaveActual('Ingresa la clave actual'); return;}
        if(nuevaClave === ''){setSpnClave(true); setAltClave('Ingresa la nueva contraseña'); return;}
        if(nuevaClave.length < 6){setSpnClave(true); setAltClave('Tiene que ser de al menos 6 caracteres'); return;}
        if(nuevaClaveC === ''){setSpnClaveC(true); setAltClaveC('Repitre la nueva contraseña'); return;}
        if(!nuevaClave === nuevaClaveC){setSpnClaveC(true); setAltClaveC('Las contraseñas no coinciden'); return;}
        
        reauthenticate(claveActual).then(() => {
            const user = firebase.auth().currentUser;
            user.updatePassword(nuevaClave)
            .then(() => {
                OpenModalNuevo(<ContrasenaActualizada CerrarModal={CerrarModal}/>)
            }).catch(() => {
                OpenModalNuevo(<ContrasenaNoActualizada CerrarModal={CerrarModal}/>)
             })
        }).catch(() => {setSpnClaveActual(true); setAltClaveActual('La contraseña actual es incorrecta')} );
    }
    
    return(
        <div className="InnerModal">
            <form className="row p-0" onSubmit={handleSubmit}>
                <div className="col-12">
                    <div className={!spnClaveActual ? 'input-general': 'input-general redLine'}>
                        <input type="password" name="claveActual" value={claveActual} onChange={handleChange} placeholder=" " />
                        <label>Contraseña Actual</label>
                        {spnClaveActual && <span className="spanAlert">{altClaveActual}</span>}
                    </div>
                </div>
                <div className="col-12">
                    <div className={!spnClave ? 'input-general': 'input-general redLine'}>
                        <input type="password" name="nuevaClave" value={nuevaClave} onChange={handleChange} placeholder=" " />
                        <label>Nueva Contraseña</label>
                        {spnClave && <span className="spanAlert">{altClave}</span>}
                    </div>
                </div>
                <div className="col-12">
                    <div className={!spnClaveC ? 'input-general': 'input-general redLine'}>
                        <input type="password" name="nuevaClaveC" value={nuevaClaveC} onChange={handleChange} placeholder=" " />
                        <label>Repite la nueva Contraseña</label>
                        {spnClaveC && <span className="spanAlert">{altClaveC}</span>}
                    </div>
                </div>
                <div className="col-12">
                    <button className="boton-general BtnLarge">Cambiar Contraseña</button>
                </div>
            </form>
            <button className="btn-text" onClick={CerrarModal} type='button'>Cancelar</button>
        </div>
    )
}

export default CambioPass;