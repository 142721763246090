import React, {useState} from 'react';
import { useAuth } from '../../../context/AuthContext';
import {firebase} from '../../../firebase/firebaseConfig';
import { AlgoSalioMal } from '../../../views/components/modales/Correos';
// import { DeleteUser } from '../../data/DeleteUser';

const DeleteAccount = ({CerrarModal, OpenModalNuevo}) =>{

    const {cerrarSesion} = useAuth();

    const [claveActual, setClaveActual] = useState('');
    const [spnClaveActual, setSpnClaveActual] = useState(false);
    const [altClaveActual, setAltClaveActual] = useState('');
    const [btnEliminando, setEliminando] = useState('');

    const handleChange = (e) => {
        setClaveActual(e.target.value);
        setSpnClaveActual(false);
    }

    const reauthenticate = (currentPassword) => {
        const user = firebase.auth().currentUser;
        const cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
    }

    const Eliminarcuenta = (e) =>{
        e.preventDefault();
        if(claveActual === ''){setSpnClaveActual(true); setAltClaveActual('Ingresa la clave actual'); return;}
        setEliminando(true);
        reauthenticate(claveActual).then(async() => {
            const user = firebase.auth().currentUser;
            //  DeleteUser({user});
            await user.delete().then(function() {
                cerrarSesion();
              }).catch(()=> {
                OpenModalNuevo(<AlgoSalioMal CerrarModal={CerrarModal}/>)
                setEliminando(false);
              });
        }).catch(() => {setSpnClaveActual(true); setAltClaveActual('La contraseña actual es incorrecta')} );
    }

    return(
        <div className="InnerModal">
            <form onSubmit={Eliminarcuenta}>
                <div className={!spnClaveActual ? 'input-general': 'input-general redLine'}>
                    <input type="password" name="claveActual" value={claveActual} onChange={handleChange} placeholder=" " />
                    <label>Contraseña Actual</label>
                    {spnClaveActual && <span className="spanAlert">{altClaveActual}</span>}
                </div>
                <button type='submit' className="boton-general BtnLarge" disabled={btnEliminando}>{btnEliminando ? 'Eliminando' : 'Eliminar'}</button>
                <button type='button' className="btn-text" onClick={CerrarModal}>Cancelar</button>
            </form>
        </div>
    );
}

export default DeleteAccount;