import React, {useState, useEffect} from 'react';
import { useObtenerCodigo } from '../../../hooks/useObternerDatos';
import Check from '../../../../img/icons/tick-square.svg';

const BuscarReferido= ({setReferido}) =>{

    const [codigoId, setCodigoId] = useState('');
    const [code, setcode] = useState('');
    const [codigo, find, setFind] = useObtenerCodigo(code);
    //ALERTAS
    const [altCodigo, setAltCodigo] = useState('');
    const [altCodigo1, setAltCodigo1] = useState('');
    const [warn, setWarn] = useState(false);
    const [spnCodigo, setSpnCodigo] = useState(false);
    const [spnCodigo1, setSpnCodigo1] = useState(false);
    const [checkCode, setCheckCode] = useState(false);
    //FORMATOS
    const idTransform = (nombre) =>{ const id = nombre.replace(/\s+/g, '').toLocaleLowerCase(); return id;}

    const handleCodigo = (e) =>{
        setCodigoId(e.target.value);
        setcode('');
        setWarn(false)
        setCheckCode(false);
        setSpnCodigo1(false); 
        setSpnCodigo(false);
        setFind('inicial');
        setReferido('');
    }

    useEffect(()=>{
        if(codigo){setReferido(codigo.id);setSpnCodigo(false); setCheckCode(true);}
        else{setReferido('');}
    },[codigo, setReferido])

    useEffect(()=>{
        if(find === 'inicial'){setSpnCodigo(false);setAltCodigo('')}
        if(find === 'noEncontrado'){setSpnCodigo(true);setWarn(true);setAltCodigo('Código no válido')}
        if(find === 'noExiste'){setSpnCodigo(true);setWarn(true);setAltCodigo('Código no válido')}
    },[find])

    const buscarCodigo = (e) =>{
        e.preventDefault();
        if(!codigoId){setSpnCodigo1(true);setWarn(true);setAltCodigo1('Ingresa el código');return}
        else{setcode(idTransform(codigoId));}
    }

    const eliminarCodigo = (e) =>{
        e.preventDefault();
        setCodigoId('');
        setcode('');
        setFind('inicial');
        setCheckCode(false);
        setReferido('');
    }

    return(
        <div className="DivReferido">
            <div><p>¿Tienes un código de <b>Referido</b>? Ingrésalo Aquí</p></div>
            <div className='row col-12 p-0'>
                <div className="inputButtonDiv">
                    <div className={'inputButton '+ (!warn ? '': 'redLine')}>
                        <input type="text" name="codigo" value={codigoId} placeholder=" " onChange={handleCodigo} autoComplete="off"/>
                        <label>Código Referido</label>
                        {checkCode && <div className='checkCode'><img src={Check} alt="" /></div>}
                        {spnCodigo1 && <span className="spnAlert">{altCodigo1}</span>}
                        {spnCodigo && <span className="spnAlert">{altCodigo}</span>}
                    </div>
                    <button onClick={!checkCode ? buscarCodigo : eliminarCodigo } type="button">{!checkCode ? 'Validar':'Remover'}</button>
                </div>
            </div>
        </div>
    )

}

export default BuscarReferido;