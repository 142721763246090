import React, {useState, useEffect} from "react";
import SelectAndInput from '../../../../views/components/inputs/SelectAndInput';
import SelectorSelec from '../../../../views/components/inputs/Selector';
import ModalValidar from "../../../../views/components/modales/MoldaValidar";
import DivLoading from "../../../../views/components/loading/Loading";
import { useObtenerCuenta } from '../../../hooks/useObtenerCuentas';
import { EditarCuentas, AgregarCuentas }  from '../../../data/AdminCuentas';
import { CuentaEditada, HuboUnError, CuentaCreada } from "../../Mensajes";
import ImgPlus from '../../../../img/icons/plus.svg';
import ImgCheck from '../../../../img/icons/check.svg';

const EditarCuenta = ({OpenModalNuevo, CerrarModal, id}) =>{

    const [cuenta] = useObtenerCuenta(id);
    const [edit, setEdit] = useState(false);

    //INFO CUENTA
    const [nombreBanco, setNombreBanco] = useState('');
    const [tipoCuenta, setTipoCuenta] = useState('');
    const [numeroCuenta, setNumeroCuenta] = useState('');
    const [titularCuenta, setTitularCuenta] = useState('');
    const [tipoId, setTipoId] = useState('CC');
    const [idTitular, setIdTitular] = useState('');

    //ALERTAS Y VALIDACIONES
    const[altNombreBanco, setAltNombreBanco] = useState('');
    const[altTipoCuenta, setAltTipoCuenta] = useState('');
    const[altNumeroCuenta, setAltNumeroCuenta] = useState('');
    const[altTitularCuenta, setAltTitularCuenta] = useState('');
    const[altIdTitular, setAltIdTitular] = useState('');

    const[spnNombreBanco, setSpnNombreBanco] = useState(false);
    const[spnTipoCuenta, setSpnTipoCuenta] = useState(false);
    const[spnNumeroCuenta, setSpnNumeroCuenta] = useState(false);
    const[spnTitularcuenta, setSpnTitularcuenta] = useState(false);
    const[spnIdTitular, setSpnIdTitular] = useState(false);

    //OPCIONES
    const tiposCuentas = [
        { id: '1', texto: 'Cuenta de Ahorros' },
        { id: '2', texto: 'Cuenta Corriente' },
    ]

    const tiposId = [
        { id: '1', name: 'Nit' },
        { id: '2', name: 'CC' },
    ]

    useEffect(()=>{
        if(cuenta){
            setEdit(true);
            setNombreBanco(cuenta.data().nombreBanco);
            setTipoCuenta(cuenta.data().tipoCuenta);
            setNumeroCuenta(cuenta.data().numeroCuenta);
            setTitularCuenta(cuenta.data().titularCuenta);
            setTipoId(cuenta.data().tipoId);
            setIdTitular(cuenta.data().idTitular);
        }
    },[cuenta])

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'nombreBanco': setNombreBanco(e.target.value); setSpnNombreBanco(false); break;
            case 'numeroCuenta': setNumeroCuenta(e.target.value); setSpnNumeroCuenta(false); break;
            case 'titularCuenta': setTitularCuenta(e.target.value); setSpnTitularcuenta(false); break;
            default: break;
        }
    }

    const handleSubmit = async () =>{
        OpenModalNuevo(<DivLoading />)
        await AgregarCuentas ({
            nombreBanco: nombreBanco,
            tipoCuenta: tipoCuenta,
            numeroCuenta: numeroCuenta,
            titularCuenta: titularCuenta,
            tipoId: tipoId,
            idTitular: idTitular
        }).then(() => {
            OpenModalNuevo(<CuentaCreada CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    const EditarSubmit = async () =>{
        OpenModalNuevo(<DivLoading />)
        await EditarCuentas({
            id: id,
            nombreBanco: nombreBanco,
            tipoCuenta: tipoCuenta,
            numeroCuenta: numeroCuenta,
            titularCuenta: titularCuenta,
            tipoId: tipoId,
            idTitular: idTitular
        }).then(() => {
            OpenModalNuevo(<CuentaEditada CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    const validar = (e) =>{
        e.preventDefault();
        if (!nombreBanco) {setSpnNombreBanco(true); setAltNombreBanco('Ingresa el nombre del Banco'); return;}
        if (!tipoCuenta) {setSpnTipoCuenta(true); setAltTipoCuenta('Selecciona el tipo de Cuenta'); return;}
        if (!numeroCuenta) {setSpnNumeroCuenta(true); setAltNumeroCuenta('Ingresa el número de cuenta'); return;}
        if (!titularCuenta) {setSpnTitularcuenta(true); setAltTitularCuenta('Ingresa el titular de la cuenta'); return;}
        if (!idTitular) {setSpnIdTitular(true); setAltIdTitular('Ingresa la identificación del titular'); return;}
        if(edit){
            OpenModalNuevo(
                <ModalValidar 
                accion={EditarSubmit} 
                titulo={'Crear Cuenta'}
                mensaje={'¿Deseas Agregar esta Cuenta?'}
                botonName={'Sí, Agregar'}
                botonClass={'ButtonGreen'}
                CerrarModal={CerrarModal}
            />)
        }else{
            OpenModalNuevo(
                <ModalValidar 
                accion={handleSubmit} 
                titulo={'Crear Cuenta'}
                mensaje={'¿Deseas Agregar esta Cuenta?'}
                botonName={'Sí, Agregar'}
                botonClass={'ButtonGreen'}
                CerrarModal={CerrarModal}
            />)
        }
    }

    return(
        <>
            <p className="CardTitulo">Agregar Cuenta</p>
            <form onSubmit={validar}>
                <div className="row p-0">
                    <div className="col-12 col-sm-6"><div className={!spnNombreBanco ? 'input-general': 'input-general redLine'}>
                        <input type="text" name="nombreBanco" value={nombreBanco} placeholder=" " onChange={handleChange} />
                        <label>Nombre del Banco</label>
                        {spnNombreBanco && <span className="spanAlert">{altNombreBanco}</span>}
                    </div></div>
                    <div className="col-12 col-sm-6"><div className="input-select">
                        <SelectorSelec opciones={tiposCuentas} TipoOpcion={'Tipo de Cuenta *'} opcion={tipoCuenta} 
                            cambiarOpcion={setTipoCuenta} spnRed={spnTipoCuenta} setSpnRed={setSpnTipoCuenta}/>
                        {spnTipoCuenta && <span className="spanAlert">{altTipoCuenta}</span>}
                    </div></div>
                    <div className="col-12 col-sm-6"><div className={!spnNumeroCuenta ? 'input-general': 'input-general redLine'}>
                        <input type="number" name="numeroCuenta" value={numeroCuenta} placeholder=" " onChange={handleChange} />
                        <label>Número de Cuenta</label>
                        {spnNumeroCuenta && <span className="spanAlert">{altNumeroCuenta}</span>}
                    </div></div>
                    <div className="col-12 col-sm-6"><div className={!spnTitularcuenta ? 'input-general': 'input-general redLine'}>
                        <input type="text" name="titularCuenta" value={titularCuenta} placeholder=" " onChange={handleChange} />
                        <label>Titular de la cuenta</label>
                        {spnTitularcuenta && <span className="spanAlert">{altTitularCuenta}</span>}
                    </div></div>
                    <div className="col-12 col-sm-6">
                        <SelectAndInput options={tiposId} opcion={tipoId} setOpcion={setTipoId} number={idTitular} setNumber={setIdTitular} 
                            fieldName={'Id Titular*'} spnSpan={spnIdTitular} setSpan={setSpnIdTitular} alert={altIdTitular} />
                    </div>
                    <div className="col-12">
                        <button className="boton-general BtnLarge imgButton-left" type="submit">
                            {!edit ? <><img src={ImgPlus} alt="agregar"/>Agregar Cuenta</> :<><img src={ImgCheck} alt="editar"/>Editar Cuenta</>}</button>
                        <button className="btn-text" onClick={CerrarModal} type="button">Cancelar</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default EditarCuenta;