import { db } from '../../firebase/firebaseConfig';

const PublicarTestimonio = async(props) => {
    const{id,estado} = props;
    return await db.collection('testimonios').doc(id).update({
        estado: estado,
    });
}

const EliminarTestimonio = async ({id}) => {
    return await db.collection('testimonios').doc(id).delete()
}

export { EliminarTestimonio, PublicarTestimonio };