import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useGetCodes } from '../../hooks/useObtenerCodigos';
import jsPDF from 'jspdf';
import {AdminCodigo} from './components/AdminCodigo';
import {EliminarCodigo} from './components/EliminarCodigo';
import '../../../styles/tablaCuentas.css';
import ImgPlus from '../../../img/icons/plus.svg';
import ImgDelete from '../../../img/icons/trash.svg';
import ImgEdit from '../../../img/icons/edit.svg';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import ImgPdf from '../../../img/icons/document-download.svg';

const CodigosPromocionales = ({OpenModalNuevo,CerrarModal}) =>{

    const [codigos, loading] = useGetCodes();
    const [data, setData] = useState('');

    const [pages, setPages] = useState('');
    const [dataLimit, setDataLimit] = useState('');
    const [pageLimit, setPageLimit] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationGroup, setPaginationGroup] = useState([]);

    //EXPORTAR A PDF
	const DownloadPDF = async() =>{
		const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'tabloid'})
        doc.autoTable({
            head: [["Código","Estado","Tipo","Descuento","Agencia"]],
            body: data
          })
        doc.save('codigos.pdf');
    }
    
    useEffect(()=>{
		if(codigos){
			const temp = codigos.map((codigo)=>{
				return [
                    codigo.id,
					codigo.estado,
                    codigo.tipo,
                    codigo.descuento,
                    codigo.agenciaId
				]
			});
			setData(temp);
		}
	},[codigos])

    //PAGINACIÓN
	useEffect(()=>{
        if(codigos.length <= 10){
			setDataLimit(codigos.length);
        }else{
			setDataLimit(10);
		}
    },[codigos])

    useEffect(() => {
        setPages(Math.ceil(codigos.length / dataLimit));
        setPageLimit(4);
    },[codigos, dataLimit])

	useEffect(() => {
        if(pageLimit){
            const grupoNumeros = () => {
                let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
                return new Array(pageLimit).fill().map((_, idx) => start + idx + 1 );
            };
            setPaginationGroup(grupoNumeros);
        }
    }, [pageLimit, currentPage]);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage]);

    const goToNextPage = () => {setCurrentPage((page) => page + 1);};
    const goToPreviousPage = () => {setCurrentPage((page) => page - 1);};
      
    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return codigos.slice(startIndex, endIndex);
    };

    const AbrirAdmin = (e) =>{OpenModalNuevo(<AdminCodigo OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} id={e.currentTarget.dataset.id} />)}
    const AbrirBorrar = (e) =>{OpenModalNuevo(<EliminarCodigo OpenModalNuevo={OpenModalNuevo} CerrarModal={CerrarModal} codigo={e.currentTarget.dataset.id} />)}

    return(
        <>
            <Helmet><title>Códigos Promocionales</title></Helmet>
            <div className="TituloPag"><p>Códigos Promocionales</p></div>
            <div className="dashboardCard">
                <p className="CardTitulo">Lista de Códigos</p>
                    <button className="boton-general imgButton-left" onClick={AbrirAdmin}>
                        <img src={ImgPlus} alt="Agregar"/>Crear Código</button>
                    {loading ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
                        : <>
                            {codigos.length > 0 ?
                                <>
                                    <div className="divTableContent">
                                        <div className="divTable-head">
                                            <div className="divTable-headRow" id="tableActions2">
                                                <div>Código</div>
                                                <div>Tipo</div>
                                                <div>Descuento</div>
                                                <div>Estado</div>
                                                <div>Agencia</div>
                                            </div>
                                        </div>
                                            
                                        {getPaginatedData().map((codigo) => {
                                            return(
                                                <div className="divTable-body" key={codigo.id}>
                                                    <div className="divTable-row" id="table5td">
                                                        <div><label>Código</label>{codigo.id}</div>
                                                        <div><label>Tipo</label>{codigo.tipo}</div>
                                                        <div><label>Descuento</label>{codigo.descuento}</div>
                                                        <div><label>Estado</label>{codigo.estado}</div>
                                                        <div><label>Agencia</label>{codigo.agenciaId}</div>
                                                    </div>
                                                    <div className="tableActions">
                                                        <div><button onClick={AbrirAdmin} data-id={codigo.id}><img src={ImgEdit} alt="edit" /></button></div>
                                                        <div><button onClick={AbrirBorrar} data-id={codigo.id}><img src={ImgDelete} alt="delete" /></button></div>
                                                    </div>
                                                </div>
                                            )})
                                        }
                                    </div>
                                    <div className="TableFooter">
                                        <div className="Btns-Export">
                                            <button className="BtnExport BtnPdf" onClick={DownloadPDF}><img src={ImgPdf} alt="" />Exportar a PDF</button>
                                        </div>
                                        <div className="paginacion">
                                            <button onClick={goToPreviousPage} className={`pagPrev ${currentPage === 1 ? 'pagDisabled' : ''}`}><img src={ImgPrev} alt="anterior" /></button>
                                                {paginationGroup ? paginationGroup.map((item, index) => (
                                                    <button key={index} onClick={changePage} className={(item > pages ? 'pagBlock' : 'pagNumber') +' '+ (currentPage === item ? 'pagActive' : '')}>
                                                        <span>{item}</span>
                                                    </button>
                                                )) : <></>}
                                            <button onClick={goToNextPage} className={`pagNext ${currentPage === pages ? 'pagDisabled' : ''}`}><img src={ImgNext} alt="siguiente" /></button>
                                        </div>
                                    </div>
                                </> 
                                :
                                <div className="itemTransferEmpty">
                                    <p>No se encontraron Códigos</p>
                                    <button className="boton-general">Ok</button>
                                </div>
                            }
                        </>}
            </div>    
        </> 
    )
}

export default CodigosPromocionales;