import { db } from '../../firebase/firebaseConfig';

const AgregarTransferencia = (props) => {
    const{
        fecha,
        fechaMaxPago,
        estado,
        usuario,
        destinatarioId,
        razon,
        colcambios,
        autoEnvio,
        transferencia,
        remitente,
        destinatario,
        cuenta,
        referido,
        promocional
    } = props;

    if(promocional.codigo){
        return db.collection('transferencias').add({
            fecha: fecha,
            fechaMaxPago: fechaMaxPago,
            estado: estado,
            usuario: usuario,
            destinatarioId: destinatarioId,
            razon: razon,
            colcambios: colcambios,
            autoEnvio: autoEnvio,
            transferencia: transferencia,
            remitente: remitente,
            destinatario: destinatario,
            cuenta: cuenta,
            referido: referido,
            promocional: promocional
        });
    }else{
        return db.collection('transferencias').add({
            fecha: fecha,
            fechaMaxPago: fechaMaxPago,
            estado: estado,
            usuario: usuario,
            destinatarioId: destinatarioId,
            razon: razon,
            colcambios: colcambios,
            autoEnvio: autoEnvio,
            transferencia: transferencia,
            remitente: remitente,
            destinatario: destinatario,
            cuenta: cuenta,
            referido: referido,
        });
    }
}

const AsignarCodigo = async(props) =>{
    const{
        usuario,
        codigo,
        codEstado,
    } = props

    return db.collection('promociones').doc(usuario).update({
        [codigo]: codEstado 
    });
}

const AsignarNuevo = async(props) =>{
    const{
        usuario,
        codigo,
        codEstado,
    } = props

    return db.collection('promociones').doc(usuario).set({
        [codigo]: codEstado 
    });
}

const AsignarEspecial = async(props) =>{
    const{codigo} = props
    return db.collection('codigos').doc(codigo).update({
        estado: 'Usado' 
    });
}

export {AgregarTransferencia, AsignarCodigo, AsignarEspecial, AsignarNuevo};