import React, {useState, useEffect} from "react";
import InputButton from "../../../../views/components/inputs/InputButton";
import SelectorSelec from "../../../../views/components/inputs/Selector";
import SelectAndInput from '../../../../views/components/inputs/SelectAndInput';
import DivLoading from "../../../../views/components/loading/Loading";
import ModalValidar from "../../../../views/components/modales/MoldaValidar";
import { AddCode, EditCode, AgregarCodigos, EditarCodigos } from "../../../data/AdminCodigos";
import { useSetAgenciasActivas } from "../../../hooks/useAgencias";
import { useObtenerCodigo } from "../../../hooks/useObtenerCodigos";
import { useGetCode } from "../../../hooks/useObtenerCodigos";
import { HuboUnError, CodigoCreado, CodigoEditado } from "../../Mensajes";
import ImgPlus from '../../../../img/icons/plus.svg';
import Check from '../../../../img/icons/tick-square.svg';

const AdminCodigo = ({OpenModalNuevo, CerrarModal, id}) =>{

    const [codigo, setCodigo] = useState('');
    const [tipo, setTipo] = useState('');
    const [agencia, setAgencia] = useState('No');
    const [agenciaId, setAgenciaId] = useState('');
    const [descuento, setDescuento] = useState('');
    const [estado, setEstado] = useState('Activo');
    const [agencias] = useSetAgenciasActivas();
    const [code] = useGetCode(id);
    const [lista, setLista] = useState([])
    const [edit, setEdit] = useState(false)

    const [spnCodigo, setSpnCodigo] = useState(false);
    const [spnTipo, setSpnTipo] = useState(false);
    const [spnDescuento, setSpnDescuento] = useState(false);
    const [spnAgencia, setSpnAgencia] = useState(false);
    const [spnAgenciaId, setSpnAgenciaId] = useState(false);
    const [spnEstado, setSpnEstado] = useState(false);

    const [altCodigo, setAltCodigo] = useState('');
    const [altTipo, setAltTipo] = useState('');
    const [altDescuento, setAltDescuento] = useState('');
    const [altAgenciaId, setAltAgenciaId] = useState('');
    const [altEstado, setAltEstado] = useState('');

    // const idTransform = (nombre) =>{ const id = nombre.replace(/\s+/g, '').toLocaleLowerCase(); return id;}
    
    useEffect(()=>{
        if(agencias){
            const temp = agencias.map((agencia =>({id: agencia.id, texto: agencia.nombre})))
            setLista(temp);
        }
    },[agencias])

    useEffect(()=>{
        if(code){
            setEdit(true)
            setCodigo(code.id);
            setTipo(code.data().tipo);
            setEstado(code.data().estado);
            setDescuento(code.data().descuento);
            if(code.data().agenciaId){
                setAgencia('Sí');
                setAgenciaId(code.data().agenciaId);
            }
        }
    },[code])

    useEffect(()=>{
        if(agencia === 'No'){
            setAgenciaId('');
        }
    },[agencia])

    const tiposdocs = [
        {id: '1', texto: 'Promocional'},
        {id: '2', texto: 'Especial'}
    ]

    const estados = [
        {id: '1', texto: 'Activo'},
        {id: '2', texto: 'Inactivo'}
    ]

    const agencyType = [
        {id: '1', texto: 'No'},
        {id: '2', texto: 'Sí'}
    ]

    const descuentos = [
        {id: '1', texto: 'Tasa'},
        {id: '2', texto: 'Fee'},
    ]

    const handleSubmit = async () =>{
        OpenModalNuevo(<DivLoading />)
        await AddCode ({
            id: codigo,
            tipo: tipo,
            estado: estado,
            descuento: descuento,
            agenciaId: agenciaId,
        }).then(() => {
            OpenModalNuevo(<CodigoCreado CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    const editarSubmit = async () =>{
        OpenModalNuevo(<DivLoading />)
        await EditCode ({
            id: codigo,
            tipo: tipo,
            estado: estado,
            descuento: descuento,
            agenciaId: agenciaId,
        }).then(() => {
            OpenModalNuevo(<CodigoCreado CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    const validar = (e) =>{
        e.preventDefault();
        if (!codigo) {setSpnCodigo(true); setAltCodigo('Ingresa el Código'); return;}
        if (!tipo) {setSpnTipo(true); setAltTipo('Selecciona el tipo'); return;}
        if (!descuento) {setSpnDescuento(true); setAltDescuento('Selecciona el Descuento'); return;}
        if (!estado) {setSpnEstado(true); setAltEstado('Selecciona un Estado'); return;}
        if (agencia === 'Sí'){if (!agenciaId) {setSpnAgenciaId(true); setAltAgenciaId('Selecciona la Agencia'); return;}}
        
        if (!edit){
            OpenModalNuevo(
                <ModalValidar 
                    accion={handleSubmit} 
                    titulo={'Crear Código'}
                    mensaje={'¿Estás seguro de crear este código?'}
                    botonName={'Sí, Crear'}
                    botonClass={'ButtonGreen'}
                    CerrarModal={CerrarModal}
                />
            )
        }else{
            OpenModalNuevo(
                <ModalValidar 
                    accion={editarSubmit} 
                    titulo={'Editar Código'}
                    mensaje={'¿Estás seguro de editar este código?'}
                    botonName={'Sí, Editar'}
                    botonClass={'ButtonGreen'}
                    CerrarModal={CerrarModal}
                />
            )
        }
    }

    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const generateCode = () =>{
        let length = 6;
        let result = '';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCodigo(result);
        return result;
    }

    return(
        <>
            <p className="CardTitulo">Crear Códigos</p>
            <form onSubmit={validar}>
                <div className="row p-0">
                    {!edit ?
                        <div className="col-12 col-md-6">
                            <InputButton spn={spnCodigo} setSpn={setSpnCodigo} alt={altCodigo} 
                                setValue={setCodigo} value={codigo} type={'text'} label={'Código*'} fill={'off'} 
                                action={generateCode} actionName={'Generar'}/>
                        </div>
                        :
                        <div className="col-12 col-sm-6">
                            <div className="inputDisabled"><label className={codigo ? 'listaAzul' : ''}>
                                Código</label><p>{codigo}</p></div>
                        </div>
                    }
                    <div className="col-12 col-md-6"><div className="input-select">
                        <SelectorSelec opciones={tiposdocs} TipoOpcion={'Tipo de Código*'} opcion={tipo} 
                            cambiarOpcion={setTipo} spnRed={spnTipo} setSpnRed={setSpnTipo} />
                        {spnTipo && <span className="spanAlert">{altTipo}</span>}
                    </div></div>
                    <div className="col-12 col-md-6"><div className="input-select">
                        <SelectorSelec opciones={descuentos} TipoOpcion={'Descuento*'} opcion={descuento} 
                        cambiarOpcion={setDescuento} spnRed={spnDescuento} setSpnRed={setSpnDescuento} />
                        {spnDescuento && <span className="spanAlert">{altDescuento}</span>}
                    </div></div>
                    <div className="col-12 col-md-6"><div className="input-select">
                        <SelectorSelec opciones={estados} TipoOpcion={'Estado*'} opcion={estado} 
                            cambiarOpcion={setEstado} spnRed={spnEstado} setSpnRed={setSpnEstado} />
                        {spnEstado && <span className="spanAlert">{altEstado}</span>}
                    </div></div>
                    <div className="col-12 col-md-6"><div className="input-select">
                        <SelectorSelec opciones={agencyType} TipoOpcion={'Asignar Agencia'} opcion={agencia} 
                            cambiarOpcion={setAgencia} spnRed={spnAgencia} setSpnRed={setSpnAgencia} />
                    </div></div>
                    {agencia === 'Sí' && 
                        <div className="col-12 col-md-6"><div className="input-select">
                            <SelectorSelec opciones={lista} TipoOpcion={'Agencia*'} opcion={agenciaId} 
                            cambiarOpcion={setAgenciaId} spnRed={spnAgenciaId} setSpnRed={setSpnAgenciaId} />
                            {spnAgenciaId && <span className="spanAlert">{altAgenciaId}</span>}
                        </div></div>
                    }
                    <div className="col-12">
                        <button onClick={validar} className="boton-general BtnLarge">{!edit ? 'Crear Código' : 'Editar Código'}</button>
                        <button className="btn-text" onClick={CerrarModal} type="button">Cancelar</button>
                    </div>
                </div>
            </form>
        </>
    )
}

const AdminReferido = ({OpenModalNuevo, CerrarModal, id}) =>{

    const [edit, setEdit] = useState(false)
    const [codigo] = useObtenerCodigo(id);

    //INFO CODIGO
    const [codigoId, setCodigoId] = useState('');
    const [nombre, setNombre] = useState('');
    const [tipoId, setTipoId] = useState('CC');
    const [numeroId, setNumeroId] = useState('');
    const [estado, setEstado] = useState('Activo');
    const [negociacion, setNegociacion] = useState('');

    //ALERTAS Y VALIDACIONES
    const [spnCodigo, setSpnCodigo] = useState(false);
    const [spnNombre, setSpnNombre] = useState(false);
    const [spnNumId, setSpnNumId] = useState(false);
    const [spnEstado, setSpnEstado] = useState(false);
    const [spnNegociacion, setSpnNegociacion] = useState(false);
    const [altCodigo, setAltCodigo] = useState('');
    const [altNombre, setAltNombre] = useState('');
    const [altNumId, setAltNumId] = useState('');
    const [altNegociacion, setAltNegociacion] = useState('');

    //OPCIONES
    const tiposId = [
        { id: '1', name: 'ABN' },
        { id: '2', name: 'CC' },
    ]

    const estados = [
        { id: '1', texto: 'Activo' },
        { id: '2', texto: 'Inactivo' },
    ]

    //SET CODIGO
    useEffect(()=>{
        if(codigo){
            setEdit(true);
            setCodigoId(codigo.id);
            setNombre(codigo.data().nombre);
            setTipoId(codigo.data().tipoId);
            setNumeroId(codigo.data().numeroId);
            setEstado(codigo.data().estado);
            setNegociacion(codigo.data().negociacion);
        }
    },[codigo])

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'codigo': setCodigoId(e.target.value); setSpnCodigo(false); break;
            case 'nombre': setNombre(e.target.value); setSpnNombre(false); break;
            case 'numero': setNumeroId(e.target.value); setSpnNumId(false); break;
            case 'negociacion': setNegociacion(e.target.value); setSpnNegociacion(false); break;
            default: break;
        }
    }

    const handleSubmit = async () =>{
        OpenModalNuevo(<DivLoading />)
        await AgregarCodigos ({
            id: codigoId.toLocaleLowerCase(),
            nombre: nombre,
            tipoId: tipoId,
            numeroId: numeroId,
            estado: estado,
            negociacion: negociacion
        }).then(() => {
            OpenModalNuevo(<CodigoCreado CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    const EditarSubmit = async () =>{
        OpenModalNuevo(<DivLoading />)
        await EditarCodigos({
            id: codigoId,
            nombre: nombre,
            tipoId: tipoId,
            numeroId: numeroId,
            estado: estado,
            negociacion: negociacion
        }).then(() => {
            OpenModalNuevo(<CodigoEditado CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        })
    }

    const validar = (e) =>{
        e.preventDefault();
        if (!codigoId) {setSpnCodigo(true); setAltCodigo('Ingresa el Código'); return;}
        if (!nombre) {setSpnNombre(true); setAltNombre('Ingresa el Nombre'); return;}
        if (!numeroId) {setSpnNumId(true); setAltNumId('Ingresa el Número'); return;}
        if (!negociacion) {setSpnNegociacion(true); setAltNegociacion('Ingresa el tipo de Negociación'); return;}
        if (!edit){
            OpenModalNuevo(
                <ModalValidar 
                    accion={handleSubmit} 
                    titulo={'Crear Código'}
                    mensaje={'¿Estás seguro de crear este código?'}
                    botonName={'Sí, Crear'}
                    botonClass={'ButtonGreen'}
                    CerrarModal={CerrarModal}
                />
            )
        }else{
            OpenModalNuevo(
                <ModalValidar 
                    accion={EditarSubmit} 
                    titulo={'Editar Código'}
                    mensaje={'¿Estás seguro de editar este código?'}
                    botonName={'Sí, Editar'}
                    botonClass={'ButtonGreen'}
                    CerrarModal={CerrarModal}
                />
            )
        }
    }

    return(
        <>
            <p className="CardTitulo">Agregar Referido</p>
            <form onSubmit={validar}>
                <div className="row p-0">
                    <div className="col-12 col-sm-6"><div className={!spnCodigo ? 'input-general': 'input-general redLine'}>
                        <input type="text" name="codigo" value={codigoId} placeholder=" " onChange={handleChange} />
                        <label>Código</label>
                        {spnCodigo && <span className="spanAlert">{altCodigo}</span>}
                    </div></div>
                    <div className="col-12 col-sm-6"><div className={!spnNombre ? 'input-general': 'input-general redLine'}>
                        <input type="text" name="nombre" value={nombre} placeholder=" " onChange={handleChange} />
                        <label>Nombre</label>
                        {spnNombre && <span className="spanAlert">{altNombre}</span>}
                    </div></div>
                    <div className="col-12 col-sm-6">
                        <SelectAndInput options={tiposId} opcion={tipoId} setOpcion={setTipoId} number={numeroId} setNumber={setNumeroId} 
                            fieldName={'Id Titular*'} spnSpan={spnNumId} setSpan={setSpnNumId} alert={altNumId} />
                    </div>
                    <div className="col-12 col-sm-6"><div className="input-select">
                        <SelectorSelec opciones={estados} TipoOpcion={'Estado'} opcion={estado} 
                            cambiarOpcion={setEstado} spnRed={spnEstado} setSpnRed={setSpnEstado}/>
                    </div></div>
                    <div className="col-12"><div className={!spnNegociacion ? 'input-general': 'input-general redLine'}>
                        <textarea type="text" value={negociacion} name="negociacion" placeholder=" " id="textAreaContact" onChange={handleChange} />
                        <label>Tipo de Negociación</label>
                        {spnNegociacion && <span className="spanAlert">{altNegociacion}</span>}
                    </div></div>
                    <div className="col-12">
                        <button className="boton-general BtnLarge imgButton-left" type="submit">
                            {edit ? <><img src={Check} alt="guardar"/>Editar Referido</> : <><img src={ImgPlus} alt="agregar"/>Agregar Referido</>}
                        </button>
                        <button className="btn-text" onClick={CerrarModal} type="button">Cancelar</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export {
    AdminCodigo, 
    AdminReferido
};