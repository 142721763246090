import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase/firebaseConfig';
import { useHistory } from 'react-router';
import { HuboUnError } from './Correos';
import InputPass from '../components/inputs/InputPass';
import Inputs from '../components/inputs/Inputs';

const Registro = ({OpenModalNuevo, CerrarModal}) =>{

    const history = useHistory();
    const [btnRegistro, setBtnRegistro] = useState(false);

    const[correo, setCorreo] = useState('');
    const[password, setPassword] = useState('');
    const[password2, setPassword2] = useState('');
    const[privacyPolicy, setPrivacyPolicy] = useState(false);
    //ALERTAS
    const[altCorreo, setAltCorreo] = useState('');
    const[altPassOne, setAltPassOne] = useState('');
    const[altPassTwo, setAltPassTwo] = useState('');
    const[altTerms, setAltTerms] = useState('');
    const[spnCorreo, setSpnCorreo] = useState(false);
    const[spnPassOne, setSpnPassOne] = useState(false);
    const[spnPassTwo, setSpnPassTwo] = useState(false);
    const[spnTerms, setSpnTerms] = useState(false);
    //FORMATOS
    const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

    useEffect(()=>{setSpnTerms(false);},[privacyPolicy])

    //ENVIO DE FORMULARIO
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(correo === ''){setSpnCorreo(true); setAltCorreo('Por favor ingresa un correo electrónico'); return;}
        if(!expresionRegular.test(correo)){setSpnCorreo(true); setAltCorreo('Por favor ingresa un correo válido'); return;}
        if(password === ''){setSpnPassOne(true); setAltPassOne('Por favor ingresa una contraseña'); return;}
        if(password.length < 6){
            setSpnPassOne(true); setAltPassOne('La contraseña tiene que ser de al menos 6 caracteres'); return;}
        if(password2 === ''){setSpnPassTwo(true); setAltPassTwo('Por favor repite tu contraseña'); return;}
        if(password !== password2){setSpnPassTwo(true); setAltPassTwo('Las contraseñas no son iguales'); return;}
        if(privacyPolicy === false){setSpnTerms(true); setAltTerms('Por favor acepta los términos y condiciones'); return;}
        setBtnRegistro(true);

        try{
            await auth.createUserWithEmailAndPassword(correo, password);
            await auth.currentUser.sendEmailVerification().then(function() {
                history.push = "/verificar-email";
            }).catch(()=>{
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>)
                setBtnRegistro(false);
            });
        } catch(error){
            setBtnRegistro(false);
            switch(error.code){
                case 'auth/email-already-in-use': setSpnCorreo(true); setAltCorreo('Ya existe una cuenta con ese correo'); break;
                case 'auth/invalid-email': setSpnCorreo(true); setAltCorreo('El correo electrónico no es válido'); break;
                case 'auth/invalid-password': setSpnPassOne(true); setAltPassOne('La contraseña tiene que ser de al menos 6 caracteres'); break;
                default: setSpnTerms(true); setAltTerms('Hubo un error al intentar crear la cuenta'); break;
            }
        }
    }

    return (
        <>
            <Helmet><title>Regístrate</title></Helmet>
            <div className="loginContainer">
                <h1>Registro</h1>
                <div className="InnerLogin">
                    <form onSubmit={handleSubmit}> 
                        <Inputs name={'emaillogin'} type={'text'} label={'Correo Electrónico'} value={correo} setValue={setCorreo} 
                            alt={altCorreo} spn={spnCorreo} setSpn={setSpnCorreo} fill={'on'} />
                        
                        <InputPass name={'password'} label={'Contraseña'} value={password} setValue={setPassword} 
                            alt={altPassOne} spn={spnPassOne} setSpn={setSpnPassOne} fill={'off'} />

                        <InputPass name={'password2'} label={'Repetir la contraseña'} value={password2} setValue={setPassword2} 
                            alt={altPassTwo} spn={spnPassTwo} setSpn={setSpnPassTwo} fill={'off'} />

                        <div className="checkbox">
                            <input type="checkbox" name="privacypolicy" defaultChecked={privacyPolicy} onChange={()=>{setPrivacyPolicy(!privacyPolicy)}}/>
                            <label></label>
                            <div className="checkboxlabel">
                                Acepto los <Link to="/terminos-y-condiciones" target="_blank" rel="noreferrer noopener">Términos y Condiciones </Link> y las 
                                <Link to="/politicas-de-privacidad" target="_blank" rel="noreferrer noopener"> Políticas de Privacidad</Link> de Colcambios Australia.
                            </div>
                        </div>
                        {spnTerms && <span className="spanAlertCheck">{altTerms}</span>}
                        <button type="submit" className="boton-general BtnLarge" disabled={btnRegistro}>
                            {btnRegistro ? 'Cargando...' : 'Registrarme'}</button>
                        <p className="TextLink">¿Ya tienes una cuenta?<br/><Link to="/ingresar">Ingresa</Link></p>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Registro;