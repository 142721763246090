import React, { useState, useEffect} from 'react';
import useObtenerDestinatarios from '../../hooks/useObtenerDestinatarios';
import '../../../views/components/inputs/selector.css';
import arrowDown from '../../../img/icons/arrow-down.svg';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

const SelectDestinatario = ({receiver, ChangeReceiver, cambiarDestinatario, setDnoId, setAutoId, spnRed, setSpnRed, setAutoEnvio}) => {
    
    const {infoUser} = useAuth();
    const [toSelf, setToSelf] = useState(false);
    const [contactos]= useObtenerDestinatarios();

    const [mostrarSelect, cambiarMostrarSelect] = useState(false);
    const [label, setLabel] = useState(false);

    const Seleccion = (e) =>{cambiarMostrarSelect(!mostrarSelect); setSpnRed(false)}
    const LeaveSelect = (e) =>{cambiarMostrarSelect(false)}

    useEffect(() => {
        if(receiver){setLabel(true);}
        else{setLabel(false);}
    }, [receiver])

    useEffect(()=>{
        if(infoUser){if(infoUser.toSelf===true){setToSelf(true);}}
    },[infoUser])

    const handleClick = (e) => {
        setLabel(true);
        setAutoEnvio(false);
        cambiarDestinatario(e.currentTarget.dataset.valor);
        setDnoId(e.currentTarget.dataset.record);
        setAutoId();
    }

    const autoEnvio = (e) => {
        setLabel(true);
        setAutoEnvio(true);
        cambiarDestinatario('Auto Envio');
        setAutoId(infoUser.usuario);
        ChangeReceiver(e);
        setDnoId();
    }

    return (
        <div className="Selec" onClick={Seleccion} onMouseLeave={LeaveSelect}>
            <div className={(!mostrarSelect ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(label ? 'Selec_blue' : '')+' '+(spnRed ? 'redWarn' : '')}>
                <label className={!label ? 'Label_in' : 'Label_out'}>Destinatario</label>{receiver}
            </div>
            <img className={!mostrarSelect ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} alt="Desplegar"/>
            {mostrarSelect &&
                <div className="Selec_opciones">
                    {toSelf && <div className="Select_opcion" onClick={autoEnvio}>Auto Envio</div>}
                    {contactos.length > 0 ? <>
                        {contactos.map((receiver) => {
                        return <div
                            key={receiver.id}
                            data-record = {receiver.id}
                            data-valor={receiver.nombre.nombre + ' ' + receiver.nombre.apellido}
                            onClick={handleClick}
                            className="Select_opcion">
                            {receiver.nombre.nombre + ' ' + receiver.nombre.apellido}</div>
                        })}
                        <div className="selectButton">
                            <Link to="/nuevo-destinatario" className="boton-general">Agregar Destinatario</Link>
                        </div>
                        </>
                        :
                        <div className="selectButton">
                            <p>Aún no tienes Destinatarios</p>
                            <Link to="/nuevo-destinatario" className="boton-general">Agregar Destinatario</Link>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default SelectDestinatario;
