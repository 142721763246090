import React, {useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { firebase } from '../../firebase/firebaseConfig';
import { CorreoClave, HuboUnError } from './Correos';

const  ForgotPass = ({OpenModalNuevo, CerrarModal})=>{

    const [correo, setCorreo] = useState('');
    const [altCorreo, setAltCorreo] = useState('');
    const [spnCorreo, setSpnCorreo] = useState(false);
    const [btnForgot, setBtnForgot] = useState(false);
    const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
    const handleLogin = (e) => {setCorreo(e.target.value); setSpnCorreo(false);}

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if(correo === ''){setSpnCorreo(true); setAltCorreo('Por favor ingresa un correo'); return;}
        if(!expresionRegular.test(correo)){setSpnCorreo(true); setAltCorreo('Por favor ingresa un correo válido'); return;}
        setBtnForgot(true);
        try{
            await firebase.auth().sendPasswordResetEmail(correo)
            .then(() => {
                setBtnForgot(false);
                OpenModalNuevo(<CorreoClave CerrarModal={CerrarModal}/>)
            })
            .catch(() => {
                setBtnForgot(false);
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>)
            });
        } catch(error){
            setBtnForgot(false);
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>)
            switch(error.code){
                case 'auth/user-not-found': setSpnCorreo(true); setAltCorreo('No se encontró ninguna cuenta con ese correo.'); break;
                default: setSpnCorreo(true); setAltCorreo('Hubo un error.'); break;
            }
        }
    }

    return (
        <>
            <Helmet><title>Olvidé mi contraseña</title></Helmet>
            <div className="loginContainer">
                <h1>Recuperar Contraseña</h1>
                <div className="InnerLogin">
                    <form onSubmit={handleLoginSubmit}>
                        <div className={!spnCorreo ? 'input-general' : 'input-general redLine'}>
                            <input name="emaillogin" type="email" placeholder=" " value={correo} onChange={handleLogin}/>
                            <label>Correo</label>
                            {spnCorreo && <span className="spanAlert">{altCorreo}</span>}
                        </div>
                        <button type="submit" className="boton-general BtnLarge" disabled={btnForgot}>
                            {btnForgot ? 'Enviando Correo...' : 'Restablecer Contraseña'}</button>
                    </form>
                    <p className="TextLink">¿Ya tienes tu contraseña?<br/><Link to="/ingresar">Ingresa</Link></p>
                </div>
            </div>
        </>
    )
}

export default ForgotPass;