import React from "react";
import ModalValidar from "../../../../views/components/modales/MoldaValidar";
import { EliminarCuentas } from "../../../data/AdminCuentas";
import { CuentaEliminada, HuboUnError } from "../../Mensajes";

const EliminarCuenta = ({OpenModalNuevo, CerrarModal, cuenta}) =>{

    const BorrarCuenta = () =>{
        EliminarCuentas({
            id: cuenta
        }).then(() => {
            OpenModalNuevo(<CuentaEliminada CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    return(
        <ModalValidar 
            accion={BorrarCuenta} 
            titulo={'Borrar Cuenta'}
            mensaje={'¿Estás seguro de eliminar esta Cuenta?'}
            botonName={'Sí, Eliminar'}
            botonClass={'ButtonRed'}
            CerrarModal={CerrarModal}
        />
    )
}

export default EliminarCuenta;