import { useEffect, useState } from 'react';
import { db } from '../../firebase/firebaseConfig';

const useObtenerDestinatario = (uidDestinatario) =>{

    const [destinatario, setDestinatario] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect (()=>{
        const unsuscribe = db.collection('contactos').doc(uidDestinatario).get()
        .then((doc)=>{
            if(doc.exists){
                setLoading(false);
                setDestinatario(doc);
            }
        })
        return unsuscribe;
    }, [uidDestinatario]);

    return[destinatario, loading]
}

export default useObtenerDestinatario;