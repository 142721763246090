import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import NumberFormat from 'react-number-format';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExportTransacciones from '../transferencias/ExportTransacciones';
import './../../../views/components/paginacion/Paginacion.css';
import '../../../styles/tablaCuentas.css';
import { useObtenerDatos } from '../../hooks/useObtenerDatos';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import ImgExcel from '../../../img/icons/document-download.svg';
import ImgPdf from '../../../img/icons/document-text-1.svg';

const TransferenciasUsario = ({id}) => {

    const [filtro, setFiltro] = useState(['Registrada', 'En Proceso','Procesada','Cancelada']);
    const [newFiltro, setNewFiltro] = useState([]);

    //DATOS
    const [transferencias, loading] = useObtenerDatos(filtro,id);
	const [data, setData] = useState('');
	const formatearFecha = (fecha) => {return format(fromUnixTime(fecha), "dd/MMM/yy", { locale: es });}

	const [pages, setPages] = useState('');
    const [dataLimit, setDataLimit] = useState('');
    const [pageLimit, setPageLimit] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationGroup, setPaginationGroup] = useState([]);

    const Filtros = (e) => {
		let newArray = [...newFiltro, e.target.value];
		if (newFiltro.includes(e.target.value)) {
		  newArray = newArray.filter(text => text !== e.target.value);
		}
		setNewFiltro(newArray);
	};

    useEffect(()=>{
		if(newFiltro.length > 0){setFiltro(newFiltro)}
		else{setFiltro(['Registrada', 'En Proceso', 'Procesada','Cancelada'])}
	},[newFiltro])

    //TRANSFORM DATA
	useEffect(()=>{
        if(transferencias){
            const temp = transferencias.map((transferencia)=>{
                let Destino
				let Tipo = transferencia.destinatario.tipo
				if(!Tipo){Tipo = 'Persona'}
				if(Tipo){
					if(Tipo === 'Empresa'){Destino = transferencia.destinatario.nombre}
					else{Destino =transferencia.destinatario.nombre + ' ' + transferencia.destinatario.apellido}
				}
                return [
					transferencia.id.substring(0, 6).toUpperCase(),
					transferencia.transferencia.metodo,
					formatearFecha(transferencia.fecha),
					transferencia.remitente.nombre + ' ' + transferencia.remitente.apellido,
					Tipo,
					Destino,
					transferencia.razon,
					transferencia.transferencia.emailCop,
					transferencia.transferencia.cargos,
					transferencia.transferencia.emailTasa,
					transferencia.transferencia.fee,
					transferencia.transferencia.emailAud,
					transferencia.estado,
					transferencia.cuenta.banco,
					transferencia.cuenta.bsb,
					transferencia.cuenta.numeroCuenta,
					transferencia.cuenta.titular,
					transferencia.colcambios.banco + ' ' + transferencia.colcambios.numero,
				]
            });
            setData(temp);
        }
    },[transferencias])

    //EXPORTAR A PDF
	const DownloadPDF = async() =>{
        const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'tabloid'})
        doc.autoTable({
            head: [["Referencia","Método","Fecha","Remitente","Tipo Destinatario","Destinatario","Razón del envio","Pagado",
            "Cargos","Tasa","Servicio","Reciben","Estado","Banco Destino","BSB","Número de Cuenta","Titular","Cuenta Ingreso Colcambios"]],
            body: data
        })
        doc.save('Transferencias.pdf');
    }

	//PAGINACIÓN
	useEffect(()=>{
        if(transferencias.length <= 10){
			setDataLimit(transferencias.length);
        }else{
			setDataLimit(10);
		}
    },[transferencias])

    useEffect(() => {
        setPages(Math.ceil(transferencias.length / dataLimit));
        setPageLimit(4);
    },[transferencias, dataLimit])

	useEffect(() => {
        if(pageLimit){
            const grupoNumeros = () => {
                let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
                return new Array(pageLimit).fill().map((_, idx) => start + idx + 1 );
            };
            setPaginationGroup(grupoNumeros);
        }
    }, [pageLimit, currentPage]);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage]);

    const goToNextPage = () => {setCurrentPage((page) => page + 1);};
    const goToPreviousPage = () => {setCurrentPage((page) => page - 1);};
      
    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return transferencias.slice(startIndex, endIndex);
    };

    return(
        <>
            <div className="CheckBoxs">
                <div className="NewCheckbox">
                    <input type="checkbox" id="Registrada" value="Registrada" onChange={Filtros}/>
                    <label className="custom-control-label" htmlFor="Registrada">Registradas</label>
                </div>
                <div className="NewCheckbox">
                    <input type="checkbox" id="proceso" value={'En Proceso'} onChange={Filtros} />
                    <label className="custom-control-label" htmlFor="proceso">En Proceso</label>
                </div>
                <div className="NewCheckbox">
                    <input type="checkbox" id="Procesada" value="Procesada" onChange={Filtros} />
                    <label className="custom-control-label" htmlFor="Procesada">Procesadas</label>
                </div>
                <div className="NewCheckbox">
                    <input type="checkbox" id="Cancelada" value="Cancelada" onChange={Filtros} />
                    <label className="custom-control-label" htmlFor="Cancelada">Canceladas</label>
                </div>
            </div>
            {loading ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
            : <>{transferencias.length > 0 ? 
                    <>
                        <div className="divTableContent">
                            <div className="divTable-head">
                                <div className="divTable-headRow" id="table9td">
                                    <div>Referencia</div>
                                    <div>Fecha</div>
                                    <div>Remitente</div>
                                    <div>Destinatario</div>
                                    <div className="tdsal">Tasa</div>
                                    <div className="tdsal">Pagado</div>
                                    <div className="tdsal">Reciben</div>
                                    <div>Estado</div>
                                </div>
                            </div>
                        {getPaginatedData().map((transferencia) => {
                            let divMetodo = 'Tradicional';
                            let claseEstado = '';
                            if(transferencia.transferencia.metodo !== 'Tradicional'){
                                divMetodo = ('Referencia');
                            }
                            if(transferencia.estado === 'Registrada'){
                                claseEstado = ('Registrada')
                            }
                            if(transferencia.estado === 'En Revisión'){
                                claseEstado = ('Revision')
                            }
                            if(transferencia.estado === 'En Proceso'){
                                claseEstado = ('Proceso')
                            }
                            if(transferencia.estado === 'Procesada'){
                                claseEstado = ('Procesada')
                            }
                            if(transferencia.estado === 'Cancelada'){
                                claseEstado = ('Cancelada')
                            }
                            return(
                                <Link to={`/transferencia/${transferencia.id}`} className="divTable-body" key={transferencia.id}>
                                    <div className="divTable-row" id="table9td">
                                        <div className='divReferencia'>
                                            <label>Referencia</label>{transferencia.id.substring(0, 6).toUpperCase()}
                                            <div className={divMetodo}>{transferencia.transferencia.metodo}</div>
                                        </div>
                                        <div><label>Fecha</label>{formatearFecha(transferencia.fecha).toUpperCase()}</div>
                                        <div><label>Remitente</label>{transferencia.remitente.nombre + ' ' + transferencia.remitente.apellido}</div>
                                        <div><label>Destinatario</label>{transferencia.destinatario.nombre + ' ' + transferencia.destinatario.apellido}</div>
                                        <div className="tdNone tdsal"><label>Tasa</label>
                                            <NumberFormat value={transferencia.transferencia.tasa} displayType={'text'} thousandSeparator={'.'} 
                                                decimalSeparator={','} decimalScale={'2'} prefix={'$'} suffix={' COP'} />
                                        </div>
                                        <div className="tdsal"><label>Pagado</label>
                                            <NumberFormat value={transferencia.transferencia.totalPagado} displayType={'text'} thousandSeparator={'.'} 
                                                decimalSeparator={','} decimalScale={'2'} prefix={'$'} suffix={' COP'} />
                                            <p>{transferencia.colcambios.banco}</p>
                                        </div>
                                        
                                        <div className="tdsal"><label>Reciben</label>
                                            <NumberFormat value={transferencia.transferencia.montoAud} displayType={'text'} thousandSeparator={','} 
                                                decimalSeparator={'.'} decimalScale={'2'} prefix={'$'} suffix={' AUD'} />
                                            <p>{transferencia.cuenta.banco}</p>
                                        </div>
                                        <div><label>Estado</label>
                                            <span className={claseEstado}>&#9679;</span>{transferencia.estado}
                                        </div>
                                        
                                    </div>
                                </Link>
                            );
                        })}
                        </div>
                        <div className="TableFooter">
                            <div className="Btns-Export">
                                <button className="BtnExport BtnExcel" onClick={()=> ExportTransacciones({data})}><img src={ImgExcel} alt="" />Exportar a Excel</button>
                                <button className="BtnExport BtnPdf" onClick={DownloadPDF}><img src={ImgPdf} alt="" />Exportar a PDF</button>
                            </div>

                            <div className="paginacion">
                                <button onClick={goToPreviousPage} className={`pagPrev ${currentPage === 1 ? 'pagDisabled' : ''}`}><img src={ImgPrev} alt="anterior" /></button>
                                    {paginationGroup ? paginationGroup.map((item, index) => (
                                        <button key={index} onClick={changePage} className={(item > pages ? 'pagBlock' : 'pagNumber') +' '+ (currentPage === item ? 'pagActive' : '')}>
                                            <span>{item}</span>
                                        </button>
                                    )) : <></>}
                                <button onClick={goToNextPage} className={`pagNext ${currentPage === pages ? 'pagDisabled' : ''}`}><img src={ImgNext} alt="siguiente" /></button>
                            </div>
                        </div>
                    </> :   
                    <div className="itemTransferEmpty">
                        <p>No se encontraron Transferencias</p>
                    </div>
                }
            </> 
            }
        </>
    )
}

export default TransferenciasUsario;
