import { db } from '../../firebase/firebaseConfig';

const AgregarCodigos = async(props) => {
    const{
        id,
        nombre,
        tipoId,
        numeroId,
        estado,
        negociacion
    } = props;
    return await db.collection('referidos').doc(id).set({
        nombre: nombre,
        tipoId: tipoId,
        numeroId: numeroId,
        estado: estado,
        negociacion: negociacion
    });
}

const EditarCodigos = async(props) => {
    const{
        id,
        nombre,
        tipoId,
        numeroId,
        estado,
        negociacion
    } = props;
    return await db.collection('referidos').doc(id).update({
        nombre: nombre,
        tipoId: tipoId,
        numeroId: numeroId,
        estado: estado,
        negociacion: negociacion
    });
}

const EliminarCodigos = async ({id}) => {
    return await db.collection('referidos').doc(id).delete()
}

const AddCode = async ({
        id,
        tipo,
        estado,
        descuento,
        agenciaId, 
    }) =>{
    return await db.collection('codigos').doc(id).set({
        tipo: tipo,
        estado: estado,
        descuento: descuento,
        agenciaId: agenciaId
    })
}

const EditCode = async ({
    id,
    tipo,
    estado,
    descuento,
    agenciaId
}) =>{
    return await db.collection('codigos').doc(id).update({
        tipo: tipo,
        estado: estado,
        descuento: descuento,
        agenciaId: agenciaId
    })
}

const DeleteCode = async ({id}) => {
    return await db.collection('codigos').doc(id).delete()
}

export { 
    AgregarCodigos, 
    EditarCodigos, 
    EliminarCodigos,
    AddCode,
    EditCode,
    DeleteCode,
};