import saveAs from 'file-saver';
import logoCol from '../../../img/ColLogo';
import {Workbook} from 'exceljs';

const ExportUsuarios = async({data})=>{
    const title = 'Transferencias Colcambios Australia';
    const header = ["Id","Nombres", "Apellidos", "Email", 'Celular', 'última Conexión'];

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Usuarios');

    let titleRow = worksheet.addRow([title], 'B1:B2');
    titleRow.font = { size: 16, bold: true };
    worksheet.addRow([]);

    let logo = workbook.addImage({
        base64: logoCol,
        extension: 'png',
    });
    worksheet.addImage(logo, 'A1:A2');
    worksheet.mergeCells('A1:H2');

    worksheet.addRow([]);

    const headerRow = worksheet.addRow(header);

    headerRow.font = {color:{argb: 'FFFFFFFF'},bold: true }
    headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0018B4' },
        };
      });

    worksheet.autoFilter = {
        from: {
            row: 4,
            column: 1
        },
        to: {
            row: 4,
            column: header.length
        }
    };

    await data.forEach(d => {
        const row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
            cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: 'FFFFFFFF'
            };
            cell.border = { color:{ argb: 'FF000000' }, top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        });
    });

    worksheet.getRow(1).height=70;
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 18;
    worksheet.addRow([]);

    const footerRow = worksheet.addRow(['Exportado desde Colcambios Australia']);
    footerRow.getCell(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECECEC' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    worksheet.mergeCells(`A${footerRow.number}:E${footerRow.number}`);

    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Usuarios Colcambios.xlsx');
    });
      
}

export default ExportUsuarios;