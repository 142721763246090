import React, {useEffect, useState} from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; 
import { useAuth } from '../../../context/AuthContext';
import { firebase } from '../../../firebase/firebaseConfig';
import File from '../../../views/components/files/File';
import {PerfilActualizado, PerfilNoActualizado} from '../../../views/components/modales/Correos';

const UpdatePhoto = ({OpenModalNuevo,CerrarModal}) =>{

    const {usuario} = useAuth();
    const [imagen, setImagen] = useState();
    const [progress, setProgress] = useState(0);

    const[spnArchivo, setSpnArchivo] = useState(false);
    const[altArchivo, setAltArchivo] = useState();

    const [src, setSrc] = useState();
    // const [cropResult, setCropResult] = useState(null);
    const [cropper, setCropper] = useState();

    useEffect(()=>{
        if(imagen){
            if(imagen.length > 0){
                const reader = new FileReader();
                reader.onload = () => {setSrc(reader.result);};
                reader.readAsDataURL(imagen[0])
            }
        }
    },[imagen])
  
    const cropImage = () => {
        if(!imagen){setSpnArchivo(true); setAltArchivo('Selecciona una imagen');return;}
        let perfilo;
        if (typeof cropper !== "undefined") {
            // setCropResult(cropper.getCroppedCanvas().toDataURL());
            perfilo = cropper.getCroppedCanvas().toDataURL()
            const StorageRef = firebase.storage().ref(usuario.uid + '/documents/' + usuario.uid)
            const uploadTask = StorageRef.putString(perfilo.split(',')[1], "base64", {contentType: 'image/png'})
            uploadTask.on( "state_changed", snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
                }, error => {
                    console.log(error);
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        usuario.updateProfile({
                            photoURL: downloadURL
                        }).then(() => {
                            OpenModalNuevo(<PerfilActualizado CerrarModal={CerrarModal}/>)
                        }).catch(() => {
                            OpenModalNuevo(<PerfilNoActualizado CerrarModal={CerrarModal}/>)
                        })
                    });
            })
        }
    }

    return(
        <div>
            <div>
                {!imagen && <File setImagen={setImagen} spnArchivo={spnArchivo} altArchivo={altArchivo}/>}
                {imagen &&
                    <div className="cropProfile">
                        <Cropper
                            className="cropperDiv"
                            aspectRatio={1}
                            preview=".img-preview"
                            guides={false}
                            src={src}
                            ref={Cropper}
                            viewMode={1}
                            dragMode="move"
                            cropBoxMovable={true}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />
                    </div>
                }
                {/* <div>
                <div className="box" style={{ width: "50%", float: "right" }}>
                    <h1>Preview</h1>
                    <div className="img-preview" style={{ width: "100%", float: "left", height: 300, overflow: "hidden" }}/>
                </div>
                <div className="box" style={{ width: "50%", float: "right" }}>
                    <h1><span>Crop</span>
                    <button onClick={} style={{ float: "right" }}>Crop Image</button>
                    </h1>
                    <img style={{ width: "50%" }} src={cropResult} alt="imagen Cortada" />
                </div>
                </div>
                <br style={{ clear: "both" }} /> */}
            </div>
            
            <progress value={progress} max="100"></progress>
            <button className="boton-general" onClick={cropImage}>Cambiar</button>
        </div>
    )
}

export default UpdatePhoto;