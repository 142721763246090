import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import SelectorSelec from '../../../views/components/inputs/Selector';
import ExportUsuarios from './ExportUsuarios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import useObtenerUsuarios from '../../hooks/useObtenerUsuarios';
import './../../../views/components/paginacion/Paginacion.css';
import '../../../styles/tablaCuentas.css';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import ImgExcel from '../../../img/icons/document-download.svg';
import ImgPdf from '../../../img/icons/document-text-1.svg';
import Search from '../../../img/icons/search.svg';

const Usuarios = () => {

    //FILTROS POR NOMBRE Y ID
	const [textName, setTexName] = useState('');
	const [textApe, setTextApe] = useState('');
	const [textId, setTextId] = useState('');
    const [textEmail, setTextEmail] = useState('');

	const [spnTextNam, setSpnTextName] = useState(false);
	const [spnTextApe, setSpnTextApe] = useState(false);
	const [spnTextId, setSpnTextId] = useState(false);
    const [spnTextEmail, setSpnTextEmail] = useState(false);
	const [altTextNam, setAltTextName] = useState('');
	const [altTextApe, setAltTextApe] = useState('');
	const [altTextId, setAltTextId] = useState('');
    const [altTextEmail, setAltTextEmail] = useState('');
	
	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [idSearch, setIdSearch] = useState('');
    const [emailSearch, setEmailSearch] = useState('');

	const handleChange = (e) => {
        switch (e.target.name) {
            case 'cliente': setTexName(e.target.value);setSpnTextName(false);break;
			case 'apellido': setTextApe(e.target.value);setSpnTextApe(false);break;
			case 'clienteId': setTextId(e.target.value);setSpnTextId(false);break;
            case 'emailCliente': setTextEmail(e.target.value); setSpnTextEmail(false);break;
            default: break;
        }
    }

    const tipoSearc = [
        { id: '1', texto: 'Nombre'},
        { id: '2', texto: 'Apellido'},
        { id: '3', texto: 'Id'},
        { id: '4', texto: 'Email'},
    ]
	const [opcionSearch, setOpcionSearch] = useState('Nombre');
	const [spnSearch, setSpnSearch] = useState(false);
    
    const buscar = (e) =>{
        e.preventDefault();
        if(opcionSearch === 'Nombre'){
            if(!textName){setSpnTextName(true);setAltTextName('Ingresa el Nombre');return;}
            else{setName(textName); setSurname(''); setIdSearch(''); setEmailSearch('');}
        }
        if(opcionSearch === 'Apellido'){
            if(!textApe){setSpnTextApe(true);setAltTextApe('Ingresa el Apellido');return;}
            else{setName(''); setSurname(textApe); setIdSearch(''); setEmailSearch('');}
        }
        if(opcionSearch === 'Id'){
            if(!textId){setSpnTextId(true);setAltTextId('Ingresa el Id del Usuario');return;}
            else{setName(''); setSurname(''); setIdSearch(textId); setEmailSearch('');}
        }
        if(opcionSearch === 'Email'){
            if(!textEmail){setSpnTextEmail(true);setAltTextEmail('Ingresa el Email');return;}
            else{setName(''); setSurname(''); setIdSearch(''); setEmailSearch(textEmail);}
        }
    }

    //FIN DE FILTROS

    const [ListadeUsuarios, loading ] = useObtenerUsuarios(name,surname,idSearch,emailSearch);
    const [data, setData] = useState('');

    const formatearFecha = (fecha) => {return format(fromUnixTime(fecha), "dd'/'MMM'/'yy", { locale: es });}

    const [pages, setPages] = useState('');
    const [dataLimit, setDataLimit] = useState('');
    const [pageLimit, setPageLimit] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationGroup, setPaginationGroup] = useState([]);

    useEffect(()=>{
        if(ListadeUsuarios){
            const temp = ListadeUsuarios.map((usuario)=>{
                return [
					usuario.id,
                    usuario.nombre.nombre+' '+usuario.nombre.segNombre,
                    usuario.nombre.apellido+' '+usuario.nombre.segApellido,
                    usuario.email,
                    usuario.celular,
                    formatearFecha(usuario.lastSign)
				]
            });
            setData(temp);
        }
    },[ListadeUsuarios])

    const DownloadPDF = async() =>{
        const doc = new jsPDF({orientation: 'l',unit: 'pt',format: 'A4'})
        doc.autoTable({
            head: [["Id","Nombres", "Apellidos", "Email", 'Celular', 'última Conexión']],
            body: data
          })
        doc.save('Usuarios colcambios.pdf')
    }

    useEffect(()=>{
        if(ListadeUsuarios.length <= 10){
            setDataLimit(ListadeUsuarios.length);
        }else{
            setDataLimit(10);
        }
    },[ListadeUsuarios])

    useEffect(() => {
        setPages(Math.ceil(ListadeUsuarios.length / dataLimit));
        setPageLimit(4);
    },[ListadeUsuarios, dataLimit])

    useEffect(() => {
        if(pageLimit){
            const grupoNumeros = () => {
                let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
                return new Array(pageLimit).fill().map((_, idx) => start + idx + 1 );
            };
            setPaginationGroup(grupoNumeros);
        }
    }, [pageLimit, currentPage]);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage]);

    const goToNextPage = () => {setCurrentPage((page) => page + 1);};
    const goToPreviousPage = () => {setCurrentPage((page) => page - 1);};
      
    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return ListadeUsuarios.slice(startIndex, endIndex);
    };

    return (
        <>
            <Helmet><title>Usuarios</title></Helmet>
            <div className="TituloPag"><p>Usuarios</p></div>
            <div className="dashboardCard">
                <p className="CardTitulo">Usuarios</p>
                <div className="SearchBox">
					<div className="row p-0">
						<div className="col-12 col-md-3"><div className="input-select">
							<SelectorSelec opciones={tipoSearc} TipoOpcion={'Buscar Por'} opcion={opcionSearch} 
								cambiarOpcion={setOpcionSearch} spnRed={spnSearch} setSpnRed={setSpnSearch} />
						</div></div>
						{opcionSearch === 'Nombre' &&
							<div className="col-12 col-sm-6 col-md-3"><div className={!spnTextNam ? 'input-general': 'input-general redLine'}>
								<input type='text' name='cliente' value={textName} placeholder=" " onChange={handleChange} autoComplete="off"></input>
								<label>Nombre</label>
								{spnTextNam && <span className="spanAlert">{altTextNam}</span>}
							</div></div>
                        }
                        {opcionSearch === 'Apellido' &&
							<div className="col-12 col-sm-6 col-md-3"><div className={!spnTextApe ? 'input-general': 'input-general redLine'}>
								<input type='text' name='apellido' value={textApe} placeholder=" " onChange={handleChange} autoComplete="off"></input>
								<label>Apellido</label>
								{spnTextApe && <span className="spanAlert">{altTextApe}</span>}
							</div></div>
                        }
                        {opcionSearch === 'Id' &&
                            <div className='col-12 col-md-3'><div className={!spnTextId ? 'input-general': 'input-general redLine'}>
								<input type='text' name='clienteId' value={textId} placeholder=" " onChange={handleChange} autoComplete="off"></input>
								<label>Id</label>
								{spnTextId && <span className="spanAlert">{altTextId}</span>}
							</div></div>
                        }
                        {opcionSearch === 'Email' &&
                            <div className='col-12 col-md-3'><div className={!spnTextEmail ? 'input-general': 'input-general redLine'}>
								<input type='email' name='emailCliente' value={textEmail} placeholder=" " onChange={handleChange} autoComplete="off"></input>
								<label>Email</label>
								{spnTextEmail && <span className="spanAlert">{altTextEmail}</span>}
							</div></div>
                        }
						<div className='col-12 col-md-3'>
							<button className='boton-general imgButton-left' type="button" onClick={buscar}>
								<img src={Search} alt="cancelar"/>Buscar</button>
						</div>
					</div>
				</div>
                {loading ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
                    : <> {ListadeUsuarios.length > 0 ?
                            <>
                                <div className="divTableContent">
                                    <div className="divTable-head">
                                        <div className="divTable-headRow" id="table6td">
                                            <div>Id</div>
                                            <div>Nombres</div>
                                            <div>Apellidos</div>
                                            <div className="tdEmail">Email</div>
                                            <div>Celular</div>
                                            <div>Última conexión</div>
                                        </div>
                                    </div>
                                    {getPaginatedData().map((cliente) => {
								        return(
                                        <Link to={`/usuario/${cliente.id}`} className="divTable-body" key={cliente.id}>
                                            <div className="divTable-row" id="table6td">
                                                <div><label className="label-mobile">Id</label>{cliente.id.substring(0, 6).toUpperCase()}</div>
                                                <div><label className="label-mobile">Nombres</label>{cliente.nombre.nombre + ' ' + cliente.nombre.segNombre}</div>
                                                <div><label className="label-mobile">Apellidos</label>{cliente.nombre.apellido + ' ' + cliente.nombre.segApellido}</div>
                                                <div className="tdEmail"><label className="label-mobile">Email</label>{cliente.email}</div>
                                                <div><label className="label-mobile">Celular</label>{cliente.celular}</div>
                                                <div><label className="label-mobile">Última sesión</label>{formatearFecha(cliente.lastSign).toUpperCase()}</div>
                                            </div>
                                        </Link>
                                        )
							        })}
							    </div>
                                <div className="TableFooter">
                                    <div className="Btns-Export">
                                        <button className="BtnExport BtnExcel" onClick={()=> ExportUsuarios({data})}><img src={ImgExcel} alt="" />Exportar a Excel</button>
                                        <button className="BtnExport BtnPdf" onClick={DownloadPDF}><img src={ImgPdf} alt="" />Exportar a PDF</button>
                                    </div>

                                    <div className="paginacion">
                                        <button onClick={goToPreviousPage} className={`pagPrev ${currentPage === 1 ? 'pagDisabled' : ''}`}><img src={ImgPrev} alt="anterior" /></button>
                                            {paginationGroup ? paginationGroup.map((item, index) => (
                                                <button key={index} onClick={changePage} className={(item > pages ? 'pagBlock' : 'pagNumber') +' '+ (currentPage === item ? 'pagActive' : '')}>
                                                    <span>{item}</span>
                                                </button>
                                            )) : <></>}
                                        <button onClick={goToNextPage} className={`pagNext ${currentPage === pages ? 'pagDisabled' : ''}`}><img src={ImgNext} alt="siguiente" /></button>
                                    </div>
                                </div>
                            </> :   
                            <div className="itemTransferEmpty">
                                <p>No se Encontraron Usuarios</p>
                            </div>
                        }
                    </> 
				}
			</div>
		</>		
	);
}

export default Usuarios;