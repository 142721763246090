import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../context/AuthContext';
import useObtenerDestinatarios from '../../hooks/useObtenerDestinatarios';
import '../../../views/components/paginacion/Paginacion.css';
import '../../../styles/tablaCuentas.css';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import ImgPlus from '../../../img/icons/plus.svg';

const Destinatarios = () => {

    const [contactos, loading] = useObtenerDestinatarios();
    const {infoUser} = useAuth();

    const [pages, setPages] = useState('');
    const [dataLimit, setDataLimit] = useState('');
    const [pageLimit, setPageLimit] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationGroup, setPaginationGroup] = useState([]);

    useEffect(()=>{
        if(contactos.length <= 10){
            setDataLimit(contactos.length);
        }else{
            setDataLimit(10);
        }
    },[contactos])

    useEffect(() => {
        setPages(Math.ceil(contactos.length / dataLimit));
        setPageLimit(4);
    },[contactos, dataLimit])

    useEffect(() => {
        if(pageLimit){
            const grupoNumeros = () => {
                let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
                return new Array(pageLimit).fill().map((_, idx) => start + idx + 1 );
            };
            setPaginationGroup(grupoNumeros);
        }
    }, [pageLimit, currentPage]);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage]);

    const goToNextPage = () => {
        setCurrentPage((page) => page + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage((page) => page - 1);
    };
      
    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return contactos.slice(startIndex, endIndex);
    };
    
    return (
        <>
            <Helmet><title>Destinatarios</title></Helmet>
            <div className="TituloPag">
				<p>Mis Destinatarios</p>
			</div>
            <div className="dashboardCard">
                <p className="CardTitulo">Destinatarios</p>
                {loading ? 
                    <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
                    :   <>  
                    <div className="Btns-top">
                        <Link to="/nuevo-destinatario" className="boton-general imgButton-left"><img src={ImgPlus} alt="" />Nuevo Destinatario</Link>
                    </div>
                    {contactos.length > 0 ? 
                        <>
                            <div className="divTableContent">
                                <div className="divTable-head">
                                    <div className="divTable-headRow" id="table6td">
                                        <div>Tipo</div>
                                        <div>Nombre</div>
                                        <div>Número Documento</div>
                                        <div>País</div>
                                        <div>Celular</div>
                                        <div>Email</div>
                                    </div>
                                </div>
                            {getPaginatedData().map((contacto) => {
                                let tipo = contacto.tipo
                                let tipoR = '';
                                if(!tipo){tipoR = ('Persona')}
                                if(tipo){tipoR = tipo}
                                return(
                                    <Link to={`/destinatario/${contacto.id}`} className="divTable-body" key={contacto.id}>
                                        <div className="divTable-row" id="table6td">
                                            <div><label>Tipo</label>{tipoR}</div>
                                            <div><label>Nombre</label>{contacto.nombre.nombre+' '+contacto.nombre.apellido}</div>
                                            <div><label>Número Documento</label>{contacto.documento.numeroDocumento}</div>
                                            <div><label>País</label>{contacto.direccion.pais}</div>
                                            <div><label>Celular</label>{contacto.celular}</div>
                                            <div><label>Email</label>{contacto.email}</div>
                                        </div>
                                    </Link>
                                    )
                                })}
                        </div>
                        <div className="TableFooter">
                            <div className="paginacion">
                                <button onClick={goToPreviousPage} className={`pagPrev ${currentPage === 1 ? 'pagDisabled' : ''}`}><img src={ImgPrev} alt="anterior" /></button>
                                    {paginationGroup ? paginationGroup.map((item, index) => (
                                    <button key={index} onClick={changePage} className={(item > pages ? 'pagBlock' : 'pagNumber') +' '+ (currentPage === item ? 'pagActive' : '')}>
                                    <span>{item}</span>
                                    </button>
                                    )) : <></>}
                                <button onClick={goToNextPage} className={`pagNext ${currentPage === pages ? 'pagDisabled' : ''}`}><img src={ImgNext} alt="siguiente" /></button>
                            </div>
                        </div>
                        </> :   
                        <div className="itemTransferEmpty">
                            <p>Aún no tienes Destinatarios</p>
                            <span>Tus destinatarios más frecuentes apareceran aquí.</span>
                            <Link to="/nuevo-destinatario" className="boton-general imgButton-left"><img src={ImgPlus} alt="" />Agregar</Link>
                        </div>
                    }
                    </>
                }
            </div>
            {infoUser.toSelf && 
                <div className="col-12 col-md-8 p-0">
                    <div className="dashboardCard">
                        <p className="CardTitulo">Cuentas de AutoEnvío</p>
                        <div className="Btns-top">
                            <Link to="/nueva-cuenta-autoenvio" className="boton-general imgButton-left"><img src={ImgPlus} alt="" />Agregar Cuenta</Link>
                        </div>
                        {infoUser.cuentas ? 
                            <>
                                <div className="divTableContent">
                                    <div className="divTable-head">
                                        <div className="divTable-headRow" id="tdCuentas">
                                            <div>Banco</div>
                                            <div>Titular</div>
                                            <div>Bsb</div>
                                            <div>Cuenta</div>
                                        </div>
                                    </div>
                                {Object.keys(infoUser.cuentas).map((cuenta,i) => {
                                    return(
                                        <div className="divTable-body" key={cuenta}>
                                            <div className="divTable-row" id="tdCuentas">
                                                <div><label>Banco</label>{infoUser.cuentas[cuenta].banco}</div>
                                                <div><label>Titular</label>{infoUser.cuentas[cuenta].titular}</div>
                                                <div><label>Bsb</label>{infoUser.cuentas[cuenta].bsb}</div>
                                                <div><label>Cuenta</label>{infoUser.cuentas[cuenta].cuenta}</div>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                                </div>
                            </> :   
                            <div className="itemTransferEmpty">
                                <p>Aún no tienes Destinatarios</p>
                                <span>Tus destinatarios más frecuentes apareceran aquí.</span>
                                <Link to="/nuevo-destinatario" className="boton-general imgButton-left"><img src={ImgPlus} alt="plus" />Agregar</Link>
                            </div>
                        }
                    </div>
                </div>
            }
            
        </>
    );
}

export default Destinatarios;