import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import NumberFormat from 'react-number-format';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import useObtenerReferidos from '../../../hooks/useObtenerReferidos';
import ExportReferidos from './ExportReferidos';
import '../../../../views/components/paginacion/Paginacion.css';
import '../../../../styles/tablaCuentas.css';
import '../../../../client/views/transferencias/transferencia.css';
import ImgNext from '../../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../../img/icons/arrow-left-1.svg';
import ImgExcel from '../../../../img/icons/document-download.svg';
import ImgPdf from '../../../../img/icons/document-text-1.svg';
import Search from '../../../../img/icons/search.svg';
import SelectorSelec from '../../../../views/components/inputs/Selector';

const TransferenciasReferidas = () => {

	//FILTROS POR NOMBRE Y ID
	const [textCod, setTexCod] = useState('');
	const [textId, setTextId] = useState('');

	const [spnTextCod, setSpnTextCod] = useState(false);
	const [spnTextId, setSpnTextId] = useState(false);
	const [altTextCod, setAltTextCod] = useState('');
	const [altTextId, setAltTextId] = useState('');
	
	const [codigoId, setCodigoId] = useState('');
	const [numId, setNumId] = useState('');

	const handleChange = (e) => {
        switch (e.target.name) {
            case 'codigo': setTexCod(e.target.value);setSpnTextCod(false);break;
			case 'numeroid': setTextId(e.target.value);setSpnTextId(false);break;
            default: break;
        }
    }

	const searchByCodigo = (e) => {
		if(!textCod){setSpnTextCod(true);setAltTextCod('Ingresa el Código');return;}
		else{setNumId('');setCodigoId(textCod);}
	}
	const searchById = (e) => {
		if(!textId){setSpnTextId(true); setAltTextId('Ingresa el Id del usuario');
		}else{setCodigoId('');setNumId(textId);}
	}

	const [searchId, setSearchId] = useState(false);
	const [opcionSearch, setOpcionSearch] = useState('Código');
	const tipoSearc = [{ id: '1', texto: 'Código' },{ id: '2', texto: 'Id'},{ id: '3', texto: 'Todos' }]
	const [spnSearch, setSpnSearch] = useState(false);

	useEffect(() =>{
		if(opcionSearch === 'Id'){
			setSearchId(true);setTexCod('');
		}if(opcionSearch === 'Todos'){
            setSearchId(false);setCodigoId('');setNumId('');setTextId('');setTexCod('');
        }else{
			setSearchId(false);setTextId('')}
		},[opcionSearch])

	//DATOS
	const [transacciones, loading] = useObtenerReferidos(codigoId,numId);
	const [data, setData] = useState('');
	const formatearFecha = (fecha) => {return format(fromUnixTime(fecha), "dd/MMM/yy", { locale: es });}

	const [pages, setPages] = useState('');
    const [dataLimit, setDataLimit] = useState('');
    const [pageLimit, setPageLimit] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationGroup, setPaginationGroup] = useState([]);

	//TRANSFORM DATA
	useEffect(()=>{
        if(transacciones){
            const temp = transacciones.map((transaccion)=>{
                return [
					transaccion.id.substring(0, 6).toUpperCase(),
					transaccion.transferencia.metodo,
					formatearFecha(transaccion.fecha),
					transaccion.remitente.nombre + ' ' + transaccion.remitente.apellido,
					transaccion.destinatario.nombre + ' ' + transaccion.destinatario.apellido,
					transaccion.razon,
					transaccion.transferencia.emailCop,
					transaccion.transferencia.cargos,
					transaccion.transferencia.emailTasa,
					transaccion.transferencia.fee,
					transaccion.transferencia.emailAud,
					transaccion.estado,
					transaccion.cuenta.banco,
					transaccion.cuenta.bsb,
					transaccion.cuenta.numeroCuenta,
					transaccion.cuenta.titular,
					transaccion.referido
				]
            });
            setData(temp);
        }
    },[transacciones])

	//EXPORTAR A PDF
	const DownloadPDF = async() =>{
        const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'tabloid'})
        doc.autoTable({
            head: [["Referencia","Método","Fecha","Remitente","Destinatario","Razón del envio","Pagado",
				"Cargos","Tasa","Servicio","Reciben","Estado","Banco Destino","BSB","Número de Cuenta","Titular","Asesor"]],
            body: data
        })
        doc.save('Transferencias Referidas.pdf');
    }

	//PAGINACIÓN
	useEffect(()=>{
        if(transacciones.length <= 10){
			setDataLimit(transacciones.length);
        }else{
			setDataLimit(10);
		}
    },[transacciones])

    useEffect(() => {
        setPages(Math.ceil(transacciones.length / dataLimit));
        setPageLimit(4);
    },[transacciones, dataLimit])

	useEffect(() => {
        if(pageLimit){
            const grupoNumeros = () => {
                let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
                return new Array(pageLimit).fill().map((_, idx) => start + idx + 1 );
            };
            setPaginationGroup(grupoNumeros);
        }
    }, [pageLimit, currentPage]);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage]);

    const goToNextPage = () => {setCurrentPage((page) => page + 1);};
    const goToPreviousPage = () => {setCurrentPage((page) => page - 1);};
      
    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return transacciones.slice(startIndex, endIndex);
    };

	return (
		<>
			<div className="dashboardCard">
				<p className="CardTitulo">Transferencias</p>
				<div className="SearchBox">
					<div className="row p-0">
						<div className="col-12 col-md-3"><div className="input-select">
							<SelectorSelec opciones={tipoSearc} TipoOpcion={'Buscar Por'} opcion={opcionSearch} 
								cambiarOpcion={setOpcionSearch} spnRed={spnSearch} setSpnRed={setSpnSearch} />
						</div></div>
						{!searchId ? <>
							<div className="col-12 col-sm-6 col-md-3"><div className={!spnTextCod ? 'input-general': 'input-general redLine'}>
								<input type='text' name='codigo' value={textCod} placeholder=" " onChange={handleChange} autoComplete="off"></input>
								<label>Código</label>
								{spnTextCod && <span className="spanAlert">{altTextCod}</span>}
							</div></div>
						</>:<>
							<div className='col-12 col-md-3'><div className={!spnTextId ? 'input-general': 'input-general redLine'}>
								<input type='text' name='numeroid' value={textId} placeholder=" " onChange={handleChange} autoComplete="off"></input>
								<label>Id</label>
								{spnTextId && <span className="spanAlert">{altTextId}</span>}
							</div></div>
						</>}
						<div className='col-12 col-md-3'>
							<button className='boton-general imgButton-left' type="button" onClick={!searchId ? searchByCodigo : searchById}>
								<img src={Search} alt="cancelar"/>Buscar</button>
						</div>
					</div>
				</div>
                {loading ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
				: <>{transacciones.length > 0 ? 
						<>
							<div className="divTableContent">
								<div className="divTable-head">
									<div className="divTable-headRow" id="table9td">
										<div>Referencia</div>
										<div>Fecha</div>
										<div>Remitente</div>
										<div>Destinatario</div>
										<div className="tdsal">Tasa</div>
										<div className="tdsal">Pagado</div>
										<div className="tdsal">Reciben</div>
										<div>Código</div>
									</div>
								</div>
							{getPaginatedData().map((transaccion) => {
								return(
									<Link to={`/transferencia/${transaccion.id}`} className="divTable-body" key={transaccion.id}>
										<div className="divTable-row" id="table9td">
											<div><label>Referencia</label>{transaccion.id.substring(0, 6).toUpperCase()}</div>
											<div><label>Fecha</label>{formatearFecha(transaccion.fecha).toUpperCase()}</div>
											<div><label>Remitente</label>{transaccion.remitente.nombre + ' ' + transaccion.remitente.apellido}</div>
											<div><label>Destinatario</label>{transaccion.destinatario.nombre + ' ' + transaccion.destinatario.apellido}</div>
											<div className="tdNone tdsal"><label>Tasa</label>
												<NumberFormat value={transaccion.transferencia.tasa} displayType={'text'} thousandSeparator={'.'} 
													decimalSeparator={','} decimalScale={'2'} prefix={'$'} suffix={' COP'} />
											</div>
											<div className="tdsal"><label>Pagado</label>
												<NumberFormat value={transaccion.transferencia.totalPagado} displayType={'text'} thousandSeparator={'.'} 
													decimalSeparator={','} decimalScale={'2'} prefix={'$'} suffix={' COP'} />
											</div>
											<div className="tdsal"><label>Reciben</label>
												<NumberFormat value={transaccion.transferencia.montoAud} displayType={'text'} thousandSeparator={','} 
													decimalSeparator={'.'} decimalScale={'2'} prefix={'$'} suffix={' AUD'} />
											</div>
											<div><label>Código</label>
												<span className={transaccion.estado}>&#9679;</span>{transaccion.referido}
											</div>
										</div>
									</Link>
								);
							})}
							</div>
							<div className="TableFooter">
								<div className="Btns-Export">
									<button className="BtnExport BtnExcel" onClick={()=> ExportReferidos({data})}><img src={ImgExcel} alt="" />Exportar a Excel</button>
									<button className="BtnExport BtnPdf" onClick={DownloadPDF}><img src={ImgPdf} alt="" />Exportar a PDF</button>
								</div>

								<div className="paginacion">
									<button onClick={goToPreviousPage} className={`pagPrev ${currentPage === 1 ? 'pagDisabled' : ''}`}><img src={ImgPrev} alt="anterior" /></button>
										{paginationGroup ? paginationGroup.map((item, index) => (
											<button key={index} onClick={changePage} className={(item > pages ? 'pagBlock' : 'pagNumber') +' '+ (currentPage === item ? 'pagActive' : '')}>
												<span>{item}</span>
											</button>
										)) : <></>}
									<button onClick={goToNextPage} className={`pagNext ${currentPage === pages ? 'pagDisabled' : ''}`}><img src={ImgNext} alt="siguiente" /></button>
								</div>
							</div>
						</> :   
						<div className="itemTransferEmpty">
							<p>No se encontraron Transferencias</p>
						</div>
					}
				</> 
				}
			</div>
		</>		
	);
}

export default TransferenciasReferidas;