import React from "react";
import { Link } from 'react-router-dom';
import Home from '../../../../img/icons/home.svg';
import Whatsapp from '../../../../img/icons/whatsapp.svg';

const CuentaSuspendida = () =>{
    return(
        <>
            <div className="formData">
                <div className="formProgress">
                    <h3>¡Cuenta Suspendida!</h3>
                </div>
                <div className="formContent">
                    <div className="formTitle">
                        <h1 className="text-title">Contáctanos</h1>
                        <p>Escríbenos para recibir más información del estado de tu cuenta.</p>
                        <div>
                            <a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className="boton-general imgButton-left">
                            <img src={Whatsapp} alt="Wsapp" />Escríbenos</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divSalir">
                <Link to="/dashboard" className="boton-text imgButton-rigth">Ir al Inicio<img src={Home} alt="Salir" /></Link> 
            </div>
        </>
    )
}

export default CuentaSuspendida