import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from 'react-router-dom';
import Router from './router/Router';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './context/AuthContext';
import { TransProvider } from './context/TransferContext';
import './index.css';
import './styles/App.css';
import './styles/Inicio.css';
import './styles/styles.css';
import './styles/bootstrap-grid.css';
import './styles/bootstrap-grid.css.map';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <TransProvider>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </TransProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
