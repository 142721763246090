import React, { useState, useEffect } from 'react';
import './selector.css';
import arrowDown from '../../../img/icons/arrow-down.svg';

const SelectorCuentas = ({ opciones, setDatosCuenta, TipoOpcion, opcion, cambiarOpcion, spnRed, setSpnRed }) => {

    const [mostrarSelect, cambiarMostrarSelect] = useState(false);
    const [label, setLabel] = useState(false);

    const Seleccion = (e) =>{cambiarMostrarSelect(!mostrarSelect);setSpnRed(false)}
    const LeaveSelect = (e) =>{cambiarMostrarSelect(false)}
    
    useEffect(() => {
        if(opcion){setLabel(true);}
        else{setLabel(false);}
    }, [opcion])

    const handleClick = (e) => {
        setLabel(true); 
        cambiarOpcion(e.currentTarget.dataset.valor);
        setDatosCuenta({
            banco: e.currentTarget.dataset.banco,
            titular: e.currentTarget.dataset.titular,
            bsb: e.currentTarget.dataset.bsb,
            numeroCuenta: e.currentTarget.dataset.cuenta
        })
    }

    return (
        <div className="Selec" onClick={Seleccion} onMouseLeave={LeaveSelect}>
            <div className={(!mostrarSelect ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(label ? 'Selec_blue' : '')+' '+(spnRed ? 'redWarn' : '')}>
                <label className={!label ? 'Label_in' : 'Label_out'}>{TipoOpcion}</label>{opcion}
            </div>
            <img className={!mostrarSelect ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} alt="Desplegar"/>
            {mostrarSelect &&
                <div className="Selec_opciones">
                    {Object.keys(opciones).map((opcion, i) => {
                        return <div 
                        key={opcion} 
                        data-valor={opciones[opcion].cuenta}
                        data-banco={opciones[opcion].banco}
                        data-titular={opciones[opcion].titular}
                        data-bsb={opciones[opcion].bsb}
                        data-cuenta={opciones[opcion].cuenta} 
                        onClick={handleClick} 
                        className="Select_opcion">
                        {opciones[opcion].cuenta}</div> 
                    })}
                </div>
            }
        </div>
    );
}

export default SelectorCuentas;