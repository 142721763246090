import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';

const useObtenerReferidos = (codigoId) => {

    const {infoUser} = useAuth();
    const [transacciones, setTransacciones] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        if(infoUser.rol === 'Administrador'){
            if(codigoId){
                const unsuscribe = db.collection('transferencias')
                .where('referido','==', codigoId)
                .where('estado', '==', 'Procesada')
                .orderBy('fecha','desc')
                .onSnapshot((snapshot) => {
                    setLoading(false);
                    setTransacciones(snapshot.docs.map((transaccion) => {
                        return {...transaccion.data(), id: transaccion.id}
                    }));
                });
                return unsuscribe;
            }else{
                const unsuscribes = db.collection('referidos')
                .onSnapshot((snapshot) => {
                    let referidos 
                    referidos = snapshot.docs.map((codigo) => {
                        return codigo.id
                    });
                    const unsuscribe = db.collection('transferencias')
                        .where('referido', 'in', referidos.slice(0,10))
                        .where('estado', '==', 'Procesada')
                        .orderBy('fecha','desc')
                        .onSnapshot((snapshot) => {
                            setLoading(false);
                            setTransacciones(snapshot.docs.map((transaccion) => {
                                return {...transaccion.data(), id: transaccion.id}
                            }));
                        });
                    return unsuscribe;
                });
                return unsuscribes;
            }
        }
    }, [infoUser,codigoId]);

    return [transacciones, loading];
}

export default useObtenerReferidos;