import { db } from "./firebaseConfig";

const AgregarRegistro = (props) => {
    const{
        fechaRegistro,
        usuario,
        rol,
        pep,
        toSelf,
        destino,
        contactos,
        fechaNacimiento,
        email,
        celular,
        numero,
        nombre,
        documento,
        redes,
        direccion,
        lastSign
    } = props
    
    return db.collection('usuarios').doc(usuario).set({
        fechaRegistro: fechaRegistro,
        usuario: usuario,
        rol: rol,
        pep: pep,
        toSelf: toSelf,
        destino: destino,
        contactos: contactos,
        fechaNacimiento: fechaNacimiento,
        email: email,
        celular: celular,
        nombre: nombre,
        numero: numero,
        documento: documento,
        redes: redes,
        direccion: direccion,
        lastSign: lastSign
    });
}

const AutoEnvio = (props) => {
    const{
        usuario,
        toSelf,
        destino,
        contactos,
        autoEnvio
    } = props

    return db.collection('usuarios').doc(usuario).update({
        toSelf: toSelf,
        destino: destino,
        contactos: contactos,
        autoEnvio: autoEnvio
    });
}

export {AgregarRegistro, AutoEnvio};