import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';

const useObtenerCodigo = (code) =>{

    const [codigo, setCodigo] = useState('');
    const [find, setFind] = useState('inicial');

    useEffect(()=>{
        if(code){
            const unsuscribe = db.collection('referidos').doc(code).get()
            .then((doc) => {
                if(doc.exists){
                    if(doc.data().estado === 'Activo'){
                        setFind('encontrado');
                        setCodigo(doc);
                    }else{
                        setFind('noEncontrado');
                        setCodigo('');
                    }
                } else{
                    setFind('noExiste');
                    setCodigo('');
                }
            })
            return unsuscribe;
        }
    },[code]);

    return [codigo, find, setFind]
}

const useObtenerPromo = (code, usuario) =>{
    const [codigo, setCodigo] = useState('');
    const [find, setFind] = useState('inicial');
    const [userCod, setUserCod] = useState('');

    useEffect(()=>{
        if(code){
            const unsuscribe = db.collection('codigos').doc(code).get()
            .then((doc) => {
                if(doc.exists){
                    const infor = doc;
                    const estado = doc.data().estado;
                    const tipo = doc.data().tipo;
                    if(estado === 'Inactivo'){
                        setFind('noDisponible'); setCodigo('');
                    }if(estado === 'Usado'){
                        setFind('usado'); setCodigo('');
                    }if(estado === 'Activo'){
                        if(tipo === 'Promocional'){
                            const unsuscribe = db.collection('promociones').doc(usuario).get()
                            .then((doc) => {
                                if(doc.exists){
                                    if(doc.data()[code] === 'Usado'){
                                        setFind('usado'); setCodigo('')
                                    }else{ 
                                        setFind('libre'); setCodigo(infor);
                                        setUserCod('second')
                                    }
                                }else{ 
                                    setFind('libre'); setCodigo(infor);
                                    setUserCod('first')
                                }
                            })
                            return unsuscribe;
                        } 
                        if(tipo === 'Especial'){setFind('libre'); setCodigo(infor); }
                    }
                }else{
                    setFind('noExiste');
                    setCodigo('');
                }
            })
            return unsuscribe;
        }
    },[code, usuario]);

    return [codigo, find, setFind, userCod]
}

const useObtenerCuentas = () =>{

    const [cuentas, setCuentas] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const unsuscribe = db.collection('cuentas')
        .onSnapshot((snapshot) => {
            setLoading(false);
            setCuentas(snapshot.docs.map((cuenta) => {
                return {...cuenta.data(), id: cuenta.id}
            }));
        });
        return unsuscribe;
    },[]);

    return [cuentas, loading]

}

export {useObtenerCodigo, useObtenerCuentas, useObtenerPromo};
