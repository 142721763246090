import React from 'react';
import { CuentaEliminada, HuboUnError } from '../../Mensajes';
import ModalValidar from '../../../../views/components/modales/MoldaValidar';
import { BorrarAgencia } from '../../../data/AdminAgencias';
import DivLoading from '../../../../views/components/loading/Loading';

const Eliminar = ({OpenModalNuevo, CerrarModal, idDelete}) =>{

    const Delete = () =>{
        OpenModalNuevo(<DivLoading />)
        BorrarAgencia({
            id: idDelete
        }).then(() => {
            OpenModalNuevo(<CuentaEliminada CerrarModal={CerrarModal}/>);
        }).catch(() => {
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
        });
    }

    return(
        <ModalValidar 
            accion={Delete} 
            titulo={'Eliminar Agencia'}
            mensaje={'¿Deseas Eliminar la agencia de la lista?'}
            botonName={'Sí, Eliminar'}
            botonClass={'ButtonRed'}
            CerrarModal={CerrarModal}
        />
    )
}

export default Eliminar;