import React, { useState, useContext, useEffect } from 'react';
import { auth, db } from '../firebase/firebaseConfig';
import { useHistory } from 'react-router';
import { getUnixTime } from 'date-fns';
// import LoadingPage from '../views/components/loading/LoadingPage';

const AuthContext = React.createContext();

const useAuth = () => {
    return useContext(AuthContext);
}

const AuthProvider = ({children}) => {
    const [usuario, cambiarUsuario] = useState();
    const [cargando, cambiarCargando] = useState(true);
    const [infoUser, setInfoUser] = useState();
    const history = useHistory();

    const cerrarSesion = async () => {
        try {
            await auth.signOut();
            window.scrollTo({ behavior: 'smooth', top: '0px' });
            history.push('/');
            setInfoUser();
            cambiarUsuario();
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        const cancelarSuscripcion = auth.onAuthStateChanged(async(user) => {
            cambiarUsuario(user);
            cambiarCargando(false);
        });
        return cancelarSuscripcion;
    }, []);

    useEffect(()=>{
        if(usuario){
            if(!usuario.emailVerified){
                history.push('/verificar-email');
                cambiarCargando(false);
            }else{
                if(!infoUser){
                    const unsuscribe = db.collection('usuarios').doc(usuario.uid).get()
                    .then(async(doc)=>{
                        if(doc.exists){
                            setInfoUser(doc.data())
                            // if (!doc.data().verificado){
                            //     history.push('/verificacion')
                            // }
                            await db.collection('usuarios').doc(usuario.uid).update({
                                lastSign: getUnixTime(new Date())
                            })
                            cambiarCargando(false)
                        }
                        else{
                            history.push('/terminar-registro');
                            cambiarCargando(false);
                        }
                    }).catch(err =>{
                        console.log(err);
                        cambiarCargando(false);
                    })
                    return unsuscribe;
                }
            }
        }
    },[usuario, history, infoUser]);

    return(
        <AuthContext.Provider value={{usuario, infoUser, cerrarSesion, setInfoUser, cargando}}>
            {children}
        </AuthContext.Provider>
    );
}

export {AuthProvider, AuthContext, useAuth};