import React, { useState, useEffect} from 'react';
import { useObtenerCuentas } from '../../hooks/useObternerDatos';
import '../../../views/components/inputs/selector.css';
import arrowDown from '../../../img/icons/arrow-down.svg';

const SelectCuenta = ({cuenta, setCuenta, spnRed, setSpnRed, setColcambios}) => {
    
    const [cuentas] = useObtenerCuentas();
    const [id, setId] = useState();
    const [mostrarSelect, cambiarMostrarSelect] = useState(false);
    const [label, setLabel] = useState(false);

    const Seleccion = (e) =>{cambiarMostrarSelect(!mostrarSelect); setSpnRed(false)}
    const LeaveSelect = (e) =>{cambiarMostrarSelect(false)}

    useEffect(() => {
        if(cuenta){setLabel(true);}
        else{setLabel(false);}
    }, [cuenta])

    const handleClick = (e) => {
        setLabel(true);
        setCuenta(e.currentTarget.dataset.banco);
        setId(e.currentTarget.dataset.record);
        setColcambios({
            banco: e.currentTarget.dataset.banco,
            tipoCuenta: e.currentTarget.dataset.tipo,
            numero: e.currentTarget.dataset.numero,
            titular: e.currentTarget.dataset.titular,
            id: e.currentTarget.dataset.id,
        })
    }

    return (
        <div className="Selec" onClick={Seleccion} onMouseLeave={LeaveSelect}>
            <div className={(!mostrarSelect ? 'Selec_select' : 'Selec_select Selec_Open Selec_blue')+' '+(label ? 'Selec_blue' : '')+' '+(spnRed ? 'redWarn' : '')}>
                <label className={!label ? 'Label_in' : 'Label_out'}>Cuenta Colcambios</label>{cuenta}
            </div>
            <img className={!mostrarSelect ? 'arrowSelect' : 'arrowSelect arrowRotate'} src={arrowDown} alt="Desplegar"/>
            {mostrarSelect &&
                <div className="Selec_opciones">
                    {cuentas.map((cuenta) => {
                        return <div
                            key={cuenta.id}
                            data-record = {cuenta.id}
                            data-banco={cuenta.nombreBanco}
                            data-tipo={cuenta.tipoCuenta}
                            data-numero={cuenta.numeroCuenta}
                            data-titular={cuenta.titularCuenta}
                            data-id={cuenta.tipoId+' '+cuenta.idTitular}
                            onClick={handleClick}
                            className="Select_opcion">
                            {cuenta.nombreBanco}</div>
                    })}
                </div>
            }
            {id && <>
                {cuentas.filter(item => item.id === id).map((item,i) => {
                    return(
                        <div className="Cuentas" key={i}>
                            <p className="CuentasBanco">{item.nombreBanco}</p>
                            <p className="CuentasTipo">{item.tipoCuenta}<br/>{item.numeroCuenta}</p>
                            <p><span>Titular</span>{item.titularCuenta}</p>
                            <p><span>Id</span>{item.tipoId+' '+item.idTitular}</p>
                        </div>
                    )
                })}
            </>}
        </div>
    );
}

export default SelectCuenta;
