import React from 'react';
import imgMail from '../../img/icons/sms-tracking.svg';
import imgWrong from '../../img/icons/sms-wrong.svg';
import imgQuestions from '../../img/icons/messages-1.svg';
import Whatsapp from '../../img/icons/whatsapp.svg';
import imgWarning from '../../img/icons/warning-2.svg';

const CorreoVerificacion = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgMail} alt="Mail Send" />
            </div>
            <h2>Correo Enviado</h2>
            <p>El link de verificación fue<br/>enviado a tu correo electrónico</p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    )
}

const CorreoClave = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgMail} alt="Mail Send" />
            </div>
            <h2>Correo Enviado</h2>
            <p>El link de recuperación de<br/>tu contraseña fue enviado.</p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    )
}

const HuboUnError = ({CerrarModal}) =>{
    return(
        <div className="InnerModal InnerWrong">
            <div className="IconModal IconWrong">
                <img src={imgWrong} alt="Mail Send" />
            </div>
            <h2>Oops!</h2>
            <p>Algo salió mal..<br/><b>Inténtalo de nuevo</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const TransaccionMayor = ({CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <div className="IconModal">
                <img src={imgQuestions} alt="Mail Send" />
            </div>
            <h2>¡Contáctanos!</h2>
            <p>Para transferencias superiores a <b>$10,000 AUD</b> debes de <b>contáctarnos.</b></p>
            <a href="https://api.whatsapp.com/send?phone=61481878097&amp;text=" target="_blank" rel="noreferrer" className="boton-general imgButton-left">
                <img src={Whatsapp} alt="Wsapp" />Escríbenos</a>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

const MinimoPermitido = ({CerrarModal}) =>{
    return(
        <div className="InnerModal InnerWrong">
            <div className="IconModal IconWrong">
                <img src={imgWarning} alt="Wrong" />
            </div>
            <h2>Oops!</h2>
            <p>El Valor Máximo en método UltraRápido<br/>por día es de <b>$4,999 AUD</b></p>
            <button className="btn-text" onClick={CerrarModal}>Cerrar</button>
        </div>
    );
}

export {CorreoVerificacion, CorreoClave, HuboUnError, TransaccionMayor, MinimoPermitido};