import { db } from '../../firebase/firebaseConfig';

const SuspenderUser = ({id, razonBlq})=> {
    return db.collection('usuarios').doc(id).update({
        suspendido: true,
        razonBlq: razonBlq
    })
}

const HabilitarUser = ({id})=> {
    return db.collection('usuarios').doc(id).update({
        suspendido: false,
        razonBlq: ''
    })
}

export { SuspenderUser, HabilitarUser };