import React, {useEffect, useState} from 'react';

const AdrresAu = ({setDireccion, CerrarModal}) =>{

    const [apartment, setAparment] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [unit, setUnit] = useState('');
    const [streetName, setStreetName] = useState('');
    const [suburb, setSuburb] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [adrresAu, setAdrresAu] = useState(unit+' '+streetName+' '+suburb+' '+state+' '+postalCode);
    
    const [spnStreetNum, setSpnStreetNum] = useState(false);
    const [spnStreetNam, setSpnStreetNam] = useState(false);
    const [spnSuburb, setSpnSuburb] = useState(false);
    const [spnState, setSpnState] = useState(false);
    const [spnPostal, setSpnPostal] = useState(false);
    
    const handleChange = (e) => {
        switch (e.target.name) {
            case 'apartment': setAparment(e.target.value); break;
            case 'streetNumber': setStreetNumber(e.target.value); setSpnStreetNum(false); break;
            case 'streetName': setStreetName(e.target.value); setSpnStreetNam(false); break;
            case 'suburb': setSuburb(e.target.value); setSpnSuburb(false); break;
            case 'state': setState(e.target.value); setSpnState(false); break;
            case 'postalCode': setPostalCode(e.target.value); setSpnPostal(false); break;
            default: break;
        }
    }

    useEffect(()=>{
        if(apartment){
            setUnit(apartment +'/'+streetNumber);
        }else{
            setUnit(streetNumber);
        }
    },[apartment, streetNumber]);

    useEffect(()=>{
        setAdrresAu(unit+' '+streetName+' '+suburb+' '+state+' '+postalCode);
    },[unit,streetName,suburb,state,postalCode]);

    const handleSubmit = (e) =>{
        if(streetNumber === ''){setSpnStreetNum(true); return;};
        if(streetName === ''){setSpnStreetNam(true); return;};
        if(suburb === ''){setSpnSuburb(true); return;};
        if(state === ''){setSpnState(true); return;};
        if(postalCode === ''){setSpnPostal(true); return;};
        setDireccion(unit+' '+streetName+' '+suburb+' '+state+' '+postalCode);
        CerrarModal(e);
    }

    return(
        <div className="row p-0">
            <div className="col-12">
                <p className="CardTitulo">Ingresa la Dirección</p>
                <p className="textNormal">Los campos marcados con * son obligatorios</p>
            </div>
            <div className="col-12 col-sm-6">
                <div className="input-general">
                    <input type="text" name="apartment" placeholder=" " value={apartment} onChange={handleChange} />
                    <label>Apartamento, piso o unidad</label>
                </div>
            </div>
            <div className="col-12 col-sm-6">
                <div className={!spnStreetNum ? 'input-general': 'input-general redLine'}>
                    <input type="text" name="streetNumber" placeholder=" " value={streetNumber} onChange={handleChange} />
                    <label>Número de la Calle*</label>
                    {spnStreetNum && <span className="spanAlert">Este campo es Obligatorio</span>}
                </div>
            </div>
            <div className="col-12 col-sm-6">
                <div className={!spnStreetNam ? 'input-general': 'input-general redLine'}>
                    <input type="text" name="streetName" placeholder=" " value={streetName} onChange={handleChange} />
                    <label>Nombre de la Calle*</label>
                    {spnStreetNam && <span className="spanAlert">Este campo es Obligatorio</span>}
                </div>
            </div>
            <div className="col-12 col-sm-6">
                <div className={!spnSuburb ? 'input-general': 'input-general redLine'}>
                    <input type="text" name="suburb" placeholder=" " value={suburb} onChange={handleChange} />
                    <label>Suburbio o Ciudad*</label>
                    {spnSuburb && <span className="spanAlert">Este campo es Obligatorio</span>}
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className={!spnState ? 'input-general': 'input-general redLine'}>
                    <input type="text" name="state" placeholder=" " value={state} onChange={handleChange} />
                    <label>Estado*</label>
                    {spnState && <span className="spanAlert">Este campo es Obligatorio</span>}
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className={!spnPostal ? 'input-general': 'input-general redLine'}>
                    <input type="text" name="postalCode" placeholder=" " value={postalCode} onChange={handleChange} />
                    <label>Código Postal*</label>
                    {spnPostal && <span className="spanAlert">Este campo es Obligatorio</span>}
                </div>
            </div>
            <div className="col-12">
                <div className="input-disabled">
                    <input type="text" name="addressAu" placeholder=" " value={adrresAu} disabled/>
                    <label>Dirección</label>
                </div>
            </div>
            <div className="col-12">
                <button className="boton-general BtnLarge" onClick={handleSubmit}>Aceptar</button>
            </div>
        </div>
    )
}

export default AdrresAu;