import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';

const useObtenerTransacciones = (filtro,name,surname,idSearch) => {

    const {infoUser} = useAuth();
    const [transacciones, setTransacciones] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        if(infoUser.rol === 'Administrador'){
            if(name){
                const unsuscribe = db.collection('transferencias')
                .where('remitente.nombre','==', name)
                .where('remitente.apellido', '==', surname)
                .where('estado', 'in', filtro)
                .orderBy('fecha','desc')
                .onSnapshot((snapshot) => {
                    setLoading(false);
                    setTransacciones(snapshot.docs.map((transaccion) => {
                        return {...transaccion.data(), id: transaccion.id}
                    }));
                });
                return unsuscribe;
            }if(idSearch){
                const unsuscribe = db.collection('transferencias')
                .where('usuario','==', idSearch)
                .where('estado', 'in', filtro)
                .orderBy('fecha','desc')
                .onSnapshot((snapshot) => {
                    setLoading(false);
                    setTransacciones(snapshot.docs.map((transaccion) => {
                        return {...transaccion.data(), id: transaccion.id}
                    }));
                });
                return unsuscribe;
            }else{
                const unsuscribe = db.collection('transferencias')
                .where('estado', 'in', filtro)
                .orderBy('fecha','desc')
                .onSnapshot((snapshot) => {
                    setLoading(false);
                    setTransacciones(snapshot.docs.map((transaccion) => {
                        return {...transaccion.data(), id: transaccion.id}
                    }));
                });
                return unsuscribe;
            }
        }
    }, [infoUser,filtro,name,surname,idSearch]);

    return [transacciones, loading];
}

export default useObtenerTransacciones;