import React from 'react';

const Inputs = (props) =>{

    const {
        name,
        type,
        label,
        value,
        setValue,
        alt,
        spn,
        setSpn,
        fill 
    }= props;

    const handleChange = (e) =>{setValue(e.target.value); setSpn(false);}

    return(
        <div className={!spn ? 'input-general': 'input-general redLine'}>
            <input name={name} type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
            <label>{label}</label>
            {spn && <span className="spanAlert">{alt}</span>}
        </div>
    )
}

export default Inputs;