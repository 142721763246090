import React from "react";

const ModalValidar = ({accion,titulo,mensaje, botonName, botonClass, CerrarModal}) =>{
    return(
        <div className="InnerModal">
            <h1>{titulo}</h1>
            <p>{mensaje}</p>
            <div className="validarButton">
                <button type='button' className="BtnCancelar" onClick={CerrarModal}>Cancelar</button>
                <button type='button' className={botonClass} onClick={accion}>{botonName}</button>
            </div>
        </div>
    )
}

export default ModalValidar;