import React, { useState, useContext, useEffect} from 'react';
import useFees from '../admin/components/fees/useFees';
import useRate from '../hooks/useRate';
import { useIdleTimer } from 'react-idle-timer';

//API
var ApiConfig = {
	endPoint: process.env.REACT_APP_CURRENCIES_ENDPOINT,
	apiKey: process.env.REACT_APP_CURRENCIES_ACCESS_KEY,
	apiCurrency: process.env.REACT_APP_APY_CURRENCY
}

const BASE_URL = `https://apilayer.net/api/${ApiConfig.endPoint}?access_key=${ApiConfig.apiKey}&currencies=${ApiConfig.apiCurrency}`

const TransferContext = React.createContext();
const useTrans = () => {
    return useContext(TransferContext);
}

const TransProvider = ({children}) => {
    
    const [rate, tasa, tasaPre] = useRate();
    const [ultraRate] = useFees();
    const [ultrarapido, setUltrarapido] = useState();
    const [generalRate, setGeneralRate] = useState();
    const [tasaFija, setTasaFija] = useState(0);
    const [tasadeCambio, setTasadeCambio] = useState();
    const [tasaOriginal, setTasaOriginal] = useState();
    const [ammountCop, setAmmountCop] = useState();
    const [tasaFix, setTasaFix] = useState();
    const [ammountAud, setAmountAud] = useState(1);
    const [destinoAu, setDestinoAu] = useState();
    const [feeMethod, setFeeMethod] = useState();
    const [feeInclude, setFeeInclude] = useState();

    useEffect(()=>{
        setUltrarapido(ultraRate);
    },[ultraRate])

    useEffect(()=>{
        if(tasa){setTasaFija(tasa);}
        if(rate){setGeneralRate(rate);}
    },[rate, tasa])

    const truncate = (n, digits) => {
        var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
            m = n.toString().match(re);
        return m ? parseFloat(m[1]) : n.valueOf();
    };

    const [cop, setCop] = useState();
    const [aud, setAud] = useState();
    
    const FijarValor = (cop, aud) => {
        setAmmountCop(cop);
        setAmountAud(aud);
    }
    
    const FijarServicio = (method, coMethod) =>{
        setFeeMethod(method);
        setFeeInclude(coMethod);
    }

    const FijarDestino = (id) =>{
        setDestinoAu(id);
    }

    useEffect(() => {
		fetch(BASE_URL)
		  .then(res => res.json())
            .then(data => {
                setCop(data.quotes[Object.keys(data.quotes)[0]]);
                setAud(data.quotes[Object.keys(data.quotes)[1]]);
            })
	})

    const handleOnActive = () => {
        if(rate){
            const fijarTasa = () =>{
                fetch(BASE_URL)
                  .then(res => res.json())
                  .then(data => {
                    setCop(data.quotes[Object.keys(data.quotes)[0]]);
                    setAud(data.quotes[Object.keys(data.quotes)[1]]);
                })
            }
            return fijarTasa;
        }
    }

    useIdleTimer({
        onActive: handleOnActive,
        timeout: 900000,
    })

    useEffect(()=>{
        if(aud || cop){
            setTasaFix(aud/cop);
        }
    },[aud, cop])

    useEffect(()=>{
        if(tasa){
            setTasadeCambio(tasa)
        }else{
            if(tasaFix){
                if(rate){
                    const plus = truncate(tasaFix,2) * rate;
                    const result = truncate(tasaFix,2) + truncate(plus,2)
                    if(result <= 2800){setTasadeCambio(2800)
                    }else{setTasadeCambio(result)}
                }else{
                    const plus = truncate(tasaFix,2) * 0.02; 
                    const result = truncate(tasaFix,2) + truncate(plus,2)
                    if(result <= 2800){setTasadeCambio(2800)
                    }else{setTasadeCambio(result)}
                }
            }
        }
    },[tasaFix, rate, tasa])

    useEffect(() =>{
        if(tasaFix){
            setTasaOriginal(truncate(tasaFix,2))
        }
    },[tasaFix])

    return(
        <TransferContext.Provider value={{tasadeCambio, 
            FijarValor,
            ultrarapido,
            setUltrarapido, 
            ammountAud, 
            ammountCop, 
            FijarDestino, 
            destinoAu, 
            FijarServicio, 
            feeMethod, 
            feeInclude, 
            generalRate,
            tasaPre, 
            tasaFija, 
            tasaOriginal}}>{children}
        </TransferContext.Provider>
    );
}

export {TransProvider, TransferContext, useTrans};