import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { firebase } from '../../../../firebase/firebaseConfig';
import { AgregarPago } from '../../../data/AdminTransfer';
import { HuboUnError, ProcesadaOk } from '../../../components/Mensajes';
import FileDocument from '../../../../views/components/files/FileDocument';
import ImgOk from '../../../../img/icons/tick-square.svg';
import SelectorSelec from '../../../../views/components/inputs/Selector';
import ModalValidar from '../../../../views/components/modales/MoldaValidar';
import DivLoading from '../../../../views/components/loading/Loading';

const SubirFactura = (props) =>{

    const{
        OpenModalNuevo, 
        CerrarModal,
        fechaEmail,
        id,
        rteAppId,
        idNuevo,
        rteEmail,
        rteNombre,
        emailAud,
        emailTasa,
        dnoNombre,
        dnoApellido,
        metodo,
        razonTrans,
        dnoEmail,
        rteApellido,
        setPagoTrans,
        setFactura,
        setEstadoTrans
    } = props;

    const [file, setFile] = useState();
    const [altFile, setAltFile] = useState();
    const [spnFile, setSpnFile] = useState(false);
    const [progresFile, setProgresFile] = useState(0);
    useEffect(()=>{setSpnFile(false);},[file]);

    const [metodoPago, setMetodoPago] = useState('');
    const [altMetodo, setAltMetodo] = useState('');
    const [spnMetodo, setSpnMetodo] = useState(false);

    const metodos = [
        { id: '1', texto: 'Transferencia' },
        { id: '2', texto: 'Efectivo' },
    ]

    useEffect(()=>{if(metodoPago){setSpnMetodo(false);}},[metodoPago])

    const SubirPago = () =>{
        OpenModalNuevo(<DivLoading />)
        const fechaTrans = fechaEmail.toUpperCase();
        const pago= file[0];
        const nombreArchivo = 'pago'+id+'.'+file[0].name.split('.').pop();
        const StorageRef = firebase.storage().ref(rteAppId + '/transferencias/' + nombreArchivo);
        const uploadTask = StorageRef.put(pago);
        uploadTask.on( "state_changed", snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresFile(progress);
            }, error => {
                OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    AgregarPago({
                        id: id, 
                        pagoTrans: url,
                        pagoMetodo: metodoPago, 
                        factura: true,
                        estado:'Procesada'
                    }).then(async() =>{
                        await axios.post('https://server-colcambios.herokuapp.com/api/exito', 
                            {idNuevo,rteEmail,rteNombre,emailAud,emailTasa,fechaTrans,dnoNombre,dnoApellido,metodo,razonTrans})
                        .then(res =>{
                            if(res.data.success){
                                axios.post('https://server-colcambios.herokuapp.com/api/notice', 
                                {idNuevo, dnoEmail, dnoNombre, rteNombre, rteApellido, emailAud, fechaTrans, razonTrans})
                                OpenModalNuevo(<ProcesadaOk CerrarModal={CerrarModal}/>);
                                setPagoTrans(url);
                                setFactura(true); 
                                setEstadoTrans('Procesada');
                            }
                        }).catch(() =>{
                            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                        });
                    })
                    .catch(() =>{
                        OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>);
                    })
                })
            })
    }

    const AbrirValidar = (e) =>{
        e.preventDefault();
        if(!metodoPago){setSpnMetodo(true); setAltMetodo('Selecciona la forma del Pago'); return;}
        if(!file){setSpnFile(true); setAltFile('Por favor selecciona el comprobante'); return;}
        if(!file[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){setSpnFile(true); setAltFile('Archivo no válido, solo Imagen o PDF');return;}
        const size = Math.round((file[0].size / 1024));
        if(size >= 1024) {setSpnFile(true); setAltFile('El tamaño máximo del archivo es de 1mb'); return;}
        OpenModalNuevo(
            <ModalValidar 
                accion={SubirPago} 
                titulo={'Subir y Procesar'}
                mensaje={'¿Estás seguro de subir el comprobante y procesar esta transferencia?'}
                botonName={'Sí, Subir'}
                botonClass={'ButtonGreen'}
                CerrarModal={CerrarModal} 
            />
        )
    }
    
    return(
        <form onSubmit={AbrirValidar}>
            <h3>Comprobante Colcambios</h3>
            <div className="col-12 p-0">
                <SelectorSelec opciones={metodos} TipoOpcion={'Tipo de Pago*'} opcion={metodoPago} 
                    cambiarOpcion={setMetodoPago} spnRed={spnMetodo} setSpnRed={setSpnMetodo}/>
                {spnMetodo && <span className="spnAlert">{altMetodo}</span>}
            </div>
            <FileDocument archivosSelec={setFile} spnAltArchivo={spnFile} altArchivo={altFile}/>
            <div className="noSee"><progress value={progresFile} max="100"></progress></div>
            <button className="boton-general BtnLarge imgButton-left" type="submit">
                <img src={ImgOk} alt="Subir" />Subir Comprobante</button>
        </form>
    )
}

export default SubirFactura;