import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import NumberFormat from 'react-number-format';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import useObtenerTransacciones from '../../hooks/useObtenerTransacciones';
import ExportTransacciones from './ExportTransacciones';
import './../../../views/components/paginacion/Paginacion.css';
import '../../../styles/tablaCuentas.css';
import '../../../client/views/transferencias/transferencia.css';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import ImgExcel from '../../../img/icons/document-download.svg';
import ImgPdf from '../../../img/icons/document-text-1.svg';
import Search from '../../../img/icons/search.svg';
import SelectorSelec from '../../../views/components/inputs/Selector';

const Transacciones = () => {

	const [filtro, setFiltro] = useState(['Registrada', 'En Revisión', 'En Proceso']);
	const [newFiltro, setNewFiltro] = useState([]);

	//FILTROS POR NOMBRE Y ID
	const [textNam, setTexName] = useState('');
	const [textApe, setTextApe] = useState('');
	const [textId, setTextId] = useState('');

	const [spnTextNam, setSpnTextName] = useState(false);
	const [spnTextApe, setSpnTextApe] = useState(false);
	const [spnTextId, setSpnTextId] = useState(false);
	const [altTextNam, setAltTextName] = useState('');
	const [altTextApe, setAltTextApe] = useState('');
	const [altTextId, setAltTextId] = useState('');
	
	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [idSearch, setIdSearch] = useState('');

	const handleChange = (e) => {
        switch (e.target.name) {
            case 'cliente': setTexName(e.target.value);setSpnTextName(false);break;
			case 'apellido': setTextApe(e.target.value);setSpnTextApe(false);break;
			case 'clienteId': setTextId(e.target.value);setSpnTextId(false);break;
            default: break;
        }
    }

	const searchByNames = (e) => {
		if(!textNam){setSpnTextName(true);setAltTextName('Ingresa el Nombre');return;}
		if(!textApe){setSpnTextApe(true);setAltTextApe('Ingresa el Apellido');return;}
		else{setName(textNam);setSurname(textApe);}
	}
	const searchById = (e) => {
		if(!textId){setSpnTextId(true); setAltTextId('Ingresa el Id del usuario');
		}else{setIdSearch(textId);}
	}

	const [searchId, setSearchId] = useState(false);
	const [opcionSearch, setOpcionSearch] = useState('Nombres');
	const tipoSearc = [{ id: '1', texto: 'Nombres' },{ id: '2', texto: 'Id' }]
	const [spnSearch, setSpnSearch] = useState(false);
	useEffect(() =>{
		if(opcionSearch === 'Id'){
			setSearchId(true);setName('');setSurname('');setTexName('');setTextApe('');
		}else{
			setSearchId(false);setIdSearch('');setTextId('')}
		},[opcionSearch])

	//DATOS
	const [transacciones, loading] = useObtenerTransacciones(filtro,name,surname,idSearch);
	const [data, setData] = useState('');
	const formatearFecha = (fecha) => {return format(fromUnixTime(fecha), "dd/MMM/yy", { locale: es });}

	const [pages, setPages] = useState('');
    const [dataLimit, setDataLimit] = useState('');
    const [pageLimit, setPageLimit] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationGroup, setPaginationGroup] = useState([]);

	const Filtros = (e) => {
		let newArray = [...newFiltro, e.target.value];
		if (newFiltro.includes(e.target.value)) {
		  newArray = newArray.filter(text => text !== e.target.value);
		}
		setNewFiltro(newArray);
	};

	useEffect(()=>{
		if(newFiltro.length > 0){setFiltro(newFiltro)}
		else{setFiltro(['Registrada','En Revisión','En Proceso'])}
	},[newFiltro])

	//TRANSFORM DATA
	useEffect(()=>{
        if(transacciones){
            const temp = transacciones.map((transaccion)=>{
				let Destino
				let Tipo = transaccion.destinatario.tipo
				if(!Tipo){Tipo = 'Persona'}
				if(Tipo){
					if(Tipo === 'Empresa'){Destino = transaccion.destinatario.nombre}
					else{Destino = transaccion.destinatario.nombre + ' ' + transaccion.destinatario.apellido}
				}
                return [
					transaccion.id.substring(0, 6).toUpperCase(),
					transaccion.transferencia.metodo,
					formatearFecha(transaccion.fecha),
					transaccion.remitente.nombre + ' ' + transaccion.remitente.apellido,
					Tipo,
					Destino,
					transaccion.razon,
					transaccion.transferencia.emailCop,
					transaccion.transferencia.cargos,
					transaccion.transferencia.emailTasa,
					transaccion.transferencia.fee,
					transaccion.transferencia.emailAud,
					transaccion.estado,
					transaccion.cuenta.banco,
					transaccion.cuenta.bsb,
					transaccion.cuenta.numeroCuenta,
					transaccion.cuenta.titular,
					transaccion.colcambios.banco + ' ' + transaccion.colcambios.numero,
				]
            });
            setData(temp);
        }
    },[transacciones])

	//EXPORTAR A PDF
	const DownloadPDF = async() =>{
        const doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'tabloid'})
        doc.autoTable({
            head: [["Referencia","Método","Fecha","Remitente","Tipo Destinatario","Destinatario","Razón del envio","Pagado",
				"Cargos","Tasa","Servicio","Reciben","Estado","Banco Destino","BSB","Número de Cuenta","Titular","Cuenta Ingreso Colcambios"]],
            body: data
        })
        doc.save('Transferencias.pdf');
    }

	//PAGINACIÓN
	useEffect(()=>{
        if(transacciones.length <= 10){
			setDataLimit(transacciones.length);
        }else{
			setDataLimit(10);
		}
    },[transacciones])

    useEffect(() => {
        setPages(Math.ceil(transacciones.length / dataLimit));
        setPageLimit(4);
    },[transacciones, dataLimit])

	useEffect(() => {
        if(pageLimit){
            const grupoNumeros = () => {
                let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
                return new Array(pageLimit).fill().map((_, idx) => start + idx + 1 );
            };
            setPaginationGroup(grupoNumeros);
        }
    }, [pageLimit, currentPage]);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage]);

    const goToNextPage = () => {setCurrentPage((page) => page + 1);};
    const goToPreviousPage = () => {setCurrentPage((page) => page - 1);};
      
    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return transacciones.slice(startIndex, endIndex);
    };

	return (
		<>
			<Helmet><title>Transferencias</title></Helmet>
			<div className="TituloPag"><p>Transferencias</p></div>
			<div className="dashboardCard">
				<p className="CardTitulo">Transferencias</p>
				<div className="CheckBoxs">
					<div className="NewCheckbox">
						<input type="checkbox" id="Registrada" value="Registrada" onChange={Filtros}/>
              			<label className="custom-control-label" htmlFor="Registrada">Registradas</label>
					</div>
					<div className="NewCheckbox">
						<input type="checkbox" id="revision" value="En Revisión" onChange={Filtros} />
              			<label className="custom-control-label" htmlFor="revision">En Revisión</label>
					</div>
					<div className="NewCheckbox">
						<input type="checkbox" id="proceso" value="En Proceso" onChange={Filtros} />
              			<label className="custom-control-label" htmlFor="proceso">En Proceso</label>
					</div>
					<div className="NewCheckbox">
						<input type="checkbox" id="Procesada" value="Procesada" onChange={Filtros} />
              			<label className="custom-control-label" htmlFor="Procesada">Procesadas</label>
					</div>
					<div className="NewCheckbox">
						<input type="checkbox" id="Cancelada" value="Cancelada" onChange={Filtros} />
              			<label className="custom-control-label" htmlFor="Cancelada">Canceladas</label>
					</div>
				</div>
				<div className="SearchBox">
					<div className="row p-0">
						<div className="col-12 col-md-3"><div className="input-select">
							<SelectorSelec opciones={tipoSearc} TipoOpcion={'Buscar Por'} opcion={opcionSearch} 
								cambiarOpcion={setOpcionSearch} spnRed={spnSearch} setSpnRed={setSpnSearch} />
						</div></div>
						{!searchId ? <>
							<div className="col-12 col-sm-6 col-md-3"><div className={!spnTextNam ? 'input-general': 'input-general redLine'}>
								<input type='text' name='cliente' value={textNam} placeholder=" " onChange={handleChange} autoComplete="off"></input>
								<label>Nombre</label>
								{spnTextNam && <span className="spanAlert">{altTextNam}</span>}
							</div></div>
							<div className="col-12 col-sm-6 col-md-3"><div className={!spnTextApe ? 'input-general': 'input-general redLine'}>
								<input type='text' name='apellido' value={textApe} placeholder=" " onChange={handleChange} autoComplete="off"></input>
								<label>Apellido</label>
								{spnTextApe && <span className="spanAlert">{altTextApe}</span>}
							</div></div>
						</>:<>
							<div className='col-12 col-md-3'><div className={!spnTextId ? 'input-general': 'input-general redLine'}>
								<input type='text' name='clienteId' value={textId} placeholder=" " onChange={handleChange} autoComplete="off"></input>
								<label>Id</label>
								{spnTextId && <span className="spanAlert">{altTextId}</span>}
							</div></div>
						</>}
						<div className='col-12 col-md-3'>
							<button className='boton-general imgButton-left' type="button" onClick={!searchId ? searchByNames : searchById}>
								<img src={Search} alt="cancelar"/>Buscar</button>
						</div>
					</div>
				</div>
                {loading ? <div className="loadingContainer"><div className="lds-ripple"><div></div><div></div></div></div>
				: <>{transacciones.length > 0 ? 
						<>
							<div className="divTableContent">
								<div className="divTable-head">
									<div className="divTable-headRow" id="table9td">
										<div>Referencia</div>
										<div>Fecha</div>
										<div>Remitente</div>
										<div>Destinatario</div>
										<div className="tdsal">Tasa</div>
										<div className="tdsal">Pagado</div>
										<div className="tdsal">Reciben</div>
										<div>Estado</div>
									</div>
								</div>
							{getPaginatedData().map((transaccion) => {
								let divMetodo = 'Tradicional';
								let claseEstado = '';
								if(transaccion.transferencia.metodo !== 'Tradicional'){
									divMetodo = ('Referencia');
								}
								if(transaccion.estado === 'Registrada'){
									claseEstado = ('Registrada')
								}
								if(transaccion.estado === 'En Revisión'){
									claseEstado = ('Revision')
								}
								if(transaccion.estado === 'En Proceso'){
									claseEstado = ('Proceso')
								}
								if(transaccion.estado === 'Procesada'){
									claseEstado = ('Procesada')
								}
								if(transaccion.estado === 'Cancelada'){
									claseEstado = ('Cancelada')
								}
								return(
									<Link to={`/transferencia/${transaccion.id}`} className="divTable-body" key={transaccion.id}>
										<div className="divTable-row" id="table9td">
											<div className='divReferencia'>
												<label>Referencia</label>{transaccion.id.substring(0, 6).toUpperCase()}
												<div className={divMetodo}>{transaccion.transferencia.metodo}</div>
											</div>
											<div><label>Fecha</label>{formatearFecha(transaccion.fecha).toUpperCase()}</div>
											<div><label>Remitente</label>{transaccion.remitente.nombre + ' ' + transaccion.remitente.apellido}</div>
											<div><label>Destinatario</label>{transaccion.destinatario.nombre + ' ' + transaccion.destinatario.apellido}</div>
											<div className="tdNone tdsal"><label>Tasa</label>
												<NumberFormat value={transaccion.transferencia.tasa} displayType={'text'} thousandSeparator={'.'} 
													decimalSeparator={','} decimalScale={'2'} prefix={'$'} suffix={' COP'} />
											</div>
											<div className="tdsal"><label>Pagado</label>
												<NumberFormat value={transaccion.transferencia.totalPagado} displayType={'text'} thousandSeparator={'.'} 
													decimalSeparator={','} decimalScale={'2'} prefix={'$'} suffix={' COP'} />
												<p>{transaccion.colcambios.banco}</p>
											</div>
											
											<div className="tdsal"><label>Reciben</label>
												<NumberFormat value={transaccion.transferencia.montoAud} displayType={'text'} thousandSeparator={','} 
													decimalSeparator={'.'} decimalScale={'2'} prefix={'$'} suffix={' AUD'} />
												<p>{transaccion.cuenta.banco}</p>
											</div>
											<div><label>Estado</label>
												<span className={claseEstado}>&#9679;</span>{transaccion.estado}
											</div>
											
										</div>
									</Link>
								);
							})}
							</div>
							<div className="TableFooter">
								<div className="Btns-Export">
									<button className="BtnExport BtnExcel" onClick={()=> ExportTransacciones({data})}><img src={ImgExcel} alt="" />Exportar a Excel</button>
									<button className="BtnExport BtnPdf" onClick={DownloadPDF}><img src={ImgPdf} alt="" />Exportar a PDF</button>
								</div>

								<div className="paginacion">
									<button onClick={goToPreviousPage} className={`pagPrev ${currentPage === 1 ? 'pagDisabled' : ''}`}><img src={ImgPrev} alt="anterior" /></button>
										{paginationGroup ? paginationGroup.map((item, index) => (
											<button key={index} onClick={changePage} className={(item > pages ? 'pagBlock' : 'pagNumber') +' '+ (currentPage === item ? 'pagActive' : '')}>
												<span>{item}</span>
											</button>
										)) : <></>}
									<button onClick={goToNextPage} className={`pagNext ${currentPage === pages ? 'pagDisabled' : ''}`}><img src={ImgNext} alt="siguiente" /></button>
								</div>
							</div>
						</> :   
						<div className="itemTransferEmpty">
							<p>No se encontraron Transferencias</p>
						</div>
					}
				</> 
				}
			</div>
		</>		
	);
}

export default Transacciones;