import React from 'react';
import './modales.css';
import Close from '../../../img/icons/close-square.svg';

const ModalNotice = ({notice, setNotice}) => {

    const CerrarModal = (e) => {
        e.preventDefault();
        setNotice(false)
    }

    return (
        <>
            {notice &&
                <div className={notice ? 'modalNoticeAct' : 'modalNotice'} onClick={CerrarModal}>
                    <div className='modalNoticeCont'>
                        <div><img src={Close} alt="cerrar" onClick={CerrarModal}/></div>
                        <img src='https://www.colcambiosaustralia.com/assets/notice.png' alt="Importante" />
                    </div>
                </div>
            }
        </>
    );
}

export default ModalNotice;

