import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { useAuth } from '../../context/AuthContext';

const useObtenerDatos = (filtro,id) => {

    const {infoUser} = useAuth();
    const [transferencias, setTransferencias] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        if(infoUser.rol === 'Administrador'){
            const unsuscribe = db.collection('transferencias')
            .where('usuario','==', id)
            .where('estado', 'in', filtro)
            .orderBy('fecha','desc')
            .onSnapshot((snapshot) => {
                setLoading(false);
                setTransferencias(snapshot.docs.map((transaccion) => {
                    return {...transaccion.data(), id: transaccion.id}
                }));
            });
            return unsuscribe;
        }
    }, [infoUser,filtro,id]);

    return [transferencias, loading];
}

const useObtenerDestinos = (id) => {
    const {infoUser} = useAuth();
    const [destinos, setDestinos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        if(infoUser.rol === 'Administrador'){
            const unsuscribe = db.collection('contactos')
            .where('usuario','==', id)
            .where('activo','==', true)
            .onSnapshot((snapshot) => {
                setDestinos(snapshot.docs.map((contacto) => {
                    setLoading(false);
                    return {...contacto.data(), id: contacto.id}
                }));
            });
            return unsuscribe;
        }
    },[infoUser, id])

    return [destinos, loading];
}

export {useObtenerDatos, useObtenerDestinos};