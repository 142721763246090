import React from "react";
import { useTrans } from "../../../context/TransferContext";
import DivLoading from "../../../views/components/loading/Loading";
import ModalValidar from "../../../views/components/modales/MoldaValidar";
import { EstadoUltra } from "../../data/AdminRate";
import { HuboUnError,UltraRapidoActivo, UltraRapidoInactivo } from "../Mensajes";

const ConfigFees = ({OpenModalNuevo, CerrarModal}) =>{
    
    const {ultrarapido, setUltrarapido} = useTrans();

    const Activar = () =>{
        OpenModalNuevo(<DivLoading />)
        EstadoUltra({ultrarapido: false})
        .then(async()=>{
            OpenModalNuevo(<UltraRapidoActivo CerrarModal={CerrarModal} />)
            setUltrarapido(false)
        }).catch(()=>{
            OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>)
        })
    }

    const Desactivar = () =>{
        OpenModalNuevo(<DivLoading />)
        EstadoUltra({ultrarapido: true})
        .then(async()=>{
            OpenModalNuevo(<UltraRapidoInactivo CerrarModal={CerrarModal} />)
            setUltrarapido(true)
        }).catch(()=>{
           OpenModalNuevo(<HuboUnError CerrarModal={CerrarModal}/>)
        })
    }

    const AbrirActivar = (e) =>{
        e.preventDefault();
        OpenModalNuevo(
            <ModalValidar 
            accion={Activar} 
            titulo={'Activar UltraRápido'}
            mensaje={'¿Deseas activar el método UltraRápido?'}
            botonName={'Sí, Activar'}
            botonClass={'ButtonGreen'}
            CerrarModal={CerrarModal}
        />)
    }

    const AbrirDesactivar = (e) =>{
        e.preventDefault();
        OpenModalNuevo(
            <ModalValidar 
            accion={Desactivar} 
            titulo={'Desactivar UltraRápido'}
            mensaje={'¿Deseas desactivar el método UltraRápido?'}
            botonName={'Sí, Desactivar'}
            botonClass={'ButtonRed'}
            CerrarModal={CerrarModal}
        />)
    }

    return(
        <div className="dashboardCard">
            <p className="CardTitulo">Configuración Fees</p>
            <div className="tablaConfig">
                <div className="tablaConfigRow">
                    <div className="DesConfig">
                        <p>Método UltraRápido</p>
                    </div>
                    <div className="botonesConfig">
                        {ultrarapido ?
                        <button className='ConfigCheck' onClick={AbrirActivar}>Activar</button> :
                        <button className='ConfigCheck' onClick={AbrirDesactivar}>Desactivar</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigFees;