import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const Ayuda = () =>{
    return(
        <>
            <Helmet><title>Ayuda</title></Helmet>
            <div className="contGeneral">
                <h1 className="MainTitle TxtCenter">Preguntas Frecuentes</h1>
                <div style={{marginTop: '40px'}}>
                    <div className="Acordion">
                        <input type="checkbox" name="question1" id="question1"/>
                        <label htmlFor="question1">¿Cómo es el proceso?</label>
                        <div className="AcordionExpand">
                            <Link to="como-funciona" className="boton-text" style={{color: '#035af4'}}>Ver guía de transferencia</Link>
                        </div>
                    </div>
                    <div className="Acordion">
                        <input type="checkbox" name="question2" id="question2"/>
                        <label htmlFor="question2">¿Qué tipo de servicios ofrece Colcambios?</label>
                        <div className="AcordionExpand">
                            <b>Tradicional:</b> Una vez pagada la transferencia y enviado el comprobante, la transferencia
                            puede tardar hasta 24 horas.<br/>
                            <b>Ultrarapido:</b> Una vez pagada la transferencia y enviado el comprobante, la transferencia
                            se efectúa de inmediato.
                        </div>
                    </div>
                    <div className="Acordion">
                        <input type="checkbox" name="question3" id="question3"/>
                        <label htmlFor="question3">¿Cómo realizar el pago de la transferencia?</label>
                        <div className="AcordionExpand">
                            <p>Mediante transferencia bancaria o deposito en los siguientes bancos.</p>
                            <ul>
                                <li>Bancolombia.</li>
                                <li>Davivienda.</li>
                                <li>Colpatria.</li>
                                <li>Banco de Bogotá.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="Acordion">
                        <input type="checkbox" name="question4" id="question4"/>
                        <label htmlFor="question4">¿Cuánto dinero puedo transferir?</label>
                        <div className="AcordionExpand">
                            <p>Puedes enviar hasta un monto equivalente a $9.999 AUD diarios.
                            Para envíos superiores a $9.999 AUD diarios debes soportar la procedencia del dinero, para
                            esto tendrás que adjuntar los siguientes soportes traducidos por traductor oficial:</p>
                            <ul>
                                <li>Declaración juramentada de la procedencia de los fondos autenticada en notaria.</li>
                                <li>Extractos bancarios.</li>
                                <li>Certificado del banco con la procedencia del dinero.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="Acordion">
                        <input type="checkbox" name="question5" id="question5"/>
                        <label htmlFor="question5">¿Cuánto tiempo se demora en llegar mi dinero?</label>
                        <div className="AcordionExpand">
                            <p>Depende del servicios escogido, hasta 24 horas con el servicio Tradicional o depósito
                            inmediato con el servicio UltraRápido.</p>
                        </div>
                    </div>
                    <div className="Acordion">
                        <input type="checkbox" name="question6" id="question6"/>
                        <label htmlFor="question6">¿Porque debo suministrar mi información personal?</label>
                        <div className="AcordionExpand">
                            <p>Estamos obligados por ley a realizar estos controles y garantizarlos.
                            Para mantener seguro el servicio, utilizamos el pasaporte para confirmar la identidad del
                            remitente del dinero así como la identificación del destinatario.</p>
                        </div>
                    </div>
                    <div className="Acordion">
                        <input type="checkbox" name="question7" id="question7"/>
                        <label htmlFor="question7">¿Desde dónde puedo enviar dinero?</label>
                        <div className="AcordionExpand">
                            <p>Desde cualquier cuidad en Colombia.</p>
                        </div>
                    </div>
                    <div className="Acordion">
                        <input type="checkbox" name="question8" id="question8"/>
                        <label htmlFor="question8">¿A dónde puedo enviar dinero?</label>
                        <div className="AcordionExpand">
                            <p>A cualquier cuidad en Australia.</p>
                        </div>
                    </div>
                    <div className="Acordion">
                        <input type="checkbox" name="question9" id="question9"/>
                        <label htmlFor="question9">¿Por qué elegir Colcambios?</label>
                        <div className="AcordionExpand">
                            <p>Somos la primera compañía registrada en Australia enfocada en la transferencia de dinero
                            desde Colombia a Australia. (COP/AUD).<br/>
                            Tenemos acuerdos comerciales para Agencias de estudios y agencia de viaje. Adicional
                            tenemos agentes en diferentes ciudades para la atención de nuestros clientes en cualquier
                            momento.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ayuda;