import React, {useState, useEffect} from 'react';
import { useObtenerPromo } from '../../../hooks/useObternerDatos';
import Check from '../../../../img/icons/tick-square.svg';

const BuscarPromo = (props) =>{

    const {
        usuario,
        setCodigoPromo,
        setTipoCode,
        setDescuento,
        setAgenciaId,
        setUserCod
    } = props;

    const [code, setCode] = useState('');
    const [codigoId, setCodigoId] = useState('');
    const [codigo, find, setFind, userCod] = useObtenerPromo(codigoId, usuario);
    
    useEffect(()=>{
        if(userCod){setUserCod(userCod)}
    },[userCod, setUserCod])
    
    const [warn, setWarn] = useState(false);
    const [checkCode, setCheckCode] = useState(false);
    const [spnCodigo, setSpnCodigo] = useState(false);
    const [spnCodigo1, setSpnCodigo1] = useState(false);
    const [altCodigo, setAltCodigo] = useState('');
    const [altCodigo1, setAltCodigo1] = useState('');
    
    // const idTransform = (nombre) =>{ const id = nombre.replace(/\s+/g, '').toLocaleLowerCase(); return id;}
    
    const handlePromo = (e) =>{
        setCode(e.target.value);
        setCodigoId('')
        setWarn(false) 
        setSpnCodigo1(false); 
        setSpnCodigo(false);
        setCheckCode(false);
        setFind('inicial');
        setCodigoPromo('');
        setTipoCode('');
        setDescuento('');
        setAgenciaId(''); 
    }

    useEffect(()=>{
        if(codigo){
            setSpnCodigo(false);setWarn(false);setCheckCode(true);
            setCodigoPromo(codigo.id);
            setTipoCode(codigo.data().tipo);
            setDescuento(codigo.data().descuento);
            setAgenciaId(codigo.data().agenciaId);
        }else{
            setCodigoPromo('');setTipoCode('');setDescuento('');setAgenciaId('');
        }
    },[codigo, setCodigoPromo, setTipoCode, setDescuento, setAgenciaId])

    useEffect(()=>{
        if(find === 'inicial'){
            setSpnCodigo(false);setAltCodigo('')
        }if(find === 'noDisponible'){
            setSpnCodigo(true);setWarn(true);setAltCodigo('Código no válido')
        }if(find === 'usado'){
            setSpnCodigo(true);setWarn(true);setAltCodigo('Código Usado')
        }if(find === 'noExiste'){
            setSpnCodigo(true);setWarn(true);setAltCodigo('Código no válido')
        }
    },[find])

    const buscarCodigo = (e) =>{
        e.preventDefault();
        if(!code){setSpnCodigo1(true);setWarn(true);setAltCodigo1('Ingresa el código');return}
        setCodigoId(code);
    }

    const eliminarCodigo = (e) =>{
        e.preventDefault();
        setCodigoId('');
        setCode('');
        setFind('inicial');
        setCheckCode(false);
        setCodigoPromo('');
        setTipoCode('');
        setDescuento('');
        setAgenciaId(''); 
    }
    
    return(
        <div className='inputButtonDiv'>
            <div className={'inputButton '+ (!warn ? '': 'redLine')}>
                <input name="codigo" type="text" value={code} placeholder=" " onChange={handlePromo} autoComplete="off"/>
                <label>Código Promocional</label>
                {checkCode && <div className='checkCode'><img src={Check} alt="" /></div>}
                {spnCodigo1 && <span className="spnAlert">{altCodigo1}</span>}
                {spnCodigo && <span className="spnAlert">{altCodigo}</span>}
            </div>
            <button onClick={!checkCode ? buscarCodigo : eliminarCodigo } type="button">{!checkCode ? 'Aplicar':'Remover'}</button>
        </div>
    )
}

export default BuscarPromo;