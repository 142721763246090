import { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';

const useObtenerTestimonios = () => {

    const [testimonios, setTestimonios] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() =>{
        const unsuscribe = db.collection('testimonios')
        .where('estado', '==', 'publicado')
        .orderBy('fecha','desc')
        .limit(4)
        .onSnapshot((snapshot) => {
            setTestimonios(snapshot.docs.map((testimonio) => {
                setLoading(false);
                return {...testimonio.data(), id: testimonio.id}
            }));
        });
        return unsuscribe;
    },[]);
    return [testimonios, loading];
}

export default useObtenerTestimonios;